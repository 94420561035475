import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Image from 'Components/Elements/Image'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const VendorCartScreen = () => {
    const navigate = useNavigate()
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    Cart
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'} >
            <BreadCrumb
                first={"Vendors"}
                url={'/vendor'}
                second={"Cart"}
            />

            <div className='grid lg:grid-cols-3 gap-2.5'>
                <RoundedCard className={"lg:col-span-2 flex flex-col gap-6"}>
                    <p className='text-base text-black'>
                        Cart
                    </p>
                    <div className='flex flex-col gap-2.5'>
                        {Array(4).fill().map((_, index) => (
                            <div key={index}
                                className='flex flex-row p-2 border border-gray-5 rounded-lg justify-between items-center min-w-max gap-4'>
                                <div className='flex flex-row  items-center gap-3'>
                                    <Image
                                        customLoaderRounded={"rounded"}
                                        customLoaderHeight={" lg:h-[86px]"}
                                        customLoaderWidth={" lg:w-[120px]"}
                                        className={"object-contain w-[64px] h-full  lg:h-[86px] lg:w-[120px] rounded"}
                                        src={"/images/Eggs.png"} />

                                    <div className="flex flex-col gap-1.5 lg:gap-2.5">
                                        <p className='font-semibold text-gray-1 text-xs lg:text-sm'>
                                            Coca Cola(case)
                                        </p>
                                        <span className=' text-xs lg:text-sm text-gray-2'>
                                            Price {" - "}
                                            <span className='font-bold text-gray-1'>
                                                $45.00
                                            </span>
                                        </span>

                                        <span className='text-xs lg:text-sm text-gray-2'>
                                            GST{" - "}
                                            <span className='font-bold text-gray-1'>
                                                NA
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='flex flex-row  gap-2.5 lg:gap-5 justify-end items-stretch'>
                                    <button className='border border-gray-1 p-1 rounded  hover:scale-105 ease-in-out duration-300'>
                                        <svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.625" width="14" height="6" rx="1" fill="#333333" />
                                        </svg>


                                    </button>
                                    <div className='text-[#333333] font-bold text-xs lg:text-sm flex items-center'>1</div>
                                    <button className='border border-gray-1 p-1  rounded hover:scale-105 ease-in-out duration-300'>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C6.44772 0 6 0.447716 6 1V6H1C0.447715 6 0 6.44772 0 7C0 7.55228 0.447716 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H8V1C8 0.447715 7.55228 0 7 0Z" fill="#333333" />
                                        </svg>

                                    </button>
                                </div>

                            </div>
                        ))}

                    </div>
                    <div className='flex items-center justify-center'>
                        <Button
                            customPadding={"py-1 px-2"}
                            title={"Add more products"}
                            className={"bg-btn-theme bg-clip-text text-transparent border border-[#F33F41] font-normal text-sm !hover:text-transparent !hover:bg-clip-text "}
                        />

                    </div>

                </RoundedCard>

                <RoundedCard className={"flex flex-col gap-10  h-max  lg:col-span-1 "}>
                    <h3 className='text-base'>Payment details</h3>

                    <div className='flex flex-col gap-10'>
                        <div className='flex flex-col gap-2.5'>
                            {Array(4).fill().map((_, index) => (
                                <div key={index} className="flex flex-row justify-between items-center text-sm">
                                    <p className='text-[#686868] '>Coca cola(case)</p>
                                    <p className='text-black font-semibold'>$45.00</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-row justify-between items-center text-sm">
                            <p className='text-[#686868] '>GST(5%)</p>
                            <p className='text-black font-semibold'>$5.00</p>
                        </div>
                    </div>

                    <div className='border-t pt-3.5'>
                        <div className="flex flex-row justify-between items-center text-sm">
                            <p className='text-[#686868] '>GST(5%)</p>
                            <p className='text-black font-semibold'>$5.00</p>
                        </div>

                        <div className='flex items-center justify-center py-8'>
                            <Button
                                onClick={() => { setOpenSuccessPopup(true) }}
                                customPadding={"p-2"}
                                title={"Confirm order"}
                            />

                        </div>

                    </div>



                </RoundedCard>

            </div>

            <SuccessPopup
                open={openSuccessPopup}
                buttonTitle={"Close"}
                title={"Order successful"}
                onClick={() => { setOpenSuccessPopup(false) }}
            />

        </SellerLayout>
    )
}
