import React, { useRef } from 'react'
import Button from 'Components/Elements/Button';
import Topbar from 'Components/Topbar';
import Svgs from 'Assets/svgs';
import Service from './Elements/ServiceCard';
import CustomerReviewCard from './Elements/CustomerReviewCard';
import Footer from 'Components/Footer';

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Scrollbar } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import DashboardAccess from '../Help&Support/Tabs/DashboardAccess';


const data = [
    { imageSrc: "/images/ServiceImg1.png", name: "Listings", type: "A free app that allows you to manage your product listing directly from your smartphone." },
    { imageSrc: "/images/ServiceImg2.png", name: "Online Ordering", type: "Start taking orders online from millions of users near you and deliver with MR Corporation." },
    { imageSrc: "/images/ServiceImg3.png", name: "Advertise", type: "For every marketing dollar spent, MR Corporation returns over 5x the investment." },
    { imageSrc: "/images/ServiceImg1.png", name: "Listings", type: "A free app that allows you to manage your product listing directly from your smartphone." },
    { imageSrc: "/images/ServiceImg2.png", name: "Online Ordering", type: "Start taking orders online from millions of users near you and deliver with MR Corporation." },
    { imageSrc: "/images/ServiceImg3.png", name: "Advertise", type: "For every marketing dollar spent, MR Corporation returns over 5x the investment." },
]

const ReviewData = [
    { name: "Sophia Moore", message: "I have been using MR Grocery App for food delivery for the past few months and I am so impressed! The delivery times are always quick and the food is amazing. The website is easy to navigate and it's easy to get the food I want.", jobDescription: "Marketing Manager of Manik Grocery Store" },
    { name: "Adam Smith", message: "I have been using MR Grocery App for food delivery for the past few months and I am so impressed! The delivery times are always quick and the food is amazing. The website is easy to navigate and it's easy to get the food I want.", jobDescription: "Business Manager of Hopmart" },
    { name: "Saint Leineker", message: "I have been using MR Grocery App for food delivery for the past few months and I am so impressed! The delivery times are always quick and the food is amazing. The website is easy to navigate and it's easy to get the food I want.", jobDescription: "Senior Manager of Amrit Sweets" },
]

const SellerLandingPage = () => {
    const swiperRef = useRef(null);

    return (
        <>
            <Topbar hideAddress={true} />
            {/* Banner Section */}
            {/* bg-[#f9eeee]  */}
            <div className='grid xl:grid-cols-2 grid-cols-1'>
                <div
                    className="relative bg-white  xl:h-screen min-h-[60vh] w-full flex items-center justify-center p-10"
                    style={{
                        backgroundImage: `url('/images/LandingBannerImg.png'), url('/images/LandingBannerImg1.png')`,
                        backgroundRepeat: 'no-repeat, no-repeat',
                        backgroundPosition: 'top left -200px, bottom right -200px',
                        backgroundSize: 'contain, contain',
                    }}
                >
                    {/* <div className='absolute bg-[#f9eeee]   w-full h-full'>
                        <img src="/images/LandingBannerImg.png" alt="" className='object-cover absolute w-fit h-full top-0 -left-[30%]' />
                        <img src="/images/LandingBannerImg1.png" alt="" className='object-cover absolute w-full h-full overflow-hidden -right-[35%] ' />

                    </div> */}

                    <div className="">
                        <h2 className="lg:text-[49px] lg:!leading-[58px] md:text-4xl xs:text-2xl text-xl font-bold ">
                            Become A Seller and Grow Your Business With MR Corporation
                        </h2>
                        <div className='mt-8 flex gap-6 items-start lg:items-center flex-col lg:flex-row'>
                            <Button customPadding="!px-4 py-2" className={'rounded-lg'}>
                                <div className='text-sm'>
                                    Go to Dashboard
                                </div>
                            </Button>
                            <Button customPadding="!px-4 py-2" customThemeBtn={'bg-transparent border font-bold border-[#969696]'} className={'rounded-lg'}>
                                <div className='text-sm'>
                                    Become A Seller
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='xl:block hidden'
                    style={{
                        backgroundImage: `url('/images/landing-banner2.png')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                ></div>
            </div>

            <div className='bg-gradient-to-b from-[#FB6D72] to-[#F33F41] rounded-md text-white mx-auto my-16 w-[90%] py-8'>
                <div className='w-[90%] mx-auto flex lg:flex-row flex-col justify-between items-center gap-4 '>
                    <div className='flex flex-col space-y-4'>
                        <h1 className='font-bold text-2xl'>Get 40% OFF On Your First Order</h1>
                        <h1 className='text-lg font-semibold'>Signup on the app and get 40% off on your first grocery or restaurant order. Download the app new by clicking on the button below.</h1>
                        <span className='bg-white text-black text-xs px-3 py-2 rounded-md font-bold w-fit'>Download The App</span>
                    </div>
                    <div className='relative px-4'>
                        <img className='w-full h-full object-cover' src='/images/GetOffImg.png' alt='' />
                        <div className='absolute sm:-left-6 -left-3 -bottom-6'>
                            <div className='relative'>
                                <img className='w-full max-h-[100px] max-w-[100px] h-full object-cover' src='/images/Star 2.png' alt='' />
                                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-bold text-sm text-black line-height-19px'>
                                    40% OFF
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-16 bg-[#f7f7f7]">
                <div className='mx-auto py-12 w-[90%] flex flex-col space-y-8'>
                    <div className='flex sm:flex-row flex-col sm:gap-2 gap-1 justify-between'>
                        <h1 className="font-bold sm:text-2xl text-xl">Our Services</h1>
                        <div className='flex items-center justify-end gap-2 cursor-pointer'>
                            <div className='p-0.5' onClick={() => {
                                swiperRef.current?.swiper.slidePrev();
                            }}>
                                <Svgs.FillArrowLeft />
                            </div>
                            <div className='p-0.5' onClick={() => {
                                swiperRef.current?.swiper.slideNext();
                            }}>
                                <Svgs.FillArrowRight />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Swiper
                            ref={swiperRef}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            spaceBetween={20}
                            modules={[Pagination, Scrollbar, Autoplay]}
                            breakpoints={{
                                1024: {
                                    slidesPerView: 3,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                480: {
                                    slidesPerView: 1,
                                },
                            }}
                            className='swiperHomeCard'
                        >
                            {data?.map((itm, index) => (
                                <SwiperSlide key={index}>
                                    <Service
                                        imageSrc={itm?.imageSrc}
                                        name={itm?.name}
                                        rating={itm?.rating}
                                        type={itm?.type}
                                        distance={itm?.name}
                                        deliveryTime={itm?.deliveryTime}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>

            <div className='my-16'>
                <div className="grid xl:grid-cols-2 grid-cols-1 gap-4 justify-center w-[90%] mx-auto items-center">
                    <div className='px-12 justify-start '
                        style={{
                            backgroundImage: `url('/images/sellerbg1.png')`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                        }}>
                        <img src='/images/LandingOrder.png' className='bg-cover w-full h-full' alt='' />
                    </div>
                    <div className='flex flex-col space-y-8 justify-between lg:pt-10 pt-0'>
                        <div className='flex flex-col space-y-0.5 px-4'>
                            <h1 className='font-bold text-2xl'>How It works?</h1>
                            <p className='text-lg font-semibold'>Get coverage on everything in just 3 easy steps.</p>
                        </div>
                        <div className='flex-flex-col'>
                            <div className='cursor-pointer p-4 flex flex-col space-y-2 hover:bg-white hover:shadow-lg hover:rounded-md'>
                                <h1 className='text-[#CE221E] text-lg font-semibold'>1. Create your page on mR Seller Website</h1>
                                <p className='text-sm'>Easily set up your seller page on our website to showcase your products and reach a wider audience effortlessly.</p>
                            </div>
                            <div className='cursor-pointer p-4 flex flex-col space-y-2 hover:bg-white hover:shadow-lg hover:rounded-md '>
                                <h1 className='text-[#CE221E] text-lg font-semibold'>2. Upload your products and advertise them</h1>
                                <p className='text-sm'>Effortlessly showcase and promote your products by uploading them to our website, reaching a wider audience and maximizing your sales potential.</p>
                            </div>
                            <div className='cursor-pointer p-4 flex flex-col space-y-2 hover:bg-white hover:shadow-lg hover:rounded-md'>
                                <h1 className='text-[#CE221E] text-lg font-semibold'>3. Start receiving online orders through our platform</h1>
                                <p className='text-sm'>Start receiving online orders effortlessly through our platform, streamlining your business operations and expanding your customer base with ease.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-16 bg-[#fcf1f0]">
                <div className='mx-auto py-12 w-[90%] flex flex-col space-y-8'>
                    <div className='flex sm:flex-row flex-col sm:gap-2 gap-1 justify-between'>
                        <h1 className="font-bold sm:text-2xl text-xl">Customer Reviews</h1>
                    </div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 justify-between'>
                        {ReviewData?.map((itm, index) => (
                            <CustomerReviewCard
                                name={itm?.name}
                                message={itm?.message}
                                rating={itm?.rating}
                                jobDescription={itm?.jobDescription}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className='my-16'>
                <div className="grid xl:grid-cols-2 grid-cols-1 gap-4 justify-center w-[90%] mx-auto items-center">
                    <div className='flex flex-col space-y-8 justify-between'>
                        <div className='flex flex-col space-y-0.5 px-4'>
                            <h1 className='font-bold text-2xl'>What do you get on sign up?</h1>
                            <p className='text-lg font-semibold'>Get coverage on everything in just 3 easy steps.</p>
                        </div>
                        <div className='flex-flex-col'>
                            <div className='cursor-pointer p-4 flex flex-col space-y-2 bg-white shadow-lg rounded-md'>
                                <h1 className='text-[#CE221E] text-lg font-semibold'>1. Seller App</h1>
                                <p className='text-sm'>Gain full control and manage your business on-the-go with our seller partner app, empowering you with real-time insights and seamless order management.</p>
                            </div>
                            <div className='cursor-pointer p-4 flex flex-col space-y-2 hover:bg-white hover:rounded-md'>
                                <h1 className='text-[#CE221E] text-lg font-semibold'>2. Seller Web Dashboard</h1>
                                <p className='text-sm'>Gain full control and insights into your sales performance with our intuitive seller partner web dashboard, empowering you to optimize and grow your business effortlessly.</p>
                            </div>
                            <div className='cursor-pointer p-4 flex flex-col space-y-2 hover:bg-white hover:rounded-md'>
                                <h1 className='text-[#CE221E] text-lg font-semibold'>3. Various Promotion and Ad Tools within the corporation</h1>
                                <p className='text-sm'>Unlock a suite of powerful promotion and advertising tools within our corporation, empowering sellers to boost visibility and drive sales effectively.</p>
                            </div>
                        </div>
                    </div>
                    <div className='px-12 pt-12 justify-start '
                        style={{
                            backgroundImage: `url('/images/sellerbg1.png')`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                        }}>
                        <img src='/images/SellerFrontImg1.png' className='bg-cover w-full h-full' alt='' />
                    </div>
                </div>
            </div>


            <div className='bg-[#f7f7f7] my-16'>
                <div className='flex flex-col space-y-8 w-[90%] mx-auto py-16'>
                    <h1 className='font-bold text-2xl'>Advertise Your Business With MR Corporation- <span className='font-normal'>Affordable promotion in Canada!</span></h1>
                    <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4'>
                        <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1 bg-white'>
                            <Svgs.GalleryIcon />
                            <h1 className='text-lg font-semibold'>Banner Ad</h1>
                            <h1>Elevate your brand with MR Corporation: Where Quality Meets Success.</h1>
                            <p className='text-sm font-bold text-primary'>Learn More</p>
                        </div>
                        <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1 bg-white'>
                            <Svgs.VideoIcon />
                            <h1 className='text-lg font-semibold'>Video Ad</h1>
                            <h1>Lights, Camera, Business! Let MR Corporation showcase your brand to the world.</h1>
                            <p className='text-sm font-bold text-primary'>Learn More</p>
                        </div>
                        <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1 bg-white'>
                            <Svgs.VisitingCardIcon />
                            <h1 className='text-lg font-semibold'>Visiting Cards</h1>
                            <h1>Unlock your business potential with MR Corporation: Your Success Partner.</h1>
                            <p className='text-sm font-bold text-primary'>Learn More</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='bg-gradient-to-b from-[#FB6D72] to-[#F33F41] rounded-md text-white mx-auto my-16 w-[90%] py-8'>
                <div className='w-[90%] mx-auto grid lg:grid-cols-10 grid-cols-1 justify-between items-center gap-8 '>
                    <div className='col-span-6 flex flex-col space-y-4'>
                        <h1 className='font-bold text-2xl'>Become A Seller</h1>
                        <h1 className='text-lg'>Ready to expand your business reach? Join our platform as a seller today! With seamless onboarding and dedicated support, start showcasing your products to a broader audience and boost your sales.</h1>
                        <div className='pt-10'>
                            <span className='bg-white text-black text-xs px-3 py-2 rounded-md font-bold w-fit'>Click Here</span>
                        </div>
                    </div>
                    <div className='col-span-4 xl:px-4 justify-center'>
                        <img className='w-full h-full object-cover' src='/images/sellerFrontImg2.png' alt='' />
                    </div>
                </div>
            </div>
            <div className='my-16 flex flex-col space-y-8 md:w-[85%] w-[90%] mx-auto '>
                <h1 className='font-bold text-2xl'>Frequently Asked Questions</h1>
                <DashboardAccess className={"border border-[#EEEEEE] px-4 rounded-md mb-2"} />
            </div>

            <div className='mt-16 w-[85%] mx-auto sm:block hidden'>
                <div
                    className="flex items-center justify-center"
                    style={{
                        backgroundImage: `url('/images/DownloadAppBg.png')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "bottom",
                        backgroundSize: "contain",
                    }}
                >
                    <div className="relative grid xs:grid-cols-2 grid-cols-1 gap-4 justify-center items-center">
                        <img src='/images/LandingOrder.png' className='bg-cover w-full h-full' alt='' />
                        <div>
                            <div className='absolute xl:bottom-4 md:bottom-2 bottom-0'>
                                <h1 className='xl:text-[49px] lg:text-[36px] text-[24px] font-bold xs:text-white pb-6'>Download Our App</h1>
                                <div className="flex items-center gap-4 lg:pb-8">
                                    <div className='border border-[#A6A6A6] p-2 w-fit min-w-[130px] text-white flex gap-2 h-fit rounded-lg bg-black'>
                                        <Svgs.AppleStore />
                                        <div className='flex flex-col'>
                                            <p className='text-[9px] font-bold'>Download on the</p>
                                            <h1 className='text-[12px] font-bold'>App Store</h1>
                                        </div>
                                    </div>
                                    <div className='border border-[#A6A6A6] p-2 w-fit min-w-[130px] text-white flex gap-2 h-fit rounded-lg bg-black'>
                                        <Svgs.GooglePlay />
                                        <div className='flex flex-col'>
                                            <p className='text-[9px] font-bold'>GET IT ON</p>
                                            <h1 className='text-[12px] font-bold'>Google Play</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gradient-to-b from-[#FB6D72] to-[#F33F41] rounded-md text-white mx-auto mt-16 w-[90%] p-4 sm:hidden block'>
                <h1 className='xl:text-[49px] lg:text-[36px] text-[24px] font-bold xs:text-white pb-6'>Download Our App</h1>
                <div className="flex items-center gap-2">
                    <div className='border border-[#A6A6A6] p-2 w-fit max-w-[130px] text-white flex gap-1 h-fit rounded-lg bg-black'>
                        <Svgs.AppleStore />
                        <div className='flex flex-col'>
                            <p className='text-[9px] font-bold'>Download on the</p>
                            <h1 className='text-[12px] font-bold'>App Store</h1>
                        </div>
                    </div>
                    <div className='border border-[#A6A6A6] p-2 w-fit max-w-[130px] text-white flex gap-1 h-fit rounded-lg bg-black'>
                        <Svgs.GooglePlay />
                        <div className='flex flex-col'>
                            <p className='text-[9px] font-bold'>GET IT ON</p>
                            <h1 className='text-[12px] font-bold'>Google Play</h1>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default SellerLandingPage