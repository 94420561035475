import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PremiumExpenseTable from 'Utility/Tables/PremiumExpenseTable'
import { VendorRecentOrderItemList } from 'Utility/Tables/VendorRecentOrderItemList'

export const RecentOrderDetail = () => {
    const navigate = useNavigate()
    const { state } = useLocation()

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    Order details
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'} >

            <BreadCrumb
                first={"Vendors"}
                url={'/vendor'}
                second={"Recent orders"}
                secondurl={"/vendor/recent-orders"}
                third={"Order details"}
            />


            <RoundedCard className={"flex flex-col "}>
                <div className='flex flex-col gap-3.5'>
                    <h2>Amrit foods</h2>
                    <div className='flex lg:flex-row flex-col gap-4  justify-between w-full'>

                        <div className="flex flex-col gap-2 items-start text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Address:{" "}</span>
                                <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Contact:{" "}</span>
                                <span>+ 653 436 7229</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Email id:{" "}</span>
                                <span>amritfoods@gmail.com</span>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 items-start lg:items-end text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Order ID:{" "}</span>
                                <span>#234516</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Date and Time:{" "}</span>
                                <span>Mar 12, 2024, 09:10 AM</span>
                            </div>
                            <div>
                                {state?.status === "completed" ?
                                    <span className='pending px-2.5 py-1 rounded'>
                                        Invoice accepted by you
                                    </span>
                                    :
                                    <span className='packed px-2.5 py-1 rounded'>
                                        Order accepted by vendor
                                    </span>
                                }
                            </div>
                        </div>
                    </div>



                </div>


            </RoundedCard>


            <div className='lg:grid lg:grid-cols-3 flex flex-col gap-2.5'>
                <RoundedCard className={"lg:col-span-2"}>
                    <h3 className='text-base'>Item List</h3>
                    <div className='relative  '>
                        <VendorRecentOrderItemList />
                    </div>
                </RoundedCard>

                <RoundedCard className={"lg:col-span-1 gap-4 flex flex-col h -max"}>
                    <h3 className='text-base'>
                        Bill Detail
                    </h3>

                    <div className='flex flex-col gap-2 border-b-2 pb-2 text-sm text-[#686868]'>
                        <div className='flex flex-row justify-between items-center'>
                            <span>Total Amount</span>
                            <span className='text-black'>$100.00</span>
                        </div>
                        <div className='flex flex-row justify-between items-center'>
                            <span>GST(5%)</span>
                            <span className='text-black'>$5.00</span>
                        </div>
                        <div className='flex flex-row justify-between items-center'>
                            <span>Total bill</span>
                            <span className='text-black'>$105.00</span>
                        </div>

                    </div>
                    <div className='flex flex-row justify-between items-center text-sm text-[#F33F41] '>
                        <span className=''>Payment Due</span>
                        <span>$210.00</span>
                    </div>

                    <div className='flex items-center justify-center'>
                        {state?.status === "completed" ?
                            <Button
                                onClick={() => navigate("/vendor/invoice-details")}
                                customThemeBtn={"theme-btn2"}
                                className='text-[#686868] font-bold'
                                customPadding={"px-2.5 py-2"}
                                title={"View Invoice"}
                            />
                            :
                            <div className='text-[#686868] font-bold'>Invoice is not available</div>
                        }
                    </div>

                </RoundedCard>

            </div>

            <RoundedCard className={"flex flex-col gap-5"}>
                <h3 className='text-base'>Order activity</h3>
                <div className='flex flex-col gap-4 text-sm'>
                    {state?.status === "completed" &&
                        <>
                            <div className="flex flex-row items-center gap-2.5">
                                <Svgs.OrderActivityIcon color={"#00B037"} />
                                <div className='flex flex-col lg:flex-row  justify-between gap-1'>
                                    <p>Invoice accepted by you</p>
                                    <p className='text-[#949494]'>Mar 15, 2024, 11:10 AM</p>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center gap-2.5">
                                <Svgs.OrderActivityIcon color={"#983FF1"} />
                                <div className='flex flex-row items-center gap-2.5'>
                                    <p>Order delivery date:15 mar, 2024</p>
                                </div>
                            </div>
                        </>
                    }
                    <div className='flex flex-row  gap-2.5 items-center '>
                        <Svgs.OrderActivityIcon color={"#FF8C2F"} />
                        <div className='flex flex-col lg:flex-row   '>
                            <p className=''>Invoice uploaded by vendor</p>
                            <div className='text-[#949494] '>
                                Mar 13, 2024, 09:12 AM
                            </div>
                        </div>

                    </div>

                    <div className='flex flex-row gap-2.5 items-center '>
                        <Svgs.OrderActivityIcon />
                        <div className='flex flex-col lg:flex-row  '>
                            <p className=''>Order #2423 accepted by vendor</p>
                            <div className='text-[#949494]'>
                                Mar 12, 2024, 09:08 AM
                            </div>
                        </div>

                    </div>
                </div>
            </RoundedCard>

        </SellerLayout >
    )
}
