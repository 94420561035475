import React from 'react'
import Svgs from 'Assets/svgs'
import Dropdown3 from 'Components/Elements/Dropdown3'
import RoundedCard from 'Components/Elements/RoundedCard'
import DashboardTopProductsTable from 'Utility/Tables/DashboardTopProductsTable'
import SellerLayout from 'Components/Layouts/SellerLayout'
import Button from 'Components/Elements/Button'
import DashboardCard from 'Pages/Seller/Dashboard/Elements/DashboardCard'
import LineChatCurve from 'Pages/Seller/Dashboard/Elements/LineChatCurve'
import PremiumInventoryProductTable from 'Utility/Tables/PremiumInventoryProductTable'
import PremiumInventoryVendorTable from 'Utility/Tables/PremiumInventoryVendorTable'
import PremiumInventoryExpenseTable from 'Utility/Tables/PremiumInventoryExpenseTable'

function InventoryPremiumScreen() {
    return (
        <SellerLayout active={'Inventory'}
            title={
                <>
                    <div className='flex flex-row gap-2 items-center'>
                        Inventory
                        <Svgs.PremiumIcon />
                    </div>
                </>
            }
            bodyClassName={'flex flex-col space-y-4'}>
            <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 lg:mt-0 mt-2'>
                <DashboardCard
                    title={'Total Profit'}
                    value={'$53k'}
                    percentage={`4.02%`}
                    description={'higher than last month'}
                    icon={<Svgs.TotalRevenueSvg />}
                />
                <DashboardCard
                    title={'Total Products'}
                    value={'83'}
                    percentage={`0%`}
                    description={'higher than last month'}
                    icon={<Svgs.TotalProductsSvg />}
                />
                <DashboardCard
                    title={'Total Employees'}
                    value={'12'}
                    percentage={`2.30%`}
                    description={'more than last month'}
                    icon={<Svgs.EmployeeIcon />}
                />
                <DashboardCard
                    title={'Expenses'}
                    value={'$18k'}
                    percentage={`6.72%`}
                    description={'higher than last month'}
                    icon={<Svgs.ActiveCustomersSvg />}
                />
            </div>

            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                <div className='flex flex-col gap-2.5'>
                    <RoundedCard>
                        <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                            <h1 className='text-lg font-semibold'>Product Details</h1>
                            <div className='flex gap-2 justify-between items-center'>
                                <Dropdown3
                                    title={''}
                                    required={false}
                                    placeholder={'This month'}
                                    option={[
                                        { value: "This week", label: "This week" },
                                        { value: "", label: "Today" }
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="relative">
                            <PremiumInventoryProductTable />
                        </div>
                    </RoundedCard>
                    <RoundedCard>
                        <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                            <h1 className='text-lg font-semibold'>Vendors</h1>
                            <div className='flex gap-2 justify-between items-center'>
                                <Dropdown3
                                    title={''}
                                    required={false}
                                    placeholder={'All categories'}
                                    option={[
                                        { value: "", label: "Wholesaler" },
                                        { value: "", label: "Retailer" }
                                    ]}
                                />
                                <button className='min-w-max border rounded-lg text-xs px-2 py-1'>
                                    View all
                                </button>
                            </div>
                        </div>
                        <div className="relative">
                            <PremiumInventoryVendorTable />
                        </div>
                    </RoundedCard>
                    <RoundedCard>
                        <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                            <h1 className='text-lg font-semibold'>Top Products</h1>
                            <div className='flex gap-2 justify-between items-center'>
                                <Dropdown3
                                    title={''}
                                    required={false}
                                    placeholder={'Last 1 Month'}
                                />
                                <Dropdown3
                                    title={''}
                                    required={false}
                                    placeholder={'All Categories'}
                                />
                            </div>
                        </div>
                        <div className="relative">
                            <DashboardTopProductsTable />
                        </div>
                    </RoundedCard>
                </div>

                <div className='flex flex-col gap-2.5'>
                    <RoundedCard>
                        <div className='flex gap-2 justify-between items-center'>
                            <h1 className='text-lg font-semibold'>Revenue</h1>
                            <div>
                                <Dropdown3
                                    title={''}
                                    required={false}
                                    placeholder={'Last 1 Week'}
                                />
                            </div>
                        </div>
                        <div className="flex justify-between items-center my-8">
                            <h4 className="text-sm">Amount in Thousand Dollars</h4>
                            <div className="flex items-center gap-4">
                                <div className="flex items-center gap-2">
                                    <div className="h-4 w-4 rounded-sm bg-[#00B037]"></div>
                                    <p className="text-sm">Organic</p>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="h-4 w-4 rounded-sm bg-[#2376F5]"></div>
                                    <p className="text-sm">Advertisement</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <LineChatCurve fill={true} chartData1={[1, 2, 1, 3, 2, 4, 2]} chartData2={[0, 1, 2, 4, 1, 3, 1]} chartData1Color={'#00B037'} chartData2Color={'#2376F5'} />
                        </div>
                    </RoundedCard>
                    <RoundedCard>
                        <div className='flex gap-2 justify-between items-center mb-4'>
                            <h1 className='text-lg font-semibold'>Expenses</h1>
                            <div className='flex flex-row gap-4'>
                                <Dropdown3
                                    title={''}
                                    required={false}
                                    placeholder={'Weekly'}
                                    option={[
                                        { value: "", label: "Monthly" },
                                        { value: "", label: "Daily" },
                                    ]}
                                />

                                <Button
                                    customPadding={"px-2 py-1"}
                                    className={""}
                                    title={"Add expenses"} />
                            </div>
                        </div>
                        <div className='relative '>
                            <PremiumInventoryExpenseTable />
                        </div>
                    </RoundedCard>
                </div>
            </div>
        </SellerLayout>
    )
}

export default InventoryPremiumScreen