import Image from 'Components/Elements/Image'
import React from 'react'

const CustomerReviewCard = ({ img, message, name, jobDescription }) => {
    return (
        <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col justify-between h-full space-y-2 bg-white'>
            <Image customLoaderRounded={"rounded-full"} customLoaderHeight={"h-[50px]"} customLoaderWidth={'w-[50px]'} className={'w-[50px] h-[50px] rounded-full object-cover'} src={'/images/profile.png'} />
            <h1 className='text-lg font-semibold'>{name}</h1>
            <h1 className='text-primary'>{jobDescription}</h1>
            <h1>{message}</h1>
        </div>
    )
}

export default CustomerReviewCard