import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useNavigate } from "react-router-dom";

const useSidebar = (active) => {
    const navigate = useNavigate()

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    const sidebarRoutes = [
        {
            url: '/',
            heading: 'Shop',
        },
        {
            url: '/',
            heading: 'Advertising',
        },
        {
            url: '/',
            heading: 'About',
        },
        {
            url: '/',
            heading: 'Contact',
        },
    ]

    const sidebarRoutesSeller = [
        {
            url: '/',
            heading: 'Services',
        },
        {
            url: '/',
            heading: 'How It Works',
        },
        {
            url: '/',
            heading: 'Benefits',
        },
        {
            url: '/',
            heading: 'Advertise',
        },
        {
            url: '/',
            heading: 'FAQ',
        },
    ]

    // handle link click
    const handleLinkClick = (itm) => {
        navigate(itm?.url)
    }

    return {
        navigate, sidebarRoutes, defaultRoute, handleLinkClick, sidebarRoutesSeller
    }
}

export default useSidebar