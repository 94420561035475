import React from 'react'
import RoundedCard from 'Components/Elements/RoundedCard'

const DashboardCard = ({ title, value, percentage, description, icon }) => {
    return (
        <RoundedCard className={"flex gap-2 justify-between"}>
            <div className='flex flex-col space-y-2'>
                <h1>{title}</h1>
                <h1 className='text-2xl font-bold'>{value}</h1>
                <p className='text-sm text-[#00B037]'>{percentage} <span className='text-primaryBlack'>{description}</span></p>
            </div>
            {icon}
        </RoundedCard>
    )
}

export default DashboardCard