import React, { useState } from 'react'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Dropdown from 'Components/Elements/Dropdown'
import Image from 'Components/Elements/Image'
import Input from 'Components/Elements/Input'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { MyProfile } from './MyProfile'
import { StoreInformation } from './StoreInformation'
import { LegalDocuments } from './LegalDocuments'
import { BankDetails } from './BankDetails'

const SellerEditProfile = () => {
    const navigate = useNavigate()
    const [activeButton, setActiveButton] = useState("My Profile")
    const buttonData = [
        { name: "My Profile" },
        { name: "Store Information" },
        { name: "Legal Documents" },
        { name: "Bank Details" },
    ]

    return (
        <SellerLayout active={'My Profile'} title={"Edit Profile"} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                // first={"Dashboard"}
                // url={'/dashboard'}
                first={"My Profile"}
                url={'/profile'}
                second={'Edit Profile'}
            />
            <RoundedCard className={'flex flex-col space-y-4'}>
                <div className="flex flex-row flex-wrap gap-3.5">
                    {buttonData?.map((data) => (
                        <button
                            onClick={() => setActiveButton(data?.name)}
                            className={`min-w-max px-2 py-1 ${activeButton === data?.name ? "bg-btn-theme text-white font-bold " : " border border-gray-1 text-gray-1 font-semibold "} text-sm rounded`}>
                            {data?.name}
                        </button>
                    )
                    )}
                </div>
                {activeButton === "My Profile" ?
                    <MyProfile />
                    :
                    activeButton === "Store Information" ?
                        <StoreInformation />
                        :
                        activeButton === "Legal Documents" ?
                            <LegalDocuments />
                            :
                            activeButton === "Bank Details" &&
                            <BankDetails />
                }

            </RoundedCard>
        </SellerLayout >
    )
}

export default SellerEditProfile