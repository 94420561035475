import Dropdown from 'Components/Elements/Dropdown'
import Image from 'Components/Elements/Image'
import Input from 'Components/Elements/Input'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const MyProfile = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className='flex items-center gap-4'>
                <Image customLoaderRounded={"rounded-full"} customLoaderHeight={"h-[60px]"} customLoaderWidth={'w-[60px]'} className={'w-[60px] h-[60px] rounded-full object-cover'} src={'/images/profile.png'} />
                {/* {<Svgs.RoundedDummyUser />} */}
                <span className={`border border-[#949494] text-[#3B3B3B] px-2 py-1.5 h-fit w-fit rounded-lg text-sm font-bold flex justify-center items-center`}>
                    Edit Profile Picture
                </span>
                {/* <span className={`border border-[#949494] text-[#3B3B3B] px-2 py-1.5 h-fit w-fit rounded-lg text-sm font-bold flex justify-center items-center`}>
                        Upload Profile Picture
                    </span> */}
                <span className={`text-[#949494] px-2 py-1.5 h-fit w-fit rounded-lg text-sm font-bold flex justify-center items-center`}>
                    Remove
                </span>
            </div>
            <div className='grid lg:grid-cols-3 grid-cols-1 gap-4 justify-between'>
                <Input
                    title={'Full Name'}
                    placeholder={'Enter Full Name'}
                    required={false}
                />
                <Input
                    title={'Email'}
                    placeholder={'Enter Email'}
                    required={false}
                />
                <Input
                    title={'Phone Number'}
                    placeholder={'Enter Phone Number'}
                    required={false}
                />
                <Input
                    title={'Occupation'}
                    placeholder={'Enter Occupation'}
                    required={false}
                />
                <Dropdown
                    title={'Province'}
                    placeholder={'Enter Province'}
                    option={[{ label: "hello", value: "hello" },
                    { label: "hello", value: "hello" },
                    { label: "hello", value: "hello" },
                    { label: "hello", value: "hello" }]}
                />
                <Input
                    title={'Password'}
                    placeholder={'Type here'}
                    required={false}
                />
                <Dropdown
                    title={'Notifications'}
                    placeholder={'On'}
                    option={[
                        { label: "Off", value: "hello" },
                        { label: "On", value: "hello" }
                    ]}
                />
                <Dropdown
                    title={'Language'}
                    placeholder={'Enter Province'}
                    option={[
                        { label: "English (US)", value: "hello" },
                        { label: "Spanish", value: "hello" },
                    ]}
                />
            </div>
            <div className={`cursor-pointer text-sm px-3 justify-start theme-btn py-1.5 w-fit`}
                onClick={() => { navigate("/profile") }}
            >
                <h2>Update Changes</h2>
            </div>
        </>
    )
}
