import { useNavigate } from "react-router-dom";


function useSignup() {
    const navigate = useNavigate();

    return {
        navigate
    }
}

export default useSignup