import React from 'react'
import Button from 'Components/Elements/Button'

const JobCard = ({ title, role, description }) => {
    return (
        <div className='flex flex-col space-y-4 border-b border-[#F2F2F2] py-8'>
            <div className='flex justify-between items-center gap-4'>
                <h1 className='text-lg font-semibold'>{title}</h1>
                <p className='text-sm text-[#686868]'>{role}</p>
            </div>
            <h1 className='text-[#1E1E1E]'>{description}</h1>
            <div className='my-8'>
                <Button
                    className={'w-fit'}
                    title={'Apply Now'}
                    customPadding={'px-2 py-1'}
                />
            </div>
        </div>
    )
}

export default JobCard