import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Dropdown3 from 'Components/Elements/Dropdown3'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import DashboardCard from 'Pages/Seller/Dashboard/Elements/DashboardCard'
import LineChatCurve from 'Pages/Seller/Dashboard/Elements/LineChatCurve'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CateringServicesChatTable } from 'Utility/Tables/CateringServicesChatTable'
import { OtherServiceOrderTable } from 'Utility/Tables/OtherServiceOrderTable'
import { OtherServicesAllSellerTable } from 'Utility/Tables/OtherServicesAllSellerTable'
import { OtherServicesPlanTable } from 'Utility/Tables/OtherServicesPlanTable'

export const CateringServicesDashboard = () => {
    const navigate = useNavigate()

    return (
        <SellerLayout active={'Other Services'} title={'Other services'} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"Other services"}
                url={"/order-services"}
                second={"Catering services"}
            />

            <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 lg:mt-0 mt-2'>
                <DashboardCard
                    title={'Total Revenue'}
                    value={'$24k'}
                    percentage={`2.48%`}
                    description={'higher than usual'}
                    icon={<Svgs.TotalRevenueSvg />}
                />
                <DashboardCard
                    title={'Payments'}
                    value={'$12k'}
                    percentage={`4.78%`}
                    description={'higher than usual'}
                    icon={<Svgs.TotalProductsSvg />}
                />
                <DashboardCard
                    title={'Total Orders'}
                    value={'172'}
                    percentage={`4.48%`}
                    description={'higher than usual'}
                    icon={<Svgs.TotalOrdersSvg />}
                />
                <DashboardCard
                    title={'Active Customers'}
                    value={'54'}
                    percentage={`6.72%`}
                    description={'higher than usual'}
                    icon={<Svgs.ActiveCustomersSvg />}
                />
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                <RoundedCard>
                    <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                        <h1 className='text-lg font-semibold'>Orders</h1>
                        <div className='flex gap-2 justify-between items-center'>
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'Last 1 Month'}
                            />
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'All Orders'}
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <OtherServiceOrderTable />
                    </div>
                </RoundedCard>
                <RoundedCard>
                    <div className='flex flex-col lg:flex-row gap-2 justify-between items-center'>
                        <h1 className='text-lg font-semibold'>Payments</h1>
                        <div className='flex flex-row gap-2'>
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'Last 1 Week'}
                            />
                            <div>
                                <Button
                                    onClick={() => navigate("/other-services/catering-services/payment")}
                                    title={"View all"} customThemeBtn={"theme-btn2"} customPadding={"px-2 py-1"} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center my-8">
                        <h4 className="text-sm">Amount in Thousand Dollars</h4>
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                                <div className="h-4 w-4 rounded-sm bg-[#00B037]"></div>
                                <p className="text-sm">Weekly</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="h-4 w-4 rounded-sm bg-[#2376F5]"></div>
                                <p className="text-sm">Monthly</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-auto w-full overflow-hidden">
                        <LineChatCurve fill={true} chartData1={[1, 2, 1, 3, 2, 4, 2]} chartData2={[0, 1, 2, 4, 1, 3, 1]} chartData1Color={'#00B037'} chartData2Color={'#2376F5'} />
                    </div>
                </RoundedCard>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                <RoundedCard>
                    <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                        <h1 className='text-lg font-semibold'>My Plans</h1>
                        <div className='flex gap-2 justify-between items-center'>
                            <Button
                                onClick={() => navigate("/other-services/catering-services/create-plan")}
                                customPadding={"p-2"}
                                title={<>
                                    <div className='flex flex-row gap-1 items-center'>
                                        <Svgs.Plus />
                                        <p className='text-sm'>Create Plan</p>

                                    </div>
                                </>}
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <OtherServicesPlanTable />
                    </div>
                </RoundedCard>
                <RoundedCard>
                    <div className='flex gap-2 justify-between items-center mb-4'>
                        <h1 className='text-lg font-semibold'>Chats</h1>
                        <div>
                            <Button title={"View all"} customThemeBtn={"theme-btn2"} customPadding={"px-2 py-1"} />
                        </div>
                    </div>
                    <div className='relative'>
                        <CateringServicesChatTable />

                    </div>
                </RoundedCard>

            </div>
        </SellerLayout>)
}
