import React from 'react'

const CartDetailCard = ({ status }) => {
    return (
        <div className='flex flex-col space-y-2'>
            <div className='flex justify-between gap-2 items-center'>
                <p className='text-sm text-[#686868]'>Total Amount</p>
                <p className='text-sm'>$13.00</p>
            </div>
            <div className='flex justify-between gap-2 items-center'>
                <p className='text-sm text-[#686868]'>Discount</p>
                <p className='text-sm text-[#CE221E]'>-$1.00</p>
            </div>
            <div className='flex justify-between gap-2 items-center'>
                <p className='text-sm text-[#686868]'>Amount to be Paid</p>
                <p className='text-sm font-bold'>$12.00</p>
            </div>
            <div className='py-4'>
                <hr className='border-[#EEEEEE]' />
            </div>
            <div className='flex justify-between gap-2 items-center'>
                <p className='text-sm text-[#686868]'>Payment Status</p>
                <span className={`${status === "Cancelled" ? "received" : "settled"} p-2 w-fit float-end rounded-md text-sm font-normal flex justify-center items-center`}>
                    {status === "Cancelled" ? "Refunded" : "Settled"}
                </span>
            </div>
            <div className='flex justify-between gap-2 items-center'>
                <p className='text-sm text-[#686868]'>Payment Mode</p>
                <p className='text-sm'>Card</p>
            </div>
            <div className='flex justify-between gap-2 items-center'>
                <p className='text-sm text-[#686868]'>Transaction ID</p>
                <p className='text-sm'>4567890124</p>
            </div>
        </div>
    )
}

export default CartDetailCard