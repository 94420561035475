import React, { useEffect, useRef, useState } from 'react'
import Svgs from 'Assets/svgs';
import { error_message } from 'Constants/Variables';

const Input = ({
    onEnterSubmit,
    rightIcon,
    leftIcon,
    title,
    required,
    type,
    name,
    placeholder,
    value,
    onChange,
    error,
    disabled,
    parentClass,
    min,
    max,
    specialCharacterNotAllowed,
    rightIconClassName,
    onlyNumericAllowed,
    autoFocus,
    showBorder,
    charactersMaxLength,
    inputPadding,
    readOnly,
    leftIconClassName,
    capatalizeInput,
    errorClass, inputClass, onBlur,
    titleClass
}) => {
    const inputRef = useRef(null);
    const [sanitizedValue, setSanitizedValue] = useState('');
    const [inputHeight, setInputHeight] = useState(0);

    useEffect(() => {
        // Calculate input height after component mounts
        if (inputRef.current) {
            setInputHeight(inputRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        // Sanitize the input value based on the specified type
        if (value) {
            if (type === "number") {
                setSanitizedValue(typeof value == 'string' ? Math.abs(value?.replace(/[^0-9]/g, "")) : value);
            } else {
                setSanitizedValue(value);
            }
        } else {
            setSanitizedValue("");
        }
    }, [value, type]);

    useEffect(() => {
        try {
            // Preserve cursor position in the input field
            if (sanitizedValue != '') {
                if (inputRef.current && inputRef.current.selectionStart !== null && inputRef.current.selectionEnd !== null) {
                    const startPosition = inputRef.current.selectionStart;
                    const endPosition = inputRef.current.selectionEnd;

                    inputRef.current.value = sanitizedValue;

                    if (startPosition !== null && endPosition !== null) {
                        inputRef.current.setSelectionRange(startPosition, endPosition);
                    }
                }
            }
        } catch (error) {
            // Handle the error
        }
    }, [sanitizedValue, value]);

    const handleInputChange = (e) => {
        let inputValue = '';
        if (charactersMaxLength) {
            inputValue = e.target.value?.slice(0, charactersMaxLength)
            e.target.value = inputValue
        }

        // Convert small alphabet to capital
        if (capatalizeInput) {
            inputValue = e.target.value?.replace(/[a-z]/g, (match) => match?.toUpperCase());
            e.target.value = inputValue
        }

        // regex for only numbers allowed
        if (onlyNumericAllowed) {
            inputValue = e.target.value?.replace(/\D/g, '')
            e.target.value = inputValue
        }

        // regex to remove special characters
        if (specialCharacterNotAllowed) {
            inputValue = e.target.value?.replace(/[^a-zA-Z0-9\s]/g, '');
            e.target.value = inputValue
        }

        let currentPosition = 0;
        try {
            // Check if inputRef, inputRef.current, and selectionStart are supported
            if (inputRef?.current && 'selectionStart' in inputRef.current) {
                currentPosition = inputRef.current.selectionStart || 0;
            }
        } catch (error) {
            // Handle any potential error if accessing selectionStart fails
            // Set currentPosition to a default value, for example 0
            currentPosition = 0;
        }

        // Update the sanitizedValue state based on the input value
        if (e.target.value !== "") {
            if (type === "number") {
                setSanitizedValue(typeof e.target.value == 'string' ? Math.abs(e.target.value.replace(/[^0-9]/g, "")) : e.target.value);
            } else {
                setSanitizedValue(e.target.value);
            }
        } else {
            setSanitizedValue(e.target.value);
        }

        // Call the external onChange handler (if provided)
        if (typeof onChange === "function") {
            onChange(e);
        }

        // Restore the cursor position
        try {
            if (
                inputRef?.current &&
                'selectionStart' in inputRef.current &&
                'setSelectionRange' in inputRef.current
            ) {
                currentPosition = inputRef.current.selectionStart || 0;
                const maxLength = inputRef.current.value.length;

                // Ensure the currentPosition is within the bounds of the input text length
                currentPosition = Math.min(currentPosition, maxLength);

                // Set the selection range if the input has focus
                if (inputRef.current === document.activeElement) {
                    inputRef.current.setSelectionRange(currentPosition, currentPosition);
                }
            }
        } catch (error) {
            // Handle any potential error if accessing or setting selection properties fails
        }
    };

    const HandleKeyPress = (e) => {
        // regular expression pattern for special characters
        const specialCharsPattern = /[0123456789=!@#$%^&*()_+{}+×÷%≤≥≈≠∞€£¥""¢''/`"'\[\]:|;<>,.?~\\-]/g;
        // Check your condition here to disallow special characters
        if (specialCharacterNotAllowed && specialCharsPattern.test(e.key)) {
            e.preventDefault(); // Prevent typing of special characters
        }

        // regular expression pattern for only numeric characters
        const onlyNumericCharsAllowed = /[a-zA-Z=!@#$%^&*()_+{}+×÷%≤≥≈≠∞€£¥""¢''/`"'\[\]:|;<>,.?~\\-]/g;
        // Check your condition here to disallow special characters
        if (onlyNumericAllowed && onlyNumericCharsAllowed.test(e.key) && e.key != "Backspace") {
            // If input doesn't match, prevent the default behavior (e.g., typing the invalid character)
            e.preventDefault();
        }

        if (type === 'number' && e.key === '-') {
            e.preventDefault(); // Prevent typing of '-' symbol
        }

        if (e.key.toLowerCase() == "enter") {
            onEnterSubmit();
        }
    };

    useEffect(() => {
        // Use setTimeout to ensure that the blur occurs after the initial render
        const timeoutId = setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.blur();
            }
        }, 0);

        window.scrollTo({ top: 0 });
        return () => clearTimeout(timeoutId);

    }, []);

    return (
        <>
            <div className={`${parentClass ? parentClass : ""} relative flex flex-col gap-2 `}>

                {title && (
                    <div className={`${title == '' ? 'hidden' : ''} ${titleClass} text-sm text-primaryBlack flex items-center gap-1`}>
                        <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                    </div>
                )}

                {rightIcon ? (
                    <div className={`${error ? "border !border-[#eb3b3b]" : showBorder ? "border border-lightBlue" : ""} relative flex overflow-hidden transition`}>
                        <input
                            ref={inputRef}
                            autofocus={(autoFocus && autoFocus == "true") ? true : "false"}
                            onClick={(e) => {
                                if (type === 'date' && typeof e.target.showPicker === 'function') {
                                    e.target.showPicker();
                                }
                            }}
                            onKeyDown={HandleKeyPress}
                            disabled={disabled}
                            type={type ? type : "text"}
                            placeholder={type == 'date' ? "yyyy-mm-dd" : placeholder}
                            name={name}
                            value={sanitizedValue}
                            min={min}
                            max={max}
                            readOnly={readOnly}
                            maxLength={charactersMaxLength}
                            onChange={handleInputChange}
                            className={` ${inputPadding ? inputPadding : "px-4 py-2"} ${(readOnly || disabled) && 'cursor-not-allowed !bg-gray-100'} min-h-10 rounded-[5px] w-full bg-lightBlue focus:outline-none ${inputClass}`}
                        />
                        <div className={`${rightIconClassName} absolute top-1/2 -translate-y-1/2 right-3 text-sm input-right-icon`}>
                            {rightIcon}
                        </div>
                    </div>
                ) : leftIcon ? (
                    <div className={`relative flex overflow-hidden transition ${error ? "border !border-[#eb3b3b]" : showBorder ? "border border-lightBlue" : ""}`}>
                        <div className={`${leftIconClassName} min-w-[40px] flex items-center justify-center absolute top-1/2 -translate-y-1/2 left-3 text-sm pr-2`} style={{ height: inputHeight }}>
                            {leftIcon}
                        </div>
                        <input
                            onClick={(e) => {
                                if (type === 'date' && typeof e.target.showPicker === 'function') {
                                    e.target.showPicker();
                                }
                            }}
                            autoFocus={(autoFocus && autoFocus == "true") ? true : "false"}
                            ref={inputRef}
                            onKeyDown={HandleKeyPress}
                            disabled={disabled}
                            type={type ? type : "text"}
                            placeholder={type == 'date' ? "yyyy-mm-dd" : placeholder}
                            name={name}
                            value={sanitizedValue}
                            min={min}
                            readOnly={readOnly}
                            max={max}
                            maxLength={charactersMaxLength}
                            onChange={handleInputChange}
                            className={`${inputPadding ? inputPadding : "pl-12 pr-4 py-2"} ${(readOnly || disabled) && 'cursor-not-allowed !bg-gray-100'} bg-lightBlue min-h-10 rounded-[5px] w-full ${showBorder ? "border border-[#C0C0C0]" : ""} bg-white text-base text-gray-700 focus:outline-none ${inputClass}`}
                        />
                    </div>
                ) : (
                    <input
                        ref={inputRef}
                        onClick={(e) => {
                            if (type === 'date' && typeof e.target.showPicker === 'function') {
                                e.target.showPicker();
                            }
                        }}
                        autofocus={(autoFocus && autoFocus == "true") ? true : "false"}
                        onKeyDown={HandleKeyPress}
                        min={min}
                        max={max}
                        readOnly={readOnly}
                        maxLength={charactersMaxLength}
                        disabled={disabled}
                        type={type ? type : "text"}
                        placeholder={type == 'date' ? "yyyy-mm-dd" : placeholder}
                        name={name}
                        value={sanitizedValue}
                        onChange={handleInputChange}
                        onBlur={onBlur}
                        className={`${error ? "border !border-[#eb3b3b]" : showBorder ? "border border-lightBlue" : ""}  ${(readOnly || disabled) && 'cursor-not-allowed !bg-gray-100'} ${inputPadding ? inputPadding : "px-4 py-2"} min-h-10 rounded-[5px] w-full bg-lightBlue focus:outline-none ${inputClass}`}
                    />
                )}
                {error && typeof error == "string" ? (
                    <p className={`text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end ${errorClass}`}>
                        <Svgs.I fill="#eb3b3b" />
                        {error}
                    </p>
                ) : typeof error == "boolean" && error == true ? (
                    <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" /> {error_message}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </>
    )
}

Input.defaultProps = {
    type: "text",
    required: true,
    value: "",
    onEnterSubmit: () => { },
    showBorder: true
};


export default Input