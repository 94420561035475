import React from 'react'
import SellerLayout from 'Components/Layouts/SellerLayout'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
// import { STOCK, TIMELINE2 } from 'Constants/Data/OrdersDropdown'
import Pagination from 'Components/Pagination'
import Dropdown3 from 'Components/Elements/Dropdown3'
// import ProductCard from './Elements/ProductCard'
import { Link, useNavigate } from 'react-router-dom'
import ProductCard from './Elements/ProductCard'
import Image from 'Components/Elements/Image'
import OutlineButton from 'Components/Elements/OutlineButton'
import BreadCrumb from 'Components/Elements/BreadCrumb'

function VendorAllProductsScreen() {
    const allProdcutData = [
        {
            image: "/images/Coca-Cola.png",
            name: "Coca Cola(1)",
            price: "$1.8"
        },
        {
            image: "/images/Cabbage.png",
            name: "Cabbage",
            price: "$1"
        },
        {
            image: "/images/Eggs.png",
            name: "Eggs(12)",
            price: "$2"
        },
    ]
    const data = [
        { imageSrc: "/images/Pancakes.png", name: 'Pancakes', price: "$10", preperationTime: "20 mints" },
        { imageSrc: "/images/ChineseNoodles.png", name: 'Chinese Noodles', price: "$16", preperationTime: "25 mints" },
        { imageSrc: "/images/PepperPizza.png", name: 'Pepper Pizza', price: "$5/price", preperationTime: "10 mints" },
        { imageSrc: "/images/CheeseBurger.png", name: 'Cheese Burger', price: "$10", preperationTime: "15 mints" },
        { imageSrc: "/images/FreshRedApples.png", name: 'Fresh Red Apples', price: "$20/kg", stockStatus: "2" },
        { imageSrc: "/images/Bun.png", name: 'Bun', price: "$16", stockStatus: "1" },
        { imageSrc: "/images/Sweet.png", name: 'Sweet', price: "$5/price", stockStatus: "2" },
        { imageSrc: "/images/RedChilli.png", name: 'Red Chilli', price: "$10", stockStatus: "0" }
    ]

    const navigate = useNavigate()

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    All Products
                    <Svgs.PremiumIcon />
                </div>
            </>
        } bodyClassName={'flex flex-col space-y-4'}>

            <BreadCrumb
                first={"Vendors"}
                url={'/vendor'}
                second={"All products"}
            />

            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search By Product Name'}
                        />
                    </div>
                    <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 md:items-center justify-between'>
                        {/* <div className='flex flex-col lg:flex-row md:flex-row  gap-2 md:items-center justify-between '> */}
                        <div className='flex flex-row items-center gap-x-2 w-full '>
                            <Svgs.PdfIcon />
                            <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                                <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                    <Svgs.DownloadBlack />
                                    Download Excel
                                </div>
                            </Button>
                        </div>

                        {/* <Link to={"/inventory/addProduct"} className=' w-full'> */}
                        <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"}>
                            <div className='flex gap-2 items-center justify-between text-sm'>
                                <Svgs.Plus />
                                Add New Product
                            </div>
                        </Button>
                        {/* </Link> */}

                    </div>
                </div>
            </RoundedCard>
            <RoundedCard>
                <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                    <h1 className='text-lg font-semibold'>All products</h1>
                    <div className='flex gap-2 justify-between items-center flex-wrap flex-row'>
                        <Dropdown3
                            title={''}
                            required={false}
                            option={[
                                { value: "Out of stock", label: "Out of stock" },
                                { value: "In stock", label: "In stock" }
                            ]}
                            placeholder={'In stock'}
                        />
                        <Dropdown3
                            title={''}
                            required={false}
                            placeholder={'Top selling'}
                            option={[
                                { value: "", label: "Top selling" }
                            ]}
                        />
                        <Dropdown3
                            title={''}
                            required={false}
                            option={[
                                { value: "Fruits", label: "Fruits" },
                                { value: "Vegetables", label: "Vegetables" },
                            ]}
                            placeholder={'Categories'}
                        />
                    </div>
                </div>
                <div className='flex flex-row flex-wrap gap-1.5 items-center justify-center'>
                    {Array(5).fill().map((_, index) => (
                        allProdcutData.map((data, index) => (
                            <div
                                onClick={() => navigate("/vendor/amrit-foods/product")}
                                key={index}
                                className=" cursor-pointer min-w-[186px] p-2 border rounded-lg flex flex-col gap-2">
                                <Image
                                    customLoaderHeight={"h-[120px]"}
                                    customLoaderRounded={"rounded-lg"}
                                    customLoaderWidth={"w-[170px]"}
                                    className={"object-cover h-[120px] w-[170px]"}
                                    src={data.image}
                                />
                                <div className='flex flex-col gap-2.5'>
                                    <div className='flex flex-row justify-between text-sm'>
                                        <p className='font-semibold'>{data.name}</p>
                                        <p className='font-bold'>{data.price}</p>
                                    </div>

                                    <div className='flex flex-row justify-between text-sm items-center'>
                                        <div className='flex flex-row items-center gap-2'>
                                            <span>4.5</span>
                                            <Svgs.StarFill />
                                        </div>
                                        <OutlineButton
                                            title={"+ Add"}
                                            className={" !px-1.5 !py-0.5"}
                                        />

                                    </div>

                                </div>


                            </div>
                        )))
                    )}

                </div>
            </RoundedCard>
            {/* {data?.length > 0 && */}
            <Pagination
                currentPage={1}
                pageSize={2}
                totalCount={3}
            // onPageChange={(page) => setCurrentPage(page)}
            />
            {/* } */}
        </SellerLayout>
    )
}

export default VendorAllProductsScreen