import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Input from 'Components/Elements/Input'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const SendRequestCouponPage = () => {
    const navigate = useNavigate()
    return (
        <SellerLayout active={'Coupons'} title={"My Coupons"} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"My coupons"}
                second={"Send request"}
                url={'/coupons'}
            />


            <RoundedCard className={"flex flex-col gap-12"}>
                <div className='grid lg:grid-cols-3 grid-cols-1 gap-6'>


                    <Input
                        title={"Coupon name"}
                        required={false}
                        placeholder={"Type here"}
                    />
                    <Input
                        title={"Start date"}
                        required={false}
                        placeholder={"Type here"}
                    />
                    <Input
                        title={"End date"}
                        required={false}
                        placeholder={"Type here"}
                    />
                    <Input
                        title={"Coupon quantity"}
                        type={"number"}
                        required={false}
                        placeholder={"Type here"}
                    />
                    <Input
                        title={"Coupon share"}
                        required={false}
                        placeholder={"Type here"}
                    />

                    <Dropdown
                        title={"Select store"}
                        placeholder={"Select"}
                    />
                </div>

                <div className='flex items-center justify-center'>
                    <Button
                        onClick={() => navigate("/coupons")}
                        customPadding={"py-2 px-9"}
                        title={"Send request"}
                    />

                </div>


            </RoundedCard>

        </SellerLayout>
    )
}
