import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import React from 'react'

function PremiumInventoryVendorTable() {
    const tableData = [
        {
            logo: "/images/logoOnPremiumVendorTable1.png",
            storename: "Jim store",
            products: "12"
        },
        {
            logo: "/images/logoOnPremiumVendorTable1.png",
            storename: "Hopkins co",
            products: "07"
        },
        {
            logo: "/images/logoOnPremiumVendorTable1.png",
            storename: "ER snacks",
            products: "16"
        },
    ]

    return (
        <div className="overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                <thead class="text-sm text-[#686868] capitalize">
                    <tr>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Logo
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Store name
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Products bought
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Buy more
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((data, index) => (
                        <tr
                            key={index}
                            class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 text-[#949494] overflow-hidden">
                                <Image customLoaderHeight={"h-[30px]"} customLoaderWidth={"w-[40px]"} src={data.logo} className="w-[40px] h-[30px] rounded-md" alt="" />
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                {data.storename}
                            </td>
                            <td class="pr-5 py-3">
                                {data.products}
                            </td>
                            <td class="pr-5 py-3 ">
                                <button className={` hover:cursor-pointer px-2 py-0.5 w-fit rounded-md text-sm font-normal flex justify-center items-center border border-gray-2 `}>
                                    Order More
                                </button>
                            </td>
                        </tr>

                    ))}
                </tbody>
                {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
            </table>
            {/* {data?.length == 0 &&
                    <NotFound />
                } */}
        </div>
    )
}

export default PremiumInventoryVendorTable