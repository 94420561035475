import Svgs from 'Assets/svgs'
import CheckBox from 'Components/Elements/Checkbox'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SellerOrdersTable = ({ data }) => {
    const navigate = useNavigate()

    const tableData = [
        {
            id: "#2345",
            customer: "Chaince Kaur",
            date: "Mar 14, 2024, 09:12 AM ",
            items: "7",
            amount: "$110",
            payment: "Paid",
            status: "Received"
        },
        {
            id: "#2346",
            customer: "Navjot Singh",
            date: "Mar 13, 2024, 02:02 PM",
            items: "3",
            amount: "$70",
            payment: "Paid",
            status: "Packed"
        },
        {
            id: "#2347",
            customer: "Manpreet Kaur",
            date: "Mar 12, 2024, 12:01 PM ",
            items: "4",
            amount: "$90",
            payment: "Paid",
            status: "Delivered"
        },
        {
            id: "#2348",
            customer: "Gurpreet Singh",
            date: "Mar 12, 2024, 06:30 PM ",
            items: "6",
            amount: "$100",
            payment: "Paid",
            status: "Dispatched"
        },
        {
            id: "#2349",
            customer: "Harpreet Kaur",
            date: "Mar 12, 2024, 04:20 PM ",
            items: "2",
            amount: "$40",
            payment: "Unpaid",
            status: "Cancelled"
        },
    ]


    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right ">
                    <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                        <tr>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                ID
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Customer
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Date & Time
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Items
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Amount
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Payment
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Status
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array(2).fill().map((_, index) => (
                            tableData?.map((data, index) => (
                                <tr key={index} class="border-b border-[#EEEEEE]">
                                    <td class="pr-5 py-3">
                                        <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                                    </td>
                                    <td class="pr-5 py-3 text-[#949494]">
                                        {data?.id}
                                    </td>
                                    <td class="pr-5 py-3 whitespace-nowrap">
                                        {data?.customer}
                                    </td>
                                    <td class="pr-5 py-3 whitespace-nowrap">
                                        {data?.date}
                                    </td>
                                    <td class="pr-5 py-3">
                                        {data?.items}
                                    </td>
                                    <td class="pr-5 py-3">
                                        {data?.amount}
                                    </td>
                                    <td class={`pr-5 py-3 whitespace-nowrap ${data?.payment === "Paid" ? 'paid' : "unpaid"}`}>
                                        {data?.payment}
                                    </td>
                                    <td class="pr-5 py-3 whitespace-nowrap">
                                        <span className={`
                                            ${data?.status === "Received" ? "recieved" :
                                                data?.status === "Packed" ? "packed" :
                                                    data?.status === "Delivered" ? "delivered" :
                                                        data?.status === "Dispatched" ? "pending" :
                                                            "cancelled"}
                                             p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                            {data?.status}
                                        </span>
                                    </td>
                                    <td class="px-5 py-3">
                                        <button
                                            onClick={() => {
                                                navigate("/order-detail",
                                                    { state: { status: data?.status } }
                                                )
                                            }}
                                            className='cursor-pointer'>
                                            <Svgs.EyeIcon />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ))}

                        {/* <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2346
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Navjot Singh
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                3
                            </td>
                            <td class="pr-5 py-3">
                                $70
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`packed p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Packed
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2347
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Manpreet Kaur
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                4
                            </td>
                            <td class="pr-5 py-3">
                                $90
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`delivered p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Delivered
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2348
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Gurpreet Singh
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                6
                            </td>
                            <td class="pr-5 py-3">
                                $100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`dispatched p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Dispatched
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2349
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Harpreet Kaur
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                2
                            </td>
                            <td class="pr-5 py-3">
                                $40
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap unpaid">
                                Unpaid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`cancelled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Cancelled
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2345
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Chaince Kaur
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                7
                            </td>
                            <td class="pr-5 py-3">
                                $110
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`recieved p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Recieved
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2346
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Navjot Singh
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                3
                            </td>
                            <td class="pr-5 py-3">
                                $70
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`packed p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Packed
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2347
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Manpreet Kaur
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                4
                            </td>
                            <td class="pr-5 py-3">
                                $90
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`delivered p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Delivered
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2348
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Gurpreet Singh
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                6
                            </td>
                            <td class="pr-5 py-3">
                                $100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`dispatched p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Dispatched
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2349
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Harpreet Kaur
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                2
                            </td>
                            <td class="pr-5 py-3">
                                $40
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap unpaid">
                                Unpaid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`cancelled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Cancelled
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/order-detail")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr> */}
                    </tbody>
                    {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                </table>
                {/* {data?.length == 0 &&
                    <NotFound />
                } */}
            </div >
        </>
    )
}

export default SellerOrdersTable