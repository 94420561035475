import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Input from 'Components/Elements/Input'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VendorAddInvoiceTable from 'Utility/Tables/VendorAddInvoiceTable'

export const VendorAddInvoice = () => {
    const navigate = useNavigate()
    const [showSaveAsDraftPopup, setShowSaveAsDraftPopup] = useState(false)
    const [showSavePopup, setShowSavePopup] = useState(false)

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    Vendors
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'}>


            <BreadCrumb
                first={"Vendors"}
                url={'/vendor'}
                second={"Add Invoice"}
            />

            <RoundedCard>
                <div className='flex lg:flex-row flex-col justify-between lg:items-center gap-4 '>
                    <div className="flex flex-col gap-3 lg:w-1/4">
                        <div className='flex flex-row items-end gap-2.5'>
                            <Dropdown
                                title={"Select Vendor"}
                                placeholder={"Amrit foods"}
                            />
                            <Button
                                customPadding={"py-1 px-1"}
                                text={<Svgs.FillArrowRight />}
                            />
                        </div>
                        <Input
                            parentClass={"w-[82.5%]"}
                            placeholder={"Type here"}
                            title={"Invoice number"}
                            required={false}
                        />
                    </div>

                    <div className="flex flex-col gap-3.5">
                        <h4 className='text-base text-black'>Amrit foods</h4>
                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col gap-2 text-[#949494] text-sm'>
                                <div>
                                    <span>Address:{" "}</span>
                                    <span className='text-black'>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                                </div>
                                <div>
                                    <span>Contact:{" "}</span>
                                    <span className='text-black'>+ 653 436 7229</span>
                                </div>
                                <div>
                                    <span>Email id:{" "}</span>
                                    <span className='text-black'>amritfoods@gmail.com</span>
                                </div>
                            </div>
                            <div>Previous payments: $00</div>

                        </div>

                    </div>

                </div>
            </RoundedCard>

            <RoundedCard>
                <h3>Invoice details</h3>
                <div className='relative pb-8'>
                    <VendorAddInvoiceTable />
                </div>

                <div className='flex flex-col gap-5'>
                    <div className='flex items-center justify-center flex-col lg:flex-row gap-3.5'>
                        <Button
                            onClick={() => { setShowSaveAsDraftPopup(true) }}
                            customThemeBtn={"theme-btn3"}
                            customPadding={"px-2.5 py-2"}
                            title={"Save as draft"} />
                        <Button
                            customThemeBtn={"theme-btn3"}
                            customPadding={"px-2.5 py-2"}
                            title={"Upload attachment"} />
                        <Button
                            onClick={() => { setShowSavePopup(true) }}
                            customPadding={"px-2.5 py-2"}
                            className={"text-sm"}
                            title={"Save invoice"} />

                    </div>
                    <button className='text-custom-blue underline'>Terms & conditions</button>
                </div>


            </RoundedCard>


            {/* Save as draft popup */}
            <SuccessPopup
                open={showSaveAsDraftPopup}
                title={"Saved as draft successfully"}
                onClick={() => { setShowSaveAsDraftPopup(false) }}
            />

            {/* Save Success Popup */}
            <SuccessPopup
                open={showSavePopup}
                numberOfButton={2}
                buttonTitle1={"Print Invoice"}
                title={"Invoice saved successfully"}
                onClickOfButton1={() => {
                    setShowSavePopup(false)
                    navigate("/vendor")
                }}
                onClickOfButton2={() => {
                    setShowSavePopup(false)
                    navigate("/vendor")
                }}
            />

        </SellerLayout>
    )
}
