import { useState } from "react"


const useSellerWallet = () => {
    const [addFunds, setAddFunds] = useState(false)

    return {
        addFunds, setAddFunds
    }
}
export default useSellerWallet