import ImageUploader from 'Components/Elements/ImageUploader'
import Input from 'Components/Elements/Input'
import React from 'react'

const StoreDetails = () => {
    return (
        <>
            <div className="flex flex-col space-y-2 text-primaryBlack">
                <h4 className="font-bold text-3xl">Add Store Details</h4>
                <h1>Please enter the required details of your store.</h1>
            </div>
            <div className="flex flex-col space-y-5 py-5">
                <Input
                    title={'Store Name'}
                    placeholder={'Enter Store Name'}
                    required={false}
                />
                <Input
                    title={'Store Description'}
                    placeholder={'Enter Store Description'}
                    required={false}
                />
                <Input
                    title={'Store Address'}
                    placeholder={'Enter Store Address'}
                    required={false}
                />
                <Input
                    title={'Store Contract'}
                    placeholder={'Enter Store Contract'}
                    required={false}
                />
                <ImageUploader
                    title={'Store Logo'}
                    placeholder={'Upload Store Logo'}
                />
            </div>
        </>
    )
}

export default StoreDetails