import React from 'react';

const RoundedCard = ({ className, children, customPadding, bgImage }) => {
    return (
        <div className={`${className ? className : ""} ${customPadding ? customPadding : "p-5"} relative rounded-md bg-white`}>
            {bgImage && (
                <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                    <img
                        src={bgImage}
                        alt=""
                        className="object-contain"
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                </div>
            )}
            {bgImage ?
                <div className="relative">
                    {children}
                </div> :
                children
            }
        </div>
    );
};

export default RoundedCard;
