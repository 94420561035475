import React from 'react'
import useSellerHelpSupport from './helper'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import DashboardAccess from './Tabs/DashboardAccess'
import Svgs from 'Assets/svgs'

const SellerHelpSupport = () => {
    const { activeTab, setActiveTab } = useSellerHelpSupport()

    return (
        <SellerLayout active={'Dashboard'} title={'Help & Support'} bodyClassName={'flex flex-col space-y-4'}>
            <RoundedCard>
                <div className='grid lg:grid-cols-10 grid-cols-1 justify-between gap-4 relative'>
                    <div className='lg:col-span-7 col-span-1 order-2 lg:order-1'>
                        <h1 className='text-lg font-semibold mb-2'>{activeTab}</h1>
                        <DashboardAccess />
                    </div>
                    <div className="lg:absolute lg:right-0 lg:col-span-3 order-1 lg:order-2 flex flex-col space-y-1 shadow-popup-card rounded-lg lg:w-fit w-full p-2 h-fit border- border-[#EEEEEE]">
                        <div className={`cursor-pointer xs:text-sm text-xs px-2 justify-start w-full ${activeTab === "Dashboard Access" ? "theme-btn py-2" : "side-nav rounded-md py-2 !text-black"}`}
                            onClick={() => {
                                setActiveTab("Dashboard Access");
                            }}
                        >
                            <h2>Dashboard Access</h2>
                        </div>
                        <div className={`cursor-pointer xs:text-sm text-xs px-2 justify-start w-full ${activeTab === "Product Managment" ? "theme-btn py-2" : "side-nav rounded-md py-2 !text-black"}`}
                            onClick={() => {
                                setActiveTab("Product Managment");
                            }}
                        >
                            <h2>Product Managment</h2>
                        </div>
                        <div className={`cursor-pointer xs:text-sm text-xs px-2 justify-start w-full ${activeTab === "Order Processing" ? "theme-btn py-2" : "side-nav rounded-md py-2 !text-black"}`}
                            onClick={() => {
                                setActiveTab("Order Processing");
                            }}
                        >
                            <h2>Order Processing</h2>
                        </div>
                        <div className={`cursor-pointer xs:text-sm text-xs px-2 justify-start w-full ${activeTab === "Payments & Payouts" ? "theme-btn py-2" : "side-nav rounded-md py-2 !text-black"}`}
                            onClick={() => {
                                setActiveTab("Payments & Payouts");
                            }}
                        >
                            <h2>Payments & Payouts</h2>
                        </div>
                        <div className={`cursor-pointer xs:text-sm text-xs px-2 justify-start w-full ${activeTab === "Performance Metrics" ? "theme-btn py-2" : "side-nav rounded-md py-2 !text-black"}`}
                            onClick={() => {
                                setActiveTab("Performance Metrics");
                            }}
                        >
                            <h2>Performance Metrics</h2>
                        </div>
                    </div>
                </div>
            </RoundedCard>
            <RoundedCard>
                <h1 className='text-lg font-semibold mb-2'>Customer Support</h1>
                <div className='flex lg:flex-row flex-col lg:gap-12 gap-4'>
                    <div className='flex gap-2 items-center'>
                        <Svgs.EmailIcon />
                        <p className='text-sm'>help@mrcorporation.com</p>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <Svgs.PhoneIcon />
                        <p className='text-sm'>+1 234 567 890</p>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <Svgs.ChatIcon />
                        <div className={`cursor-pointer text-sm px-2 justify-start theme-btn py-1`}
                        >
                            <h2>Chat With Us</h2>
                        </div>
                    </div>
                </div>
            </RoundedCard>
        </SellerLayout>
    )
}

export default SellerHelpSupport