import React, { useRef } from 'react'
import Button from 'Components/Elements/Button';
import Topbar from 'Components/Topbar';
import Svgs from 'Assets/svgs';
import StoreCard from './Elements/StoreCard';

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Scrollbar } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Dropdown2 from 'Components/Elements/Dropdown2';
import { CUISINE_TYPE, DISTANCE, RESTAURANT_TYPE, SORT_BY } from 'Constants/Data/LandingPageDropdown';
import CustomerReviewCard from './Elements/CustomerReviewCard';
import Footer from 'Components/Footer';

const data = [
  { imageSrc: "/images/Manraj Sweet.png", name: "Manraj Sweet", rating: "4.0", type: "Indian", distance: "3 Miles away", deliveryTime: "30-45 mints" },
  { imageSrc: "/images/Pizza 24.png", name: "Pizza 24", rating: "4.0", type: "Italian", distance: "3 Miles away", deliveryTime: "30-45 mints" },
  { imageSrc: "/images/Zaiqa.png", name: "Zaiqa", rating: "4.0", type: "Indian", distance: "3 Miles away", deliveryTime: "30-45 mints" },
  { imageSrc: "/images/Pizza 360.png", name: "Pizza 360", rating: "4.0", type: "Indian", distance: "3 Miles away", deliveryTime: "30-45 mints" },
  { imageSrc: "/images/Manraj Sweet.png", name: "Manraj Sweet", rating: "4.0", type: "Indian", distance: "3 Miles away", deliveryTime: "30-45 mints" },
  { imageSrc: "/images/Pizza 24.png", name: "Pizza 24", rating: "4.0", type: "Italian", distance: "3 Miles away", deliveryTime: "30-45 mints" },
  { imageSrc: "/images/Zaiqa.png", name: "Zaiqa", rating: "4.0", type: "Indian", distance: "3 Miles away", deliveryTime: "30-45 mints" },
  { imageSrc: "/images/Pizza 360.png", name: "Pizza 360", rating: "4.0", type: "Indian", distance: "3 Miles away", deliveryTime: "30-45 mints" },
]

const ReviewData = [
  { name: "Sophia Moore", message: "I have been using MR Grocery App for food delivery for the past few months and I am so impressed! The delivery times are always quick and the food is amazing. The website is easy to navigate and it's easy to get the food I want.", rating: 4 },
  { name: "Adam Smith", message: "I have been using MR Grocery App for food delivery for the past few months and I am so impressed! The delivery times are always quick and the food is amazing. The website is easy to navigate and it's easy to get the food I want.", rating: 4 },
  { name: "Saint Leineker", message: "I have been using MR Grocery App for food delivery for the past few months and I am so impressed! The delivery times are always quick and the food is amazing. The website is easy to navigate and it's easy to get the food I want.", rating: 5 },
]

const LandingPage = () => {
  const swiperRef = useRef(null);

  return (
    <>
      <Topbar />
      {/* Banner Section */}
      <div className='grid xl:grid-cols-2 grid-cols-1'>
        <div
          className="relative  xl:h-screen min-h-[60vh] w-full flex items-center justify-center p-10"
          style={{
            backgroundImage: `url('/images/LandingBannerImg.png'), url('/images/LandingBannerImg1.png')`,
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'top left -200px, bottom right -200px',
            backgroundSize: 'contain, contain',
          }}
        >
          <div className="">
            <h2 className="lg:text-[49px] line-height-58px md:text-4xl xs:text-2xl text-xl font-bold">Your Ultimate Culinary Destination for Groceries and Restaurant Delights!</h2>
            <div className='mt-8 flex gap-6 items-center'>
              <Button customPadding="!px-4 py-2" className={'rounded-lg'}>
                <div className='text-sm'>
                  Order Now
                </div>
              </Button>
              <Button customPadding="!px-4 py-2" customThemeBtn={'bg-transparent border font-bold border-[#969696]'} className={'rounded-lg'}>
                <div className='text-sm'>
                  Become A Seller
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className='xl:block hidden'
          style={{
            backgroundImage: `url('/images/landing-banner.png')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      </div>

      <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4 w-[90%] mx-auto my-16'>
        <div className='bg-gradient-to-r from-[#617726] to-[#8CAE35] p-4 rounded-md flex flex-col space-y-1'>
          <h1 className='font-bold text-lg text-white'>Shop Groceries</h1>
          <h1 className='text-white text-sm'>Find all your daily food essentials at one place</h1>
          <div className='flex sm:flex-row flex-col gap-2 justify-between items-end'>
            <img src='/images/ShopGroceries.png' alt='' />
            <span className='bg-white text-[#617726] text-sm px-3 py-1 rounded-md font-bold w-fit'>Order Now</span>
          </div>
        </div>
        <div className='bg-gradient-to-r from-[#B14212] to-[#E9AF71] p-4 rounded-md flex flex-col space-y-2'>
          <h1 className='font-bold text-lg text-white'>Satisfy Your Cravings</h1>
          <h1 className='text-white text-sm'>Order food from your favourite restaurants</h1>
          <div className='flex sm:flex-row flex-col gap-2 justify-between items-end'>
            <img src='/images/SatisfyYourCravings.png' alt='' />
            <span className='bg-white text-[#B14212] text-sm px-3 py-1 rounded-md font-bold w-fit'>Order Now</span>
          </div>
        </div>
        <div className='bg-gradient-to-r from-[#264677] to-[#3574AE] p-4 rounded-md flex flex-col space-y-2'>
          <h1 className='font-bold text-lg text-white'>Get Multiple Deals & Coupons</h1>
          <h1 className='text-white text-sm'>Special student discounts with proper verification</h1>
          <div className='flex sm:flex-row flex-col gap-2 justify-between items-end'>
            <img src='/images/GetMultiple.png' className='xl:w-[230px]' alt='' />
            <span className='bg-white text-[#264677] text-xs px-3 py-2 rounded-md font-bold w-fit'>Order Now</span>
          </div>
        </div>
      </div>

      <div className='bg-gradient-to-b from-[#FB6D72] to-[#F33F41] rounded-md text-white mx-auto my-16 w-[90%] py-8'>
        <div className='w-[90%] mx-auto flex lg:flex-row flex-col justify-between items-center gap-4 '>
          <div className='flex flex-col space-y-4'>
            <h1 className='font-bold text-2xl'>Get 40% OFF on your first Order</h1>
            <h1 className='text-lg font-semibold'>Signup on the app and get 40% off on your first grocery or restaurant order. Download the app new by clicking on the button below. </h1>
            <span className='bg-white text-black text-xs px-3 py-2 rounded-md font-bold w-fit'>Download The App</span>
          </div>
          <div className='relative px-4'>
            <img className='w-full h-full object-cover' src='/images/GetOffImg.png' alt='' />
            <div className='absolute sm:-left-6 -left-3 -bottom-6'>
              <div className='relative'>
                <img className='w-full max-h-[100px] max-w-[100px] h-full object-cover' src='/images/Star 2.png' alt='' />
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-bold text-sm text-black line-height-19px'>
                  40% OFF
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-16 bg-[#fcf1f0]">
        <div className='mx-auto py-12 w-[90%] flex flex-col space-y-8'>
          <div className='flex sm:flex-row flex-col sm:gap-2 gap-1 justify-between'>
            <h1 className="font-bold sm:text-2xl text-xl">Grocery Stores/ Restaurants Near You</h1>
            <div className='flex items-center justify-end gap-2 cursor-pointer' >
              <p className='font-semibold text-sm text-primary pr-2'>View All</p>
              <div className='p-0.5' onClick={() => {
                swiperRef.current?.swiper.slidePrev();
              }}>
                <Svgs.FillArrowLeft />
              </div>
              <div className='p-0.5' onClick={() => {
                swiperRef.current?.swiper.slideNext();
              }}>
                <Svgs.FillArrowRight />
              </div>
            </div>
          </div>
          <div className='gap-4 grid xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-2 grid-cols-1 items-center'>
            <div className='gap-4 grid sm:grid-cols-4 xs:grid-cols-2 grid-cols-1 items-center'>
              <Dropdown2
                title={''}
                noBorder={true}
                required={false}
                placeholder={'Restaurant'}
                option={RESTAURANT_TYPE}
              />
              <Dropdown2
                title={''}
                noBorder={true}
                required={false}
                placeholder={'Sort By'}
                option={SORT_BY}
              />
              <Dropdown2
                title={''}
                noBorder={true}
                required={false}
                placeholder={'Distance'}
                option={DISTANCE}
              />
              <Dropdown2
                title={''}
                noBorder={true}
                required={false}
                placeholder={'Cuisine'}
                option={CUISINE_TYPE}
              />
            </div>
          </div>
          <div>
            <Swiper
              ref={swiperRef}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              spaceBetween={20}
              modules={[Pagination, Scrollbar, Autoplay]}
              breakpoints={{
                1500: {
                  slidesPerView: 4,
                },
                1390: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 1,
                },
              }}
              className='swiperHomeCard'
            >
              {data?.map((itm, index) => (
                <SwiperSlide key={index}>
                  <StoreCard
                    imageSrc={itm?.imageSrc}
                    name={itm?.name}
                    rating={itm?.rating}
                    type={itm?.type}
                    distance={itm?.name}
                    deliveryTime={itm?.deliveryTime}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div className='flex flex-col space-y-8 w-[90%] mx-auto my-16'>
        <h1 className='font-bold text-2xl'>What We Offer?</h1>
        <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4'>
          <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1'>
            <Svgs.TruckSvg />
            <h1 className='text-lg font-semibold'>Free Delivery</h1>
            <h1>Convenient doorstep service at no extra charge, ensuring affordability and ease for all your grocery and restaurant needs.</h1>
            <p className='text-sm font-bold text-primary'>Learn More</p>
          </div>
          <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1'>
            <Svgs.AwardSvg />
            <h1 className='text-lg font-semibold'>Get Fresh Products</h1>
            <h1>Indulge in superior freshness and quality with our meticulously selected range, guaranteeing satisfaction in every bite.</h1>
            <p className='text-sm font-bold text-primary'>Learn More</p>
          </div>
          <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1'>
            <Svgs.TruckSvg />
            <h1 className='text-lg font-semibold'>Easy To Order</h1>
            <h1>Streamline your shopping process with our intuitive platform, offering effortless navigation and seamless checkout for ultimate convenience.</h1>
            <p className='text-sm font-bold text-primary'>Learn More</p>
          </div>
        </div>
      </div>

      <div className='my-16 bg-[#fcf1f0]'>
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-4 justify-center w-[90%] mx-auto items-center">
          <div className='px-12 justify-start '>
            <img src='/images/LandingOrder.png' className='bg-cover w-full h-full' alt='' />
          </div>
          <div className='flex flex-col space-y-8 justify-between'>
            <div className='flex flex-col space-y-0.5'>
              <h1 className='font-bold text-2xl'>How To Offer?</h1>
              <p className='text-lg font-semibold'>Get coverage on everything in just 3 easy steps.</p>
            </div>
            <div className='flex-flex-col'>
              <div className='cursor-pointer p-4 flex flex-col space-y-2 bg-white rounded-md'>
                <h1 className='text-[#CE221E] text-lg font-semibold'>1. Create your account on our website</h1>
                <p className='text-sm'>Start your journey with us by creating your account on our website—it's quick, easy, and the gateway to endless possibilities</p>
              </div>
              <div className='cursor-pointer p-4 flex flex-col space-y-2 hover:bg-white hover:rounded-md'>
                <h1 className='text-[#CE221E] text-lg font-semibold'>2. Search for your order items</h1>
                <p className='text-sm'>Easily locate your desired items on our website by utilizing our intuitive search function, streamlining your shopping experience effortlessly.</p>
              </div>
              <div className='cursor-pointer p-4 flex flex-col space-y-2 hover:bg-white hover:rounded-md'>
                <h1 className='text-[#CE221E] text-lg font-semibold'>3. Add to cart and pay for your order</h1>
                <p className='text-sm'>Effortlessly add items to your cart and securely pay for your purchases in just a few clicks, right on our website.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col space-y-8 w-[90%] mx-auto my-16'>
        <h1 className='font-bold text-2xl'>Advertise Your Business With MR Corporation- <span className='font-normal'>Affordable promotion in Canada!</span></h1>
        <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4'>
          <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1'>
            <Svgs.GalleryIcon />
            <h1 className='text-lg font-semibold'>Banner Ad</h1>
            <h1>Elevate your brand with MR Corporation: Where Quality Meets Success.</h1>
            <p className='text-sm font-bold text-primary'>Learn More</p>
          </div>
          <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1'>
            <Svgs.VideoIcon />
            <h1 className='text-lg font-semibold'>Video Ad</h1>
            <h1>Lights, Camera, Business! Let MR Corporation showcase your brand to the world.</h1>
            <p className='text-sm font-bold text-primary'>Learn More</p>
          </div>
          <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col space-y-1'>
            <Svgs.VisitingCardIcon />
            <h1 className='text-lg font-semibold'>Visiting Cards</h1>
            <h1>Unlock your business potential with MR Corporation: Your Success Partner.</h1>
            <p className='text-sm font-bold text-primary'>Learn More</p>
          </div>
        </div>
      </div>

      <div className='bg-gradient-to-b from-[#FB6D72] to-[#F33F41] rounded-md text-white mx-auto my-16 w-[90%] py-8'>
        <div className='w-[90%] mx-auto grid lg:grid-cols-10 grid-cols-1 justify-between items-center gap-8 '>
          <div className='col-span-6 flex flex-col space-y-4'>
            <h1 className='font-bold text-2xl'>Refer A Friend</h1>
            <h1 className='text-lg'>Introduce a friend to the joy of convenient dining and grocery shopping with our Refer a Friend scheme! <br /><br /> Simply share your unique referral link, and when your friend makes their first purchase, both of you will receive a generous discount on your next orders.</h1>
            <span className='bg-white text-black text-xs px-3 py-2 rounded-md font-bold w-fit'>Click Here</span>
          </div>
          <div className='col-span-4 xl:px-4 justify-center'>
            <img className='w-full h-full object-cover' src='/images/Hands_Share_Referral 2 1.png' alt='' />
          </div>
        </div>
      </div>

      <div className="my-16">
        <div className='mx-auto py-12 w-[90%] flex flex-col space-y-8'>
          <div className='flex sm:flex-row flex-col sm:gap-2 gap-1 justify-between'>
            <h1 className="font-bold sm:text-2xl text-xl">Customer Reviews</h1>
            <div className='flex items-center justify-end gap-2 cursor-pointer' >
              <div className='p-0.5' onClick={() => {
                swiperRef.current?.swiper.slidePrev();
              }}>
                <Svgs.FillArrowLeft />
              </div>
              <div className='p-0.5' onClick={() => {
                swiperRef.current?.swiper.slideNext();
              }}>
                <Svgs.FillArrowRight />
              </div>
            </div>
          </div>
          <div >
            <Swiper
              ref={swiperRef}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              spaceBetween={20}
              modules={[Pagination, Scrollbar, Autoplay]}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 1,
                },
              }}
              className='swiperHomeCard'
            >
              {ReviewData?.map((itm, index) => (
                <SwiperSlide key={index}>
                  <CustomerReviewCard
                    name={itm?.name}
                    message={itm?.message}
                    rating={itm?.rating}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div className='mt-16 w-[85%] mx-auto sm:block hidden'>
        <div
          className="flex items-center justify-center"
          style={{
            backgroundImage: `url('/images/DownloadAppBg.png')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "contain",
          }}
        >
          <div className="relative grid xs:grid-cols-2 grid-cols-1 gap-4 justify-center items-center">
            <img src='/images/LandingOrder.png' className='bg-cover w-full h-full' alt='' />
            <div>
              <div className='absolute xl:bottom-4 md:bottom-2 bottom-0'>
                <h1 className='xl:text-[49px] lg:text-[36px] text-[24px] font-bold xs:text-white pb-6'>Download Our App</h1>
                <div className="flex items-center gap-4 lg:pb-8">
                  <div className='border border-[#A6A6A6] p-2 w-fit min-w-[130px] text-white flex gap-2 h-fit rounded-lg bg-black'>
                    <Svgs.AppleStore />
                    <div className='flex flex-col'>
                      <p className='text-[9px] font-bold'>Download on the</p>
                      <h1 className='text-[12px] font-bold'>App Store</h1>
                    </div>
                  </div>
                  <div className='border border-[#A6A6A6] p-2 w-fit min-w-[130px] text-white flex gap-2 h-fit rounded-lg bg-black'>
                    <Svgs.GooglePlay />
                    <div className='flex flex-col'>
                      <p className='text-[9px] font-bold'>GET IT ON</p>
                      <h1 className='text-[12px] font-bold'>Google Play</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gradient-to-b from-[#FB6D72] to-[#F33F41] rounded-md text-white mx-auto mt-16 w-[90%] p-4 sm:hidden block'>
        <h1 className='xl:text-[49px] lg:text-[36px] text-[24px] font-bold xs:text-white pb-6'>Download Our App</h1>
        <div className="flex items-center gap-2">
          <div className='border border-[#A6A6A6] p-2 w-fit max-w-[130px] text-white flex gap-1 h-fit rounded-lg bg-black'>
            <Svgs.AppleStore />
            <div className='flex flex-col'>
              <p className='text-[9px] font-bold'>Download on the</p>
              <h1 className='text-[12px] font-bold'>App Store</h1>
            </div>
          </div>
          <div className='border border-[#A6A6A6] p-2 w-fit max-w-[130px] text-white flex gap-1 h-fit rounded-lg bg-black'>
            <Svgs.GooglePlay />
            <div className='flex flex-col'>
              <p className='text-[9px] font-bold'>GET IT ON</p>
              <h1 className='text-[12px] font-bold'>Google Play</h1>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default LandingPage