import React, { useState } from 'react'

function UploadImage() {
    const [noOfImage, setNoOfImage] = useState(4)
    const [uploadedImages, setUploadedImages] = useState([])

    const handleImageUpload = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newUploadedImages = [...uploadedImages];
                newUploadedImages[index] = reader.result;
                setUploadedImages(newUploadedImages);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className='flex flex-col gap-2 '>
            <div className='text-black text-base font-normal'>Upload product images</div>
            <div className='flex flex-row gap-4 flex-wrap'>
                {Array(noOfImage)?.fill().map((_, index) => (
                    <label key={index} className={`border border-dashed rounded-lg border-[#BDBDBD] flex cursor-pointer  max-h-16 w-16  ${uploadedImages[index] ? "" : "p-3"}`}>
                        {uploadedImages[index] ? (
                            <img src={uploadedImages[index]} alt={`uploaded ${index}`} className='object-contain w-full h-full rounded-lg' />
                        ) : (
                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 0H35C38.866 0 42 3.13401 42 7V35C42 38.866 38.866 42 35 42H7C3.13401 42 0 38.866 0 35V7C0 3.13401 3.13401 0 7 0ZM7 3.10333L6.66378 3.11764C4.66912 3.2881 3.10333 4.9612 3.10333 7V35C3.10333 37.1521 4.84793 38.8967 7 38.8967H8.55555L24.887 17.4433C25.0944 17.1708 25.324 16.9159 25.5734 16.6812C28.0745 14.3277 32.0099 14.4473 34.3634 16.9484L38.8967 21.7659V7C38.8967 4.84793 37.1521 3.10333 35 3.10333H7ZM38.8967 26.2943L32.1034 19.0751C30.9244 17.8222 28.953 17.7623 27.7001 18.9413C27.5752 19.0588 27.4602 19.1865 27.3563 19.323L12.4558 38.8967H35C37.1521 38.8967 38.8967 37.1521 38.8967 35V26.2943ZM12.8333 16.3333C14.7663 16.3333 16.3333 14.7663 16.3333 12.8333C16.3333 10.9003 14.7663 9.33333 12.8333 9.33333C10.9003 9.33333 9.33333 10.9003 9.33333 12.8333C9.33333 14.7663 10.9003 16.3333 12.8333 16.3333ZM14 12.8333C14 13.4777 13.4777 14 12.8333 14C12.189 14 11.6667 13.4777 11.6667 12.8333C11.6667 12.189 12.189 11.6667 12.8333 11.6667C13.4777 11.6667 14 12.189 14 12.8333Z" fill="#949494" fillOpacity="0.6" />
                            </svg>
                        )}

                        <input type="file" name="" id="" className='hidden' onChange={(e) => handleImageUpload(e, index)} />
                    </label>
                ))}

                <div
                    onClick={() => setNoOfImage(prevNo => prevNo + 1)}
                    className='border border-dashed p-5 rounded-lg border-[#BDBDBD] flex hover:cursor-pointer'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2852 0C10.7329 0 10.2852 0.447716 10.2852 1V10.2852H1C0.447715 10.2852 0 10.7329 0 11.2852V12.7137C0 13.266 0.447716 13.7137 1 13.7137H10.2852V23C10.2852 23.5523 10.7329 24 11.2852 24H12.7137C13.266 24 13.7137 23.5523 13.7137 23V13.7137H23C23.5523 13.7137 24 13.266 24 12.7137V11.2852C24 10.7329 23.5523 10.2852 23 10.2852H13.7137V1C13.7137 0.447715 13.266 0 12.7137 0H11.2852Z" fill="#3B3B3B" />
                    </svg>
                </div>
            </div>


        </div>
    )
}

export default UploadImage