import Image from 'Components/Elements/Image'
import React from 'react'
import { Link } from 'react-router-dom'

const VendorCard = ({ imageSrc, name, price, preperationTime, stockStatus }) => {
    return (
        <div className='border-2 rounded-lg border-[#EEEEEE] p-3 flex flex-col space-y-2 '>
            <Image className={'w-full h-full object-cover max-w-[252px] max-h-[140px] self-center'} src={imageSrc} />
            <div className='flex flex-col gap-2.5'>
                <div className='flex justify-between gap-4 text-sm font-semibold'>
                    <h1>{name}</h1>
                    <div className='flex flex-row gap-1 text-xs'>
                        <span className='text-[#686868]'>Products:{" "}</span>
                        <span className='settled-text font-semibold'>{price}</span>

                    </div>
                </div>
                <div className='flex justify-between gap-4 text-sm font-semibold'>
                    <div className='flex flex-row gap-1 text-xs'>
                        <span className='text-[#686868]'>Orders:{" "}</span>
                        <span className='settled-text font-semibold'>{10}</span>

                    </div>
                    <div className='flex flex-row gap-1 text-sm items-center'>
                        <span className=''>4.5</span>
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.14722 0L10.3208 4.73373L15.4741 5.34752L11.6641 8.88692L12.6755 14L8.14722 11.4537L3.61894 14L4.6303 8.88692L0.820312 5.34752L5.97364 4.73373L8.14722 0Z" fill="#FF8C2F" />
                        </svg>

                    </div>
                </div>
                <div className='flex justify-between gap-4 text-sm font-semibold'>
                    <div className='flex flex-row gap-1 text-xs'>
                        <span className='text-[#F33F41]'>Pending:{" "}</span>
                        <span className=' font-semibold'>{"$120"}</span>

                    </div>
                    <div className='flex flex-row gap-1 text-xs'>
                        <span className='settled-text'>Recent:{" "}</span>
                        <span className=' font-semibold'>{"$120"}</span>

                    </div>
                </div>

                <div className="flex items-center justify-center">
                    <Link
                        to={"/vendor/amrit-foods"}
                        className='text-xs text-[#FA676C] underline'>
                        View products
                    </Link>
                </div>

            </div>


        </div>
    )
}

export default VendorCard