import React, { useState } from 'react'
import SellerTopBar from 'Components/SellerTopBar';
import SellerSideBar from 'Components/SellerSidebar';

const SellerLayout = ({ children, active, bodyClassName, title }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            <div class="antialiased">
                <SellerTopBar openSidebar={toggleSidebar} title={title} />
                <SellerSideBar openSidebar={isSidebarOpen} active={active} />
                <main className="md:ml-64 h-auto pt-16 min-h-screen bg-lightGray">
                    <div className={`${bodyClassName} p-4`}>
                        {children}
                    </div>
                </main>
            </div>
        </>
    )
}

export default SellerLayout