import React from 'react'
import SellerLayout from 'Components/Layouts/SellerLayout'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import Dropdown2 from 'Components/Elements/Dropdown2'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import { PAYMENT_STATUSES, TIMELINE } from 'Constants/Data/OrdersDropdown'
import Pagination from 'Components/Pagination'
import PromotionCard from './Elements/PromotionCard'

const data = [
    { imageSrc: "/images/Pancakes.png", name: 'Spice Luxe Bistro Ad', created_at: "5 days ago", reach: "20k", likes: "6k", status: "active" },
    { imageSrc: "/images/Pancakes.png", name: 'Spice Luxe Bistro Ad', created_at: "5 days ago", reach: "20k", likes: "6k", status: "inactive" },
    { imageSrc: "/images/Pancakes.png", name: 'Spice Luxe Bistro Ad', created_at: "5 days ago", reach: "20k", likes: "6k", status: "inactive" },
    { imageSrc: "/images/Pancakes.png", name: 'Spice Luxe Bistro Ad', created_at: "5 days ago", reach: "20k", likes: "6k", status: "inactive" },
    { imageSrc: "/images/Pancakes.png", name: 'Spice Luxe Bistro Ad', created_at: "5 days ago", reach: "20k", likes: "6k", status: "inactive" },
    { imageSrc: "/images/Pancakes.png", name: 'Spice Luxe Bistro Ad', created_at: "5 days ago", reach: "20k", likes: "6k", status: "inactive" },
]

const SellerPromote = () => {
    return (
        <SellerLayout active={'Promote'} title={'Promote Your Business'} bodyClassName={'flex flex-col space-y-4'}>
            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search By Order ID or Customer Name'}
                        />
                    </div>
                    <div className='flex sm:flex-row flex-col gap-2 sm:items-center justify-between'>
                        <Dropdown2
                            title={''}
                            required={false}
                            placeholder={'Monthly'}
                            option={TIMELINE}
                        />
                        <Dropdown2
                            title={''}
                            required={false}
                            placeholder={'All'}
                            option={PAYMENT_STATUSES}
                        />
                        <Button customPadding={'px-3 py-2'} className={"!rounded-md"}>
                            <div className='flex gap-2 items-center justify-between text-sm'>
                                <Svgs.DownloadIcon />
                                Download Excel
                            </div>
                        </Button>
                    </div>
                </div>
            </RoundedCard>
            <RoundedCard>
                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 justify-center items-center'>
                    {data?.map((itm, ind) => (
                        <PromotionCard
                            key={ind}
                            name={itm?.name}
                            reach={itm?.reach}
                            likes={itm?.likes}
                            created_at={itm?.created_at}
                            status={itm?.status}
                            imageSrc={itm?.imageSrc}
                        />
                    ))}
                </div>
            </RoundedCard>
            {/* {data?.length > 0 && */}
            <Pagination
                currentPage={1}
                pageSize={2}
                totalCount={3}
            // onPageChange={(page) => setCurrentPage(page)}
            />
            {/* } */}
        </SellerLayout>
    )
}

export default SellerPromote