import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Dropdown2 from 'Components/Elements/Dropdown2'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VendorAllInvoicesTable } from 'Utility/Tables/VendorAllInvoicesTable'

export const VendorAllInvoices = () => {
    const navigate = useNavigate()

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    All Invoices
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'} >
            <BreadCrumb
                first={"Vendors"}
                url={'/vendor'}
                second={"All invoices"}
            />


            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search By Order ID'}
                        />
                    </div>
                    <div className='flex sm:flex-row flex-col gap-2 sm:items-center justify-between'>
                        <div className='flex flex-row gap-2 w-full'>

                            <button><Svgs.PdfIcon /></button>
                            <Button customThemeBtn={"theme-btn2"} customPadding={'px-3 py-2'} className={"!rounded-md gap-2 w-full"}>
                                <Svgs.DownloadBlack />
                                Download Excel
                            </Button>
                        </div>
                        <Button customThemeBtn={"theme-btn2"} customPadding={'px-3 py-2'} className={"!rounded-md"}>
                            Upload Invoice
                        </Button>
                    </div>
                </div>
            </RoundedCard >



            <RoundedCard className={"flex flex-col gap-4 "}>
                <div className="flex lg:flex-row  flex-col justify-between gap-4">
                    <h2>All Invoices</h2>
                    <div className='flex flex-row min-w-max'>
                        <Dropdown2
                            title={""}
                            placeholder={"All"}
                            option={[
                                { value: "", label: "Paid" }
                            ]}
                        />
                        <Dropdown2
                            title={""}
                            placeholder={"Calendar"}
                        />

                    </div>
                </div>

                <div className='relative pb-4'>
                    <VendorAllInvoicesTable />
                </div>
            </RoundedCard>
        </SellerLayout >

    )
}
