import React from 'react'
import useNotifications from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotificationShimmer from 'Utility/Shimmers/NotificationShimmer'
import NotificationCard from './Elements/NotificationCard'
import Svgs from 'Assets/svgs'

const NotificationsDropdown = ({ open, setOpen }) => {
    const { notification, loading, pagination, currentPage, setCurrentPage } = useNotifications()
    const notifications = [
        { title: "Orders", message: "You have a new order pending.", created_at: "2 mins ago", type: "Order" },
        { title: "Inventory", message: "Inventory levels are running low for certain products.", created_at: "5 mins ago", type: "Inventory" },
        { title: "Payments", message: "Your recent payment has been successfully processed.", created_at: "10 mins ago", type: "Payment" },
        { title: "Messages", message: "A customer has inquired about an order.", created_at: "10 mins ago", type: "Message" },
        { title: "Orders", message: "Your recent order has been dispatched.", created_at: "15 mins ago", type: "Dispatch" }
    ]
    return (
        <>
            <div className={`fixed inset-0 h-[100vh] top-[4rem] w-[100vw] z-50 flex justify-end`}>
                <div
                    className="bg-[#101928]/10 absolute inset-0 z-[1]"
                    onClick={() => { setOpen(false); }}
                ></div>
                <div className={`fixed mt-2 mx-5 bg-white shadow-2xl rounded-lg lg:max-w-[30vw] sm:max-w-[40vw] max-w-fit sm:w-11/12 z-[2]`}>
                    <div className={'gap-3 w-full rounded-2xl overflow-hidden'}>
                        <div className={"flex gap-4 items-center justify-between p-4"} >
                            <h1 className="font-semibold text-lg sticky bg-white !my-0">
                                Notifications
                            </h1>
                            <p className='underline text-xs'>Mark all as read</p>
                        </div>
                        <div className="flex flex-col gap-4 pt-0 max-h-[70vh] overflow-hidden">
                            {loading ?
                                <div className="flex flex-col p-3 gap-3">
                                    {[0, 1, 2, 3, 4]?.map(itm => {
                                        return <NotificationShimmer />

                                    })}
                                </div>
                                :
                                <InfiniteScroll
                                    dataLength={notification?.length ? notification?.length : 10} //This is important field to render the next data
                                    next={() => { setCurrentPage(currentPage + 1) }}
                                    hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                    loader={(pagination?.current_page !== pagination?.total_pages)
                                        && <div className="flex justify-center items-center my-4">
                                            <PreloaderSm />
                                        </div>
                                    }
                                    height={'40rem'}
                                    className={" top-full !m-0 !mt-1 bg-white z-[180] max-h-[65vh] overflow-auto rounded-b-2xl"}>
                                    <div className="flex flex-col space-y-3">
                                        {notifications?.map((ele, index) => {
                                            return <>
                                                <NotificationCard
                                                    title={ele?.title}
                                                    message={ele?.message}
                                                    created_at={ele?.created_at}
                                                    type={ele?.type}
                                                    icon={ele?.type == 'Order' ? <Svgs.TimeIcon />
                                                    : ele?.type == 'Inventory' ? <Svgs.WarningIcon />
                                                    : ele?.type == 'Payment' ? <Svgs.PaymentSuccessIcon />
                                                    : ele?.type == 'Message' ? <Svgs.InfoIcon />
                                                    :  <Svgs.DispatchIcon />
                                                    }
                                                />
                                            </>
                                        })}
                                    </div>
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationsDropdown