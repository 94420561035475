import Input from 'Components/Elements/Input'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const BankDetails = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className='grid lg:grid-cols-3 grid-cols-1 gap-4 justify-between'>
                <Input
                    title={'Account number'}
                    placeholder={'5656443457765'}
                    required={false}
                />
                <Input
                    title={'IFSC code'}
                    placeholder={'IDSCF342d'}
                    required={false}
                />
                <Input
                    title={'Bank name'}
                    placeholder={'Scotia bank'}
                    required={false}
                />
                <Input
                    title={'Name on account'}
                    placeholder={'Jack smith'}
                    required={false}
                />
                <Input
                    title={'Bank address'}
                    placeholder={'Surrey, BC'}
                    required={false}
                />

            </div>
            <div className={`cursor-pointer text-sm px-3 justify-start theme-btn py-1.5 w-fit`}
                onClick={() => { navigate("/profile") }}
            >
                <h2>Update Changes</h2>
            </div></>
    )
}
