import React from 'react'

const NotificationCard = ({ title, message, created_at, navigate_url, read_at, icon }) => {
    return (
        <>
            <div
                className={`flex !mx-0 cursor-pointer gap-2 !items-start justify-between px-4 py-1.5 transition-all 
                bg-white `}
            // ${read_at == null ? "bg-[#E0F5F0]" : "bg-white"}
            >
                <div className="!mt-2 !m-0" >
                    {icon}
                </div>
                <div className="flex flex-col gap-2 p-2 w-full">
                    <p className=" cursor-pointer text-sm">
                        {message}
                    </p>
                    <div className='flex justify-between gap-2 text-xs'>
                        <h1 className="cursor-pointer text-[#2376F5]">
                            {title}
                        </h1>
                        <p className='text-[#949494]'>{created_at}</p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default NotificationCard