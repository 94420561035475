import React from 'react'
import Svgs from 'Assets/svgs'


function PremiumInventoryProductTable() {
    return (
        <div className="overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-left min-w-max  ">

                <tr className="border-b border-[#EEEEEE] text-sm py-3">
                    <td className='text-[#686868] text-sm'>
                        High stock products
                    </td>
                    <td></td>
                    <td></td>
                    <td className='pl-5 py-3 text-custom-green text-right'>36</td>
                    <td class="pl-5 py-3 whitespace-nowrap  bg-white flex items-end justify-end">
                        <span className={`bg-[#2376F5] bg-opacity-10 text-[#2376F5] p-2 rounded-md text-xs font-normal flex justify-center items-center`}>
                            In stock
                        </span>
                    </td>
                </tr>
                <tr className="border-b border-[#EEEEEE] text-sm">
                    <td className='text-[#686868] text-sm pr-5 py-3'>
                        Low stock products
                    </td>
                    <td className='pr-5 py-3'></td>
                    <td className='pr-5 py-3'></td>
                    <td className='text-custom-green text-right'>12</td>
                    <td class="lr-5 py-3 whitespace-nowrap flex justify-end">
                        <span className={`bg-[#FF8C2F] bg-opacity-10 text-[#FF8C2F] p-2 w-fit rounded-md text-xs font-normal flex justify-center items-center`}>
                            Order now
                        </span>
                    </td>
                </tr>
            </table>
        </div>)
}

export default PremiumInventoryProductTable