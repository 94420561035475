import Button from 'Components/Elements/Button'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const OtherServicesPlanTable = () => {
    const tableData = [
        {
            name: "Basic plan",
            sales: "1240",
            price: "$240"
        },
        {
            name: "Premium plan",
            sales: "459",
            price: "$240"
        },
        {
            name: "Platinum plan",
            sales: "547",
            price: "$240"
        },
        {
            name: "Custom plan",
            sales: "876",
            price: "$240"
        },
    ]
    const navigate = useNavigate()

    return (
        <div className="overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right ">
                <thead class="text-sm text-[#686868] capitalize">
                    <tr>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Plan Name
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Sales
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Price
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal text-center">
                            Edit
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((data, index) => (
                        <tr
                            key={index} class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 text-[#949494]">
                                {data?.name}
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                {data?.sales}
                            </td>
                            <td class="pr-5 py-3">
                                {data?.price}
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap flex items-center justify-center">
                                <Button onClick={() => navigate("/other-services/tiffin-services/basic-plan")} customPadding={"px-2.5 py-1"} customThemeBtn={"theme-btn2"} title={"Edit"} className={"border-gray-1 font-normal text-xs"} />

                            </td>
                        </tr>

                    ))}

                </tbody>

            </table>
            {/* {data?.length == 0 &&
                    <NotFound />
                } */}
        </div>)
}
