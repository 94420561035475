import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Dropdown2 from 'Components/Elements/Dropdown2'
import Input from 'Components/Elements/Input'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { VendorCreditMemoTable } from 'Utility/Tables/VendorCreditMemoTable'
import { VendorAllInvoiceDetails } from './VendorAllInvoiceDetails'
import VendorInvoiceDetailsTable from 'Utility/Tables/VendorInvoiceDetailsTable'
import { VendorRequestCreditMemoTable, } from 'Utility/Tables/VendorRequestCreditMemoTable'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import BreadCrumb from 'Components/Elements/BreadCrumb'

export const VendorRequestCreditMemo = () => {
    const navigate = useNavigate()
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    Request credit memo
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'}>

            <BreadCrumb
                first={"Vendors"}
                url={'/vendor'}
                second={"All invoices"}
                secondurl={"/vendor/all-invoices"}
                third={"Request credit memo"}
            />


            <RoundedCard>
                <div className='flex flex-col lg:flex-row gap-6 lg:items-end'>
                    <div className=' flex-row grid lg:grid-cols-2 lg:w-2/4 gap-3 items-end'>
                        <Dropdown
                            required={false}
                            title={"Select Vendor"}
                            placeholder={"Amrit foods"}
                        />
                        <Input
                            parentClass={"min-w-max"}
                            type={"number"}
                            required={false}
                            title={"Invoice number"}
                            placeholder={"234516"}
                        />

                    </div>
                    <div>
                        <Button
                            className={"text-sm"}
                            customPadding={"px-2.5 py-2"}
                            title={"Get invoice details"}
                        />
                    </div>

                </div>
            </RoundedCard >

            <RoundedCard className={"flex flex-col "}>
                <div className='flex lg:flex-row flex-col gap-4  justify-between w-full'>
                    <div className='flex flex-col gap-3.5'>
                        <h2>Bill to</h2>

                        <div className="flex flex-col gap-2 items-start text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Address:{" "}</span>
                                <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Contact:{" "}</span>
                                <span>+ 653 436 7229</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Email id:{" "}</span>
                                <span>amritfoods@gmail.com</span>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col gap-3.5 lg:justify-end lg:items-end'>
                        <div className="flex flex-col gap-2 lg:items-end text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Invoice no:{" "}</span>
                                <span> #234516</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Date:{" "}</span>
                                <span>Mar 12, 2024</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Due date:{" "}</span>
                                <span>Mar 27, 2024</span>
                            </div>
                        </div>
                    </div>
                </div>
            </RoundedCard>


            <RoundedCard>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-4">
                    <h2>Invoice details</h2>
                </div>
                <div className='relative py-4'>
                    <VendorRequestCreditMemoTable />
                </div>

                <div className="items-center justify-center flex pt-9">
                    <Button
                        onClick={() => { setShowSuccessPopup(true) }}
                        customPadding={"px-2.5 py-2"}
                        title={"Request credit memo"}
                    />
                </div>


            </RoundedCard>

            <SuccessPopup
                open={showSuccessPopup}
                title={"Credit memo request sent"}
                onClick={() => {
                    setShowSuccessPopup(false)
                    navigate(-1)
                }}
            />

        </SellerLayout>
    )
}
