import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'

export const CouponDetails = () => {
    return (
        <SellerLayout active={'Coupons'} title={"My Coupons"} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"My coupons"}
                second={"Requested"}
                url={'/coupons'}
            />

            <div className='grid grid-cols-1 xl:grid-cols-5 gap-4'>
                <div className='flex flex-col gap-5 lg:col-span-3'>

                    <RoundedCard className={"flex flex-col gap-3.5"}>
                        <div className='grid xl:grid-cols-2 grid-cols-1 gap-3.5 items-center '>
                            <div className='flex flex-row items-center gap-4'>
                                <Svgs.CouponDummyImage />
                                <div className='flex flex-col gap-2.5'>
                                    <div>
                                        <span className='packed px-2.5 py-2 rounded  min-w-max'>Requested</span>
                                    </div>
                                    <h2 className='text-lg font-semibold'>Flat 10% off on ABC store</h2>
                                    <div className='flex md::flex-row flex-col justify-between text-xs gap-2 '>
                                        <p className='font-semibold'>By: DA Grocery Store</p>
                                        <p className='text-[#686868]'>Min. Order: $50</p>
                                    </div>
                                </div>

                            </div>


                            <div className='flex items-center lg:justify-end justify-center'>
                                <Button
                                    customPadding={"px-5 py-2"}
                                    customThemeBtn={"theme-btn2"}
                                    title={"Ask for reconsideration"}
                                />
                            </div>
                        </div>


                    </RoundedCard>

                    <RoundedCard className={"flex flex-col gap-7 lg:gap-14"}>
                        <div className='flex flex-col justify-between  gap-8'>
                            <h3 className='font-semibold text-lg'>Coupon Description</h3>
                            <p className='text-[#949494] font-inter text-base leading-5 text-justify'>
                                Indulging in the vibrant, jewel-like seeds of pomegranate offers a myriad of health benefits. Bursting with antioxidants, these ruby-red gems promote heart health by combating oxidative stress and reducing inflammation. Pomegranates have also been linked to cognitive benefits, with regular consumption potentially enhancing memory and supporting overall brain function. Indulging in the vibrant, jewel-like seeds of pomegranate offers a myriad of health benefits. Bursting with antioxidants, these ruby-red gems promote heart health by combating oxidative stress and reducing inflammation. Pomegranates have also been linked to cognitive benefits.</p>
                        </div>

                        <div className='flex md:justify-end justify-center  gap-6 lg:gap-12 items-center flex-row'>
                            <button className='font-bold text-sm'>
                                Deny
                            </button>
                            <Button
                                customPadding={" px-4 lg:px-9 py-2"}
                                title={"Accept coupon"}
                            />


                        </div>


                    </RoundedCard>
                </div>

            </div>

        </SellerLayout>
    )
}
