import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, LineElement, CategoryScale, PointElement, LinearScale, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(LineElement, CategoryScale, PointElement, LinearScale, Filler);

const LineChatCurve = ({ fill, chartData1, chartData2, chartData1Color, chartData2Color, LineBorderWidth }) => {

    const [LabelFontSize, setLabelFontSize] = useState(14);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 770) {
                setLabelFontSize(14)
            } else {
                setLabelFontSize(10)
            }
        };
        // Initial setup
        handleResize();
        // Event listener for window resize
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const createLinearGradient = (color1, color2) => {
        const ctx = document.createElement('canvas').getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, color1);
        gradient.addColorStop(1, color2);
        return gradient;
    };

    const data = {
        type: "line",
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
            {
                label: 'Organic',
                data: chartData1,
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: [chartData1Color],
                pointHoverBorderColor: ["#fff"],
                pointHoverBorderWidth: 2,
                fill: fill,
                borderColor: chartData1Color,
                // Use a more transparent background color
                backgroundColor: createLinearGradient('rgba(0, 176, 55, 0.2)', 'rgba(132, 96, 127, 0.05)'),
                borderWidth: LineBorderWidth ? LineBorderWidth : 1,
            },
            {
                label: 'Advertisement',
                data: chartData2,
                borderColor: chartData2Color,
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: [chartData2Color],
                pointHoverBorderColor: ["#fff"],
                pointHoverBorderWidth: 2,
                fill: fill,
                // Use a more transparent background color
                backgroundColor: createLinearGradient('rgba(35, 118, 245, 0.2)', 'rgba(255, 255, 255, 0.05)'),
                borderWidth: LineBorderWidth ? LineBorderWidth : 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: false,
            tooltip: {
                // Disable the on-canvas tooltip
                enabled: true,
                intersect: false,
                mode: 'index',
                usePointStyle: true,
                bodyAlign: "center",
                yAlign: "bottom",
                xAlign: "center",
                footerAlign: "center",
                titleAlign: "center",
                displayColors: false,
                pointStyle: false,
                padding: 10,
                caretPadding: 10,
                bodyFont: {
                    size: 15,
                },
                callbacks: {
                    title: function () {
                        return "";
                    },
                },
            },
        },
        scales: {
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: LabelFontSize, // Set the font size for the y-axis labels
                        family: 'Arial', // Set the font family for the y-axis labels
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: LabelFontSize, // Set the font size for the x-axis labels
                        family: 'Arial', // Set the font family for the x-axis labels
                    },
                },
            },
        },
    };

    return (
        <Line data={data} options={options} />
    );
};

export default LineChatCurve;
