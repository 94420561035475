import React, { useState } from 'react'
import CheckBox from 'Components/Elements/Checkbox'

function VendorAddInvoiceTable() {
    const tableData = [
        {
            productCode: "MAN-002",
            description: "MANUAL GRINDING STONE 1PC",
            um: "Each",
            totalPrice: "$45.00",
            unitPrice: "$45.00",
            orderedQuantity: "2",
            receivedQuantity: "2",
            finalPrice: "$90.00",
        },
        {
            productCode: "TAM-002",
            description: "TAMARIND SEEDLEES THAI 400GX50",
            um: "Case",
            totalPrice: "$45.00",
            unitPrice: "$0.90",
            orderedQuantity: "1",
            receivedQuantity: "1",
            finalPrice: "$0.90",
        },


    ]
    const [checkedItems, setCheckedItems] = useState(tableData.map(() => false));
    const [allChecked, setAllChecked] = useState(false);
    const [numberOfLines, setNumberOfLines] = useState(1)

    const handleAllChecked = () => {
        const newCheckedStatus = !allChecked;
        setAllChecked(newCheckedStatus);
        setCheckedItems(checkedItems.map(() => newCheckedStatus));
    };

    const handleCheckBoxChange = (index) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
        setAllChecked(newCheckedItems.every(item => item === true));
    };

    return (
        <>
            <div className="overflow-x-auto ">
                <div className='min-w-max'>

                    <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                        <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                            <tr>
                                <th scope="col" class="pr-5 py-4 font-normal">
                                    <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                        checked={allChecked}
                                        onChange={() => handleAllChecked()} />
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal">
                                    Product code
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal whitespace-nowrap">
                                    Description
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal min-w-max min">
                                    U/M
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal">
                                    Total price
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal whitespace-nowrap">
                                    Unit Price
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal whitespace-nowrap">
                                    Ordered Quantity
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal">
                                    Received Quantity
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal">
                                    Final price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((data, index) => (
                                <tr
                                    index={index}
                                    class="border-b border-[#EEEEEE]">
                                    <td class="pr-5 py-4">
                                        <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                            checked={checkedItems[index]}
                                            onChange={() => handleCheckBoxChange(index)} />
                                    </td>
                                    <td class="pr-5 py-4 ">
                                        {data.productCode}
                                    </td>
                                    <td class="pr-5 py-4 whitespace-nowrap">
                                        {data.description}
                                    </td>
                                    <td class="pr-5 py-4  min-w-max">
                                        {data.um}
                                    </td>
                                    <td class="pr-5 py-4 whitespace-nowrap">
                                        {data.totalPrice}
                                    </td>
                                    <td class="pr-5 py-4">
                                        {data.unitPrice}
                                    </td>
                                    <td class="pr-5 py-4 whitespace-nowrap">
                                        {data?.orderedQuantity}
                                    </td>
                                    <td class="px-5 py-4">
                                        {data?.receivedQuantity}
                                    </td>
                                    <td class="pr-5 py-4 whitespace-nowrap">
                                        {data?.finalPrice}
                                    </td>
                                </tr>
                            ))
                            }

                            {Array(numberOfLines)?.fill()?.map((_, index) => (
                                <tr
                                    key={index}
                                    class="border-b border-[#EEEEEE] min-w-max">
                                    <td class="pr-5 py-4">
                                        <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                        // checked={checkedItems[index]}
                                        // onChange={() => handleCheckBoxChange(index)}
                                        />
                                    </td>
                                    <td class="pr-5 py-4 ">
                                        <input type='text'
                                            placeholder="Type here"
                                            className='!outline-none ring-0  w-24' />
                                    </td>
                                    <td class="pr-5 py-4 whitespace-nowrap">
                                        <input type='text'
                                            placeholder="Type here"
                                            className='!outline-none ring-0  w-24' />
                                    </td>
                                    <td class="pr-5 py-4  min-w-max">
                                        <input type='text'
                                            placeholder="Type here"
                                            className='!outline-none ring-0  w-24' />
                                    </td>
                                    <td class="pr-5 py-4 whitespace-nowrap">
                                        $
                                        <input type='number'
                                            placeholder="00"
                                            className='!outline-none ring-0  w-24' />
                                    </td>
                                    <td class="pr-5 py-4">
                                        $
                                        <input type='text'
                                            placeholder="00"
                                            className='!outline-none ring-0  w-24' />
                                    </td>
                                    <td class="pr-5 py-4 whitespace-nowrap">
                                        <input type='number'
                                            placeholder="0"
                                            className='!outline-none ring-0  w-24' />
                                    </td>
                                    <td class="px-5 py-4">
                                        <input type='number'
                                            placeholder="0"
                                            className='!outline-none ring-0  w-24' />
                                    </td>
                                    <td class="pr-5 py-4 whitespace-nowrap">
                                        $
                                        <input type='number'
                                            placeholder="00"
                                            className='!outline-none ring-0  w-24' />
                                    </td>
                                </tr>
                            ))}


                            <tr
                                class="border-b border-[#EEEEEE] min-w-max">
                                <td class="pr-5 py-4">
                                </td>
                                <td class="pr-5 py-4 ">
                                    <button onClick={() => { setNumberOfLines(prev => prev + 1) }} className='pending px-1  py-0.5 rounded'>
                                        Add more lines
                                    </button>
                                </td>
                                {/* <td class="pr-5 py-4 whitespace-nowrap">
                                    <input type='text'
                                        placeholder="Type here"
                                        className='!outline-none ring-0  w-24' />
                                </td>
                                <td class="pr-5 py-4  min-w-max">
                                    <input type='text'
                                        placeholder="Type here"
                                        className='!outline-none ring-0  w-24' />
                                </td>
                                <td class="pr-5 py-4 whitespace-nowrap">
                                    $
                                    <input type='number'
                                        placeholder="00"
                                        className='!outline-none ring-0  w-24' />
                                </td>
                                <td class="pr-5 py-4">
                                    $
                                    <input type='text'
                                        placeholder="00"
                                        className='!outline-none ring-0  w-24' />
                                </td>
                                <td class="pr-5 py-4 whitespace-nowrap">
                                    <input type='number'
                                        placeholder="0"
                                        className='!outline-none ring-0  w-24' />
                                </td>
                                <td class="px-5 py-4">
                                    <input type='number'
                                        placeholder="0"
                                        className='!outline-none ring-0  w-24' />
                                </td>
                                <td class="pr-5 py-4 whitespace-nowrap">
                                    $
                                    <input type='number'
                                        placeholder="00"
                                        className='!outline-none ring-0  w-24' />
                                </td> */}
                            </tr>




                        </tbody>
                        {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-4 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-4 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-4">
                                                7
                                            </td>
                                            <td class="pr-5 py-4">
                                                $110
                                            </td>
                                            <td class="pr-5 py-4 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-4 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-4">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                    </table>
                    <div className='flex flex-row w-full py-3.5 border-b justify-end border-b-gray-200'>
                        <div className='min-w-max'>
                            <div className='flex flex-col gap-2 text-sm text-[#686868] min-w-max items-end '>
                                <div className="flex flex-row">
                                    <span>Subtotal:{" "}</span>
                                    <span className='text-black'>$360.00</span>
                                </div>
                                <div className="flex flex-row">
                                    <span>GST @5%:{" "}</span>
                                    <span className='text-black'>$18.00</span>
                                </div>
                                <div className="flex flex-row">
                                    <span>Balance due:{" "}</span>
                                    <span className='text-black'>$378.00</span>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}

export default VendorAddInvoiceTable