import React from 'react'

const SellerReferEarnTable = ({ data }) => {
    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right ">
                    <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                        <tr>
                            <th scope="col" class="pr-10 py-3 font-normal whitespace-nowrap">
                                Invited
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Status
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Updated
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Points Earned
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Send Invite Reminder
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-10 py-3 whitespace-nowrap">
                                Store 1
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Started using the MR Seller’s Platform
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap settled-text">
                                +100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`border border-[#D9D9D9] text-[#D9D9D9] px-2 py-1.5 w-fit rounded-lg text-sm font-normal flex justify-center items-center`}>
                                    Send
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Store 2
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Started using the MR Seller’s Platform
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 13, 2024, 02:12 PM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap settled-text">
                                +100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`border border-[#D9D9D9] text-[#D9D9D9] px-2 py-1.5 w-fit rounded-lg text-sm font-normal flex justify-center items-center`}>
                                    Send
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Store 3
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Started using the MR Seller’s Platform
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 11, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap settled-text">
                                +100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`border border-[#D9D9D9] text-[#D9D9D9] px-2 py-1.5 w-fit rounded-lg text-sm font-normal flex justify-center items-center`}>
                                    Send
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Store 4
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Started using the MR Seller’s Platform
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 11, 2024, 10:12 AM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap settled-text">
                                +100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`border border-[#D9D9D9] text-[#D9D9D9] px-2 py-1.5 w-fit rounded-lg text-sm font-normal flex justify-center items-center`}>
                                    Send
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Store 5
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap failed-text">
                                Not using the platform
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 03, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                0
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`border border-[#949494] text-[#3B3B3B] px-2 py-1.5 w-fit rounded-lg text-sm font-bold flex justify-center items-center`}>
                                    Send
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Store 6
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap failed-text">
                                Not using the platform
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 18, 2024, 07:00 AM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                0
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`border border-[#949494] text-[#3B3B3B] px-2 py-1.5 w-fit rounded-lg text-sm font-bold flex justify-center items-center`}>
                                    Send
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                </table>
                {/* {data?.length == 0 &&
                    <NotFound />
                } */}
            </div>
        </>
    )
}

export default SellerReferEarnTable