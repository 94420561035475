import React from 'react'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import DashboardCard from 'Pages/Seller/Dashboard/Elements/DashboardCard'
import Dropdown3 from 'Components/Elements/Dropdown3'
import OutlineButton from 'Components/Elements/OutlineButton'
import VendorCard from './VendorCard'
import Pagination from 'Components/Pagination'
import { useNavigate } from 'react-router-dom'
import SellerLayout from 'Components/Layouts/SellerLayout'

function PremiumInventoryVendorScreen() {
    const recentOrderTableData = [
        {
            order: "Dairy products",
            amount: "$110",
            statusText: "Invoice uploaded",
            status: "Pending"
        },
        {
            order: "Sweets",
            amount: "$70",
            statusText: "Order confirmed",
            status: "Settled"
        },
        {
            order: "Spices",
            amount: "$90",
            statusText: "Paid",
            status: "Settled"
        },
        {
            order: "Fruits",
            amount: "$100",
            statusText: "Pay $40",
            status: "Packed"
        },
    ]

    const data = [
        { imageSrc: "/images/Pancakes.png", name: 'Amrit foods', price: "40", preperationTime: "20 mints" },
        { imageSrc: "/images/ChineseNoodles.png", name: 'ALLINONE', price: "40", preperationTime: "25 mints" },
        { imageSrc: "/images/PepperPizza.png", name: 'AMA GLOBAL FOODS', price: "40", preperationTime: "10 mints" },
        { imageSrc: "/images/CheeseBurger.png", name: 'APF FOODS LTD', price: "40", preperationTime: "15 mints" },
        { imageSrc: "/images/FreshRedApples.png", name: 'APF FOODS LTD', price: "40", stockStatus: "2" },
        { imageSrc: "/images/Bun.png", name: 'APF FOODS LTD', price: "40", stockStatus: "1" },
        { imageSrc: "/images/Sweet.png", name: 'APF FOODS LTD', price: "40", stockStatus: "2" },
        { imageSrc: "/images/RedChilli.png", name: 'APF FOODS LTD', price: "40", stockStatus: "0" }
    ]

    const navigate = useNavigate()

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    Vendors
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'}>
            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search '}
                        />
                    </div>
                    {/* <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2 md:items-center justify-between'> */}
                    <div className='flex flex-col lg:flex-row md:flex-row  gap-2 md:items-center justify-between '>
                        <div className='flex flex-row items-center gap-x-2 w-full '>
                            <Svgs.PdfIcon />
                            <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                                <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                    <Svgs.DownloadBlack />
                                    Download Excel
                                </div>
                            </Button>
                        </div>
                        <div className='flex flex-row gap-2'>

                            <Button
                                onClick={() => navigate("/vendor/add-invoice")}
                                customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                                <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                    Add invoice
                                </div>
                            </Button>
                            <Button
                                // onClick={() => setOpenAddExpensePopup(true)}
                                customPadding={'px-3 py-2'} className={"!rounded-md w-full"}>
                                <div className='flex gap-2 items-center justify-between text-sm'>
                                    Add new
                                </div>
                            </Button>
                        </div>


                    </div>
                </div>
            </RoundedCard>

            <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 lg:mt-0 mt-2'>
                <DashboardCard
                    title={'Total Vendors'}
                    value={'50'}
                    percentage={`4`}
                    description={'new vendors last month'}
                    icon={<Svgs.TotalRevenueSvg />}
                />
                <DashboardCard
                    title={'Total products'}
                    value={'83'}
                    percentage={`0%`}
                    description={'higher than last month'}
                    icon={<Svgs.TotalProductsSvg />}
                />
                <DashboardCard
                    title={'Total orders'}
                    value={'12'}
                    percentage={`2.30%`}
                    description={'more than last month'}
                    icon={<Svgs.EmployeeIcon />}
                />
                <DashboardCard
                    title={'Expenses'}
                    value={'$18k'}
                    percentage={`6.72%`}
                    description={'higher than last month'}
                    icon={<Svgs.ActiveCustomersSvg />}
                />
            </div>

            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                <RoundedCard className={"flex flex-col gap-1"}>
                    <div className='flex lg:flex-row justify-between flex-col gap-2'>
                        <h5>Recent Orders</h5>
                        <div className="flex flex-row gap-3">
                            <Dropdown3
                                title={""}
                                placeholder={"Last week"}
                                option={[
                                    { value: "", label: "Last month" },
                                    { value: "", label: "Last 3 months" },
                                ]}
                            />
                            <div>
                                <Button
                                    onClick={() => navigate("/vendor/recent-orders")}
                                    customThemeBtn={"theme-btn2"}
                                    title={"View all"}
                                    className={"!text-sm !font-normal"}
                                    customPadding={"px-2 py-1"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='relative'>
                        <div className="overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-left min-w-max  ">
                                <thead class="text-sm text-[#686868] capitalize">
                                    <tr>
                                        <th scope="col" class="pr-5 py-3 font-normal">
                                            Order
                                        </th>
                                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                            Amount
                                        </th>
                                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                            Status
                                        </th>
                                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                            Invoice
                                        </th>
                                    </tr>
                                </thead>

                                {recentOrderTableData.map((data, index) => (
                                    <tr
                                        key={index}
                                        className="border-b border-[#EEEEEE] text-sm py-3">
                                        <td className='pr-5 py-3 text-[#949494]'>
                                            {data.order}
                                        </td>
                                        <td className={`pr-5 py-3 ${data.status === "Pending" ? " text-[#2F80ED]" : "settled-text"}`}>
                                            {data.amount}
                                        </td>
                                        <td className='pr-5 py-3'>
                                            <span className={`${data.status === "Pending" ? "pending " : data.status === "Packed" ? "packed" : "settled"} px-2.5 py-1 rounded`}>
                                                {data.statusText}
                                            </span>
                                        </td>
                                        <td className="pr-5 py-3 text-center  flex justify-center items-start cursor-pointer">
                                            <Svgs.EyeIcon />
                                        </td>


                                    </tr>
                                ))
                                }

                            </table>
                        </div>
                    </div>
                </RoundedCard>


                <RoundedCard className={"flex flex-col gap-1"}>
                    <div className='flex lg:flex-row justify-between flex-col gap-2'>
                        <div className='flex flex-row gap-4 items-center '>
                            <div>
                                <h5>All Invoices</h5>
                            </div>
                            <div className='flex flex-row gap-7 text-[#2F80ED] underline text-sm'>
                                <button onClick={() => navigate("/vendor/statement")}>
                                    Statement
                                </button>
                                <button onClick={() => navigate("/vendor/credit-memo")}>
                                    Credit Memo
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-row gap-3">
                            <Dropdown3
                                title={""}
                                placeholder={"Last week"}
                                option={[
                                    { value: "", label: "Last month" },
                                    { value: "", label: "Last 3 months" },
                                ]}
                            />
                            <div>
                                <Button
                                    onClick={() => navigate("/vendor/all-invoices")}
                                    customThemeBtn={"theme-btn2"}
                                    title={"View all"}
                                    className={"!text-sm !font-normal"}
                                    customPadding={"px-2 py-1"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='relative'>
                        <div className="overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-left min-w-max  ">
                                <thead class="text-sm text-[#686868] capitalize">
                                    <tr>
                                        <th scope="col" class="pr-5 py-3 font-normal">
                                            Order
                                        </th>
                                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                            Amount
                                        </th>
                                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                            Status
                                        </th>
                                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                            Invoice
                                        </th>
                                    </tr>
                                </thead>

                                {recentOrderTableData.map((data, index) => (
                                    <tr
                                        key={index}
                                        className="border-b border-[#EEEEEE] text-sm py-3">
                                        <td className='pr-5 py-3 text-[#949494]'>
                                            {data.order}
                                        </td>
                                        <td className={`pr-5 py-3 ${data.status === "Pending" ? " packed-text" : "settled-text"}`}>
                                            {data.amount}
                                        </td>
                                        <td className='pr-5 py-3'>
                                            <span className={`${data.status === "Pending" ? "packed " : "settled"} px-2.5 py-1 rounded`}>
                                                {data.status}
                                            </span>
                                        </td>
                                        <td className="pr-5 py-3 text-center  flex justify-center items-start cursor-pointer">
                                            {index === 0 ?
                                                <OutlineButton
                                                    className={"!px-2 !py-1"}
                                                    title={"Pay now"}
                                                />
                                                :
                                                <Svgs.EyeIcon />
                                            }
                                        </td>


                                    </tr>
                                ))
                                }

                            </table>
                        </div>
                    </div>
                </RoundedCard>


            </div>

            <RoundedCard>
                <div className='flex flex-col gap-4'>
                    <div className='flex lg:flex-row flex-col justify-between lg:items-center gap-2'>
                        <div className='text-base min-w-max'>
                            All vendors
                        </div>
                        <div className='flex flex-row gap-3'>
                            <Dropdown3
                                title={""}
                                placeholder={"Top selling"}
                                option={[
                                    { value: "", label: "Lowest price" }
                                ]}
                            />
                            <Dropdown3
                                title={""}
                                placeholder={"Location"}
                                option={[
                                    { value: "", label: "Toronto" }
                                ]}
                            />

                        </div>

                    </div>
                    <div className='grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4  justify-center items-center'>
                        {data?.map((itm, ind) => (
                            <VendorCard
                                key={ind}
                                name={itm?.name}
                                price={itm?.price}
                                preperationTime={itm?.preperationTime}
                                imageSrc={itm?.imageSrc}
                                stockStatus={itm?.stockStatus}
                            />
                        ))}
                    </div>
                </div>
            </RoundedCard>
            <Pagination
                currentPage={1}
                pageSize={5}
                totalCount={26}
                onPageChange={() => console.log("ggd")}
            />
            {/* } */}


        </SellerLayout>
    )
}

export default PremiumInventoryVendorScreen