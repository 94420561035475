import React, { useState } from 'react'
import Svgs from 'Assets/svgs'
import { error_message } from 'Constants/Variables'


const PasswordInput = ({ hideError, onEnterSubmit, title, placeholder, name, value, onChange, onBlur, error, required, showBorder }) => {
    const [show, setshow] = useState(false)
    const HandleKeyPress = (e) => {
        if (e.key.toLowerCase() == 'enter') {
            onEnterSubmit()
        }
    }
    return (
        <div className='flex flex-col gap-2 relative'>
            {title && (
                <div className={`${title == '' ? 'hidden' : ''} text-sm text-primaryBlack flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            <div className={`${error ? "focus-within:border-error" : "focus-within:border-lightBlue"} rounded-[5px] relative flex overflow-hidden transition`}>
                <input onKeyDown={HandleKeyPress} type={show ? 'text' : 'password'}
                    name={name} value={value} onChange={onChange} onBlur={onBlur}
                    className={`${error ? " border-[1.5px] border-error" : showBorder ? "border-[1.5px] border-lightBlue" : ""} min-h-10 rounded-[5px] w-full bg-lightBlue px-4 py-2 text-base`}
                    placeholder={placeholder} />
                {!show
                    ? <div onClick={() => { setshow(!show) }} className='absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer'>
                        <Svgs.EyeHide />
                    </div>
                    : <div onClick={() => { setshow(!show) }} className='absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer'>
                        <Svgs.EyeShow />
                    </div>
                }
            </div>
            {
                !hideError && (error) && (typeof error == "string") ?
                    <p className='text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'>
                        <Svgs.I fill='var(--error-color)' />{error}</p>
                    :
                    (typeof error == "boolean" && error == true)
                        ?
                        <p className='text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'>
                            <Svgs.I fill='var(--error-color)' />{error_message}</p>
                        :
                        ""
            }

        </div>
    )
}

PasswordInput.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    onEnterSubmit: () => { },
    hideError: false,
    required: true,
    showBorder: true
}

export default PasswordInput