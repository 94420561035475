import React from 'react'
import StarsRating from 'Pages/Seller/Orders/Elements/StarsRating'

const CustomerReviewCard = ({ rating, message, name }) => {
    return (
        <div className='border border-[#D9D9D9] p-4 rounded-md flex flex-col justify-between h-full space-y-4 bg-white'>
            <StarsRating rating={rating} className={'flex gap-2 items-center'} />
            <h1>{message}</h1>
            <h1 className='text-lg font-semibold'>{name}</h1>
        </div>
    )
}

export default CustomerReviewCard