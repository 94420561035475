import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const DiscountOfferDetail = () => {
    const navigate = useNavigate()
    const tableData = [
        {
            category: "Fruits",
            orders: "25",
        },
        {
            category: "Spices",
            orders: "25",
        },
        {
            category: "Diary",
            orders: "25",
        },
        {
            category: "Meat",
            orders: "25",
        },
        {
            category: "Poultry",
            orders: "25",
        }
    ]
    return (

        <SellerLayout active={"Discounts"} title={"Discounts & Offers"}
            bodyClassName={'flex flex-col space-y-4'}>

            <BreadCrumb
                first={"Discount & Offers"}
                url={'/discount-offer'}
                second={"Holiday offer"}
            />

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='flex flex-col gap-5'>
                    <RoundedCard className={"flex flex-col gap-3.5"}>
                        <div className='flex flex-row gap-3.5 items-center'>
                            <h2 className='text-base'>Holiday offer</h2>
                            <span className='settled px-2.5 py-2 rounded'>Active</span>
                        </div>

                        <div className='flex flex-col lg:flex-row gap-4 lg:justify-between justify-center'>
                            <div className="flex flex-col gap-2 text-sm text-[#949494]">
                                <div>
                                    <span>Offer created on:{" "}</span>
                                    <span className='text-black'>12-05-2024</span>
                                </div>
                                <div>
                                    <span>Minimum order value:{" "}</span>
                                    <span className='text-black'>$50</span>
                                </div>
                                <div>
                                    <span>Discount type:{" "}</span>
                                    <span className='text-black'>Buy1Get1</span>
                                </div>

                            </div>

                            <div className="flex flex-row gap-6 items-center justify-center">
                                <Button
                                    customPadding={"px-9 py-2"}
                                    title={"Edit"} />

                                <Button
                                    customPadding={"p-2"}
                                    className={"border-black"}
                                    customThemeBtn={"theme-btn2"}
                                    title={<Svgs.DeleteIcon />}
                                />
                            </div>

                        </div>
                    </RoundedCard>

                    <RoundedCard className={"flex flex-col gap-6"}>
                        <div className='flex flex-row justify-between items-center'>
                            <h3 className='font-semibold text-lg'>Offer Activity</h3>
                            <div>
                                <Button
                                    customPadding={"py-2 px-8"}
                                    customThemeBtn={"theme-btn3"}
                                    title={"Add"}
                                />
                            </div>
                        </div>

                        <div className="overflow-x-auto ">
                            <div className='min-w-max'>
                                <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                                    <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                                        <tr>
                                            <th scope="col" class="pr-5 py-4 font-normal">
                                                Product categories
                                            </th>
                                            <th scope="col" class="pr-5 py-4 font-normal whitespace-nowrap">
                                                Orders
                                            </th>
                                            <th scope="col" class="pr-5 py-4 font-normal text-center">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((data, index) => (
                                            <tr
                                                index={index}
                                                class="border-b border-[#EEEEEE]">
                                                <td class="pr-5 py-4 ">
                                                    {data?.category}
                                                </td>
                                                <td class="pr-5 py-4 whitespace-nowrap">
                                                    {data?.orders}
                                                </td>
                                                <td class="pr-5 py-4  min-w-max flex items-center justify-center">
                                                    <Button
                                                        className={"text-sm"}
                                                        customPadding={"py-1 px-2"}
                                                        title={"Remove"}
                                                        customThemeBtn={"theme-btn2"} />
                                                </td>

                                            </tr>
                                        ))
                                        }


                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </RoundedCard>
                </div>

            </div>


        </SellerLayout>
    )
}
