import Svgs from "Assets/svgs";
import React, { useEffect } from "react";

const Popup = ({ customPadding, z, open, close, removeClose, heading, children, size, onclose, noanimation, header, removeHeading, popupHeight, customSizeStyle, customHeight, FooterContent }) => {
    useEffect(() => {
        const rootElement = document.getElementById('root');
        if (rootElement && open) {
            rootElement.classList.add('overflow-hidden');
            rootElement.classList.add('h-[100vh]');
        } else {
            rootElement?.classList.remove('overflow-hidden');
        }
        return () => {
            // Cleanup when the component unmounts or the dependencies change
            rootElement.classList.remove('overflow-hidden');
            rootElement.classList.remove('h-[100vh]');
        };
    }, [open]);

    return (
        <>
            {open ? (
                <div
                    className={`fixed inset-0 h-[100vh] w-[100vw] ${z ? z : "z-[4444]"} flex justify-center items-center`}>
                    <div
                        className="bg-[#ffffff]/50 absolute inset-0 z-[1]"
                    ></div>
                    <div
                        className={`relative overflow-hidden shadow-2xl bg-white  rounded-2xl ${customSizeStyle ? customSizeStyle : size == "xs"
                            ? "lg:w-[35vw] xl:w-[30vw]"
                            : size == "sm"
                                ? "lg:w-[45vw] xl:w-[40vw]"
                                : size == "lg"
                                    ? "lg:w-[65vw]"
                                    : size == "xl"
                                        ? "lg:w-[90vw]"
                                        : "lg:w-[50vw]"
                            } w-[90vw] z-[2] ${!noanimation && 'slide-in-elliptic-top-fwd'} ${customHeight ? customHeight : ''}`}
                    >
                        {header && <div className="sticky top-0 z-[22]">
                            <div className={`flex justify-between items-center gap-4 ${!removeHeading ? "border-b-2 border-[#EEEEEE] mx-4" : ""} px-1 py-4`}>
                                {!removeHeading &&
                                    <>
                                        <h1
                                            className={` normal-case font-semibold text-lg text-left`}>
                                            {heading ? heading : "Add prop heading"}
                                        </h1>
                                        <hr />
                                    </>
                                }
                                {!removeClose &&
                                    <div className="flex flex-1 w-full items-end justify-end">
                                        <div className="cursor-pointer" onClick={() => {
                                            const rootElement = document.getElementById('root');
                                            rootElement?.classList.remove('overflow-hidden')
                                            close(false);
                                            onclose();
                                        }}>
                                            <Svgs.CrossIcon2 />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>}
                        <div className={`${customPadding ? customPadding : ''} bg-white  p-[1.2rem] overflow-y-auto overflow-x-hidden scroll-hidden ${popupHeight ? popupHeight : "lg:max-h-[75vh] max-h-[65vh]"}`}>
                            {children ? children : "Add prop children!"}
                        </div>
                        {FooterContent &&
                            <>
                                <div className='border border-b-[#E8E6EA] bg-white mt-2 !mx-0'></div>
                                {FooterContent}
                            </>
                        }
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

Popup.defaultProps = {
    close: () => { },
    open: true,
    header: true,
    children: <></>,
    onclose: () => { },
    removeClose: false,
    removeHeading: false
};

export default Popup;
