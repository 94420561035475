import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import React from 'react'

const CouponCard = ({ name, user, price }) => {
    return (
        <div className='border-2 rounded-lg border-[#EEEEEE] p-3 flex gap-4'>
            <Svgs.CouponDummyImage />
            <div className='flex flex-col space-y-2'>
                <h1>{name}</h1>
                <div className='flex xs:flex-row flex-col justify-between gap-2 text-xs'>
                    <p>By: {user}</p>
                    <p className='text-[#686868]'>Min. Order: ${price}</p>
                </div>
                <div className='flex gap-3'>
                    <Button customPadding="!px-4 py-2" className={'rounded-lg w-fit'}>
                        <div className='text-sm'>
                            Accept
                        </div>
                    </Button>
                    <span className={`border border-[#949494] text-[#3B3B3B] px-4 py-1.5 w-fit rounded-lg text-sm font-bold flex justify-center items-center`}>
                        Reject
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CouponCard