import Svgs from 'Assets/svgs'
import DateInput from 'Components/Elements/DateInput'
import Dropdown3 from 'Components/Elements/Dropdown3'
import RoundedCard from 'Components/Elements/RoundedCard'
import React from 'react'
// import DatePicker from 'react-modern-calendar-datepicker'
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';

function Salaries() {
    const tableData = [
        {
            period: "01-07-2024- 15-07-2-24",
            name: "Anna",
            payment: "Bi-weekly",
            pay: "$22/hour",
            status: "Paid"
        },
        {
            period: "01-07-2024- 15-07-2-24",
            name: "Catherine",
            payment: "Bi-weekly",
            pay: "$22/hour",
            status: "Pending"
        },
        {
            period: "01-07-2024- 15-07-2-24",
            name: "Orton",
            payment: "Bi-weekly",
            pay: "$22/hour",
            status: "Paid"
        },
    ]

    return (
        <RoundedCard>
            <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                <h1 className='text-lg font-semibold'>Salaries </h1>
                <div className='flex gap-2 justify-between items-center'>
                    <Dropdown3
                        title={''}
                        required={false}
                        placeholder={'All'}
                        option={[
                            { value: "This week", label: "This week" },
                            { value: "", label: "Today" }
                        ]}
                    />
                    {/* <DatePicker inputPlaceholder='Calendar' /> */}
                    <DateInput />
                </div>
            </div>
            <div className="relative">
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-left min-w-max  ">
                        <thead class="text-sm text-[#686868] capitalize">
                            <tr>
                                <th scope="col" class="pr-5 py-3 font-normal">
                                    Time period
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                    Employee name
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                    Payments
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                    Pay
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                    Status
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal text-center">
                                    View Details
                                </th>
                            </tr>
                        </thead>

                        {tableData.map((data, index) => (
                            <tr
                                key={index}
                                className="border-b border-[#EEEEEE] text-sm py-3">
                                <td className='pr-5 py-3'>
                                    {data.period}
                                </td>
                                <td className='pr-5 py-3'>
                                    {data.name}
                                </td>
                                <td className='pr-5 py-3'>
                                    {data.payment}
                                </td>
                                <td className='pr-5 py-3'>
                                    {data.pay}
                                </td>
                                <td className='pr-5 py-3'>
                                    <span className={`${data.status === "Pending" ? " packed " : "settled"} px-2.5 py-1 rounded`}>
                                        {data.status}
                                    </span>
                                </td>
                                <td className="pr-5 py-3 text-center  flex justify-center items-start cursor-pointer">
                                    <Svgs.EyeIcon />
                                </td>


                            </tr>
                        ))
                        }

                    </table>
                </div>
            </div>
        </RoundedCard>)
}

export default Salaries