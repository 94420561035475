import React from "react";
import Image from "Components/Elements/Image";
import Button from "Components/Elements/Button";
import useSellerAccountSetup from "./helper";
import StoreDetails from "./Steps/StoreDetails";
import SetupAccount from "./Steps/SetupAccount";
import LegalDocuments from "./Steps/LegalDocuments";

const SellerAccountSetup = () => {
    const { navigate, step, setStep } = useSellerAccountSetup()

    return (
        <div className="min-h-[100vh] grid grid-cols-1 lg:grid-cols-10">
            <div className="mx-auto relative w-full flex flex-col col-span-1 lg:col-span-6">
                <div className="absolute top-6 sm:left-10 left-8">
                    <Image src={'/logo.png'} className={'h-full w-full object-cover'} />
                </div>
                <div className="sm:px-16 pt-4 absolute top-20 sm:left-32 px-8 sm:max-w-[80%] w-full">
                    {step === 1 && <SetupAccount />}
                    {step === 2 && <StoreDetails />}
                    {step === 3 && <LegalDocuments />}
                    {step === 4 &&
                        <div className="flex flex-col gap-4 text-primaryBlack mt-56 mb-10">
                            <h4 className="font-bold text-3xl">Congratulations! Your Store Is Successfully Added.</h4>
                            <h1>You can manage product and payments in the store.</h1>
                        </div>}
                    <div className="mt-3">
                        <Button
                            text={step <= 3 ? 'Continue' : 'Go To My Store'}
                            className={'w-full'}
                            onClick={() => {
                                if (step <= 3) {
                                    setStep(step + 1)
                                } else {
                                    navigate('/login')
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div
                className="hidden lg:block lg:col-span-4 h-screen bg-cover bg-center"
                style={{
                    backgroundImage: `url('/images/LoginImage.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            />
        </div>
    );
};

export default SellerAccountSetup;
