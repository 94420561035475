import React from 'react'
import CheckBox from './Checkbox'

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const WeekDaysSelection = () => {
    return (
        <div className='flex flex-col space-y-6 justify-between'>
            {days?.map((itm, ind) =>
                <CheckBox
                    name={itm}
                    id={itm}
                    label={itm}
                    className={"accent-primary h-[1rem] w-[1rem]"}
                    customGap={'gap-6'}
                />
            )}
        </div>
    )
}

export default WeekDaysSelection