import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import CheckBox from 'Components/Elements/Checkbox'
import Dropdown from 'Components/Elements/Dropdown'
import Input from 'Components/Elements/Input'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const CreateCateringPlan = () => {
    const navigate = useNavigate()
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)

    return (
        <SellerLayout active={'Other Services'}
            title={'Other services'} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"Other Services"}
                url={"/other-services"}
                second={"Catering service"}
                secondurl={"/other-services/catering-services"}
                third={"Create plan"}
            />

            <RoundedCard className={"flex flex-col gap-11"}>
                <div className='flex flex-col gap-6'>

                    <div className='grid lg:grid-cols-3 grid-cols-1 gap-6'>

                        <div className='grid lg:grid-cols-2 lg:col-span-2 grid-cols-1 gap-6 items-center'>
                            <Input
                                title={"Plan name"}
                                placeholder={"Type here"}
                                required={false}
                            />
                            <Dropdown
                                title={"Plan type"}
                                placeholder={"Per plate order"}
                            />
                            <Dropdown
                                title={"Categories"}
                                placeholder={"Wedding"}
                            />
                        </div>
                        <div className='flex flex-col gap-2.5 '>
                            <p>Plan description</p>
                            <textarea
                                className='resize-none bg-[#F2F2F2] p-2.5 rounded text-sm'
                                placeholder='Type here'
                                rows={6}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-center flex-col gap-9'>
                    <div className='flex flex-row flex-wrap items-center gap-1'>
                        <CheckBox />
                        <p className='font-inter text-base'>Yes, I agreee to the <span className='text-[#2F80ED] '>Terms & conditions</span> and <span className='text-[#2F80ED] '>Privacy Policy</span> </p>
                    </div>

                    <Button
                        onClick={() => setOpenSuccessPopup(true)}
                        customPadding={"px-9 py-2"}
                        title={"Create plan"}
                    />

                </div>
            </RoundedCard>


            <SuccessPopup
                open={openSuccessPopup}
                title={"Meal plan created successfully!"}
                onClick={() => {
                    setOpenSuccessPopup(false)
                    navigate("/other-services/tiffin-services")
                }}
            />
        </SellerLayout>
    )
}
