import { useState } from "react"


const useSellerHelpSupport = () => {
    const [activeTab, setActiveTab] = useState('Dashboard Access')

    return {
        activeTab, setActiveTab
    }
}
export default useSellerHelpSupport