import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import SellerOrderDetailTable from 'Utility/Tables/SellerOrderDetailTable'
import React from 'react'
import CartDetailCard from '../Elements/CartDetailCard'
import VerticalStatusSlider from '../Elements/VerticalStatusSlider'
import StarsRating from '../Elements/StarsRating'
import { useLocation } from 'react-router-dom'

const SellerOrderDetail = () => {
    const { state } = useLocation()

    return (
        <SellerLayout active={'Orders'} title={"Order Detail"} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"Orders"}
                url={'/orders'}
                second={"Order Detail"}
            />
            {state?.status === "Received" &&
                <div className='flex gap-6 items-center'>
                    <Button
                        title={'Decline Order'}
                        customThemeBtn={'theme-btn2'}
                        customPadding={'px-3 py-2'}
                    />
                    <Button
                        title={'Accept Order'}
                        customPadding={'px-3 py-2.5'}
                    />
                </div>
            }

            <RoundedCard>
                <h1 className='mb-2'>Customer Details</h1>
                <div className='flex lg:flex-row flex-col lg:gap-4 gap-2 justify-between'>
                    <div className='flex flex-col space-y-2'>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Customer Name:
                            </p>
                            <p className='text-sm'>
                                Chanice Kaur
                            </p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Address:
                            </p>
                            <p className='text-sm'>
                                234, Humming Bird Street, Ottawa, Ontario, 12345
                            </p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Contact:
                            </p>
                            <p className='text-sm'>
                                + 123 456 789
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col space-y-2 lg:text-right lg:items-end'>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Order ID:
                            </p>
                            <p className='text-sm'>
                                #234516
                            </p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Date & Time:
                            </p>
                            <p className='text-sm'>
                                Mar 12, 2024, 09:10 AM
                            </p>
                        </div>
                        <span className={`${state?.status === "Received" ? "recieved" :
                            state?.status === "Packed" ? "packed" :
                                state?.status === "Delivered" ? "delivered" :
                                    state?.status === "Dispatched" ? "pending" :
                                        "cancelled"}
                                         p-2 w-fit float-end rounded-md text-sm font-normal flex justify-center items-center`}>
                            {state?.status}
                        </span>
                    </div>
                </div>
            </RoundedCard>
            <div className='grid lg:grid-cols-10 grid-cols-1 gap-3'>
                <RoundedCard className={'lg:col-span-6'}>
                    <h1 className='mb-2'>Item List</h1>
                    <div className="relative">
                        <SellerOrderDetailTable />
                    </div>
                </RoundedCard>
                <RoundedCard className={'lg:col-span-4'}>
                    <div className='flex gap-2 justify-between items-center mb-4'>
                        <h1 className=''>Bill Detail</h1>
                        <p className='text-[#2376F5] text-xs'>View Payment Detail</p>
                    </div>
                    <CartDetailCard status={state?.status} />
                </RoundedCard>
            </div>


            {state?.status !== "Received" &&
                (state?.status === "Packed" ?
                    <RoundedCard>
                        <h1 className='mb-2'>History</h1>
                        <VerticalStatusSlider
                            parentClass={"pr-4 pt-4"}
                            steps={[
                                {
                                    name: <Button
                                        customPadding={"py-1 px-1"}
                                        className={"text-sm"}
                                        title={"Assign to Delivery Partner"} />,
                                    type: "dispatced"
                                },
                                { name: "Items are packed into shipping containers.", type: "packed", time: "Mar 10, 2024, 09:12 AM" },
                                { name: "Order #2423 received", type: "placed", time: "Mar 09, 2024, 09:12 AM" }
                            ]}
                        />
                    </RoundedCard>
                    :
                    state?.status === "Delivered" ?
                        <>
                            <RoundedCard>
                                <h1 className='mb-2'>History</h1>
                                <VerticalStatusSlider
                                    parentClass={"pr-4 pt-4"}
                                    steps={[
                                        { name: "Package is successfully delivered to the customer.", type: "delivered", time: "Mar 12, 2024, 09:12 AM" },
                                        { name: "Package is en route to the customer's address.", type: "dispatced", time: "Mar 11, 2024, 09:12 AM" },
                                        { name: "Items are packed into shipping containers.", type: "packed", time: "Mar 10, 2024, 09:12 AM" },
                                        { name: "Order #2423 received", type: "placed", time: "Mar 09, 2024, 09:12 AM" }
                                    ]}
                                />
                            </RoundedCard>

                            <RoundedCard>
                                <h1 className='mb-2 font-semibold'>Review</h1>
                                <div className='flex gap-4 justify-between'>
                                    <div className='flex flex-col space-y-2 text-xs'>
                                        <p>Exceptional service! Received my fresh groceries promptly through the app. Quality and convenience at its finest!</p>
                                        <div className='flex gap-2'>
                                            <p className='text-[#949494]'>
                                                Date & Time:
                                            </p>
                                            <p className=''>
                                                Mar 12, 2024, 09:10 AM
                                            </p>
                                        </div>
                                    </div>
                                    <StarsRating
                                        rating={4}
                                    />
                                </div>
                                <div className='mt-4'>
                                    <Button
                                        title={'Reply'}
                                        customPadding={'py-1 px-2'}
                                    />
                                </div>
                            </RoundedCard>
                        </>
                        :
                        state?.status === "Dispatched" ?
                            <RoundedCard>
                                <h1 className='mb-2'>History</h1>
                                <VerticalStatusSlider
                                    parentClass={"pr-4 pt-4"}
                                    steps={[
                                        { name: <Button customPadding={"px-2 py-1"} title={"Track Order"} />, type: "delivered" },
                                        { name: "Package is en route to the customer's address.", type: "dispatced", time: "Mar 11, 2024, 09:12 AM" },
                                        { name: "Items are packed into shipping containers.", type: "packed", time: "Mar 10, 2024, 09:12 AM" },
                                        { name: "Order #2423 received", type: "placed", time: "Mar 09, 2024, 09:12 AM" }
                                    ]}
                                />
                            </RoundedCard>
                            :
                            <>
                                <RoundedCard>
                                    <h1 className='mb-2'>History</h1>
                                    <VerticalStatusSlider
                                        parentClass={"pr-4 pt-4"}
                                        steps={[
                                            { name: "Order is cancelled.", cancelReason: "Products aren’t required anymore.", type: "cancelled", time: "Mar 13, 2024, 09:12 AM" },
                                            // { name: "Package is successfully delivered to the customer.", type: "delivered", time: "Mar 12, 2024, 09:12 AM" },
                                            // { name: "Package is en route to the customer's address.", type: "dispatced", time: "Mar 11, 2024, 09:12 AM" },
                                            // { name: "Items are packed into shipping containers.", type: "packed", time: "Mar 10, 2024, 09:12 AM" },
                                            { name: "Order #2423 received", type: "placed", time: "Mar 09, 2024, 09:12 AM" }
                                        ]}
                                    />
                                </RoundedCard>
                            </>)
            }

        </SellerLayout>
    )
}

export default SellerOrderDetail