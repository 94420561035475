import Svgs from 'Assets/svgs'
import React from 'react'

const AddNewPaymentMethod = () => {
    return (
        <div className='border-dashed border-2 rounded-lg border-[#EEEEEE] p-3 flex gap-4 min-h-[140px]'>
            <Svgs.PlusFill />
            <div className='flex flex-col space-y-4'>
                <h1 className='text-primary'>Add New Payment Method</h1>
                <div className='flex gap-2 items-center'>
                    <Svgs.MasterCardIcon />
                    <Svgs.VisaIcon />
                    <Svgs.ApplePayIcon />
                </div>
            </div>
        </div>
    )
}

export default AddNewPaymentMethod