import React from 'react'
import { useNavigate } from 'react-router-dom'

export const CartButton = () => {
    const navigate = useNavigate()
    return (
        <div
            onClick={() => navigate("/vendor/cart")}
            className=' fixced  rig ht-5 botto m-5  flex justify-end items-center  '>
            <div className=' cursor-pointer hover:scale-105 ease-in-out duration-300 bg-white flex flex-row items-center  rounded-full border gap-3.5'>
                <div className='bg-btn-theme  px-4 py-3 rounded-full'>
                    <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 0C9.39313 0 7.85208 0.632141 6.71586 1.75736C5.57963 2.88258 4.9413 4.4087 4.9413 6V7H4.10318C3.25306 6.99911 2.43535 7.32297 1.82029 7.90413C1.20522 8.4853 0.84019 9.279 0.801194 10.12L0.00346571 26.57C-0.0169839 27.0123 0.0532237 27.4542 0.209856 27.869C0.366489 28.2837 0.606303 28.6628 0.914843 28.9833C1.22338 29.3038 1.59426 29.5591 2.00512 29.7338C2.41598 29.9086 2.85831 29.9991 3.30546 30H18.6945C19.1417 29.9991 19.584 29.9086 19.9949 29.7338C20.4057 29.5591 20.7766 29.3038 21.0852 28.9833C21.3937 28.6628 21.6335 28.2837 21.7901 27.869C21.9468 27.4542 22.017 27.0123 21.9965 26.57L21.1988 10.12C21.1598 9.279 20.7948 8.4853 20.1797 7.90413C19.5646 7.32297 18.7469 6.99911 17.8968 7H17.0587V6C17.0587 4.4087 16.4204 2.88258 15.2841 1.75736C14.1479 0.632141 12.6069 0 11 0ZM6.96087 6C6.96087 4.93913 7.38642 3.92172 8.1439 3.17157C8.90139 2.42143 9.92876 2 11 2C12.0712 2 13.0986 2.42143 13.8561 3.17157C14.6136 3.92172 15.0391 4.93913 15.0391 6V7H6.96087V6ZM19.1792 10.21L19.977 26.67C19.9833 26.842 19.9553 27.0135 19.8947 27.1748C19.8341 27.3361 19.7419 27.484 19.6235 27.61C19.5027 27.7329 19.3582 27.8307 19.1987 27.8977C19.0392 27.9647 18.8678 27.9994 18.6945 28H3.30546C3.1322 27.9994 2.96079 27.9647 2.80127 27.8977C2.64176 27.8307 2.49733 27.7329 2.37646 27.61C2.25808 27.484 2.16594 27.3361 2.1053 27.1748C2.04466 27.0135 2.0167 26.842 2.02303 26.67L2.82076 10.21C2.83634 9.88368 2.97831 9.57586 3.21714 9.35052C3.45597 9.12517 3.77331 8.99964 4.10318 9H17.8968C18.2267 8.99964 18.544 9.12517 18.7829 9.35052C19.0217 9.57586 19.1637 9.88368 19.1792 10.21Z" fill="white" />
                        <path d="M6.31447 12.1895C6.87216 12.1895 7.32425 11.7417 7.32425 11.1895C7.32425 10.6372 6.87216 10.1895 6.31447 10.1895C5.75678 10.1895 5.30469 10.6372 5.30469 11.1895C5.30469 11.7417 5.75678 12.1895 6.31447 12.1895Z" fill="white" />
                        <path d="M15.6895 12.1895C16.2472 12.1895 16.6993 11.7417 16.6993 11.1895C16.6993 10.6372 16.2472 10.1895 15.6895 10.1895C15.1318 10.1895 14.6797 10.6372 14.6797 11.1895C14.6797 11.7417 15.1318 12.1895 15.6895 12.1895Z" fill="white" />
                    </svg>

                </div>
                <p className='pr-5 font-semibold text-base'>Cart is Empty</p>



            </div>
        </div >
    )
}
