import React from "react";
import Image from "Components/Elements/Image";
import Svgs from "Assets/svgs";
import Input from "Components/Elements/Input";
import PasswordInput from "Components/Elements/PasswordInput";
import Button from "Components/Elements/Button";
import CheckBox from "Components/Elements/Checkbox";
import useSignup from "./helper";

const Signup = () => {
    const { navigate } = useSignup()

    return (
        <div className="min-h-[100vh] grid grid-cols-1 lg:grid-cols-10">
            <div className="mx-auto relative w-full flex flex-col col-span-1 lg:col-span-6">
                <div className="absolute top-6 sm:left-10 left-8">
                    <Image src={'/logo.png'} className={'h-full w-full object-cover'} />
                </div>
                <div className="sm:px-16 pt-4 absolute top-20 sm:left-32 px-8 sm:w-fit w-full">
                    <div className="flex flex-col space-y-2 text-primaryBlack">
                        <h4 className="font-bold text-3xl">Create A New Account</h4>
                        <h1>Please enter the required details to create your account securely.</h1>
                    </div>
                    <div className="sm:pr-12 flex flex-col space-y-5">
                        <button className="min-h-10 mt-8 gap-3 w-full text-base flex items-center justify-center rounded-md font-bold border border-[#969696] px-4 py-2 outline-none ring-[#C0C0C0] ring-offset-2 focus:ring-2">
                            <Svgs.Google />
                            Continue With Google
                        </button>
                        <div class="flex items-center text-xs">
                            <div class="flex-1 border-t border-darkBackground"></div>
                            <div class="mx-1 text-darkBackground">OR</div>
                            <div class="flex-1 border-t border-darkBackground"></div>
                        </div>
                        <Input
                            title={'Email Address or Mobile Number'}
                            placeholder={'Enter Email Address or Mobile Number'}
                            required={false}
                        />
                        <PasswordInput
                            title={'Password'}
                            placeholder={'Enter Password'}
                            required={false}
                        />
                        <PasswordInput
                            title={'Confirm Password'}
                            placeholder={'Enter Confirm Password'}
                            required={false}
                        />
                        <CheckBox
                            name={'remember-me'}
                            required={true}
                            id={'remember-me'}
                            label={<div>
                                I agree to all the <span className="text-customBlue">Terms & Conditions</span> and <span className="text-customBlue">Privacy Policy</span>
                            </div>}
                        />
                        <Button
                            text={'Signup'}
                            className={'w-full'}
                            onClick={() => {
                                navigate('/otp-verification')
                            }}
                        />
                        <div className="mx-auto text-primaryBlack text-sm">Already have an account? <span className="text-customBlue cursor-pointer" onClick={() => { navigate('/login') }}>Login</span></div>
                    </div>
                </div>
            </div>
            <div
                className="hidden lg:block lg:col-span-4 h-screen bg-cover bg-center"
                style={{
                    backgroundImage: `url('/images/LoginImage.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            />
        </div>
    );
};

export default Signup;
