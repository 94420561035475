import React, { useState } from 'react'
import useSellerSidebar from './helper'
import { usePremium } from 'Context/PremiumContext'

const SellerSideBar = ({ openSidebar, active }) => {
    const { navigate, sidebarRoutes, defaultRoute, handleLinkClick, sidebarRoutesWithPremium } = useSellerSidebar(active)

    const { isPremium } = usePremium()

    return (
        <>
            <aside id="asidebar" className={`fixed bg-white top-0 left-0 z-[55] h-screen w-64 transition-transform ${openSidebar ? "" : '-translate-x-full'}  md:translate-x-0 inset-0 backdrop-blur-[2px] md:backdrop-blur-0`}>
                <div className="w-64 h-screen pt-14 md:pt-0">
                    <div className="overflow-y-auto py-5 px-3 h-full">
                        <div onClick={() => { navigate(defaultRoute ? defaultRoute : "/dashboard") }} className="hidden md:flex items-center justify-start mr-4 mb-6 pl-2 cursor-pointer">
                            <img src="/logo.png" className="mr-3 h-12" alt="Logo" />
                        </div>
                        <ul className="space-y-2 ">

                            {(isPremium ? sidebarRoutesWithPremium : sidebarRoutes)?.map(itm => {
                                return (
                                    <li>
                                        <button
                                            onClick={() => { handleLinkClick(itm) }}
                                            type="button"
                                            className={`flex items-center p-1 md:p-3 w-full text-sm rounded-lg transition duration-75 ${itm?.active ? 'activeLink' : 'side-nav'}`}
                                        >
                                            <p> {itm?.icon}</p>
                                            <span className="flex-1 ml-3 font-medium text-left whitespace-wrap">{itm.heading}</span>
                                        </button>
                                    </li>
                                )
                            })}

                        </ul>
                    </div>
                </div>
            </aside>
        </>
    )
}

export default SellerSideBar