export const RESTAURANT_TYPE = [
    { label: 'Grocery Store', value: "Grocery Store" },
    { label: 'Departmental Store', value: "Departmental Store" },
    { label: 'Restaurant', value: "Restaurant" }
]

export const SORT_BY = [
    { label: 'Popular', value: "Popular" },
    { label: 'Near Me', value: "Near Me" },
    { label: 'Price (Low to High)', value: "Price (Low to High)" },
    { label: 'Price (High to Low)', value: "Price (High to Low)" },
    { label: 'Rating (High to Low)', value: "Rating (High to Low)" }
]

export const CUISINE_TYPE = [
    { label: 'Indian', value: "Indian" },
    { label: 'Italian', value: "Italian" },
    { label: 'Chinese', value: "Chinese" },
    { label: 'Mexican', value: "Mexican" },
    { label: 'Continental', value: "Continental" }
]

export const DISTANCE = [
    { label: '1 Mile Away', value: "1 Mile Away" },
    { label: '2 Miles Away', value: "2 Miles Away" },
    { label: '3 Miles Away', value: "3 Miles Away" },
    { label: '4 Miles Away', value: "4 Miles Away" },
    { label: '5 Miles Away', value: "5 Miles Away" },
]