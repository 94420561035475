import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Image from 'Components/Elements/Image'
import Input from 'Components/Elements/Input'
import Popup from 'Components/Elements/Popup'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'
import { copyToClipBoard } from 'Constants/Functions/copyToClipboard'
import SellerReferEarnTable from 'Utility/Tables/SellerReferEarnTable'
import React, { useState } from 'react'

const SellerReferEarn = () => {
    const [openRedeemPopup, setOpenRedeemPopup] = useState(false)
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
    return (
        <SellerLayout active={'ReferEarn'} title={'Refer & Earn'} bodyClassName={'flex flex-col space-y-4'}>
            <RoundedCard>
                <h1 className='text-lg font-semibold mb-2'>Refer To A Store/ Restaurant Owner and Earn 100 Points ($1) Each!</h1>
                <div className='lg:grid xl:grid-cols-3 lg:grid-cols-2 gap-16 flex flex-col justify-between w-full  items-center'>
                    <div className='items-center justify-center flex flex-col space-y-2 w-full'>
                        <div className=' flex flex-col w-full'>
                            <Input
                                title={'Share Your Link'}
                                readOnly={true}
                                required={false}
                                value={'https:// LSUbaske188343666655'}
                                name={'share_link'}
                                rightIconClassName={'right-[0.8px] !bottom- !top-none'}
                                showBorder={false}
                                rightIcon={<>
                                    <Button customPadding="!px-4 py-2" customThemeBtn={"flex items-center justify-center bg-transparent !py-2.5 h-full"}
                                        onClick={() => {
                                            copyToClipBoard('https:// LSUbaske188343666655')
                                        }}
                                    >
                                        <Svgs.CopySvg />
                                    </Button>
                                </>}
                                placeholder={"Enter your Email Address"}
                            />
                            <div class="flex items-center text-xs mb-4 mt-6">
                                <div class="flex-1 border-t border-darkBackground"></div>
                                <div class="mx-1 text-darkBackground">OR</div>
                                <div class="flex-1 border-t border-darkBackground"></div>
                            </div>
                            <Input
                                title={'Invite By Email'}
                                required={false}
                                showBorder={false}
                                placeholder={'Enter or Paste Email Address'}
                                name={'share_link'}
                            />
                            <div className='mt-6'>
                                <Button customPadding="!px-4 py-2" className={'rounded-lg'}>
                                    <div className='text-sm'>
                                        Send Invite
                                    </div>
                                </Button>
                            </div>

                        </div>
                    </div>
                    <div className='col-span- col-start- items-center justify-center  xl:flex hidden xl:mt-14 lg:mt-20 mt-0'>
                        <Image src={'/images/Hands_Share_Referral.png'} />
                    </div>
                    <div className='flex items-center justify-center lg:justify-end'>
                        <div className='flex border border-[#AEAEAE] shadow-md h-full py-2.5 px-10 lg:py-5 lg:px-16 rounded-lg items-center justify-center'>
                            <div className='flex flex-col gap-12 items-center'>
                                <p className='font-semibold text-lg'> My Rewards</p>
                                <p className='text-[#20AE5C] font-semibold text-[64px]'>$50.00</p>

                                <div>
                                    <Button
                                        onClick={() => setOpenRedeemPopup(true)}
                                        customPadding={" py-1 px-8"}
                                        className={"text-sm"}
                                        title={"Redeem"}
                                    />
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </RoundedCard>
            <RoundedCard>
                <SellerReferEarnTable />
            </RoundedCard>

            <Popup
                open={openRedeemPopup}
                removeClose={false}
                removeHeading={false}
                header={false}
                customSizeStyle={"lg:w-[25vw] "}
            >
                <div className='flex flex-col gap-6 items-center justify-center px-12'>
                    <h3 className='font-roboto font-bold text-xl text-gray-1  text-center'>
                        Redeem reward points
                    </h3>

                    <form className='flex flex-col gap-6 w-full '>
                        <Input
                            type={"number"}
                            title={"Total reward points"}
                            placeholder={"200"}
                            required={false}
                        />
                        <Input
                            readOnly={true}
                            title={"Total value"}
                            placeholder={"$20"}
                            required={false}
                        />
                        <Input
                            title={"Points to redeem"}
                            placeholder={"Type here"}
                            required={false}
                        />
                        <Input
                            title={"You will get"}
                            placeholder={"Type here"}
                            required={false}
                        />
                        <div className='flex items-center justify-center'>
                            <Button
                                onClick={() => {
                                    setOpenRedeemPopup(false)
                                    setOpenSuccessPopup(true)
                                }}
                                customPadding={"px-9 py-2"}
                                title={"Redeem"}
                            />
                        </div>
                    </form>
                </div>
            </Popup>


            <SuccessPopup
                open={openSuccessPopup}
                onClick={() => {
                    setOpenSuccessPopup(false)
                }}
                title={"200 points redeemed successfully!"}
            />
        </SellerLayout >
    )
}

export default SellerReferEarn