import React, { useState } from 'react'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import Dropdown2 from 'Components/Elements/Dropdown2'
import { TIMELINE } from 'Constants/Data/OrdersDropdown'
import Button from 'Components/Elements/Button'
import Svgs from 'Assets/svgs'
import CouponCard from './Elements/CouponCard'
import { SellerCouponTable } from 'Utility/Tables/SellerCouponTable'
import { useNavigate } from 'react-router-dom'

const data = [
    { name: "Flat 10% off on ABC store", user: "DA Grocery Store", price: "50" },
    { name: "Flat 10% off on ABC store", user: "DA Grocery Store", price: "50" },
    { name: "Flat 10% off on ABC store", user: "DA Grocery Store", price: "50" }
]

const SellerCoupons = () => {
    const navigate = useNavigate()
    const [activeButton, setActiveButton] = useState("Active")

    return (
        <SellerLayout active={'Coupons'} title={"My Coupons"} bodyClassName={'flex flex-col space-y-4'}>

            <RoundedCard>
                <div className='flex justify-between gap-4 items-center mb-4'>
                    <h1 className='text-lg font-semibold'>Requests Received</h1>
                    <p className='text-sm text-primary font-bold'>View All</p>
                </div>
                <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4 justify-between'>
                    {data?.map((itm, ind) => (
                        <CouponCard
                            key={ind}
                            name={itm?.name}
                            price={itm?.price}
                            user={itm?.user}
                        />
                    ))}
                </div>
            </RoundedCard>
            <RoundedCard className={'min-h-[65vh]'}>
                <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                    <div className='flex flex-row items-center justify-center gap-4'>
                        <Button
                            onClick={() => setActiveButton("Active")}
                            customThemeBtn={activeButton !== "Active" && "theme-btn2"}
                            customPadding={"px-3 py-1"}
                            title={"Active"}
                        />
                        <Button
                            onClick={() => setActiveButton("Requested")}
                            customThemeBtn={activeButton !== "Requested" && "theme-btn2"}
                            customPadding={"px-3 py-1"}
                            title={"Requested"}
                        />

                    </div>
                    <div className='flex sm:flex-row flex-col gap-2 sm:items-center justify-between'>
                        <Dropdown2
                            title={''}
                            required={false}
                            placeholder={'Monthly'}
                            option={TIMELINE}
                        />
                        <Dropdown2
                            title={''}
                            required={false}
                            placeholder={'My Coupons'}
                        />
                        <Button
                            onClick={() => navigate("/request-coupon")}
                            customPadding={'px-3 py-2'} className={"!rounded-md"}>
                            <div className='flex gap-2 items-center justify-between text-sm'>
                                <Svgs.SideArrow />
                                Send Coupon Request
                            </div>
                        </Button>
                    </div>
                </div>

                <div className="relative pt-6 lg:pt-9">
                    <SellerCouponTable />
                </div>
            </RoundedCard>
        </SellerLayout>
    )
}

export default SellerCoupons