import React from 'react'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Dropdown from 'Components/Elements/Dropdown'
import Image from 'Components/Elements/Image'
import Input from 'Components/Elements/Input'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import { useNavigate } from 'react-router-dom'

const SellerProfile = () => {
    const navigate = useNavigate()

    return (
        <SellerLayout active={'My Profile'} title={"My Profile"} bodyClassName={'flex flex-col space-y-4'}>
            {/* <BreadCrumb
                first={"Dashboard"}
                second={"My Profile"}
                url={'/dashboard'}
            /> */}
            <RoundedCard className={'flex flex-col space-y-4'}>
                <Image customLoaderRounded={"rounded-full"} customLoaderHeight={"h-[60px]"} customLoaderWidth={'w-[60px]'} className={'w-[60px] h-[60px] rounded-full object-cover'} src={'/images/profile.png'} />
                <div className='grid md:grid-cols-3 grid-cols-1 gap-4 justify-between'>
                    <Input
                        title={'Full Name'}
                        placeholder={'Enter Full Name'}
                        required={false}
                        readOnly={true}
                    />
                    <Input
                        title={'Email'}
                        placeholder={'Enter Email'}
                        required={false}
                        readOnly={true}
                    />
                    <Input
                        title={'Phone Number'}
                        placeholder={'Enter Phone Number'}
                        required={false}
                        readOnly={true}
                    />
                    <Input
                        title={'Occupation'}
                        placeholder={'Enter Occupation'}
                        required={false}
                        readOnly={true}
                    />
                    <Dropdown
                        title={'Province'}
                        placeholder={'Enter Province'}
                        option={[{ label: "hello", value: "hello" },
                        { label: "hello", value: "hello" },
                        { label: "hello", value: "hello" },
                        { label: "hello", value: "hello" }]}
                        disabled={true}
                    />
                </div>
                <div className={`cursor-pointer text-sm px-3 justify-start theme-btn py-1.5 w-fit`}
                    onClick={() => { navigate("/edit-profile") }}
                >
                    <h2>Edit Profile</h2>
                </div>
            </RoundedCard>
        </SellerLayout>
    )
}

export default SellerProfile