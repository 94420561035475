import Svgs from 'Assets/svgs'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const VendorAllInvoicesTable = () => {
    const navigate = useNavigate()

    const tableData = [
        {
            date: "12-07-2024",
            invoice: "Products order",
            orderedQuantity: "10",
            amount: "$45.00",
            status: "Paid",
        },
        {
            date: "12-07-2024",
            invoice: "Fruits order",
            orderedQuantity: "1",
            amount: "$45.00",
            status: "Payment due",
        },
        {
            date: "12-07-2024",
            invoice: "Spices order",
            orderedQuantity: "1",
            amount: "$40.00",
            status: "Payment due",
        },
        {
            date: "12-07-2024",
            invoice: "Dry fruits order",
            orderedQuantity: "1",
            amount: "$45.00",
            status: "Payment due",
        },

    ]
    return (
        <div className="overflow-x-auto ">
            <table className="w-full text-sm text-left rtl:text-right min-w-max">
                <thead class="text-sm text-[#686868] capitalize">
                    <tr>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Invoice date
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Invoice
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Ordered quantity
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Amount
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Status
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal text-center">
                            Invoice details
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((data, index) => (
                        <tr key={index} class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 whitespace-nowrap">
                                {data?.date}
                            </td>
                            <td class="pr-5 py-3">
                                {data?.invoice}
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                {data?.orderedQuantity}
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`${data?.status === "Paid" ? "text-[#00B037]" : "text-[#FE914E]"}`}>
                                    {data?.amount}
                                </span>
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap ">
                                <span className={`px-2.5 py-1 ${data?.status === "Paid" ? "settled" : "packed"} rounded text-xs `}>
                                    {data?.status}
                                </span>
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap ">
                                <div className='flex items-center justify-center'>
                                    <button onClick={() => navigate("/vendor/all-invoices/invoice-details", { state: { status: (data?.status === "Paid" ? "paid" : "pending") } })}>
                                        <Svgs.EyeIcon />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    )
}
