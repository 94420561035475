import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import React from 'react'

function Employee() {
    const employeeData = [
        {
            logo: "/images/EmployeeProfile1.png",
            name: "Anna"
        },
        {
            logo: "/images/EmployeeProfile2.png",
            name: "Catherine"
        },
        {
            logo: "/images/EmployeeProfile3.png",
            name: "Orton"
        },
    ]

    return (
        <div className='flex flex-row flex-wrap gap-2.5'>
            {employeeData?.map((data, index) => (
                <div key={index} className='bg-white rounded-lg border py-5 px-6  min-w-[358px] flex-grow lg:max-w-[358px] w-full font-public-sans '>
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-row gap-5 '>
                            <Image customLoaderRounded={"rounded-full"} customLoaderHeight={"h-[90px]"} customLoaderWidth={'w-[90px]'} className={'w-[90px] h-[90px] rounded-full object-cover'} src={data?.logo} />
                            <div className='flex flex-col gap-2.5'>
                                <p className='text-gray-1 text-xl font-semibold'>
                                    {data.name}
                                </p>
                                <div className='failed px-3 py-1.5 font-medium text-sm rounded-full'>
                                    Team member
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col px-5 '>
                            <div className='flex flex-row justify-between items-center w-full'>
                                <p className='text-xs text-gray-4'>SHIFT TIMING</p>
                                <p className='text-sm text-gray-1 font-medium'>
                                    8:00 AM - 5:00 PM
                                </p>
                            </div>
                            <div className='flex flex-row justify-between items-center w-full'>
                                <p className='text-xs text-gray-4'>SALARY</p>
                                <p className='text-sm settled-text font-medium'>
                                    $22/hour
                                </p>
                            </div>

                        </div>

                        <div className='flex flex-col p-4 text-gray-2 text-sm font-medium bg-gray-5 rounded-xl border border-gray-5 bg-opacity-30 gap-2.5'>
                            <div className='flex flex-row items-center gap-2'>
                                <Svgs.BlockEmailIcon />
                                <p>o.williams@email.com</p>
                            </div>
                            <div className=' border border-gray-5' />
                            <div className='flex flex-row items-center gap-2'>
                                <Svgs.BlockCallIcon />
                                <p>+1 650 434 5655</p>
                            </div>

                        </div>

                        <div className='flex items-center justify-center'>

                            <button className='bg-btn-theme bg-clip-text border px-3 py-1.5 border-[#F33F41] rounded-xl'>
                                <p className='text-transparent font-semibold text-sm '>
                                    View details
                                </p>
                            </button>
                        </div>

                    </div>
                </div>
            ))}

        </div>)
}

export default Employee