import Svgs from "Assets/svgs";
import { error_message } from "Constants/Variables";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const Dropdown2 = ({
    custom_min_width,
    className,
    leftRounded,
    title,
    name,
    value,
    onBlur,
    bgColor,
    placeholder,
    option,
    onChange,
    error,
    disabled,
    required,
    noBorder,
    edit,
    handleFocus,
    filterOption,
    custom_opening_hours_width,
    custom_padding,
    inputClass,
    errorClass
}) => {

    const [field_err, setFieldErr] = useState(undefined)
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const parsedPlaceholder = placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

    const colourStyles = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            boxShadow: isFocused ? "none" : "none",
            border: noBorder
                ? 0
                : isFocused
                    ? "1.5px solid #EEEEEE"
                    : "1.5px solid #EEEEEE",
            color: "#000000",
            backgroundColor: bgColor ? bgColor : '#FFFFFF',
            fontSize: "12px",
            padding: "0px 0.08rem",
            outline: "none",
            borderRadius: leftRounded ? "5px 0 0 5px" : '5px',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000000",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isDisabled
                ? "#FFFFFF"
                : isFocused
                    ? "#F2F2F2"
                    : bgColor ? bgColor : "#FFFFFF",
            color: "#000000",
            fontSize: "12px",
            cursor: isDisabled ? "not-allowed" : "pointer",
        }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: bgColor ? bgColor : '#FFFFFF',
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
            zIndex: 111111111111,
        }),
        input: (styles) => ({
            ...styles,
            '[type="text"]': {
                color: '#000000 !important'
            }
        })
    };

    const errorStyle = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            boxShadow: isFocused ? "none" : "none",
            border: field_err && "1.5px solid var(--error-color)",
            color: "#000000",
            backgroundColor: bgColor ? bgColor : '#FFFFFF',
            fontSize: "12px",
            padding: "0.01rem 0.08rem",
            outline: "none",
            borderRadius: leftRounded ? "5px 0 0 5px" : '5px',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000000",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isDisabled
                ? "#FFFFFF"
                : isFocused
                    ? "#F2F2F2"
                    : bgColor ? bgColor : "#FFFFFF",
            color: "#000000",
            fontSize: "12px",
            cursor: isDisabled ? "not-allowed" : "pointer",
        }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: bgColor ? bgColor : '#FFFFFF',
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
            zIndex: 111111111111,
        }),
        input: (styles) => ({
            ...styles,
            '[type="text"]': {
                color: '#000000 !important'
            }
        })
    };


    useEffect(() => {
        setFieldErr(error)
    }, [error])

    const handleMenuOpen = () => {
        setMenuIsOpen(true);
    };

    const handleMenuClose = () => {
        setTimeout(() => {
            setMenuIsOpen(false);
        }, 100);
    };

    return (
        <>
            <div
                className={`flex flex-col gap-2 flex-1 whitespace-nowrap capitalize relative  ${className} min-w-[12ch] ${custom_min_width ? custom_min_width : 'lg:min-w-[16ch]'} ${disabled ? "cursor-not-allowed" : ""
                    }`}
            >
                {title != "" && (
                    <p className="text-sm flex gap-1 capitalize text-blueGrey">
                        {title && title}
                        <span className="text-red-600">
                            {required && required ? " * " : ""}
                        </span>
                    </p>
                )}
                <Select
                    styles={field_err ? errorStyle : colourStyles}
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    name={name}
                    className={`${error && "error"} ${inputClass} `}
                    menuPosition="fixed"
                    menuShouldBlockScroll
                    menuPortalTarget={document.body}
                    classNamePrefix="custom"
                    isSearchable={true} // Enable search for larger screens
                    value={{
                        value: option
                            ? option?.find((itm) => itm?.value == value)
                                ? option?.find((itm) => itm?.value == value)
                                : ""
                            : "",
                        label: option
                            ? option.find((itm) => itm?.value == value)
                                ? option?.find((itm) => itm?.value == value)?.label
                                : placeholder
                            : placeholder
                    }}
                    options={option && option?.length > 0 ? option : []}
                    onChange={(e) => {
                        setFieldErr(undefined)
                        if (option.length > 0) {
                            onChange &&
                                onChange({
                                    target: {
                                        value: e.value,
                                        name: name,
                                    },
                                });
                        }
                    }}
                    isDisabled={edit ? false : option.length == 0 ? true : disabled}
                    placeholder={parsedPlaceholder}
                    isOptionDisabled={(option) => option.disabled}
                    onFocus={() => {
                        if (handleFocus) {
                            handleFocus();
                        }
                    }}
                    filterOption={filterOption}
                />

                {field_err && typeof field_err == "string" ? (
                    <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end normal-case pt-1 ${errorClass} ${custom_opening_hours_width || ''}`}>
                        <Svgs.I fill="#eb3b3b" />
                        {field_err}
                    </p>
                ) : typeof field_err == "boolean" && field_err == true ? (
                    <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end normal-case pt-1 ${custom_padding || ''}`}>
                        <Svgs.I fill="#eb3b3b" />
                        {error_message}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

Dropdown2.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    option: [],
    name: "no_name",
    value: "no_value",
    onBlur: () => { },
    onChange: () => { },
    error: false,
    disabled: false,
    required: false,
    noBorder: false,
};

export default Dropdown2;