import React from 'react';

const VerticalStatusSlider = ({ parentClass, steps, customeUnActiveIcon }) => {
    return (
        <div className={`flex flex-col w-full items-start text-black relative ${parentClass ? parentClass : ""}`}>
            {steps?.map((step, ind) => (
                <React.Fragment key={ind}>
                    {ind !== 0 && <div className={`sm:h-7 h-14 w-0.5 ml-3 bg-gray-300`} />}
                    <div className={`transition-all w-full duration-300 relative grid`}>
                        <div className={`absolute text-xs left-[1.2rem] pl-4 w-full flex md:flex-row flex-col justify-between md:gap-4`}>
                            <div className='flex text-start flex-wrap gap-1'>
                                {step?.name}
                                {step?.type === 'cancelled' && (
                                    <p className='text-[#949494]'>
                                        Reason: <span className='pl-1 text-black'>{step?.cancelReason}</span>
                                    </p>
                                )}
                            </div>
                            {step?.time && <p className='text-[#949494] text-right'>{step?.time}</p>}
                        </div>
                    </div>
                    <div className={`rounded-full border border-dashed p-1 ${step?.type === 'placed' ? "border-[#2376F5]" :
                        step?.type === 'packed' ? "border-[#FF8C2F]" :
                            step?.type === 'dispatced' ? "border-[#983FF1]" :
                                step?.type === 'delivered' ? "border-[#00B037]" :
                                    "border-[#CE221E]"}`}>
                        <div className={`w-4 h-4 rounded-full flex items-center justify-center
                                ${step?.type === 'placed' ? "bg-[#2376F5]" :
                                step?.type === 'packed' ? "bg-[#FF8C2F]" :
                                    step?.type === 'dispatced' ? "bg-[#983FF1]" :
                                        step?.type === 'delivered' ? "bg-[#00B037]" :
                                            "bg-[#CE221E]"}`}>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default VerticalStatusSlider;
