import Svgs from 'Assets/svgs'
import React from 'react'

const Footer = () => {
    return (
        <div
            className="relative bg-black text-white w-full"
            style={{
                backgroundImage: `url('/images/FooterImage.png')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
            }}
        >
            <div className='mx-auto w-[90%]'>
                <div className='pt-20 pb-16'>
                    <hr className=' border-white' />
                </div>
                <div className='grid xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 gap-4 pb-16'>
                    <div className='lg:col-span-2 col-span-1 flex justify-between gap-4 xl:w-3/4'>
                        <div className='flex-1 flex flex-col space-y-4'>
                            <h1 className='text-lg font-semibold pb-4'>Contact Us</h1>
                            <div className='flex flex-col space-y-2 pb-4 text-xs text-[#F2F2F2]'>
                                <p>P: +1 (672) 588-1590</p>
                                <p>E: sales@mrcorporation@mail.com</p>
                                <p>MON–FRI 9AM–6PM</p>
                            </div>
                            <div className='flex gap-4 items-center'>
                                <Svgs.Facebook />
                                <Svgs.Twitter />
                                <Svgs.Linkdin />
                                <Svgs.Youtube />
                                <Svgs.Pinterest />
                                <Svgs.Instagram />
                            </div>
                        </div>
                        <div className='justify-end'>
                            <h1 className='text-lg font-semibold pb-4'>Company</h1>
                            <div className='flex flex-col space-y-2 pb-4 text-xs text-[#F2F2F2]'>
                                <p>About</p>
                                <p>Partners</p>
                                <p>Results</p>
                            </div>
                        </div>
                    </div>
                    <div className='xl:block hidden'></div>
                    <div>
                        <div className='flex-1 flex flex-col space-y-4'>
                            <h1 className='text-lg font-semibold pb-4'>News Letter</h1>
                            <div className='flex gap-2 items-center'>
                                <input
                                    type="text"
                                    placeholder="Enter email address"
                                    className="bg-transparent border-b-2 border-white !text-white focus:!border-white focus:outline-none w-80 px-2 py-1"
                                />
                                <button
                                    className='hover:bg-white hover:text-black duration-300 ease-in-out rounded-3xl border border-white px-3 text-sm py-1 w-fit h-fit'>
                                    Subscribe
                                </button>
                            </div>
                            <p className='text-xs pt-3 text-[#F2F2F2]'>
                                Subscribe to our newsletter for updates, promotions, and offers. You can unsubscribe at any time. We respect your privacy and won't share your information.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='pb-8 flex sm:flex-row flex-col gap-4 justify-between'>
                    <div className='flex items-center md:justify-start justify-between sm:gap-4 gap-2 xs:text-xs text-[11px] text-[#F2F2F2]'>
                        <p>Privacy Policy</p>
                        <p>Terms & Conditions</p>
                        <p>Legal</p>
                        <p>Community</p>
                    </div>
                    <p className='text-xs sm:text-right text-[#F2F2F2]'>© 2024 MR Corporation All Rights Reserved.</p>
                </div>
            </div>
        </div>

    )
}

export default Footer