import React from 'react'
import { DOTS, usePagination } from './helper';
import Svgs from 'Assets/svgs';

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        totalDataCount,
        currentPageCount
    } = props;


    const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange?.length - 1];

    props.setLastPage && props.setLastPage(paginationRange[paginationRange?.length - 1])

    return (
        <div>
            {/* Pagination  */}
            <div className="flex gap-5 items-center justify-end w-auto">
                {/* <p className="text-[#595959] text-sm">Showing 1 to {currentPageCount} of {totalDataCount} entries</p> */}
                <div className="flex sm:flex-nowrap flex-wrap items-center gap-2">
                    <Svgs.PreviousSvg className={`${currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={() => {
                        if (currentPage !== 1) {
                            onPrevious()
                        }
                    }} />

                    <div className="flex items-center gap-2">
                        {paginationRange?.map(pageNumber => {
                            if (pageNumber === DOTS) {
                                return <div className="pagination-item dots">&#8230;</div>;
                            }
                            return (
                                <button
                                    onClick={() => onPageChange(pageNumber)}
                                    className={`relative h-[32px] max-h-[32px] w-[32px] max-w-[32px] select-none rounded-md  ${pageNumber === currentPage ? 'theme-btn' : ''} text-center align-middle px-4 py-2 text-sm  transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:opacity-50 disabled:shadow-none`}
                                    type="button">
                                    <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                        {pageNumber}
                                    </span>
                                </button>
                            )
                        })
                        }
                    </div>
                    <Svgs.NextSvg className={`${currentPage === lastPage ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={() => {
                        if (currentPage !== lastPage) {
                            onNext()
                        }
                    }} />
                </div>
            </div>
        </div>
    )
}

export default Pagination