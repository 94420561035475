import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import React from 'react'

const PromotionCard = ({ imageSrc, name, created_at, reach, likes, status }) => {
    return (
        <div className='border-2 rounded-lg border-[#EEEEEE] flex flex-col'>
            <Image className={'w-full h-full object-cover  max-h-[140px] rounded-t-lg'} customLoaderHeight={'h-[140px]'} src={imageSrc} />
            <div className='flex justify-between gap-4 text-sm font-semibold p-2'>
                <h1>{name}</h1>
                <span className={`${status} capitalize py-1 px-2 w-fit rounded-3xl text-sm font-normal flex justify-center items-center`}>
                    • {status}
                </span>
            </div>
            <div className='flex justify-between gap-4 items-center text-xs p-2'>
                <div className='flex gap-1 justify-between items-center'>
                    <div className='flex gap-1 justify-between items-center'>
                        <Svgs.Thumb />
                        {likes}
                    </div>
                    <div className='flex gap-1 justify-between items-center'>
                        <Svgs.BarChart />
                        {reach}
                    </div>
                    <div className='flex gap-1 justify-between items-center'>
                        <Svgs.ClockIcon />
                        {created_at}
                    </div>
                </div>
                <p className='text-[#2376F5]'>View Detail</p>
            </div>
        </div>
    )
}

export default PromotionCard