import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Dropdown2 from 'Components/Elements/Dropdown2'
import Image from 'Components/Elements/Image'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const CateringOrderRequest = () => {
    const navigate = useNavigate()
    return (
        <SellerLayout active={'Other Services'}
            title={'Other services'} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"Other services"}
                url={"/other-services"}
                second={"Catering services"}
                secondurl={"/other-services/catering-services"}
                third={"Orders requests"}
            />
            <RoundedCard className={"flex flex-col gap-4 "}>
                <h2>Order Requests</h2>

                <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-x-5 gap-y-8'>
                    {Array(6).fill().map((_, index) => (
                        <div key={index} className='flex flex-row p-4 items-center border border-[#EEEEEE] rounded-lg gap-4'>
                            <Image
                                customLoaderRounded={"rounded-full"}
                                customLoaderHeight={"h-[84px]"}
                                customLoaderWidth={"w-[84px]"}
                                className={"object-contain w-[84px] h-[84px] rounded-full"}
                                src={"/images/catering-order-request.png"}
                            />
                            <div className='flex flex-col gap-2.5'>
                                <div>
                                    <p className='font-semibold text-base text-black'>Need catering service</p>
                                    <p className='text-xs text-[#686868]'>Event date:{" "}<span className='font-semibold text-[#3B3B3B]'>12-06-2024</span></p>
                                </div>
                                <div>
                                    <Button
                                        className={"text-xs"}
                                        customThemeBtn={"theme-btn2"}
                                        customPadding={"px-2 py-1"}
                                        title={"Chat Now"}
                                    />
                                </div>

                            </div>

                        </div>
                    ))}

                </div>


            </RoundedCard>

        </SellerLayout>)
}
