import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Image from 'Components/Elements/Image'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'

export const PlanDetailsPage = () => {
    return (
        <SellerLayout active={"Other Services"} title={"Other services"}
            bodyClassName={'flex flex-col space-y-4'}>

            <BreadCrumb
                first={"Other services"}
                url={'/other-services'}
                second={"Tiffin services"}
                secondurl={"/other-services/tiffin-services"}
                third={"Plan details"}
            />


            <div className='flex flex-row gap-2.5 '>
                <Image src={"/images/tiffin-service-main-image.png"} className={"object-cover h-[222px] w-[500px]"} />

                <div className='lg:flex flex-col gap-2.5 hidden'>
                    <div className='lg:grid xl:grid-cols-3 hidden grid-cols-1  gap-2.5'>
                        <Image src={"/images/tiffin-service-image1.png"} className={"w-[220px] h-[106px] object-cover min-w-max"} />
                        <Image src={"/images/tiffin-service-image2.png"} className={"w-[220px] h-[106px] object-cover min-w-max hidden xl:block"} />
                        <Image src={"/images/tiffin-service-image3.png"} className={"w-[220px] h-[100px] object-cover min-w-max hidden xl:block"} />
                    </div>
                    <div className='lg:grid xl:grid-cols-3 hidden grid-cols-1  gap-2.5'>
                        <Image src={"/images/tiffin-service-image4.png"} className={"w-[220px] h-[106px] object-cover min-w-max"} />
                        <Image src={"/images/tiffin-service-image5.png"} className={"w-[220px] h-[106px] object-cover min-w-max hidden xl:block"} />
                        <Image src={"/images/tiffin-service-image6.png"} className={"w-[220px] h-[100px] object-cover min-w-max hidden xl:block"} />
                    </div>

                </div>
            </div>

            <RoundedCard>
                <div className='grid lg:grid-cols-5 grid-cols-1 gap-2.5 '>
                    <div className='col-span-3 flex lg:flex-row flex-col justify-between w-full lg:items-center gap-2.5'>
                        <div className='flex flex-col gap-3.5  justify-center'>
                            <div className='flex flex-row gap-4'>
                                <h4 className='text-base text-[#000000]'>Basic Plan</h4>
                                <span className='cancelled px-2.5 py-1 rounded'>Top selling plan</span>
                            </div>

                            <div className="flex flex-col items-center-justify-center gap-2">
                                <div className='text-sm'>
                                    <span className='text-[#949494]'>Total sales:</span>
                                    <span>{" "}124</span>
                                </div>
                                <div className='text-sm'>
                                    <span className='text-[#949494]'>Active customers:</span>
                                    <span>{" "}64</span>
                                </div>
                                <div className='flex flex-row gap-3 items-center'>
                                    <div className='text-sm'>
                                        <span className='text-[#949494]'>Reviews:</span>
                                        <span>{" "}123 reviews</span>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='flex flex-col justify-between h-full gap-2.5'>
                            <div className='flex flex-row font-bold text-2xl'>
                                <div className='text-[#949494]'>Price:</div>
                                <div>$240</div>
                            </div>

                            <div className='flex flex-col gap-2 lg:items-end'>
                                <div className='text-sm'>
                                    <span className='text-[#949494]'>Created on:</span>
                                    <span>12-05-2024</span>
                                </div>
                                <div>

                                    <span className='active px-2.5 py-1 min-w-max rounded'>
                                        Active
                                    </span>
                                </div>

                            </div>

                        </div>


                    </div>



                    <div className='col-span-2 flex flex-row items-center justify-end gap-6'>
                        <Button
                            customPadding={" py-2 px-9"}
                            title={"Edit"}
                        />

                        <button className='border border-[#1E1E1E] min-w-max p-2.5'>
                            <Svgs.DeleteIcon />
                        </button>

                    </div>
                </div>
            </RoundedCard>


            <div className='grid xl:grid-cols-5 grid-cols-1 gap-2.5 '>
                <RoundedCard className={"xl:col-span-3 space-y-4"}>
                    <h4 className='text-base'>Plan Description</h4>
                    <p className='text-[#686868] text-base'>Indulge in the vibrant and refreshing taste of our premium pomegranates, nature's juicy gems. Each pomegranate is hand-picked to ensure peak freshness and juiciness, offering a delightful sweet-tart flavor that bursts with every bite. Perfect for snacking, juicing, or enhancing your salads and desserts, our pomegranates are not only delicious but also packed with antioxidants, vitamins, and minerals. Elevate your meals and boost your health with these nutrient-rich fruits that bring a pop of color and flavor to any dish. Enjoy the unparalleled taste and benefits of our fresh pomegranates, carefully selected to meet the highest quality standards.</p>
                </RoundedCard>

                <RoundedCard className={"xl:col-span-2 "}>
                    <div className='flex flex-col gap-4'>
                        <div className='text-base'>Meals</div>

                        <div className='flex flex-col gap-1'>
                            <div className='flex flex-row justify-between items-center'>
                                <span>Breakfast</span>
                                <span className='font-semibold '>2 Prantha+Butter/Curd</span>

                            </div>
                            <div className='flex flex-row justify-between items-center'>
                                <span>Lunch</span>
                                <span className='font-semibold '>2 Sabzi+3Roti+Rice+Salad</span>

                            </div>
                            <div className='flex flex-row justify-between items-center'>
                                <span>Dinner</span>
                                <span className='font-semibold '>2 Sabzi+3Roti+Rice+Salad</span>

                            </div>

                        </div>

                        <div className='border-[#EEEEEE] border m-6' />
                    </div>

                    <div className='flex justify-center items-center gap-5'>
                        <Button
                            customPadding={"px-3 py-1.5"}
                            title={"Edit meals"} />
                    </div>


                </RoundedCard>
            </div>

            <RoundedCard className={"space-y-3"}>
                <h4 className='font-semibold text-lg'>Review</h4>
                <div className='lg:flex lg:flex-row lg:gap-8 flex flex-col gap-4 lg:justify-between'>
                    <div className='flex flex-col gap-3.5 '>

                        <div className='flex flex-col gap-2'>
                            <p>Exceptional service! Received my fresh groceries promptly through the app. Quality and convenience at its finest!</p>
                            <div>
                                <span className='text-[#949494]'>Date and Time:</span>
                                <span>Jun 16, 2024, 11:05 AM</span>
                            </div>
                        </div>
                    </div>


                    <div className='flex lg:flex-col flex-row lg:justify-start lg:items-start  '>
                        <div className='flex flex-row items-center gap-2.5'>
                            <svg width="112" height="18" viewBox="0 0 112 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.4203 0L12.2149 6.08623L18.8406 6.87539L13.942 11.426L15.2424 18L9.4203 14.7262L3.59824 18L4.89856 11.426L0 6.87539L6.62571 6.08623L9.4203 0Z" fill="#FF8C2F" />
                                <path d="M32.7719 0L35.5665 6.08623L42.1922 6.87539L37.2936 11.426L38.5939 18L32.7719 14.7262L26.9498 18L28.2501 11.426L23.3516 6.87539L29.9773 6.08623L32.7719 0Z" fill="#FF8C2F" />
                                <path d="M56.1234 0L58.918 6.08623L65.5437 6.87539L60.6452 11.426L61.9455 18L56.1234 14.7262L50.3014 18L51.6017 11.426L46.7031 6.87539L53.3288 6.08623L56.1234 0Z" fill="#FF8C2F" />
                                <path d="M79.475 0L82.2696 6.08623L88.8953 6.87539L83.9967 11.426L85.2971 18L79.475 14.7262L73.6529 18L74.9532 11.426L70.0547 6.87539L76.6804 6.08623L79.475 0Z" fill="#FF8C2F" />
                                <path d="M102.827 0L100.032 6.08623L93.4062 6.87539L98.3048 11.426L97.0045 18L102.827 14.7262V0Z" fill="#FF8C2F" />
                                <path d="M102.578 14.7262L108.4 18L107.1 11.426L111.998 6.87539L105.373 6.08623L102.578 0V14.7262Z" fill="#D9D9D9" />
                            </svg>
                            <p>4.5</p>
                        </div>
                    </div>

                </div>

                <div className='flex lg:justify-end w-full  '>
                    <Button
                        customPadding={" px-2 py-1"}
                        title={"View all"} />
                </div>

            </RoundedCard>






        </SellerLayout>)
}
