import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Dropdown2 from 'Components/Elements/Dropdown2'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import SellerLayout from 'Components/Layouts/SellerLayout'
import Pagination from 'Components/Pagination'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SellerDiscountTable } from 'Utility/Tables/SellerDiscountTable'

export const SellerDiscountPage = () => {
    const navigate = useNavigate()
    return (
        <SellerLayout active={"Discounts"} title={"Discounts & Offers"}
            bodyClassName={'flex flex-col space-y-4'}>


            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search'}
                        />
                    </div>

                    <div className='flex flex-col  lg:flex-row gap-2 lg:items-center justify-'>
                        <Dropdown2
                            title={""}
                            placeholder={"All"}
                        />
                        <div className='flex flex-row items-center gap-x-2  '>
                            <button>
                                <Svgs.PdfIcon />
                            </button>
                            <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                                <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                    <Svgs.DownloadBlack />
                                    Download Excel
                                </div>
                            </Button>
                        </div>

                        <div>
                            <Button
                                onClick={() => navigate("/create-discount-offer")}
                                customPadding={'px-2.5 py-2'}
                                // className={"!rounded-md min-w-max"}
                                title={"Create offer"}
                            />
                        </div>


                    </div>
                </div>
            </RoundedCard>

            <RoundedCard>
                <SellerDiscountTable />
            </RoundedCard>


            <Pagination
                currentPage={1}
                pageSize={2}
                totalCount={3}
            />

        </SellerLayout>
    )
}
