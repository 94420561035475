import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import React from 'react'

export const OtherServicesAllSellerTable = () => {
    const tableData = [
        {
            image: "/images/TopProductImg1.png",
            name: "ER Tiffins",
            city: "Surrey",
            customers: "450",
            ratings: "4.5"
        },
        {
            image: "/images/TopProductImg2.png",
            name: "Zaika foods",
            city: "Surrey",
            customers: "430",
            ratings: "4.5"
        },
        {
            image: "/images/TopProductImg3.png",
            name: "Manraj sweets",
            city: "Vancouver",
            customers: "320",
            ratings: "4.5"
        },
        {
            image: "/images/TopProductImg4.png",
            name: "Punjabi Tiffins",
            city: "Abbottsford",
            customers: "267",
            ratings: "4.5"
        },
    ]

    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right ">
                    <thead class="text-sm text-[#686868] capitalize">
                        <tr>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Image
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Seller name
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                City
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Customers
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Ratings
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((data, index) => (
                            <tr class="border-b border-[#EEEEEE]">
                                <td class="pr-5 py-3">
                                    <Image customLoaderHeight={"h-[30px]"} customLoaderWidth={"w-[40px]"} className={'w-[40px] h-[30px] object-cover'} src={data?.image} />
                                </td>
                                <td class="pr-5 py-3 whitespace-nowrap">
                                    {data?.name}
                                </td>
                                <td class="pr-5 py-3 whitespace-nowrap">
                                    {data?.city}
                                </td>
                                <td class="pr-5 py-3 whitespace-nowrap ">
                                    {data?.customers}
                                </td>
                                <td class="pr-5 py-3 whitespace-nowrap flex flex-row items-center gap-1 font-inter font-semibold text-xs">
                                    {data?.ratings}
                                    <Svgs.SmallStarIcon />
                                </td>
                            </tr>
                        ))}

                    </tbody>
                    {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                </table>
                {/* {data?.length == 0 &&
                    <NotFound />
                } */}
            </div>
        </>)
}
