import React from 'react'
import SellerLayout from 'Components/Layouts/SellerLayout'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import Dropdown2 from 'Components/Elements/Dropdown2'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import { ORDER_STATUSES, TIMELINE } from 'Constants/Data/OrdersDropdown'
import SellerOrdersTable from 'Utility/Tables/SellerOrdersTable'
import Pagination from 'Components/Pagination'

const SellerOrders = () => {
    return (
        <SellerLayout active={'Orders'} title={'Orders'} bodyClassName={'flex flex-col space-y-4'}>
            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search By Order ID or Customer Name'}
                        />
                    </div>
                    <div className='flex sm:flex-row flex-col gap-2 sm:items-center justify-between'>
                        <Dropdown2
                            title={''}
                            required={false}
                            placeholder={'Monthly'}
                            option={TIMELINE}
                        />
                        <Dropdown2
                            title={''}
                            required={false}
                            placeholder={'All Orders'}
                            option={ORDER_STATUSES}
                        />
                        <Button customPadding={'px-3 py-2'} className={"!rounded-md"}>
                            <div className='flex gap-2 items-center justify-between text-sm'>
                                <Svgs.DownloadIcon />
                                Download Excel
                            </div>
                        </Button>
                    </div>
                </div>
            </RoundedCard>
            <RoundedCard>
                <SellerOrdersTable />
            </RoundedCard>
            {/* {data?.length > 0 && */}
            <Pagination
                currentPage={1}
                pageSize={2}
                totalCount={3}
            // onPageChange={(page) => setCurrentPage(page)}
            />
            {/* } */}
        </SellerLayout>
    )
}

export default SellerOrders