import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import MainRoutes from './Routes';

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        theme='colored'
      />
      <MainRoutes />
    </>
  );
}

export default App;
