import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useSellerTopBar = () => {
    const navigate = useNavigate()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const defaultRoute = getDataFromLocalStorage('defaultRoute')
    const activeTab = getDataFromLocalStorage('activeTab')

    // states
    const [Notifications, setNotifications] = useState(false)

    return {
        navigate, defaultRoute, activeTab, Notifications, setNotifications
    }
}
export default useSellerTopBar