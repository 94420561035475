import React from "react";
import PreloaderSm from "./PreloaderSm";

const Button = ({
  title,
  type,
  onClick,
  disabled,
  text,
  children,
  loading,
  customThemeBtn,
  className,
  customPadding,
}) => {

  const handleClick = (e) => {
    if (!loading && !disabled) {
      onClick(e)
    }
  }
  return (
    <button
      title={title}
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={`${customPadding ? customPadding : 'px-5 py-3'} ${customThemeBtn ? customThemeBtn : 'theme-btn'} ${className} ${(disabled || loading) && "cursor-not-allowed disabledButton"}`}>
      {loading ? (
        <>
          <PreloaderSm className={"!px-5 !py-[5.5px] "} />
        </>
      ) : (
        <>
          {text ? text : title ? title : ""}
          {children}
        </>
      )}
    </button>
  );
};




Button.defaultProps = {
  animation: true,
  onClick: () => { },
};

export default Button;