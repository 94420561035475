import Svgs from 'Assets/svgs';
import React from 'react'

const StarsRating = ({ rating, maximumRating, starSize, className }) => {
    const maxRating = maximumRating ? maximumRating : 5;
    const filledStars = Array.from({ length: +rating }, (_, index) => index + 1);
    const emptyStars = Array.from({ length: maxRating - +rating }, (_, index) => index + +rating + 1);
    return (
        <div className={`${className ? className : "flex gap-2 items-center justify-center"}`}>
            {filledStars?.map((_, index) => (
                <Svgs.StarFill width={starSize} height={starSize} />
            ))}
            {emptyStars?.map((_, index) => (
                <Svgs.StarEmpty width={starSize} height={starSize} />
            ))}
            <p className='text-sm'>{rating}</p>
        </div>
    )
}

export default StarsRating