import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Dropdown2 from 'Components/Elements/Dropdown2'
import Image from 'Components/Elements/Image'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const SellerOtherServicesPage = () => {
    const navigate = useNavigate()
    return (
        <SellerLayout active={'Other Services'}
            title={'Other services'} bodyClassName={'flex flex-col space-y-4'}>
            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search By Order ID or Customer Name'}
                        />
                    </div>
                    <div className='flex sm:flex-row flex-col gap-2 sm:items-center justify-between'>
                        <Button customPadding={'px-3 py-2'} className={"!rounded-md"} customThemeBtn={"theme-btn2"}>
                            <div className='flex gap-2 items-center justify-between text-sm'>
                                <Svgs.DownloadBlack />
                                Download Excel
                            </div>
                        </Button>
                        <Button customPadding={'px-3 py-2'} className={"!rounded-md"} >
                            <div className='flex gap-2 items-center justify-between text-sm'>
                                <Svgs.DownloadIcon />
                                Add New Service
                            </div>
                        </Button>
                    </div>
                </div>
            </RoundedCard>

            <RoundedCard className={"flex flex-col gap-4 "}>
                <h2>All services</h2>
                <div className=' flex flex-row gap-6 flex-wrap'>
                    <div className='p-2.5 flex flex-col border rounded gap-2 items-center justify-center w-[272px]'>
                        <Image
                            customLoaderHeight={"h-[140px]"}
                            customLoaderRounded={"rounded"}
                            customLoaderWidth={"w-[252px]"}
                            className={"object-contain w-[252px] h-[140px] rounded"}
                            src={"./images/TiffenServices.png"}
                        />
                        <div className='font-semibold text-sm text-[#000000]'>Tiffin services</div>
                        <div>
                            <Button
                                onClick={() => navigate("/other-services/tiffin-services")}
                                title={"Open"}
                                customPadding={"px-6 py-2"}
                            />
                        </div>
                    </div>

                    <div className='p-2.5 flex flex-col border rounded gap-2 items-center justify-center w-[272px]'>
                        <Image
                            customLoaderHeight={"h-[140px]"}
                            customLoaderRounded={"rounded"}
                            customLoaderWidth={"w-[252px]"}
                            className={"object-contain w-[252px] h-[140px] rounded"}
                            src={"./images/CateringServices.png"}
                        />
                        <div className='font-semibold text-sm text-[#000000]'>Catering services</div>
                        <div>
                            <Button
                                onClick={() => navigate("/other-services/catering-services")}
                                title={"Open"}
                                customPadding={"px-6 py-2"}
                            />
                        </div>

                    </div>
                </div>

            </RoundedCard>

        </SellerLayout>
    )
}
