import React from 'react'

function CheckBox({ customGap, parentClass, disabled, label_text_transform, mainLabelClass, label_Class, name, onChange, value, checked, error, label, colorLabel1, colorLabel2, className, id, onClickLabel1, onClickLabel2 }) {
    var unique_id = Math.floor(Math.random() * 100) * Date.now();
    return (
        <div className={`flex items-center ${customGap ? customGap : "gap-2"} ${parentClass ? parentClass : ""}`}>
            <input disabled={disabled} id={`${id ? id : unique_id}`} type="checkbox" name={name} onChange={onChange} value={value} checked={checked} className={`${className ? className : "accent-primary h-[1.3rem] w-[1.3rem]"} !color-primary ${disabled && "cursor-not-allowed"} `} />
            <label htmlFor={`${id ? id : unique_id}`} className={`${mainLabelClass}`} >
                <p className={`text-sm font-medium cursor-pointer text-primaryBlack ${label_Class && label_Class} ${label_text_transform ? label_text_transform : 'normal-case'}`}>
                    {label}
                    <span className='text-primary font-semibold' onClick={onClickLabel1 && onClickLabel1}>{colorLabel1}  </span>  {colorLabel2 && <span> <span>&</span> <span className='text-primary' onClick={onClickLabel2 && onClickLabel2}>{colorLabel2}</span></span>}
                </p>
            </label>
            {error && <span className='text-error text-sm'>{error}</span>}
        </div>
    )
}

export default CheckBox