import React from 'react'
import Popup from './Popup'
import Button from './Button'

function SuccessPopup({ onClick, open, customSizeStyle, title, buttonTitle, buttonTitle1, buttonTitle2, onClickOfButton1, onClickOfButton2, numberOfButton = 1 }) {
    return (
        <Popup
            open={open}
            removeClose={true}
            removeHeading={true}
            customSizeStyle={customSizeStyle ? customSizeStyle : "lg:w-[25vw]"}>
            <div className='flex flex-col items-center gap-4 justify-center'>
                <div className="flex flex-col gap-6 items-center justify-center ">
                    <svg width="46" height="44" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M44.0202 24.5006C42.3386 32.9088 35.9988 40.826 27.1028 42.5951C22.7641 43.4592 18.2634 42.9324 14.2416 41.0897C10.2197 39.247 6.88172 36.1825 4.70288 32.3323C2.52405 28.4822 1.61545 24.0428 2.10646 19.6462C2.59748 15.2497 4.46307 11.1201 7.4376 7.8455C13.5386 1.12562 23.8404 -0.724196 32.2487 2.63911" stroke="#20AE5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.4336 21.1368L23.8419 29.5451L44.0217 7.68359" stroke="#20AE5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div className='text-[#27AE60] font-bold text-xl '
                        style={{
                            fontFamily: "Roboto"
                        }}>
                        SUCCESS
                    </div>
                </div>
                <div className='text-[#4A4A4A] font-medium text-base font-roboto'>{title}</div>
                {numberOfButton === 1 ?

                    <Button
                        onClick={onClick}
                        className={"min-w-max !px-9 !py-2"}
                        title={buttonTitle ? buttonTitle : "Close"} />

                    :

                    <div className='flex flex-row gap-4'>
                        <Button
                            onClick={onClickOfButton1}
                            className={"min-w-max !px-9 !py-2"}
                            title={buttonTitle1 ? buttonTitle1 : "Close"} />

                        <Button
                            onClick={onClickOfButton2}
                            customThemeBtn={"theme-btn3"}
                            className={"min-w-max !px-9 !py-2"}
                            title={buttonTitle2 ? buttonTitle2 : "Close"} />

                    </div>
                }
            </div>

        </Popup>
    )
}

export default SuccessPopup