import Image from 'Components/Elements/Image';
import RoundedCard from 'Components/Elements/RoundedCard'
import React, { useState } from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Svgs from 'Assets/svgs';
import Button from 'Components/Elements/Button';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


function Attendance() {

    const tableData = [
        {
            image: "/images/EmployeeProfile1.png",
            name: "Anna",
            position: "Python developer"
        },
        {
            image: "/images/EmployeeProfile2.png",
            name: "Catherine",
            position: "Web developer"
        },
        {
            image: "/images/EmployeeProfile3.png",
            name: "Orton",
            position: "Python developer"
        },
    ]
    const [selectedDay, setSelectedDay] = useState(new Date());

    return (
        <div className='lg:grid lg:grid-cols-3 flex flex-col gap-8  w-full'>
            <RoundedCard className={"font-public-sans col-span-2  "}>
                <div className='flex flex-col gap-2.5'>

                    <h3 className='text-gray-1 font-semibold text-base'>Employees Attendance</h3>
                    <div className='bg-gray-5 bg-opacity-30 rounded-xl gap-8 py-2.5 px-4 flex lg:flex-row flex-col lg:items-center '>

                        <div className=' flex items-center justify-center  '>
                            <div className=' w-[161px] h-[161px]'>

                                <CircularProgressbarWithChildren value={100} strokeWidth={12} styles={buildStyles({
                                    pathColor: '#FB6D72',
                                })}>
                                    <div className='flex flex-col items-center font-public-sans'>
                                        <h4 className='text-3xl text-gray-1 font-bold'>100%</h4>
                                        <p className='text-xs text-gray-4'>attendance</p>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>

                        </div>

                        <div className='flex flex-col  flex-1 font-public-sans text-gray-1 text-sm font-medium'>
                            <div className='flex flex-row justify-between min-w-max items-center py-3 border-b-gray-5 border-b-2 w-full'>
                                <p className='text-gray-2'>PRESENT</p>
                                <p>3</p>
                            </div>
                            <div className='flex flex-row justify-between min-w-max items-center py-3 border-b-gray-5 border-b-2 w-full'>
                                <p className='text-gray-2'>ON LEAVE</p>
                                <p>0</p>
                            </div>
                            <div className='flex flex-row justify-between min-w-max items-center py-3  w-full'>
                                <p className='text-gray-2'>ABSENT</p>
                                <p>0</p>
                            </div>

                        </div>

                    </div>


                    <div className='overflow-x-auto'>
                        <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                            {tableData.map((data, index) => (
                                <tr key={index} className="border-b border-[#EEEEEE]">
                                    <td className='py-3 pr-5'>
                                        <div className='flex flex-row gap-3.5'>
                                            <Image customLoaderRounded={"rounded-full"}
                                                customLoaderHeight={"h-[42px]"}
                                                customLoaderWidth={"w-[42px]"}
                                                src={data.image}
                                                className={"object-cover h-[42px] w-[42px]"}
                                            />
                                            <div className='font-medium ' >
                                                <p className='text-sm text-gray-1'>{data.name}</p>
                                                <p className='text-[10px] text-gray-4'>{data.position}</p>
                                            </div>

                                        </div>
                                    </td>


                                    <td className='font-medium text-sm text-gray-1 font-public-sans pr-5'>
                                        Present
                                    </td>
                                    <td>
                                        <span className='bg-gradient-to-tr from-[#F33F41] to-[#FB6D72] bg-clip-text text-transparent text-sm font-medium pr-5'>
                                            8:00 AM-5:00 PM
                                        </span>
                                    </td>
                                    <td>
                                        <button className='min-w-max px-3 py-1.5 outline-none border rounded-lg border-gray-1 font-semibold text-sm'>
                                            Edit
                                        </button>
                                    </td>

                                </tr>

                            ))}

                        </table>

                    </div>



                </div>

            </RoundedCard>

            <RoundedCard >

                <Calendar
                    className={"!border-0 !w-full"}
                    value={selectedDay}
                    onChange={setSelectedDay}
                />

                <div className='border border-dashed  border-gray-5 m-5' />

                <div className='flex flex-col gap-3.5  items-center justify-center pt-4 w-full'>
                    <Button customPadding={'px-3 py-2'} className={"!rounded-md min-w-max border-[#F33F41]"} customThemeBtn={'theme-btn2'}>
                        <div className='flex flex-row items-center justify-center gap-x-2 min-w-max '>
                            <Svgs.PdfIcon />
                            <div className='flex gap-2 items-center justify-between text-sm text-[#F33F41]'>
                                Download Attendance PDF
                            </div>
                        </div>
                    </Button>
                    <Button customPadding={'px-3 py-2'} className={"!rounded-md min-w-max"} customThemeBtn={'theme-btn2'}>
                        <div className='flex flex-row items-center gap-x-2 w-full '>
                            <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                <Svgs.DownloadBlack />
                                Download Attendance Excel
                            </div>
                        </div>
                    </Button>
                </div>

            </RoundedCard>

        </div>
    )
}

export default Attendance