import React from 'react'
import Image from 'Components/Elements/Image'

export const VendorRecentOrderItemList = () => {

    const tableData = [
        {
            image: "/images/Coca-Cola.png",
            name: "Coca Cola",
            quantity: "1",
            um: "Case",
            total: "$45.00"
        },
        {
            image: "/images/Cabbage.png",
            name: "Cabbage",
            quantity: "1",
            um: "1",
            total: "$1.50"
        },
        {
            image: "/images/Eggs.png",
            name: "Eggs",
            quantity: "1",
            um: "Dozen",
            total: "$3.00"
        },
    ]

    return (
        <>
            <div className="overflow-x-auto ">
                <table className="w-full text-sm text-left rtl:text-right min-w-max">
                    <thead class="text-sm text-[#686868] capitalize">
                        <tr>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Sr. No.
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Product Image
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Product Name
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Quantity
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                U/M
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((data, index) => (
                            <tr key={index} class="border-b border-[#EEEEEE]">
                                <td class="pr-5 py-3 whitespace-nowrap">
                                    {index + 1}.
                                </td>
                                <td class="pr-5 py-3">
                                    <Image customLoaderHeight={"h-[30px]"} customLoaderWidth={"w-[40px]"} className={'w-[40px] h-[30px] object-cover'} src={data?.image} />
                                </td>
                                <td class="pr-5 py-3 whitespace-nowrap">
                                    {data?.name}
                                </td>
                                <td class="pr-5 py-3 whitespace-nowrap">
                                    {data?.quantity}
                                </td>
                                <td class="pr-5 py-3 whitespace-nowrap ">
                                    {data?.um}
                                </td>
                                <td class="pr-5 py-3 whitespace-nowrap">
                                    {data?.total}
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </>

    )
}
