import React from 'react'

const PaymentMethodCard = ({ icon, name, cardNumber, csvDate, selected }) => {
    return (
        <div className='border-2 rounded-lg border-[#EEEEEE] p-3 flex gap-4 min-h-[140px]'>
            <div className='pt-1'>
                {icon}
            </div>
            <div className='flex flex-col space-y-2 justify-between'>
                <div className='flex flex-col space-y-2'>
                    <h1>{name}</h1>
                    <p className='text-[#686868] text-sm'>{cardNumber}</p>
                    <p className='text-[#686868] text-sm'>{csvDate}</p>
                </div>
                <span className={`cursor-pointer justify-end border ${!selected ? "border-[#949494] text-[#3B3B3B]" : "border-primary text-primary bg-[#fcf2f2]"} px-2 py-1.5 w-fit rounded-lg text-sm font-bold flex justify-center items-center`}>
                    {!selected ? "Select" : "Selected"}
                </span>
            </div>
        </div>
    )
}

export default PaymentMethodCard