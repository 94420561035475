import Accordion from 'Components/Elements/Accordian'
import React from 'react'

const data = [
    { heading: "How do I access the seller's dashboard?", content: "To access the seller's dashboard, log in to your account on the platform and navigate to the seller section using the designated menu or link provided. Alternatively, use the direct URL provided for the seller's dashboard login page." },
    { heading: "Is there a separate login for sellers?", content: "" },
    { heading: "What credentials do I need to log in as a seller?", content: "" },
    { heading: "Can I manage multiple stores from the same dashboard?", content: "" },
    { heading: "How can I reset my seller dashboard password?", content: "" },
]

const DashboardAccess = ({ className }) => {
    return (
        <div>
            {data?.map((obj, index) => (
                <Accordion
                    className={className}
                    index={index}
                    heading={obj?.heading}
                    content={obj?.content}
                    arrayLength={data?.length}
                />
            ))}
        </div>
    )
}

export default DashboardAccess