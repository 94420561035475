import Input from 'Components/Elements/Input'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const LegalDocuments = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 justify-between xl:w-3/5'>
                <Input
                    type={"file"}
                    title={'Terms of service'}
                    placeholder={'Upload'}
                    required={false}
                />
                <Input
                    type={"file"}
                    title={'Refund and Return policy'}
                    placeholder={'Enter address here'}
                    required={false}
                />
                <Input
                    type={"file"}
                    title={'Dispute resolution'}
                    placeholder={'Enter Phone Number'}
                    required={false}
                />
                <Input
                    type={"file"}
                    title={'Business registration information'}
                    placeholder={'Enter store description'}
                    required={false}
                />

            </div>
            <div className={`cursor-pointer text-sm px-3 justify-start theme-btn py-1.5 w-fit`}
                onClick={() => { navigate("/profile") }}
            >
                <h2>Update Changes</h2>
            </div></>
    )
}
