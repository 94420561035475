import React, { useState } from 'react'
import Button from 'Components/Elements/Button'
import Svgs from 'Assets/svgs'
import SearchInput from 'Components/Elements/SearchInput'
import RoundedCard from 'Components/Elements/RoundedCard'
import DashboardCard from 'Pages/Seller/Dashboard/Elements/DashboardCard'
import Image from 'Components/Elements/Image'
import Popup from 'Components/Elements/Popup'
import Input from 'Components/Elements/Input'
import Dropdown from 'Components/Elements/Dropdown'
import OutlineButton from 'Components/Elements/OutlineButton'
import Employee from './Employee'
import Attendance from './Attendance'
import Schedule from './Schedule'
import Salaries from './Salaries'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'

function PremiumEmployeeScreen() {
    const buttonData = [
        {
            title: "Employees"
        },
        {
            title: "Attendances"
        },
        {
            title: "Schedule"
        },
        {
            title: "Salaries"
        },
    ]


    const steps = [
        { name: "Basic Info" },
        { name: "Personal Info" },
        { name: "Documents" },
        { name: "Bank Details" },
    ];

    const [activeButton, setActiveButton] = useState("Employees")
    const [activeEmployeeStep, setActiveEmployeeStep] = useState(0)
    const [isAddEmployeePopup, setAddEmployeePopup] = useState(false)
    const [uploadedDoc, setUploadedDoc] = useState(null)
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
    const [openAddSchedule, setOpenAddSchedule] = useState(false)
    const [openNewSchedulePopup, setOpenNewSchedulePopup] = useState(false)

    const handleNext = (e) => {
        e.preventDefault()
        if (activeEmployeeStep === 3) {
            setActiveEmployeeStep(0)
            setAddEmployeePopup(false)
            setOpenSuccessPopup(true)

        }
        else {
            setActiveEmployeeStep(prev => prev + 1)
        }
    }

    const handleUploadDoc = (e) => {
        const file = e.target.files[0];
        console.log("File selected: ", file); // Debugging
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                console.log("File content: ", reader.result); // Debugging
                setUploadedDoc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <SellerLayout active={'Employees'}
                title={
                    <>
                        <div className='flex flex-row gap-2 items-center'>
                            Employees
                            <Svgs.PremiumIcon />
                        </div>
                    </>
                }
                bodyClassName={'flex flex-col space-y-4'}>
                <RoundedCard>
                    <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                        <div className='xl:w-4/12 lg:w-6/12 w-full'>
                            <SearchInput
                                title={''}
                                required={false}
                                placeholder={'Search By Employee Name'}
                            />
                        </div>
                        <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 md:items-center justify-between'>
                            {/* <div className='flex flex-col lg:flex-row md:flex-row  gap-2 md:items-center justify-between '> */}
                            <div className='flex flex-row items-center gap-x-2 w-full '>
                                <Svgs.PdfIcon />
                                <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                                    <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                        <Svgs.DownloadBlack />
                                        Download Excel
                                    </div>
                                </Button>
                            </div>

                            {activeButton !== "Schedule" ?
                                <Button
                                    onClick={() => {
                                        setActiveEmployeeStep(0)
                                        setAddEmployeePopup(true)
                                    }}
                                    customPadding={'px-3 py-2'} className={"!rounded-md min-w-max"}>
                                    <div className='flex gap-2 items-center justify-between text-sm'>
                                        Add Employee
                                    </div>
                                </Button>
                                :
                                <Button
                                    onClick={() => {
                                        setOpenAddSchedule(true)
                                    }}
                                    customPadding={'px-3 py-2'} className={"!rounded-md min-w-max"}>
                                    <div className='flex gap-2 items-center justify-between text-sm'>
                                        New schedule
                                    </div>
                                </Button>
                            }


                        </div>
                    </div>
                </RoundedCard>

                <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 lg:mt-0 mt-2'>
                    <DashboardCard
                        title={'Total Employees'}
                        value={'3'}
                        // percentage={`4.02%`}
                        description={'Same as last month'}
                        icon={<Svgs.TotalRevenueSvg />}
                    />
                    <DashboardCard
                        title={'Attendance'}
                        value={'100%'}
                        percentage={`0%`}
                        description={'higher than last month'}
                        icon={<Svgs.TotalProductsSvg />}
                    />
                    <DashboardCard
                        title={'Schedule'}
                        value={'10AM-9PM'}
                        // percentage={`2.30%`}
                        description={'This week'}
                        icon={<Svgs.EmployeeIcon />}
                    />
                    <DashboardCard
                        title={'Salaries'}
                        value={'$18k'}
                        percentage={`6.72%`}
                        description={'higher than last month'}
                        icon={<Svgs.ActiveCustomersSvg />}
                    />
                </div>


                <div className='flex flex-row flex-wrap gap-3.5'>
                    {buttonData?.map((data, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveButton(data.title)}
                            className={`min-w-max px-2 py-1 ${activeButton === data.title ? "bg-btn-theme text-white font-bold " : " border border-gray-1 text-gray-1 font-semibold "} text-sm rounded`}
                        >
                            {data.title}
                        </button>
                    ))}
                </div>

                {activeButton === "Employees" ?
                    <Employee />
                    :
                    activeButton === "Attendances" ?
                        <Attendance />
                        :
                        activeButton === "Schedule" ?
                            <Schedule />
                            :
                            activeButton === "Salaries" &&
                            <Salaries />
                }
            </SellerLayout>


            <Popup
                open={isAddEmployeePopup}
                removeClose={true}
                removeHeading={true}
                header={false}
                customSizeStyle={" lg:w-[50vw] xl:w-[30vw]"}
            >
                <div className="flex items-center justify-center flex-col gap-8">
                    <h2 className='font-public-sans text-gray-1 font-semibold text-2xl'>
                        {steps[activeEmployeeStep].name}
                    </h2>

                    <div className="md:flex items-center flex-row justify-between w-full max-w-sm mx-auto hidden">
                        {steps.map((_, index) => (
                            <>
                                <div className="flex flex-col items-center">
                                    <div className={`relative flex items-center justify-center w-10 h-10 rounded-full ${activeEmployeeStep >= index ? 'bg-btn-theme' : 'bg-gray-200'}`}>
                                        <span className="text-white font-medium font-public-sans text-xl">{index + 1}</span>
                                    </div>
                                    <span className={`mt-2 text-center ${activeEmployeeStep >= index ? "bg-btn-theme text-transparent bg-clip-text " : "text-gray-2"} text-xs font-medium font-public-sans`}>
                                        {steps[index].name}
                                    </span>
                                </div>
                                {index !== 3 &&
                                    <div className="flex-1 border-2 flex relative -top-2.5 border-dashed border-gray-300 "></div>
                                }
                            </>
                        )
                        )}
                    </div>

                    <div className="flex flex-col items-center md:hidden">
                        <div className={`relative flex items-center justify-center w-10 h-10 rounded-full bg-btn-theme`}>
                            <span className="text-white font-bold">{activeEmployeeStep + 1}</span>
                        </div>
                        <span className="mt-2 text-center bg-btn-theme text-transparent bg-clip-text text-sm">
                            {steps[activeEmployeeStep].name}
                        </span>
                    </div>

                    <form action="" className='w-full gap-8 flex flex-col ' onSubmit={handleNext}>
                        {activeEmployeeStep === 0 ?
                            <>
                                <div className='flex flex-col gap-6 w-full'>

                                    <Input
                                        required={false}
                                        placeholder={"Enter full name"}
                                        title={"Full name"} />
                                    <div className='lg:grid lg:grid-cols-2 flex flex-col gap-2.5'>
                                        <Dropdown
                                            title={"Department"}
                                            placeholder={"Select"}
                                            option={[
                                                { value: "ht", label: "Human Resource" },
                                                { value: "mn", label: "Management" },
                                                { value: "ac", label: "Accounts" },
                                            ]}
                                        />
                                        <Dropdown
                                            title={"Position"}
                                            placeholder={"Select"}
                                            option={[
                                                { value: "ds", label: "Fresher" },
                                                { value: "", label: "Mid Senior" },
                                                { value: "", label: "Senior" },
                                            ]}
                                        />
                                    </div>
                                    <Input
                                        title={"Date of joining"}
                                        type={"date"}
                                        required={false}
                                        placeholder={"Select"}
                                    />

                                    <div className='grid lg:grid-cols-2 lg:gap-2.5 gap-6 grid-cols-1'>
                                        <Input
                                            type={"number"}
                                            title={"Phone number"}
                                            required={false}
                                            placeholder={"+1 000 000 0000"}
                                        />
                                        <Input
                                            title={"Email id"}
                                            type={"email"}
                                            required={false}
                                            placeholder={"user@email.com"}
                                        />
                                    </div>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <Button
                                        type={"submit"}
                                        title={"Next"}
                                    />
                                </div>
                            </>
                            :
                            activeEmployeeStep === 1 ?
                                <>
                                    <div className='flex flex-col gap-6 w-full'>
                                        <div className='h-[175px]  items-center justify-center inset-0 bg-[#F2F2F2] flex flex-col rounded-md gap-2.5 border '>
                                            {uploadedDoc ?
                                                <img src={uploadedDoc} alt={`uploaded`} className='object-cover w-full h-full rounded-lg' />
                                                :
                                                <>
                                                    <label htmlFor='uploadDoc' className='rounded-full bg-gray-5 border w-[110px] h-[110px] flex items-center justify-center border-gray-5 bg-opacity-30 cursor-pointer'>
                                                        <div className=' rounded-lg font-public-sans text-gray-2 border border-gray-2 px-3 py-1.5'>
                                                            Browse
                                                        </div>
                                                    </label>

                                                    <p className='font-bold text-base text-gray-1 font-mulish'>Upload image</p>
                                                    <input type="file" name="" id="uploadDoc" className='hidden' onChange={handleUploadDoc} />
                                                </>

                                            }
                                        </div>

                                        <div className='lg:grid lg:grid-cols-2 flex flex-col gap-2.5'>
                                            <Dropdown
                                                title={"Identify proof"}
                                                placeholder={"Select"}
                                                inputClass={" !z-[99999999] "}
                                                option={[
                                                    { value: "ht", label: "Driving License" },
                                                    { value: "mn", label: "Passport" },
                                                    // { value: "ac", label: "" },
                                                ]}
                                            />
                                            <Input
                                                title={"Unique Id number"}
                                                placeholder={"Type here"}
                                                required={false}
                                            />
                                        </div>
                                        <Input
                                            title={"Date of birth"}
                                            type={"date"}
                                            required={false}
                                            placeholder={"Select"}
                                        />

                                        <div className='grid lg:grid-cols-2 lg:gap-2.5 gap-6 grid-cols-1'>
                                            <Input
                                                type={"number"}
                                                title={"Phone number"}
                                                required={false}
                                                placeholder={"+1 000 000 0000"}
                                            />
                                            <Input
                                                title={"Email id"}
                                                type={"email"}
                                                required={false}
                                                placeholder={"user@email.com"}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-center gap-8'>
                                        {activeEmployeeStep > 0 &&
                                            <OutlineButton
                                                type={"button"}
                                                onClick={() => setActiveEmployeeStep(prev => prev - 1)}
                                                title={"Back"}
                                            />
                                        }
                                        <Button
                                            customPadding={" px-6 py-2"}
                                            type={"submit"}
                                            title={"Next"}
                                        />
                                    </div>
                                </>
                                :
                                activeEmployeeStep === 2 ?
                                    <>
                                        <div className='flex flex-col gap-6 w-full'>
                                            <Input
                                                title={"Offer letter"}
                                                type={"file"}
                                                required={false}
                                                placeholder={"Browse"}
                                            />
                                            <Input
                                                title={"Experience letter"}
                                                type={"file"}
                                                required={false}
                                                placeholder={"Browse"}
                                            />
                                            <Input
                                                title={"Education certificate"}
                                                type={"file"}
                                                required={false}
                                                placeholder={"Browse"}
                                            />
                                        </div>
                                        <div className='flex items-center justify-center gap-8'>
                                            {activeEmployeeStep > 0 &&
                                                <OutlineButton
                                                    type={"button"}
                                                    onClick={() => setActiveEmployeeStep(prev => prev - 1)}
                                                    title={"Back"}
                                                />
                                            }
                                            <Button
                                                customPadding={" px-6 py-2"}
                                                type={"submit"}
                                                title={"Next"}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='flex flex-col gap-6 w-full'>
                                            <Input
                                                required={false}
                                                placeholder={"Type ID here"}
                                                title={"National tax ID"} />
                                            <Input
                                                title={"Account number"}
                                                required={false}
                                                placeholder={"Type account number"}
                                            />
                                            <Input
                                                title={"Bank code"}
                                                required={false}
                                                placeholder={"Type bank code"}
                                            />
                                            <Input
                                                title={"Bank Name"}
                                                required={false}
                                                placeholder={"Type bank name"}
                                            />
                                            <Input
                                                title={"Name on account"}
                                                required={false}
                                                placeholder={"Type name here"}
                                            />
                                        </div>
                                        <div className='flex items-center justify-center gap-8'>
                                            {activeEmployeeStep > 0 &&
                                                <OutlineButton
                                                    type="button"
                                                    onClick={() => setActiveEmployeeStep(prev => prev - 1)}
                                                    title={"Back"}
                                                />
                                            }
                                            <Button
                                                customPadding={" px-6 py-2"}
                                                type={"submit"}
                                                title={"Next"}
                                            />
                                        </div>
                                    </>
                        }


                    </form>

                </div>


            </Popup>


            {/* Success Popup */}
            <Popup
                removeClose={true}
                removeHeading={true}
                header={false}
                open={openSuccessPopup}
                customSizeStyle={" lg:w-[30vw] "}
            >
                <div className='flex flex-col items-center justify-center font-public-sans gap-8'>
                    <div className='flex flex-col gap-2.5 items-center'>
                        <Svgs.FilledRightIcon />
                        <p className='font-public-sans text-gray-4 text-base'>
                            Employee added successfully
                        </p>
                    </div>

                    <div className='flex flex-col gap-4 items-center justify-center w-[280px] text-center'>
                        <div className='font-semibold text-xl'>
                            <div>
                                <span className=' text-gray-4'>Username: </span>
                                <span className='text-gray-1'>Jacksmith01234</span>
                            </div>
                            <div >
                                <span className=' text-gray-4'>Password: </span>
                                <span className=' text-gray-1'>Jack#1234</span>
                            </div>
                        </div>
                        <p>username and password has been sent
                            over employee email.</p>
                    </div>

                    <div className='flex flex-row gap-5'>
                        <OutlineButton
                            onClick={() => {
                                setActiveEmployeeStep(0)
                                setOpenSuccessPopup(false)
                                setAddEmployeePopup(false)
                            }}
                            title={"Close"} />
                        <Button
                            onClick={() => {
                                setActiveEmployeeStep(0)
                                setOpenSuccessPopup(false)
                                setAddEmployeePopup(false)
                            }}
                            customPadding={"py-2 px-6"}
                            title={"Share"}
                        />
                    </div>

                </div>

            </Popup >


            <Popup
                open={openAddSchedule}
                removeClose={true}
                header={false}
                removeHeading={true}
                customSizeStyle={" lg:w-[30vw]"}
            >
                <div className='flex flex-col font-public-sans gap-8 items-center'>
                    <h3 className='text-gray-1 font-semibold text-2xl'>
                        Add schedule
                    </h3>

                    <div className='flex w-full flex-col gap-6'>
                        <Input
                            required={false}
                            type={"date"}
                            title={"Select week"}
                            placeholder={"Select"}
                        />

                        <Dropdown
                            title={"Select Employee"}
                            placeholder={"Select"}
                        />

                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-2.5 gap-6'>
                            <Dropdown
                                title={"Shift time"}
                                placeholder={"Select"}
                            />
                            <Dropdown
                                title={"Breaks"}
                                placeholder={"Select"}
                            />
                        </div>


                    </div>

                    <div className='flex flex-col gap-5 '>
                        <button className='underline text-[#2F80ED] underline-offset-5'>
                            Add more employees
                        </button>

                        <Button
                            onClick={() => {
                                setOpenAddSchedule(false)
                                setOpenNewSchedulePopup(true)
                            }}
                            className={"min-w-max"}
                            customPadding={" !py-2 !px-6 min-w-max "}
                            title={"Save"}
                        />
                    </div>
                </div>
            </Popup>


            <SuccessPopup
                onClick={() => {
                    setOpenNewSchedulePopup(false)
                }}
                open={openNewSchedulePopup}
                title={"Schedule created successfully"}
            />




        </>



    )
}

export default PremiumEmployeeScreen