import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'Components/Elements/Button'
import RoundedCard from 'Components/Elements/RoundedCard'
import Image from 'Components/Elements/Image'
import Svgs from 'Assets/svgs'
import DashboardCard from 'Pages/Seller/Dashboard/Elements/DashboardCard'
import Dropdown3 from 'Components/Elements/Dropdown3'
import OutlineButton from 'Components/Elements/OutlineButton'
import Popup from 'Components/Elements/Popup'
import Textarea from './Elements/Textarea'
import { toast } from 'react-toastify'
import SellerLayout from 'Components/Layouts/SellerLayout'
import { CartButton } from 'Components/Elements/CartButton'
import BreadCrumb from 'Components/Elements/BreadCrumb'

function VendorDetails() {
    const recentOrderTableData = [
        {
            order: "Dairy products",
            amount: "$110",
            statusText: "Invoice uploaded",
            status: "Pending"
        },
        {
            order: "Sweets",
            amount: "$70",
            statusText: "Order confirmed",
            status: "Settled"
        },
        {
            order: "Spices",
            amount: "$90",
            statusText: "Paid",
            status: "Settled"
        },
        {
            order: "Fruits",
            amount: "$100",
            statusText: "Pay $40",
            status: "Packed"
        },
    ]

    const allProdcutData = [
        {
            image: "/images/Coca-Cola.png",
            name: "Coca Cola(1)",
            price: "$1.8"
        },
        {
            image: "/images/Cabbage.png",
            name: "Cabbage",
            price: "$1"
        },
        {
            image: "/images/Eggs.png",
            name: "Eggs(12)",
            price: "$2"
        },
    ]

    const tableData = [
        {
            category: "Salary",
            title: "Roston chase",
            amount: "$1200",
        },
        {
            category: "Electricity bill",
            title: "Aug month",
            amount: "$320",
        },
        {
            category: "Water bill",
            title: "Aug month",
            amount: "$115",
        },
    ]
    const navigate = useNavigate()

    const [activeButtonReview, setActiveButtonReview] = useState("All")
    const [rating, setRating] = useState(0)

    return (
        <>
            <SellerLayout active={"Vendors"} title={
                <>
                    <div className='flex flex-row gap-2 items-center'>
                        Amrit Foods
                        <Svgs.PremiumIcon />
                    </div>
                </>
            } bodyClassName={'flex flex-col space-y-4'}>

                <div className='flex flex-row flex-wrap justify-between'>
                    <BreadCrumb
                        first={"Vendors"}
                        url={'/vendor'}
                        second={"Amrit foods"}
                    />
                    <div>
                        <Button
                            customPadding={"px-2.5 py-2"}
                            title={"Add Invoice"}
                            customThemeBtn={"theme-btn2"} />
                    </div>

                </div>

                <RoundedCard>
                    <div className='flex lg:flex-row flex-col gap-4 justify-between lg:items-center'>
                        <div className='flex flex-row gap-4'>
                            <Image
                                customLoaderHeight={" h-[102px]"}
                                customLoaderRounded={"rounded-full"}
                                customLoaderWidth={"w-[102px]"}
                                className={' object-cover w-[102px] h-[102px] self-center rounded-full'} src={"/images/Pancakes.png"} />
                            <div className='flex flex-col gap-3  justify-center'>
                                <h5 className='font-bold text-2xl'>Amrit foods</h5>
                                <div className='flex flex-row gap-2 items-center text-sm'>
                                    <p>4.5</p>
                                    <Svgs.StarFill />
                                    <span>(223 Reviews)</span>
                                </div>
                            </div>
                        </div>


                        <div className='flex flex-col justify-between h-full gap-2 items-start text-sm'>
                            <div>
                                <span className='text-[#949494]'>Address:{" "}</span>
                                <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                            </div>
                            <div>
                                <span className='text-[#949494]'>Contact:{" "}</span>
                                <span>+ 653 436 7229</span>
                            </div>
                            <div>
                                <span className='text-[#949494]'>Email id:{" "}</span>
                                <span>amritfoods@gmail.com</span>
                            </div>

                        </div>


                    </div>
                </RoundedCard>


                <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 lg:mt-0 mt-2'>
                    <DashboardCard
                        title={'Total orders'}
                        value={'32'}
                        percentage={`4.02%`}
                        description={'higher than last month'}
                        icon={<Svgs.TotalRevenueSvg />}
                    />
                    <DashboardCard
                        title={'Total Products'}
                        value={'83'}
                        percentage={`0%`}
                        description={'higher than last month'}
                        icon={<Svgs.TotalProductsSvg />}
                    />
                    <DashboardCard
                        title={'Total spending\'s'}
                        value={'$12k'}
                        percentage={`2.30%`}
                        description={'more than last month'}
                        icon={<Svgs.EmployeeIcon />}
                    />
                    <DashboardCard
                        title={'Pending payments'}
                        value={'$200'}
                        percentage={`0%`}
                        description={'higher than last month'}
                        icon={<Svgs.ActiveCustomersSvg />}
                    />
                </div>

                <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                    <RoundedCard className={"flex flex-col gap-1"}>
                        <div className='flex lg:flex-row justify-between flex-col gap-2'>
                            <h5>Recent Orders</h5>
                            <div className="flex flex-row gap-3">
                                <Dropdown3
                                    title={""}
                                    placeholder={"Last week"}
                                    option={[
                                        { value: "", label: "Last month" },
                                        { value: "", label: "Last 3 months" },
                                    ]}
                                />
                                <div>
                                    <Button
                                        customThemeBtn={"theme-btn2"}
                                        title={"View all"}
                                        className={"!text-sm !font-normal"}
                                        customPadding={"px-2 py-1"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='relative'>
                            <div className="overflow-x-auto">
                                <table className="w-full text-sm text-left rtl:text-left min-w-max  ">
                                    <thead class="text-sm text-[#686868] capitalize">
                                        <tr>
                                            <th scope="col" class="pr-5 py-3 font-normal">
                                                Order
                                            </th>
                                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                                Amount
                                            </th>
                                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                                Status
                                            </th>
                                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                                Invoice
                                            </th>
                                        </tr>
                                    </thead>

                                    {recentOrderTableData.map((data, index) => (
                                        <tr
                                            key={index}
                                            className="border-b border-[#EEEEEE] text-sm py-3">
                                            <td className='pr-5 py-3 text-[#949494]'>
                                                {data.order}
                                            </td>
                                            <td className={`pr-5 py-3 ${data.status === "Pending" ? " text-[#2F80ED]" : "settled-text"}`}>
                                                {data.amount}
                                            </td>
                                            <td className='pr-5 py-3'>
                                                <span className={`${data.status === "Pending" ? "pending " : data.status === "Packed" ? "packed" : "settled"} px-2.5 py-1 rounded`}>
                                                    {data.statusText}
                                                </span>
                                            </td>
                                            <td className="pr-5 py-3 text-center  flex justify-center items-start cursor-pointer">
                                                <Svgs.EyeIcon />
                                            </td>


                                        </tr>
                                    ))
                                    }

                                </table>
                            </div>
                        </div>
                    </RoundedCard>


                    <RoundedCard className={"flex flex-col gap-1"}>
                        <div className='flex lg:flex-row justify-between flex-col gap-2'>
                            <div className='flex flex-row gap-4 items-center '>
                                <div>
                                    <h5>All Invoices</h5>
                                </div>
                                <div className='flex flex-row gap-7 text-[#2F80ED] underline text-sm'>
                                    <button>Statement</button>
                                    <button>Credit Memo</button>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <Dropdown3
                                    title={""}
                                    placeholder={"Last week"}
                                    option={[
                                        { value: "", label: "Last month" },
                                        { value: "", label: "Last 3 months" },
                                    ]}
                                />
                                <div>
                                    <Button
                                        customThemeBtn={"theme-btn2"}
                                        title={"View all"}
                                        className={"!text-sm !font-normal"}
                                        customPadding={"px-2 py-1"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='relative'>
                            <div className="overflow-x-auto">
                                <table className="w-full text-sm text-left rtl:text-left min-w-max  ">
                                    <thead class="text-sm text-[#686868] capitalize">
                                        <tr>
                                            <th scope="col" class="pr-5 py-3 font-normal">
                                                Order
                                            </th>
                                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                                Amount
                                            </th>
                                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                                Status
                                            </th>
                                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                                Invoice
                                            </th>
                                        </tr>
                                    </thead>

                                    {recentOrderTableData.map((data, index) => (
                                        <tr
                                            key={index}
                                            className="border-b border-[#EEEEEE] text-sm py-3">
                                            <td className='pr-5 py-3 text-[#949494]'>
                                                {data.order}
                                            </td>
                                            <td className={`pr-5 py-3 ${data.status === "Pending" ? " packed-text" : "settled-text"}`}>
                                                {data.amount}
                                            </td>
                                            <td className='pr-5 py-3'>
                                                <span className={`${data.status === "Pending" ? "packed " : "settled"} px-2.5 py-1 rounded`}>
                                                    {data.status}
                                                </span>
                                            </td>
                                            <td className="pr-5 py-3 text-center  flex justify-center items-start cursor-pointer">
                                                {index === 0 ?
                                                    <OutlineButton
                                                        className={"!px-2 !py-1"}
                                                        title={"Pay now"}
                                                    />
                                                    :
                                                    <Svgs.EyeIcon />
                                                }
                                            </td>


                                        </tr>
                                    ))
                                    }

                                </table>
                            </div>
                        </div>
                    </RoundedCard>


                </div>

                <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                    <RoundedCard className={"flex flex-col gap-6"}>
                        <div className="flex flex-col gap-4">
                            <div className='flex flex-row items-center justify-between'>
                                <h4 className='font-semibold text-lg'>
                                    All Products
                                </h4>
                                <div className='flex flex-row gap-2'>
                                    <Dropdown3
                                        title={""}
                                        placeholder={"Categories"}
                                    />
                                    <Button
                                        onClick={() => navigate("/vendor/amrit-foods/all-products")}
                                        title={"View all"}
                                        customThemeBtn={"theme-btn2"}
                                        customPadding={"px-2 py-1"}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='flex flex-row gap-1.5 flex-wrap items-center justify-center '>
                            {Array(3).fill().map((_, index) => (
                                allProdcutData.map((data, index) => (
                                    <div
                                        onClick={() => navigate("/vendor/amrit-foods/product")}
                                        key={index}
                                        className=" cursor-pointer min-w-[186px] p-2 border rounded-lg flex flex-col gap-2">
                                        <Image
                                            customLoaderHeight={"h-[120px]"}
                                            customLoaderRounded={"rounded-lg"}
                                            customLoaderWidth={"w-[170px]"}
                                            className={"object-cover h-[120px] w-[170px]"}
                                            src={data.image}
                                        />
                                        <div className='flex flex-col gap-2.5'>
                                            <div className='flex flex-row justify-between text-sm'>
                                                <p className='font-semibold'>{data.name}</p>
                                                <p className='font-bold'>{data.price}</p>
                                            </div>

                                            <div className='flex flex-row justify-between text-sm items-center'>
                                                <div className='flex flex-row items-center gap-2'>
                                                    <span>4.5</span>
                                                    <Svgs.StarFill />
                                                </div>
                                                <OutlineButton
                                                    title={"+ Add"}
                                                    className={" !px-1.5 !py-0.5"}
                                                />

                                            </div>

                                        </div>


                                    </div>
                                ))

                            ))}
                        </div>
                        <div className='flex items-center justify-center'>
                            <Button
                                customPadding={"px-2 py-1"}
                                title={"Load more"}
                                customThemeBtn={"theme-btn2"} />

                        </div>
                    </RoundedCard>

                    <div className='flex flex-col gap-2.5'>
                        <RoundedCard className={"flex flex-col gap-6"}>
                            <div className='flex flex-row justify-between items-center'>
                                <div>
                                    <h4 className='font-semibold text-lg'>Saved Invoices</h4>
                                </div>
                                <div>

                                    <Dropdown3
                                        title={""}
                                        placeholder={"Last week"}
                                        option={[
                                            { value: "", label: "Last month" },
                                            { value: "", label: "Last 3 months" },
                                        ]}
                                    />
                                </div>


                            </div>
                            <div className='relative'>
                                <div className="overflow-x-auto">
                                    <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                                        <thead class="text-sm text-[#686868] capitalize">
                                            <tr>
                                                <th scope="col" class="pr-5 py-3 font-normal text-left">
                                                    Invoice ID
                                                </th>
                                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap text-center">
                                                    Vendor name
                                                </th>
                                                <th scope="col" class="pr-5 py-3 font-normal text-right">
                                                    Details
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {Array(3).fill().map((data, index) => (
                                                <tr class="border-b border-[#EEEEEE]">
                                                    <td class="pr-5 py-3 text-[#949494] text-left">
                                                        #234516
                                                    </td>
                                                    <td class="pr-5 py-3 whitespace-nowrap text-center">
                                                        Amrit foods
                                                    </td>
                                                    <td class="pr-5 py-3 flex justify-end">
                                                        <Button
                                                            customPadding={"px-2 py-1"}
                                                            title={"Edit now"}
                                                            customThemeBtn={"theme-btn2"} />
                                                    </td>

                                                </tr>

                                            ))}
                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </RoundedCard>

                        <RoundedCard>
                            <div className='flex flex-col gap-5'>
                                <div className='space-y-2.5'>

                                    <h4 className='font-semibold text-lg'>
                                        Review
                                    </h4>
                                    <div className='flex flex-row gap-3'>
                                        <Button
                                            onClick={() => setActiveButtonReview("All")}
                                            customThemeBtn={`${activeButtonReview !== "All" ? "theme-btn2" : ""}`}
                                            customPadding={"px-1.5 py-0.5"}
                                            title={"All reviews"}
                                        />
                                        <Button
                                            onClick={() => setActiveButtonReview("Write")}
                                            customThemeBtn={`${activeButtonReview !== "Write" ? "theme-btn2" : ""}`}
                                            className={" !px-1.5 !py-0.5"}
                                            title={"Write a review"}
                                        />

                                    </div>
                                </div>


                                {activeButtonReview === "All"
                                    ?
                                    Array(2).fill().map((_, index) => (
                                        <div key={index} className='border  rounded-2xl p-4 flex flex-col gap-4'>
                                            <div className='flex justify-between flex-row items-center'>
                                                <div className='flex flex-row items-center gap-3'>

                                                    <Image
                                                        src={"/images/profile.png"}
                                                        customLoaderRounded={"rounded-full"}
                                                        customLoaderHeight={"h-[49px]"}
                                                        customLoaderWidth={"w-[49px]"}
                                                    />
                                                    <div className='flex flex-col gap-2 font-poppins'>
                                                        <p className='font-semibold text-[#414042] text-xl'>
                                                            BX Bakery
                                                        </p>
                                                        <p className='text-[#7A7A7A] text-base'>Investor</p>

                                                    </div>

                                                </div>
                                                <div className='flex lg:hidden flex-row items-center gap-2'>
                                                    <p className='font-semibold text-lg '>
                                                        4
                                                    </p>
                                                    <Svgs.StarFill />
                                                </div>

                                                <div className='lg:flex hidden flex-row items-center gap-1.5'>
                                                    {Array(4).fill().map((_, index) => (
                                                        <Svgs.StarFill key={index} />
                                                    ))}
                                                    <Svgs.StarEmpty />
                                                </div>
                                            </div>
                                            <p className='text-[#828282] text-sm font-poppins'>
                                                I have been purchasing products in bulk from Amritsweets for my store, and I couldn't be happier with the experience. The quality of the goods is consistently excellent, and the prices are very competitive.
                                            </p>
                                        </div>
                                    ))
                                    :
                                    <div className="border border-gray-5 rounded-lg flex flex-col p-4 gap-5">
                                        <div className='flex justify-between flex-row  gap-4  lg:items-center'>
                                            <div className='flex flex-row items-center gap-3'>
                                                <Image
                                                    src={"/images/profile.png"}
                                                    customLoaderRounded={"rounded-full"}
                                                    customLoaderHeight={"h-[49px]"}
                                                    customLoaderWidth={"w-[49px]"}
                                                />
                                                <div className='flex flex-col gap-2 font-poppins'>
                                                    <p className='font-semibold text-[#414042]text-xl '>
                                                        Products Order
                                                    </p>
                                                    <p className='text-[#7A7A7A] text-sm lg:text-base'>Vendor: Amritsweets</p>

                                                </div>
                                            </div>

                                            <div className='flex lg:hidden flex-row items-center gap-2'>
                                                <p className='font-semibold text-lg '>
                                                    4
                                                </p>
                                                <Svgs.StarFill />
                                            </div>
                                            <div className='lg:flex hidden flex-row items-center gap-1.5'>
                                                {Array(4).fill().map((_, index) => (
                                                    <Svgs.StarFill key={index} />
                                                ))}
                                                <Svgs.StarEmpty />
                                            </div>
                                        </div>

                                        <div className='flex flex-col items-center justify-center gap-4'>
                                            <h1 className='font-poppins font-medium text-xl text-[#414042]'>
                                                Rate your order
                                            </h1>
                                            <div className='flex flex-row gap-1.5'>
                                                {Array.from({ length: 5 }, (_, index) => (
                                                    index < rating ?
                                                        <div className='cursor-pointer' onClick={() => setRating(index + 1)}>
                                                            <Svgs.StarFill key={index} />
                                                        </div>
                                                        :
                                                        <div className='cursor-pointer' onClick={() => setRating(index + 1)}>
                                                            <Svgs.StarEmpty key={index} />
                                                        </div>

                                                ))}
                                            </div>
                                            <Textarea
                                                parentClass={"w-full"}
                                                inputClass={"h-[96px]"}
                                                title={"Write review"}
                                                placeholder={"Type here"}
                                            />



                                        </div>

                                        <div className='flex items-center justify-center'>
                                            <Button
                                                onClick={() => {
                                                    toast.success("Thanks for the review")
                                                    setActiveButtonReview("All")
                                                }}
                                                className={" !text-sm"}
                                                customPadding={"px-1.5 py-1"}
                                                title={"Send review"}
                                            />

                                        </div>

                                    </div>
                                }
                            </div>
                        </RoundedCard>

                    </div>

                </div>



                <CartButton />
            </SellerLayout>
        </>

    )
}

export default VendorDetails