
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Svgs from "Assets/svgs";
import Button from "Components/Elements/Button";
import SideBar from "Components/Sidebar";

const Topbar = ({ hideAddress }) => {
    const navigate = useNavigate();
    const [menuToggle, setMenuToggle] = useState(false);

    return (
        <>
            <div className="sticky top-0 bg-white z-[444] py-5">
                <div className="grid xl:grid-cols-3 grid-cols-2 justify-between mx-auto w-[90%] items-center">
                    <div className="flex items-center gap-2">
                        <div className="xl:hidden block cursor-pointer" onClick={() => { setMenuToggle(!menuToggle) }}>
                            <Svgs.MenuToggler />
                        </div>
                        <h1 className="text-primary font-extrabold text-3xl pl-3 cursor-pointer" onClick={() => { navigate('/') }}>
                            <img src="/logo.png" className="mr-3 h-10" alt="Logo" />
                        </h1>
                        {!hideAddress &&
                            <div className="md:block hidden">
                                <div className="flex items-center gap-2">
                                    <Svgs.LocationIcon />
                                    <p className="text-primary text-sm">Your Address</p>
                                    <Svgs.ArrowDown />
                                </div>
                            </div>
                        }
                    </div>
                    {hideAddress ?
                        <div className="xl:block hidden">
                            <div className="flex items-center gap-8 justify-center">
                                <h1 className="cursor-pointer">Services</h1>
                                <h1 className="cursor-pointer min-w-max whitespace-nowrap">How It Works</h1>
                                <h1 className="cursor-pointer">Benefits</h1>
                                <h1 className="cursor-pointer">Advertise</h1>
                                <h1 className="cursor-pointer">FAQ</h1>
                            </div>
                        </div>
                        :
                        <div className="xl:block hidden">
                            <div className="flex items-center gap-8 justify-center">
                                <h1 className="cursor-pointer">Shop</h1>
                                <h1 className="cursor-pointer">Advertising</h1>
                                <h1 className="cursor-pointer">About</h1>
                                <h1 className="cursor-pointer">Contact</h1>
                            </div>
                        </div>
                    }
                    <div className="flex items-center gap-4 justify-end">
                        {!hideAddress &&
                            <div className="md:block hidden">
                                <div className="flex gap-4 items-center">
                                    <Svgs.SearchIcon />
                                    <Svgs.NotificationIcon />
                                    <Svgs.Cart />
                                </div>
                            </div>
                        }
                        <Button customPadding={'px-4 py-1'} className={"!rounded-md"} onClick={() => { navigate("/login") }}>
                            Login/ Signup
                        </Button>
                    </div>
                </div>
                {!hideAddress &&
                    <div className="md:hidden block">
                        <div className="grid grid-cols-2 gap-2 justify-between mx-auto w-[90%] items-center">
                            <div className="flex items-center gap-1">
                                <Svgs.LocationIcon />
                                <p className="text-primary xs:text-sm text-xs">Your Address</p>
                                <Svgs.ArrowDown />
                            </div>
                            <div className="flex gap-2 items-center justify-end">
                                <Svgs.SearchIcon />
                                <Svgs.NotificationIcon />
                                <Svgs.Cart />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <SideBar toggle={menuToggle} setToggle={setMenuToggle} hideAddress={hideAddress} />
        </>
    );
};

export default Topbar;
