import Dropdown from 'Components/Elements/Dropdown'
import Input from 'Components/Elements/Input'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'Components/Elements/Button'
import AddOfferScreen from './AddOfferScreen'
import UploadImage from 'Components/Elements/UploadImage'
import BreadCrumb from 'Components/Elements/BreadCrumb'

export const AddProduct = () => {
    const navigation = useNavigate()
    const [addOffer, setAddOffer] = useState(false)

    return (
        <SellerLayout active={'Inventory'} title={'Add product'} bodyClassName={'flex flex-col space-y-4'}>
            {!addOffer ?
                <>
                    <BreadCrumb
                        first={"Inventory"}
                        url={'/inventory'}
                        second={"Add product"}
                    />
                    <RoundedCard>
                        <div className=' gap-6  flex flex-col'>
                            <div className='grid xl:grid-cols-3 gap-6 w-full'>
                                <div className='flex flex-col gap-6 '>
                                    <Input
                                        title={"Product name"}
                                        required={false}
                                        placeholder={"Type here"}
                                        parentClass={"w-full"}
                                    />
                                    <Dropdown
                                        placeholder={"Select"}
                                        title={"Category"}
                                        option={[
                                            { value: "Fruits", label: "Fruits" },
                                            { value: "Vegetables", label: "Vegetables" }
                                        ]}
                                    />
                                    <Input
                                        type={"number"}
                                        title={"Retail price"}
                                        required={false}
                                        placeholder={"Type here"}
                                        parentClass={"w-full"}
                                    />
                                    <Dropdown
                                        title={"TAX"}
                                        placeholder={"GST"}
                                        required={false}
                                        parentClass={"w-full"}
                                        option={[
                                            { value: "GST", label: "GST" },
                                            { value: "PGST", label: "PGST" }
                                        ]}
                                    />

                                </div>
                                <div className='flex flex-col gap-6 '>
                                    <Dropdown
                                        title={"Country of origin"}
                                        placeholder={"Select"}
                                        inputClass={" !placeholder:text-black "}
                                        option={[
                                            { value: "India", label: "India" },
                                            { value: "Pakistan", label: "Pakistan" },
                                            { value: "Canada", label: "Canada" },
                                        ]}
                                        parentClass={"w-full"}
                                    />
                                    <Dropdown
                                        placeholder={"Select"}
                                        title={"Sub category"}
                                        option={[
                                            { value: "Exotic", label: "Exotic Fruits" },
                                            { value: "Common ", label: "Common Fruits" }
                                        ]}
                                    />
                                    <Input
                                        type={"number"}
                                        title={"Wholesale price"}
                                        required={false}
                                        placeholder={"Type here"}
                                        parentClass={"w-full"}
                                    />
                                    <Input
                                        type={"number"}
                                        title={"Quantity"}
                                        placeholder={"Type here"}
                                        required={false}
                                        parentClass={"w-full"}
                                    />

                                </div>
                                <div className='flex flex-col gap-6 '>

                                    <div className={` relative flex flex-col gap-2 h-2/4 min-h-32 `}>

                                        <div className={` text-sm text-primaryBlack flex items-center gap-1`}>
                                            <div>{"Product description"}</div>
                                        </div>

                                        <div className={`${false ? "border !border-[#eb3b3b]" : false ? "border border-lightBlue" : ""} relative flex overflow-hidden transition h-full`}>
                                            <textarea
                                                placeholder='Type here'
                                                // ref={inputRef}
                                                // autofocus={(autoFocus && autoFocus == "true") ? true : "false"}
                                                // onClick={(e) => {
                                                //     if (type === 'date' && typeof e.target.showPicker === 'function') {
                                                //         e.target.showPicker();
                                                //     }
                                                // }}
                                                // onKeyDown={HandleKeyPress}
                                                // disabled={disabled}
                                                // type={type ? type : "text"}
                                                // placeholder={type == 'date' ? "yyyy-mm-dd" : placeholder}
                                                // name={name}
                                                // value={sanitizedValue}
                                                // min={min}
                                                // max={max}
                                                // readOnly={readOnly}
                                                // maxLength={charactersMaxLength}
                                                // onChange={handleInputChange}
                                                className={` px-4 py-2 !bg-gray-100 min-h-10 rounded-[5px] w-full bg-lightBlue focus:outline-none  resize-none h-full text-sm `}
                                            />
                                        </div>
                                    </div>
                                    {/* <Textarea
                            title={"Product description"}
                            required={false}
                            placeholder={"Type here"}
                            inputClass={"h-full justify-start text-stat"}
                            parentClass={"w-full h-2/4"}
                        /> */}
                                    <Input
                                        type={"number"}
                                        title={"Markup price"}
                                        required={false}
                                        placeholder={"Type here"}
                                        parentClass={"w-full"}
                                    />
                                    <Input
                                        type={"number"}
                                        title={"Reorder level"}
                                        placeholder={"Type here"}
                                        required={false}
                                        parentClass={"w-full"}
                                    />

                                </div>
                            </div>
                            <UploadImage />
                        </div>
                        <div className='flex items-center justify-center py-8'>
                            <Button
                                onClick={() => setAddOffer(true)}
                                className={"px-9 !py-2"}
                                title={"Next"} />

                            {/* </Button> */}
                        </div>

                    </RoundedCard>
                </>
                :
                <AddOfferScreen setAddOffer={setAddOffer} />}
        </SellerLayout>
    )
}
