import React from 'react'
import Button from 'Components/Elements/Button'
import Footer from 'Components/Footer'
import Topbar from 'Components/Topbar'
import JobCard from './Elements/JobCard'

const data = [
    { title: "Visual Designer", role: "2+ years experience | Remote", description: "As a Visual Designer, you'll create captivating visuals for diverse projects, refining layouts and collaborating closely with teams to bring concepts to life." },
    { title: "Graphic Designer", role: "2+ years experience | Remote", description: "As a Graphic Designer, you'll create captivating visuals for diverse projects, refining layouts and collaborating closely with teams to bring concepts to life." },
    { title: "Graphic Designer", role: "2+ years experience | Remote", description: "As a Graphic Designer, you'll create captivating visuals for diverse projects, refining layouts and collaborating closely with teams to bring concepts to life." },
    { title: "Visual Designer", role: "2+ years experience | Remote", description: "As a Visual Designer, you'll create captivating visuals for diverse projects, refining layouts and collaborating closely with teams to bring concepts to life." },
]

const Careers = () => {
    return (
        <>
            <Topbar />
            {/* Banner Section */}
            <div
                className="relative bg-[#f9eeee] xl:min-h-[90vh] min-h-[50vh] w-full flex items-center justify-center p-10 py-20 mx-auto"
                style={{
                    backgroundImage: `url('/images/LandingBannerImg.png'), url('/images/LandingBannerImg1.png')`,
                    backgroundRepeat: 'no-repeat, no-repeat',
                    backgroundPosition: 'top left, bottom right',
                    backgroundSize: 'contain, contain',
                }}
            >
                <div className="text-center flex flex-col justify-center items-center space-y-24">
                    <div className='text-center max-w-3xl mx-auto flex flex-col justify-center items-center space-y-8 py-20'>
                        <h2 className="lg:text-[49px] line-height-58px md:text-4xl xs:text-2xl text-xl font-bold text-primary max-w-xl">Join our firm <br />& Become a better you</h2>
                        <h1>Hey there! Ready to kickstart your career journey with us? Dive in and discover exciting opportunities waiting just for you!</h1>
                        <div className="pt-8">
                            <Button customPadding="!px-4 py-2" className={'rounded-lg'}>
                                <div className='text-sm'>
                                    See Open Roles
                                </div>
                            </Button>
                        </div>
                    </div>
                    <img src='/images/CareerImg1.png' alt='' className='h-full w-full object-cover' />
                </div>
            </div>
            <div className='w-[85%] mx-auto flex flex-col space-y-16 my-16'>
                <div className='grid md:grid-cols-2 grid-cols-1 md:gap-20 gap-4 justify-between py-10'>
                    <h1 className='font-semibold text-3xl line-height-58px'>We are an equal opportunity workplace and an affirmative action employer.</h1>
                    <div className='flex flex-col space-y-8 justify-between'>
                        <div className='flex flex-col space-y-4 justify-between'>
                            <h1>To build equitable solutions, we're proud to have a diverse team who bring in unique perspectives.</h1>
                            <hr />
                            <h1>At MR Corporation, you will learn as much as you will inspire, you will grow as much as you will empower and achieve as much as you will be challenged.</h1>
                            <Button
                                className={'w-fit'}
                                title={'Apply Now'}
                                customPadding={'px-2 py-1'}
                            />
                        </div>
                    </div>
                </div>

                <img src='/images/CareerImg1.png' alt='' className='h-full w-full object-cover' />

                <div className='text-center max-w-3xl mx-auto flex flex-col justify-center items-center space-y-8 py-10'>
                    <h2 className="line-height-58px md:text-3xl xs:text-2xl text-xl font-bold text-primary max-w-xl">Checkout the latest roles</h2>
                    <h1>Hey there! Ready to kickstart your career journey with us? Dive in and discover exciting opportunities waiting just for you!</h1>
                </div>

                <div className='grid md:grid-cols-2 grid-cols-1 gap-8 justify-between items-center'>
                    {data?.map((itm, ind) => (
                        <JobCard
                            key={ind}
                            title={itm?.title}
                            role={itm?.role}
                            description={itm?.description}
                        />
                    ))}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Careers