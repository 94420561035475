import Image from 'Components/Elements/Image'
import React from 'react'

const SellerOrderDetailTable = ({ data }) => {
    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right ">
                    <thead class="text-sm text-[#686868] capitalize">
                        <tr>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Sr. No.
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Product Image
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Product Name
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Price
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Quantity
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                1.
                            </td>
                            <td class="pr-5 py-3">
                                <Image customLoaderHeight={"h-[30px]"} customLoaderWidth={"w-[48px]"} className={'w-[48px] h-[30px] object-cover'} src={'/images/TopProductImg1.png'} />
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Amul Milk
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                $1
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                1
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                $1
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                2
                            </td>
                            <td class="pr-5 py-3">
                                <Image customLoaderHeight={"h-[30px]"} customLoaderWidth={"w-[48px]"} className={'w-[48px] h-[30px] object-cover'} src={'/images/TopProductImg2.png'} />
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Bell Peppers
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                $2
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                1
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                $2
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                3
                            </td>
                            <td class="pr-5 py-3">
                                <Image customLoaderHeight={"h-[30px]"} customLoaderWidth={"w-[48px]"} className={'w-[48px] h-[30px] object-cover'} src={'/images/TopProductImg3.png'} />
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Chips
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                $2
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                5
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                $10
                            </td>
                        </tr>
                    </tbody>
                    {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                </table>
                {/* {data?.length == 0 &&
                    <NotFound />
                } */}
            </div>
        </>
    )
}

export default SellerOrderDetailTable