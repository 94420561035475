import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VendorInvoiceDetailsTable from 'Utility/Tables/VendorInvoiceDetailsTable'

export const VendorInvoiceDetail = () => {
    const navigate = useNavigate()
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)
    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    #234516
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'} >

            <div className='flex flex-col sm:flex-row gap-2 flex-wrap lg:items-center text-sm lg:text-base text-[#686868] justify-between'>

                <div className='flex flex-row gap-1.5 flex-wrap items-center'>
                    <button
                        className='text-[#686868]'
                        onClick={() => navigate("/vendor")}>
                        Vendors
                    </button>
                    <div className='text-[#686868]'>{">"}</div>
                    <button
                        className='text-[#686868]'
                        onClick={() => navigate(-2)}>
                        Recent orders
                    </button>
                    <div className='text-[#686868]'>{">"}</div>
                    <button className=''
                        onClick={() => navigate(-1)}>
                        Order details
                    </button>
                    <div className='text-[#686868]'>{">"}</div>
                    <div className='text-black'>
                        #234516
                    </div>
                </div>

                <div className='flex flex-row gap-3.5'>
                    <div className='flex flex-row items-center gap-x-2 w-full '>
                        <button>
                            <Svgs.PdfIcon />
                        </button>
                        <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                            <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                <Svgs.DownloadBlack />
                                Download Excel
                            </div>
                        </Button>
                    </div>
                </div>



            </div>

            <RoundedCard className={"flex flex-col "}>
                <div className='flex flex-col gap-3.5'>
                    <h2>Amrit foods</h2>
                    <div className='flex lg:flex-row flex-col gap-4  justify-between w-full'>

                        <div className="flex flex-col gap-2 items-start text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Address:{" "}</span>
                                <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Contact:{" "}</span>
                                <span>+ 653 436 7229</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Email id:{" "}</span>
                                <span>amritfoods@gmail.com</span>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 items-start lg:items-end text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Invoice ID:{" "}</span>
                                <span>#234516</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Date:{" "}</span>
                                <span>Mar 12, 2024</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Due Date:{" "}</span>
                                <span>Mar 27, 2024</span>
                            </div>

                        </div>
                    </div>
                </div>
            </RoundedCard>


            <RoundedCard className={"flex flex-col "}>
                <div className='flex lg:flex-row flex-col gap-4  justify-between w-full'>
                    <div className='flex flex-col gap-3.5'>
                        <h2>Bill to</h2>

                        <div className="flex flex-col gap-2 items-start text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Address:{" "}</span>
                                <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Contact:{" "}</span>
                                <span>+ 653 436 7229</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Email id:{" "}</span>
                                <span>amritfoods@gmail.com</span>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col gap-3.5 lg:justify-end lg: items-end'>
                        <h2>Ship to</h2>

                        <div className="flex flex-col gap-2 lg:items-end text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Address:{" "}</span>
                                <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Contact:{" "}</span>
                                <span>+ 653 436 7229</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Email id:{" "}</span>
                                <span>amritfoods@gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </RoundedCard>


            <RoundedCard className={"flex flex-col "}>
                <h2 className='pb-6'>Invoice details</h2>
                <div className='relative'>
                    <VendorInvoiceDetailsTable />
                </div>

                <div className='flex flex-row w-full pt-3.5 '>
                    <div className='flex items-end justify-center w-full '>
                        <Button onClick={() => { setShowSuccessPopup(true) }} title={"Accept Invoice"} />
                    </div>
                    <div className='min-w-max'>
                        <div className='flex flex-col gap-2 text-sm text-[#686868] min-w-max items-end '>
                            <div className="flex flex-row">
                                <span>Subtotal:{" "}</span>
                                <span className='text-black'>$360.00</span>
                            </div>
                            <div className="flex flex-row">
                                <span>GST @5%:{" "}</span>
                                <span className='text-black'>$18.00</span>
                            </div>
                            <div className="flex flex-row">
                                <span>Balance due:{" "}</span>
                                <span className='text-black'>$378.00</span>
                            </div>


                        </div>
                    </div>


                </div>

            </RoundedCard>

            <SuccessPopup
                open={showSuccessPopup}
                onClick={() => {
                    navigate(-1)
                    setShowSuccessPopup(false)
                }}
                title={"Invoice accepted successfully"} />


        </SellerLayout>
    )
}
