import React from 'react'
import DashboardCard from './Elements/DashboardCard'
import Svgs from 'Assets/svgs'
import DashboardOrdersTable from 'Utility/Tables/DashboardOrdersTable'
import Dropdown3 from 'Components/Elements/Dropdown3'
import RoundedCard from 'Components/Elements/RoundedCard'
import DashboardTopProductsTable from 'Utility/Tables/DashboardTopProductsTable'
import SellerLayout from 'Components/Layouts/SellerLayout'
import LineChatCurve from './Elements/LineChatCurve'
import Button from 'Components/Elements/Button'

const SellerDashboard = () => {
    return (
        <SellerLayout active={'Dashboard'} title={'Dashboard'} bodyClassName={'flex flex-col space-y-4'}>
            <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 lg:mt-0 mt-2'>
                <DashboardCard
                    title={'Total Revenue'}
                    value={'100k'}
                    percentage={`2.48%`}
                    description={'higher than usual'}
                    icon={<Svgs.TotalRevenueSvg />}
                />
                <DashboardCard
                    title={'Total Products'}
                    value={'83'}
                    percentage={`1.02%`}
                    description={'higher than usual'}
                    icon={<Svgs.TotalProductsSvg />}
                />
                <DashboardCard
                    title={'Total Orders'}
                    value={'72'}
                    percentage={`4.48%`}
                    description={'higher than usual'}
                    icon={<Svgs.TotalOrdersSvg />}
                />
                <DashboardCard
                    title={'Active Customers'}
                    value={'50'}
                    percentage={`6.72%`}
                    description={'higher than usual'}
                    icon={<Svgs.ActiveCustomersSvg />}
                />
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                <RoundedCard>
                    <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                        <h1 className='text-lg font-semibold'>Orders</h1>
                        <div className='flex gap-2 justify-between items-center'>
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'Last 1 Month'}
                            />
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'All Orders'}
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <DashboardOrdersTable />
                    </div>
                </RoundedCard>
                <RoundedCard>
                    <div className='flex gap-2 justify-between items-center'>
                        <h1 className='text-lg font-semibold'>Revenue</h1>
                        <div>
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'Last 1 Week'}
                            />
                        </div>
                    </div>
                    <div className="flex justify-between items-center my-8">
                        <h4 className="text-sm">Amount in Thousand Dollars</h4>
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                                <div className="h-4 w-4 rounded-sm bg-[#00B037]"></div>
                                <p className="text-sm">Organic</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="h-4 w-4 rounded-sm bg-[#2376F5]"></div>
                                <p className="text-sm">Advertisement</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-auto w-full overflow-hidden">
                        <LineChatCurve fill={true} chartData1={[1, 2, 1, 3, 2, 4, 2]} chartData2={[0, 1, 2, 4, 1, 3, 1]} chartData1Color={'#00B037'} chartData2Color={'#2376F5'} />
                    </div>
                </RoundedCard>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                <RoundedCard>
                    <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                        <h1 className='text-lg font-semibold'>Top Products</h1>
                        <div className='flex gap-2 justify-between items-center'>
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'Last 1 Month'}
                            />
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'All Categories'}
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <DashboardTopProductsTable />
                    </div>
                </RoundedCard>
                <RoundedCard>
                    <div className='flex gap-2 justify-between items-center mb-4'>
                        <h1 className='text-lg font-semibold'>Running Advertisements</h1>
                        <div>
                            <Dropdown3
                                title={''}
                                required={false}
                                placeholder={'Last 1 Week'}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center min-h-[200px]'>
                        <h1 className='text-[#686868] mb-4'>No Ad’s running at the moment.</h1>
                        <Button customPadding={'px-3 py-2'}>
                            <div className='flex gap-2 items-center justify-between'>
                                <Svgs.Plus />
                                Create New Ad
                            </div>
                        </Button>
                    </div>
                </RoundedCard>

            </div>
        </SellerLayout>
    )
}

export default SellerDashboard