import React from 'react'

function OutlineButton({ title, className, onClick, type, customPadding }) {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`${className ? className : ""} outline-none bg-btn-theme bg-clip-text text-transparent border-[#F33F41] border ${customPadding ? customPadding : "py-2 px-6"} rounded-lg font-bold text-base`}>
            {title}
        </button>)
}

export default OutlineButton