import Svgs from 'Assets/svgs'
import React from 'react'

export const VendorStatementTable = () => {

    const tableData = [
        {
            date: "12-07-2024",
            number: "#2345456",
            dueDate: "12-07-2024",
            amount: "$45.00",
            status: "Paid"
        },
        {
            date: "12-07-2024",
            number: "Fruits order",
            dueDate: "13-07-2024",
            amount: "$45.00",
            status: "Payment due"
        },
        {
            date: "12-07-2024",
            number: "Spices order",
            dueDate: "12-07-2024",
            amount: "$45.00",
            status: "Paid"
        },
        {
            date: "12-07-2024",
            number: "Dry fruits order",
            dueDate: "12-07-2024",
            amount: "$45.00",
            status: "Payment due"
        },
        {
            date: "12-07-2024",
            number: "Products order",
            dueDate: "12-07-2024",
            amount: "$45.00",
            status: "Payment due"
        },
    ]

    return (
        <>
            <div className="overflow-x-auto ">
                <div className="min-w-max">
                    <table className="w-full text-sm text-center rtl:text-right min-w-max">
                        <thead class="text-sm text-[#686868] capitalize">
                            <tr>
                                <th scope="col" class="pr-5 py-3 font-normal">
                                    Invoice date
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                    Invoice number
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                    Due date
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                    Amount
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                    Status
                                </th>
                                <th scope="col" class="pr-5 py-3 font-normal text-center">
                                    Invoice details
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((data, index) => (
                                <tr key={index} class="border-b border-[#EEEEEE]">
                                    <td class="pr-5 py-3 whitespace-nowrap">
                                        {data?.date}.
                                    </td>
                                    <td class="pr-5 py-3">
                                        {data?.number}
                                    </td>
                                    <td class="pr-5 py-3 whitespace-nowrap">
                                        {data?.dueDate}
                                    </td>
                                    <td class="pr-5 py-3 whitespace-nowrap">
                                        {data?.amount}
                                    </td>
                                    <td class={`pr-5 py-3 whitespace-nowrap text-center ${data?.status === "Paid" ? "settled-text" : "packed-text"}`}>
                                        <span className={` text-xs py-1 px-2.5 rounded  ${data?.status === "Paid" ? " settled" : "packed"}`}>
                                            {data?.status}
                                        </span>
                                    </td>
                                    <td class="pr-5 py-3 whitespace-nowrap">
                                        <div className='flex items-center justify-center'>
                                            <button className=''>
                                                <Svgs.EyeIcon />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                    <div className='flex items-center justify-center py-3'>
                        <div>
                            <span>Total due:{" "}</span>
                            <span>$135.00</span>
                        </div>

                    </div>
                </div>

            </div>
        </>

    )
}
