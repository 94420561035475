import React from 'react'
import useSellerManageStore from './helper'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import WeekDaysSelection from 'Components/Elements/WeekDaysSelection'

const SellerManageStore = () => {
    const { activeTab, setActiveTab } = useSellerManageStore()

    return (
        <SellerLayout active={'ManageStore'} title={'Manage Store'} bodyClassName={'flex flex-col space-y-4'}>
            <RoundedCard customPadding={'px-5 py-2'}>
                <div className="flex items-center gap-4">
                    <div className={`cursor-pointer xs:text-sm text-xs ${activeTab === "Store Timings" ? "bg-black font-semibold px-2 py-1 rounded-lg text-white" : ""}`}
                        onClick={() => {
                            setActiveTab("Store Timings");
                        }}
                    >
                        <h2>Store Timings</h2>
                    </div>
                    <div className={`cursor-pointer xs:text-sm text-xs ${activeTab === "Employees" ? "bg-black font-semibold px-2 py-1 rounded-lg text-white" : ""}`}
                        onClick={() => {
                            setActiveTab("Employees");
                        }}
                    >
                        <h2>
                            Employees
                        </h2>
                    </div>
                    <div className={`cursor-pointer xs:text-sm text-xs ${activeTab === "Employees Check In-Out" ? "bg-black font-semibold px-2 py-1 rounded-lg text-white" : ""}`}
                        onClick={() => {
                            setActiveTab("Employees Check In-Out");
                        }}
                    >
                        <h2>
                            Employees Check In-Out
                        </h2>
                    </div>
                </div>
            </RoundedCard>
            {activeTab === 'Store Timings' &&
                <RoundedCard className={'min-h-[70vh]'}>
                    <h1 className='mb-6 font-semibold'>Opening Hours</h1>
                    <WeekDaysSelection />
                </RoundedCard>
            }
        </SellerLayout>
    )
}

export default SellerManageStore