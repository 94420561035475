import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import React from 'react'

const StoreCard = ({ imageSrc, name, rating, type, distance, deliveryTime }) => {
    return (
        <div className='rounded-lg flex flex-col bg-white'>
            <Image className={'w-full h-full object-cover  max-h-[200px] rounded-t-lg'} customLoaderHeight={'h-[140px]'} src={imageSrc} />
            <div className='flex justify-between gap-4 p-3'>
                <div className='flex flex-col space-y-0.5'>
                    <h1 className='font-semibold text-lg'>{name}</h1>
                    <p className='text-sm text-[#1E1E1E]'>{type}</p>
                </div>
                <span className={`bg-[#00B037] text-white h-fit py-1 px-2 w-fit rounded-md text-xs font-normal flex justify-center gap-1 items-center`}>
                    {rating}
                    <Svgs.StoreRatingIcon />
                </span>
            </div>
            <hr className='border-dashed border-[#EEEEEE]' />
            <div className='flex gap-1 justify-between items-center text-[#949494] text-sm pl-2 pr-3 py-2'>
                <div className='flex gap-1 justify-between items-center'>
                    <Svgs.MapPinIcon />
                    {distance}
                </div>
                <div className='flex gap-1 justify-between items-center'>
                    <Svgs.DeliveryTime />
                    {deliveryTime}
                </div>
            </div>
        </div>
    )
}

export default StoreCard