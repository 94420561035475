import React, { useState } from 'react'
import SearchInput from 'Components/Elements/SearchInput'
import RoundedCard from 'Components/Elements/RoundedCard'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import DashboardCard from 'Pages/Seller/Dashboard/Elements/DashboardCard'
import PremiumExpenseTable from 'Utility/Tables/PremiumExpenseTable'
import Popup from 'Components/Elements/Popup'
import Dropdown from 'Components/Elements/Dropdown'
import Input from 'Components/Elements/Input'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'

function PremiumInventoryExpensesScreen() {
    const buttonData = [
        {
            title: "Office expenses",
            onclick: ""
        },
        {
            title: "Salary expenses",
            onclick: ""
        },
        {
            title: "Product bills",
            onclick: ""
        },
        {
            title: "Other expenses",
            onclick: ""
        },
    ]

    const [activeButton, setActiveButton] = useState("Office expenses")
    const [openAddExpensePopup, setOpenAddExpensePopup] = useState(false)
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)

    return (
        <>
            <SellerLayout active={'Expenses'}
                title={
                    <>
                        <div className='flex flex-row gap-2 items-center'>
                            Expenses
                            <Svgs.PremiumIcon />
                        </div>
                    </>
                }
                bodyClassName={'flex flex-col space-y-4'}>
                <RoundedCard>
                    <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                        <div className='xl:w-4/12 lg:w-6/12 w-full'>
                            <SearchInput
                                title={''}
                                required={false}
                                placeholder={'Search By Product Name'}
                            />
                        </div>
                        <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 md:items-center justify-between'>
                            {/* <div className='flex flex-col lg:flex-row md:flex-row  gap-2 md:items-center justify-between '> */}
                            <div className='flex flex-row items-center gap-x-2 w-full '>
                                <button>
                                    <Svgs.PdfIcon />
                                </button>
                                <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                                    <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                        <Svgs.DownloadBlack />
                                        Download Excel
                                    </div>
                                </Button>
                            </div>

                            {/* <Link to={"/inventory/addProduct"} className=' w-full'> */}
                            <Button
                                onClick={() => setOpenAddExpensePopup(true)}
                                customPadding={'px-3 py-2'} className={"!rounded-md w-full"}>
                                <div className='flex gap-2 items-center justify-between text-sm'>
                                    <Svgs.Plus />
                                    Add expenses
                                </div>
                            </Button>
                            {/* </Link> */}

                        </div>
                    </div>
                </RoundedCard>

                <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 lg:mt-0 mt-2'>
                    <DashboardCard
                        title={'Total Profit'}
                        value={'$53k'}
                        percentage={`4.02%`}
                        description={'higher than last month'}
                        icon={<Svgs.TotalRevenueSvg />}
                    />
                    <DashboardCard
                        title={'Salary expenses'}
                        value={'$23k'}
                        percentage={`0%`}
                        description={'higher than last month'}
                        icon={<Svgs.TotalProductsSvg />}
                    />
                    <DashboardCard
                        title={'Product bills'}
                        value={'$12k'}
                        percentage={`2.30%`}
                        description={'more than last month'}
                        icon={<Svgs.EmployeeIcon />}
                    />
                    <DashboardCard
                        title={'Other Expenses'}
                        value={'$10k'}
                        percentage={`6.72%`}
                        description={'higher than last month'}
                        icon={<Svgs.ActiveCustomersSvg />}
                    />
                </div>

                <div className='flex flex-row flex-wrap gap-3.5'>
                    {buttonData?.map((data, index) => (
                        <button
                            onClick={() => setActiveButton(data.title)}
                            // to={data.to}
                            key={index}
                            className={`min-w-max px-2 py-1 ${activeButton === data.title ? "bg-btn-theme text-white font-bold " : " border border-gray-1 text-gray-1 font-semibold "} text-sm rounded`}
                        >
                            {data.title}
                        </button>
                    ))}
                </div>


                <RoundedCard>
                    <div className='relative'>
                        <PremiumExpenseTable />

                    </div>
                </RoundedCard>
            </SellerLayout>

            <Popup
                open={openAddExpensePopup}
                removeClose={true}
                removeHeading={true}
                header={false}
                customSizeStyle={" lg:w-[30vw] "}
            >

                <div className='flex flex-col items-center justify-center font-roboto gap-6'>
                    <div className='text-gray-1 font-bold text-xl font-roboto   '>
                        Add expenses
                    </div>

                    <div className='flex flex-col items-center justify-center gap-3 w-full lg:px-12 px-4'>
                        <Dropdown
                            className={"w-full"}
                            title={"Expense type"}
                            placeholder={"Select"}
                            option={[
                                { value: "", label: "Office expense" },
                                { value: "", label: "Salary expense" },
                                { value: "", label: "Product expense" },
                                { value: "", label: "Other expense" },
                            ]}
                        />
                        <Input
                            parentClass={"w-full"}
                            type={"number"}
                            title={"Amount"}
                            required={false}
                            placeholder={"$00"}
                        />
                        <Input
                            parentClass={"w-full"}
                            title={"Notes"}
                            required={false}
                            placeholder={"Type here"}
                        />
                        <Input
                            parentClass={"w-full"}
                            type={"file"}
                            title={"Upload proof"}
                            required={false}

                            placeholder={"Type here"}
                        />
                        <Input
                            parentClass={"w-full"}
                            title={"Gst/Tax"}
                            required={false}
                            placeholder={"Type here"}
                        />

                    </div>

                    <Button
                        onClick={() => {
                            setOpenAddExpensePopup(false)
                            setOpenSuccessPopup(true)
                        }}
                        customPadding={" px-8 py-2"}
                        title={"Save"}
                    />
                </div>

            </Popup>

            <SuccessPopup
                open={openSuccessPopup}
                title={"Expense updated successfully"}
                onClick={() => {
                    setOpenSuccessPopup(false)
                }} />

        </>

    )
}

export default PremiumInventoryExpensesScreen