import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Input from 'Components/Elements/Input'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const CreateOfferPage = () => {
    const navigate = useNavigate()
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)

    return (
        <SellerLayout active={"Discounts"} title={"Discounts & Offers"}
            bodyClassName={'flex flex-col space-y-4'}>

            <BreadCrumb
                first={"Discount & Offers"}
                url={'/discount-offer'}
                second={"Create offer"}
            />

            <RoundedCard>
                <div className=' gap-6  flex flex-col'>
                    <div className='grid xl:grid-cols-3 gap-6 w-full'>
                        <div className='flex flex-col gap-6 '>
                            <Input
                                title={"Offer name"}
                                required={false}
                                placeholder={"Type here"}
                                parentClass={"w-full"}
                            />
                            <Input
                                required={false}
                                parentClass={"w-full"}
                                placeholder={"Type here"}
                                title={"Minimum purchase amount"}
                            />
                            <Dropdown
                                title={"Target audience"}
                                required={false}
                                placeholder={"Select"}
                                parentClass={"w-full"}
                            />

                        </div>
                        <div className='flex flex-col gap-6 '>
                            <Dropdown
                                title={"Offer type"}
                                placeholder={"Select"}
                                inputClass={" !placeholder:text-black "}
                                option={[
                                    { value: "India", label: "India" },
                                    { value: "Pakistan", label: "Pakistan" },
                                    { value: "Canada", label: "Canada" },
                                ]}
                                parentClass={"w-full"}
                            />
                            <Input
                                required={false}
                                type={"date"}
                                placeholder={"Select"}
                                title={"Start date"}
                            />
                            <Dropdown
                                title={"Select products to apply offer"}
                                placeholder={"Select"}
                                inputClass={" !placeholder:text-black "}
                                parentClass={"w-full"}
                            />

                        </div>
                        <div className='flex flex-col gap-6 '>
                            <Input
                                title={"Discount value"}
                                required={false}
                                placeholder={"Type here"}
                                parentClass={"w-full"}
                            />
                            <Input
                                type={"date"}
                                title={"End date"}
                                placeholder={"Type here"}
                                required={false}
                                parentClass={"w-full"}
                            />

                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center py-8'>
                    <Button
                        onClick={() => setOpenSuccessPopup(true)}
                        className={"px-9 !py-2"}
                        title={"Create offer"} />

                </div>

            </RoundedCard>

            <SuccessPopup
                open={openSuccessPopup}
                onClick={() => {
                    navigate("/discount-offer")
                    setOpenSuccessPopup(false)
                }}
                title={"Offer created successfully"}
            />


        </SellerLayout>

    )
}
