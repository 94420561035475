import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Dropdown2 from 'Components/Elements/Dropdown2'
import Input from 'Components/Elements/Input'
import Popup from 'Components/Elements/Popup'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import VendorInvoiceDetailsTable from 'Utility/Tables/VendorInvoiceDetailsTable'

export const VendorAllInvoiceDetails = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)
    const [uploadInvoicePopup, setUploadInvoicePopup] = useState(false)
    const [updatePaymentPopup, setUpdatePaymentPopup] = useState(false)

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    #234516
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'} >

            <div className='flex flex-col sm:flex-row gap-2 flex-wrap lg:items-center text-sm lg:text-base text-[#686868] justify-between'>
                <BreadCrumb
                    first={"Vendors"}
                    url={'/vendor'}
                    second={"All invoices"}
                    secondurl={"/vendor/all-invoices"}
                    third={"#234516"}
                />

                <div className='flex flex-row gap-3.5'>
                    <div className='flex flex-row items-center gap-x-2 w-full '>
                        <button>
                            <Svgs.PdfIcon />
                        </button>
                        <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                            <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                <Svgs.DownloadBlack />
                                Download Excel
                            </div>
                        </Button>
                    </div>
                </div>
            </div>

            <RoundedCard className={"flex flex-row w-full justify-between "}>
                {/* <div className='flex flex-col gap-3.5'> */}
                <div className='flex lg:flex-row flex-col gap-4  justify-between w-full'>
                    {/* <h2>Amrit foods</h2> */}

                    <div className="flex flex-col gap-2 items-start text-sm">
                        <div className='pb-3.5'>
                            <h2 className='text-black'>Amrit foods</h2>
                        </div>
                        <div className=''>
                            <span className='text-[#949494]'>Address:{" "}</span>
                            <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                        </div>
                        <div className=''>
                            <span className='text-[#949494]'>Contact:{" "}</span>
                            <span>+ 653 436 7229</span>
                        </div>
                        <div className=''>
                            <span className='text-[#949494]'>Email id:{" "}</span>
                            <span>amritfoods@gmail.com</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 items-start lg:items-end text-sm justify-end">
                        <div className=''>
                            <span className='text-[#949494]'>Invoice ID:{" "}</span>
                            <span>#234516</span>
                        </div>
                        <div className=''>
                            <span className='text-[#949494]'>Date:{" "}</span>
                            <span>Mar 12, 2024</span>
                        </div>
                        {state?.status === "paid" || true ?
                            <>
                                <div className='flex lg:flex-row gap-1.5 items-center flex-row-reverse'>
                                    <span className='settled px-2.5 py-1 rounded'>
                                        Paid
                                    </span>
                                    <div>
                                        <span className='text-[#949494]'>Pay Date:{" "}</span>
                                        <span>Mar 27, 2024</span>
                                    </div>
                                </div>
                                <div>
                                    <span className='text-[#949494]'>Payment Mode:{" "}</span>
                                    <span>Cheque</span>
                                </div>
                                <div>
                                    <span className='text-[#949494]'>Cheque no & date:{" "}</span>
                                    <span>#23450021(12-07-2024)</span>
                                </div>
                            </>
                            :
                            <div className=''>
                                <span className='text-[#949494]'>Due Date:{" "}</span>
                                <span>Mar 27, 2024</span>
                            </div>

                        }

                    </div>
                    {/* </div> */}
                </div>
            </RoundedCard>


            <RoundedCard className={"flex flex-col "}>
                <div className='flex lg:flex-row flex-col gap-4  justify-between w-full'>
                    <div className='flex flex-col gap-3.5'>
                        <h2>Bill to</h2>

                        <div className="flex flex-col gap-2 items-start text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Address:{" "}</span>
                                <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Contact:{" "}</span>
                                <span>+ 653 436 7229</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Email id:{" "}</span>
                                <span>amritfoods@gmail.com</span>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col gap-3.5 lg:justify-end lg:items-end'>
                        <h2>Ship to</h2>

                        <div className="flex flex-col gap-2 lg:items-end text-sm">
                            <div className=''>
                                <span className='text-[#949494]'>Address:{" "}</span>
                                <span>234, Humming Bird Street, Ottawa, Ontario, 12345</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Contact:{" "}</span>
                                <span>+ 653 436 7229</span>
                            </div>
                            <div className=''>
                                <span className='text-[#949494]'>Email id:{" "}</span>
                                <span>amritfoods@gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </RoundedCard>


            <RoundedCard className={"flex flex-col "}>
                <h2 className='pb-6'>Invoice details</h2>
                <div className='relative overflow-x-auto'>
                    <VendorInvoiceDetailsTable />
                </div>
                {/* <div className='flex flex-row w-full py-3.5 border-b justify-between overflow-x-auto'>
                    <div className='flex flex-row gap-4 '>
                        <p>1 -</p>
                        <button className='flex flex-row gap-4'>
                            <Svgs.PdfIcon />
                            <p className='text-[#F75659] underline'>Invoice #234516</p>
                        </button>
                    </div>
                    <div className='min-w-max'>
                        <div className='flex flex-col gap-2 text-sm text-[#686868] min-w-max items-end '>
                            <div className="flex flex-row">
                                <span>Subtotal:{" "}</span>
                                <span className='text-black'>$360.00</span>
                            </div>
                            <div className="flex flex-row">
                                <span>GST @5%:{" "}</span>
                                <span className='text-black'>$18.00</span>
                            </div>
                            <div className="flex flex-row">
                                <span>Balance due:{" "}</span>
                                <span className='text-black'>$378.00</span>
                            </div>


                        </div>
                    </div>


                </div> */}


                <div className='pt-9 flex items-center justify-center flex-col  lg:flex-row gap-3.5'>
                    <Button
                        onClick={() => { navigate("/vendor/request-credit-memo") }}
                        customPadding={"px-2.5 py-2"}
                        customThemeBtn={"theme-btn2"}
                        title={"Request credit memo"} />
                    <Button
                        onClick={() => setUploadInvoicePopup(true)}
                        customPadding={"px-2 py-2.5"}
                        className={"px-2.5 py-2"}
                        customThemeBtn={"theme-btn2"}
                        title={"Upload Invoice"}
                    />
                    <Button
                        onClick={() => setUpdatePaymentPopup(true)}
                        customPadding={"px-2.5 py-2"}
                        title={"Update payment"} />
                </div>

            </RoundedCard>

            {/* Upload Invoice Popup */}
            <Popup
                open={uploadInvoicePopup}
                removeClose={false}
                customSizeStyle={"lg:w-[25vw]"}
                removeHeading={false}
                header={false}
            >
                <div className='flex flex-col gap-6'>
                    <h3 className='text-center font-roboto font-bold text-gray-1 text-xl'>
                        Upload Invoice
                    </h3>
                    <form
                        className='flex flex-col gap-6'
                        onSubmit={(e) => { e.preventDefault() }}>
                        <Input
                            title={"Upload Invoice"}
                            placeholder={"Browse"}
                            type={"file"}
                        />
                        <div className='flex items-center justify-center'>
                            <Button
                                onClick={() => setUploadInvoicePopup(false)}
                                title={"Upload and save"}
                            />
                        </div>
                    </form>

                </div>


            </Popup>

            {/* Upload Payment Popup */}
            <Popup
                open={updatePaymentPopup}
                removeClose={false}
                customSizeStyle={"lg:w-[25vw]"}
                removeHeading={false}
                header={false}
            >
                <div className='flex flex-col gap-6'>
                    <h3 className='text-center font-roboto font-bold text-gray-1 text-xl'>
                        Upload payment
                    </h3>
                    <form
                        className='flex flex-col gap-6'
                        onSubmit={(e) => { e.preventDefault() }}>
                        <div className='flex flex-col gap-3'>
                            <Dropdown
                                placeholder={"Partial Payment"}
                                title={"Payment"}
                            />

                            <Input
                                required={false}
                                title={"Amount"}
                                placeholder={"$00"}
                            />


                            <Input
                                required={false}
                                title={"Upload Proof"}
                                placeholder={"Browse"}
                                type={"file"}
                            />
                        </div>

                        <div className='flex items-center justify-center'>
                            <Button
                                onClick={() => {
                                    setShowSuccessPopup(true)
                                    setUpdatePaymentPopup(false)
                                }}
                                title={"Save Payment"}
                            />
                        </div>
                    </form>

                </div>


            </Popup>

            <SuccessPopup
                open={showSuccessPopup}
                onClick={() => {
                    navigate(-1)
                    setShowSuccessPopup(false)
                }}
                title={"Payment updated successfully"} />


        </SellerLayout>)
}
