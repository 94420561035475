import React from "react";
import Image from "Components/Elements/Image";
import Button from "Components/Elements/Button";
import useOTPVerification from "./helper";
import MobileVerifyInput from "Components/Elements/MobileVerifyInput";

const OTPVerification = () => {
    const {
        count, isFinished, inp_1, inp_2, inp_3, inp_4, inp_5, inp_6, verificationCode,
        // handleSubmit, 
        onChangeHandler, navigate, loading,
        //  emailForVerification,
        handleResendCode
    } = useOTPVerification()
    const { num1, num2, num3, num4, num5, num6 } = verificationCode;

    return (
        <div className="min-h-[100vh] grid grid-cols-1 lg:grid-cols-10">
            <div className="mx-auto relative w-full flex flex-col col-span-1 lg:col-span-6">
                <div className="absolute top-6 sm:left-10 left-8">
                    <Image src={'/logo.png'} className={'h-full w-full object-cover'} />
                </div>
                <div className="sm:px-16 pt-4 absolute top-28 sm:left-20 px-8 sm:w-fit w-full">
                    <div className="flex flex-col space-y-2 text-primaryBlack">
                        <h4 className="font-bold text-3xl">Enter OTP</h4>
                        <h1 className="font-semibold leading-8">Please enter the one-time password (OTP) sent to your email jacobneruda@gmail.com to verify your account and proceed.</h1>
                    </div>
                    <div className="sm:pr-12 flex flex-col space-y-5">
                        <div className="flex items-center gap-1 mt-6 justify-center">
                            <div className="w-full">
                                <MobileVerifyInput
                                    name={"num1"}
                                    value={num1}
                                    inp_ref={inp_1}
                                    onChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <MobileVerifyInput
                                    name={"num2"}
                                    value={num2}
                                    inp_ref={inp_2}
                                    onBackSpace={() => {
                                        if (num2 === "" && inp_1 && inp_1.current) {
                                            inp_1.current.focus();
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            if (inp_1 && inp_1.current) {
                                                inp_1.current.focus();
                                            }
                                        }
                                        onChangeHandler(e);
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <MobileVerifyInput
                                    name={"num3"}
                                    value={num3}
                                    inp_ref={inp_3}
                                    onBackSpace={() => {
                                        if (num3 === "" && inp_2 && inp_2.current) {
                                            inp_2.current.focus();
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            if (inp_2 && inp_2.current) {
                                                inp_2.current.focus();
                                            }
                                        }
                                        onChangeHandler(e);
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <MobileVerifyInput
                                    name={"num4"}
                                    value={num4}
                                    inp_ref={inp_4}
                                    onBackSpace={() => {
                                        if (num4 === "" && inp_3 && inp_3.current) {
                                            inp_3.current.focus();
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            if (inp_3 && inp_3.current) {
                                                inp_3.current.focus();
                                            }
                                        }
                                        onChangeHandler(e);
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <MobileVerifyInput
                                    name={"num5"}
                                    value={num5}
                                    inp_ref={inp_5}
                                    onBackSpace={() => {
                                        if (num5 === "" && inp_4 && inp_4.current) {
                                            inp_3.current.focus();
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            if (inp_4 && inp_4.current) {
                                                inp_4.current.focus();
                                            }
                                        }
                                        onChangeHandler(e);
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <MobileVerifyInput
                                    name={"num6"}
                                    value={num6}
                                    inp_ref={inp_6}
                                    onBackSpace={() => {
                                        if (num6 === "" && inp_5 && inp_5.current) {
                                            inp_5.current.focus();
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            if (inp_5 && inp_5.current) {
                                                inp_5.current.focus();
                                            }
                                        }
                                        onChangeHandler(e);
                                    }}
                                />
                            </div>
                        </div>
                        <h1 className="text-primaryBlack text-sm pb-4">Didn’t receive the code? <span className={`cursor-pointer text-customBlue`} onClick={() => {
                            if (count === 0) {
                                handleResendCode()
                            }
                        }}>{count > 0 && !isFinished ? `Resend in ${count} sec` : 'Resend'}</span></h1>
                        <Button
                            text={'Continue'}
                            className={'w-full'}
                            loading={loading}
                            disabled={loading || !num1 || !num2 || !num3 || !num4 || !num5 || !num6}
                            // onClick={handleSubmit}
                            onClick={() => { navigate("/setup-account") }}
                        />
                    </div>
                </div>
            </div>
            <div
                className="hidden lg:block lg:col-span-4 h-screen bg-cover bg-center"
                style={{
                    backgroundImage: `url('/images/LoginImage.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            />
        </div>
    );
};

export default OTPVerification;
