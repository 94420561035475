import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'Components/Elements/Button'
import Svgs from 'Assets/svgs'
import RoundedCard from 'Components/Elements/RoundedCard'
import Dropdown from 'Components/Elements/Dropdown'
import Dropdown3 from 'Components/Elements/Dropdown3'
import Pagination from 'Components/Pagination'
import Popup from 'Components/Elements/Popup'
import Input from 'Components/Elements/Input'
import SellerLayout from 'Components/Layouts/SellerLayout'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import SearchInput from 'Components/Elements/SearchInput'
import Image from 'Components/Elements/Image'

function ProductTags() {
    const navigate = useNavigate()
    const [openAddTagPopup, setOpenAddTagPopup] = useState(false)
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
    const [openDownloadTagPopup, setOpenDownloadTagPopup] = useState(false)
    const [activeButton, setActiveButton] = useState("Tags")
    const [selectedTag, setSelectedTag] = useState("")
    const [promotionalSelectedTag, setPromotionalSelectedTag] = useState("")
    const [promotionalTagSelectedSize, setPromotionalTagSelectedSize] = useState("size1")

    return (
        <SellerLayout active={"All products"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    All tags
                    {/* <Svgs.PremiumIcon /> */}
                </div>
            </>
        } bodyClassName={'flex flex-col space-y-4'}>

            {/* <div className='flex lg:flex-row flex-col gap-3 lg:items-center justify-between w-full'>
                <BreadCrumb
                    first={"All products"}
                    url={'/all-products'}
                    second={"Product tags"}
                />

                <div className='flex flex-row gap-3.5'>
                    <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                        <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                            <Svgs.DownloadBlack />
                            Download Excel
                        </div>
                    </Button>
                    <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                        <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                            Print all tags
                        </div>
                    </Button>
                    <Button
                        onClick={() => setOpenAddTagPopup(true)}
                        customPadding={'px-3 py-2'} className={"!rounded-md w-full"} >
                        <div className='flex gap-2 items-center justify-between text- text-sm'>
                            <Svgs.DownloadIcon />
                            Create product tag
                        </div>
                    </Button>
                </div>



            </div> */}

            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search By Tag Name'}
                        />
                    </div>

                    {/* <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2 md:items-center justify-between'> */}
                    <div className='flex flex-col lg:flex-row md:flex-row  gap-2 md:items-center justify-between '>
                        <div className='flex flex-row items-center gap-x-2 w-full '>
                            <Svgs.PdfIcon />
                            <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                                <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                    <Svgs.DownloadBlack />
                                    Download Excel
                                </div>
                            </Button>
                        </div>

                        <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                            <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                Print all tags
                            </div>
                        </Button>
                        <Button
                            onClick={() => setOpenAddTagPopup(true)}
                            customPadding={'px-3 py-2'} className={"!rounded-md w-full"} >
                            <div className='flex gap-2 items-center justify-between text- text-sm'>
                                <Svgs.DownloadIcon />
                                Create product tag
                            </div>
                        </Button>

                    </div>
                </div>
            </RoundedCard>

            <div className='flex flex-row gap-3.5 text-sm'>
                <Button
                    customPadding={"py-0.5 px-1"}
                    onClick={() => setActiveButton("Tags")}
                    title={"Tags"}
                    className={"text-sm"}
                    customThemeBtn={activeButton !== "Tags" && "theme-btn2"}
                />
                <Button
                    customPadding={"py-0.5 px-1"}
                    onClick={() => setActiveButton("Promotional")}
                    title={"Promotional Tags"}
                    className={"text-sm"}
                    customThemeBtn={activeButton !== "Promotional" && "theme-btn2"}
                />

            </div>

            <RoundedCard className={"flex flex-col gap-4"}>
                <div className='flex flex-row justify-between items-center'>
                    <h4 className='text-base'>{activeButton === "Tags" ? "My Tags" : "Promotional tags"}</h4>
                    <div>
                        {activeButton === "Tags" ?
                            <Dropdown3
                                title={""}
                                placeholder={"All products"}
                                option={[
                                    { value: "", label: "In stock" },
                                    { value: "", label: "Out of stock" },
                                ]}
                            />
                            :
                            <Dropdown3
                                value={promotionalTagSelectedSize}
                                onChange={(e) => {
                                    setPromotionalTagSelectedSize(e.target.value)
                                }}
                                title={""}
                                placeholder={"Size"}
                                option={[
                                    { value: "size1", label: "Size 1" },
                                    { value: "size2", label: "Size 2" },
                                ]}
                            />
                        }
                    </div>

                </div>


                <div className="flex flex-row flex-wrap gap-5 items-center lg:justify-start justify-center">
                    {activeButton === "Tags" ?
                        Array(8).fill().map((_, index) => (
                            <div
                                onClick={() => setSelectedTag(index)}
                                key={index} className='min-w-[272px] w-auto  border border-[#EEEEEE] rounded-lg p-2.5 gap-2.5 flex flex-col cursor-pointer'>


                                <div className={`bg-[#F9F9F9] w-full  border border-[#AEAEAE] rounded-lg p-1.5`}>
                                    <div className={`${selectedTag === index ? "bg-black bg-opacity-40 relative " : ""}w-full h-full border border-black  rounded flex flex-col items-center justify-center py-2 gap-1 `}>
                                        <h6 className='font-bold text-base'>Pomegranate</h6>
                                        <div className='flex flex-col gap-0.5'>

                                        </div>
                                        <div className="relative  px-4 inline-block">
                                            <p className="font-semibold text-xl relative text-[#949494]">$5.50</p>
                                            <div className="absolute inset-0">
                                                <div className=" w-full transform rotate-12 origin-left border-t-2 border-[#949494]"></div>
                                                <div className="absolute w-full h transform -rotate-12 origin-top-right border-t-2 border-[#949494]"></div>
                                            </div>
                                        </div>
                                        <div className='text-[#F33F41] font-bold text-4xl'>
                                            $3.50
                                        </div>
                                        <div>
                                            1 Kilogram
                                        </div>



                                        {selectedTag === index &&
                                            <div className='flex items-center justify-center absolute inset-0'>
                                                <div className='text-white  p-2 bg-[#45A843] rounded font-semibold text-sm '>
                                                    Tag selected
                                                </div>

                                            </div>
                                        }

                                    </div>




                                </div>

                                <div className='flex flex-col gap-2'>

                                    <div className='flex flex-row items-center justify-between font-semibold text-sm'>
                                        <p>Pomegranate</p>
                                        <p>$10</p>
                                    </div>

                                    <div className='flex items-center justify-center flex-row '>
                                        <div className='flex flex-row justify-between items-center gap-4'>

                                            <button
                                                // onClick={() => setOpenDownloadTagPopup(true)}
                                                className='text-[#FA676C] text-xs min-w-max underline'>
                                                Edit tag
                                            </button>
                                            <button
                                                onClick={() => setOpenDownloadTagPopup(true)}
                                                className='text-[#FA676C] text-xs min-w-max underline'>
                                                Print Tag
                                            </button>
                                        </div>

                                    </div>
                                </div>


                            </div>

                        ))
                        :
                        Array(8).fill().map((_, index) => (
                            <div
                                onClick={() => setPromotionalSelectedTag(index)}
                                key={index} className='min-w-[272px] w-auto  border border-[#EEEEEE] rounded-lg p-2.5 gap-2.5 flex flex-col cursor-pointer'>


                                <div className={`relative`}
                                //  className={`bg-[#F9F9F9] w-full  border border-[#AEAEAE] rounded-lg p-1.5`}
                                >
                                    {/* <div className={`${selectedTag === index ? "bg-black bg-opacity-40 relative " : ""} w-full h-full   rounded flex flex-col items-center justify-center  gap-1 `}> */}
                                    <Image
                                        customLoaderHeight={`${promotionalTagSelectedSize === "size1" ? "h-[286px]" : "h-[142px]"}`}
                                        customLoaderRounded={"rounded"}
                                        customLoaderWidth={"w-[252px]"}
                                        src={promotionalSelectedTag === "size1" ? "/images/promotional-tag.png" : "/images/promotional-tag-size2.png"}
                                        className={`object-contain w-[252px] ${promotionalTagSelectedSize === "size1" ? "h-[286px]" : "h-[142px]"} rounded`}
                                    />


                                    {promotionalSelectedTag === index &&
                                        <div className='flex items-center justify-center absolute inset-0 bg-black w-full h-full bg-opacity-30 rounded'>
                                            <div className='text-white  p-2 bg-[#45A843] rounded font-semibold text-sm '>
                                                Tag selected
                                            </div>

                                        </div>
                                    }





                                </div>

                                <div className='flex flex-col gap-2'>

                                    <div className='flex flex-row items-center justify-between font-semibold text-sm'>
                                        <p>Promotional tags</p>
                                    </div>

                                    <div className='flex items-center justify-center flex-row '>
                                        <div className='flex flex-row justify-between items-center gap-4'>
                                            <button
                                                // onClick={() => setOpenDownloadTagPopup(true)}
                                                className='text-[#FA676C] text-xs min-w-max underline'>
                                                Edit tag
                                            </button>
                                            <button
                                                onClick={() => setOpenDownloadTagPopup(true)}
                                                className='text-[#FA676C] text-xs min-w-max underline'>
                                                Print Tag
                                            </button>
                                        </div>

                                    </div>
                                </div>


                            </div>

                        ))


                    }
                </div>

                {selectedTag !== "" &&
                    <div className='flex items-center justify-center'>
                        <Button
                            customThemeBtn={"theme-btn2"}
                            className={"px-9 py-2 border-[#2F80ED] text-[#2F80ED]"}
                            title={"Print tag"}
                        />
                    </div>
                }

            </RoundedCard>


            {selectedTag === "" &&
                <Pagination
                    currentPage={1}
                    pageSize={2}
                    totalCount={3}
                // onPageChange={(page) => setCurrentPage(page)}
                />
            }


            {/* Tag creation */}
            <Popup
                open={openAddTagPopup}
                removeClose={true}
                removeHeading={true}
                header={false}
                customSizeStyle={" lg:w-[25vw]"}
            >

                <div className='flex flex-col gap-6'>
                    <h4 className='font-roboto font-bold text-gray-1 text-xl text-center'>Create product tag</h4>

                    <div className="flex flex-col gap-3 w-full">
                        <Dropdown
                            placeholder={"Select"}
                            title={"Select product"} />
                        <Dropdown
                            placeholder={"Select"}
                            title={"Select quantity"} />
                        <Input
                            required={false}
                            type={"number"}
                            title={"Price"}
                            placeholder={"Type here"}
                        />
                        <Input
                            required={false}
                            type={"number"}
                            title={"Offer price"}
                            placeholder={"Type here"}
                        />

                    </div>

                    <div className="flex items-center justify-center">
                        <Button
                            onClick={() => {
                                setOpenAddTagPopup(false)
                                setOpenSuccessPopup(true)
                            }}
                            customPadding={"px-9 py-2"}
                            title={"Save"}
                        />
                    </div>

                </div>

            </Popup>


            {/* Promotional tags created */}
            <Popup
                open={false}
                removeClose={true}
                removeHeading={true}
                header={false}
                customSizeStyle={" lg:w-[25vw]"}
            >

                <div className='flex flex-col gap-6'>
                    <h4 className='font-roboto font-bold text-gray-1 text-xl text-center'>
                        Create promotional tag</h4>

                    <div className="flex flex-col gap-3 w-full ">
                        <Dropdown
                            placeholder={"Select"}
                            title={"Select size"} />

                        <Input
                            required={false}
                            title={"Title"}
                            placeholder={"Type here"}
                        />
                        <Input
                            required={false}
                            title={"Description"}
                            placeholder={"Type here"}
                        />
                        <Input
                            required={false}
                            type={"input"}
                            title={"Offer price"}
                            placeholder={"Type here"}
                        />
                        <Input
                            required={false}
                            type={"file"}
                            title={"Upload logo"}
                            placeholder={"Type here"}
                        />
                        <Input
                            required={false}
                            type={"file"}
                            title={"Upload Image"}
                            placeholder={"Type here"}
                        />

                    </div>

                    <div className="flex items-center justify-center">
                        <Button

                            onClick={() => {
                                setOpenAddTagPopup(false)
                                setOpenSuccessPopup(true)
                            }}
                            customPadding={"px-9 py-2"}
                            title={"Save"}
                        />
                    </div>

                </div>

            </Popup>


            {/* Success Popuo */}
            <Popup
                open={openSuccessPopup}
                removeClose={true}
                removeHeading={true}
                header={false}
                customSizeStyle={" lg:w-[25vw]"}
            >

                <div className='flex flex-col gap-5 items-center'>
                    <h4 className='font-roboto font-medium text-gray-1 text-base'>Tag created successfully!</h4>

                    <div className='w-4/5 bg-[#F9F9F9] border border-[#AEAEAE] rounded-lg p-1.5 '>
                        <div className='w-full h-full border border-black  rounded flex flex-col items-center justify-center py-2 gap-1'>

                            <h6 className='font-bold text-base'>Pomegranate</h6>

                            <div className='flex flex-col gap-0.5'>

                            </div>
                            <div className="relative  px-4 inline-block">
                                <p className="font-semibold text-xl relative text-[#949494]">$5.50</p>
                                <div className="absolute inset-0">
                                    <div className=" w-full transform rotate-12 origin-left border-t-2 border-[#949494]"></div>
                                    <div className="absolute w-full h transform -rotate-12 origin-top-right border-t-2 border-[#949494]"></div>
                                </div>
                            </div>

                            <div className='text-[#F33F41] font-bold text-4xl'>
                                $3.50
                            </div>

                            <div>
                                1 Kilogram
                            </div>





                        </div>


                    </div>

                    <div className="flex flex-row gap-6 items-center justify-center">
                        <Button
                            onClick={() => setOpenSuccessPopup(false)}
                            className={"px-9 py-2"}
                            title={"Close"}
                        />
                        <Button
                            onClick={() => {
                                setOpenSuccessPopup(false)
                                setOpenDownloadTagPopup(true)
                            }}
                            customThemeBtn={"theme-btn2"}
                            className={"px-9 py-2 border-[#2F80ED] text-[#2F80ED]"}
                            title={"Print tag"}
                        />
                    </div>

                </div>


            </Popup>



            <Popup
                open={openDownloadTagPopup}
                removeClose={true}
                removeHeading={true}
                header={false}
                customSizeStyle={" lg:w-[25vw]"}
            >

                <div className='flex flex-col gap-6 items-center'>
                    <h4 className='font-roboto font-bold text-gray-1 text-xl'>Download tag</h4>

                    <div className="flex flex-col gap-3 w-full">
                        <Input
                            required={false}
                            placeholder={"Pomegranate"}
                            title={"Product name"} />
                        <Input
                            required={false}
                            type={"number"}
                            title={"Price"}
                            placeholder={"Type here"}
                        />
                        <Input
                            required={false}
                            title={"Offer name"}
                            placeholder={"Type here"}
                        />

                    </div>

                    <div className="flex items-center justify-center">
                        <Button
                            onClick={() => {
                                setOpenDownloadTagPopup(false)
                            }}
                            className={"px-9 py-2"}
                            title={"Print"}
                        />
                    </div>

                </div>

            </Popup>




            {/* Tags download Preview */}
            <Popup
                open={false}
                removeClose={true}
                removeHeading={true}
                header={false}
                customSizeStyle={" lg:w-[1000px]"}
            >

                <div className='flex flex-col lg:gap-16 gap-8 items-center lg:py-12'>
                    <h4 className='font-roboto font-bold text-gray-1 text-4xl'>Preview</h4>
                    <div className='flex flex-row flex-wrap items-center  justify-center gap-x-6  gap-y-6 lg:gap-y-16'>
                        {Array(18).fill().map((_, index) => (
                            <Image
                                key={index}
                                className={"object-contain  w-[940px] lg:h-[450px] lg:w-[420px]"}
                                src={"/images/promotional-tag-download.png"}
                            />
                        ))}

                    </div>

                    <div className='flex items-center justify-center'>
                        <Button
                            customPadding={"py-2 px-6"}
                            className={"text-[#2F80ED] border-[#2F80ED]"}
                            customThemeBtn={"theme-btn2"}
                            title={"Print tags"}
                        />

                    </div>

                </div>


            </Popup>

            {/* Promotional tags Download preview */}
            <Popup
                open={false}
                removeClose={true}
                removeHeading={true}
                header={false}
                customSizeStyle={"  lg:w-[1000px]"}
            >

                <div className='flex flex-col lg:gap-16 gap-8 items-center lg:py-12'>
                    <h4 className='font-roboto font-bold text-gray-1 text-4xl'>Preview</h4>
                    <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 items-center  justify-center gap-9'>
                        {Array(18).fill().map((_, index) => (
                            <div
                                key={index} className=' min-w-[260px] lg:min-w-[272px] w-auto  border border-[#EEEEEE] rounded-lg p-2.5 gap-2.5 flex flex-col cursor-pointer'>
                                <Image
                                    customLoaderHeight={"h-[286px]"}
                                    customLoaderRounded={"rounded"}
                                    customLoaderWidth={"w-[252px]"}
                                    src={"/images/promotional-tag.png"}
                                    className={"object-contain w-[252px] h-[286px] rounded"}
                                />
                            </div>
                        ))}

                    </div>

                    <div className='flex items-center justify-center'>
                        <Button
                            customPadding={"py-2 px-6"}
                            className={"text-[#2F80ED] border-[#2F80ED]"}
                            customThemeBtn={"theme-btn2"}
                            title={"Print tags"}
                        />

                    </div>

                </div>


            </Popup>



        </SellerLayout >
    )
}

export default ProductTags