import React from 'react'
import { useNavigate } from 'react-router-dom'

const BreadCrumb = ({ url, first, second, secondurl, third }) => {
    const navigate = useNavigate();
    return (
        <>
            <p className="text-[#686868] text-xs">
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        navigate(`${url}`);
                    }}
                >
                    {first}
                </span>{" "}
                &gt;{" "}
                <span onClick={() => {
                    if (secondurl) {
                        navigate(`${secondurl}`)
                    }
                }} className={`${!secondurl && 'text-black font-semibold'} cursor-pointer`}>
                    {second}
                </span>
                {
                    third && <>
                        {" "}
                        &gt;{" "}
                        <span className="text-black font-semibold cursor-pointer">
                            {third}
                        </span>
                    </>
                }
            </p>
        </>
    )
}

export default BreadCrumb