export default function useSetDataInLocalStorage() {
    const storeDataInLocalStorage = (key, data) => {
        localStorage.setItem(key, JSON.stringify(data));
    }

    const storeDataInSessionStorage = (key, data) => {
        if (data) {
            sessionStorage.setItem(key, JSON.stringify(data));
        }
    }

    const removeDataFromSessionStorage = (key) => {
        sessionStorage.removeItem(key);
    };

    const getDataFromSessionStorage = (key) => {
        const sessionStorageData = sessionStorage.getItem(key);
        if (sessionStorageData) {
            const result = JSON.parse(sessionStorageData);
            return result;
        }
        return null
    }


    const getDataFromLocalStorage = (key) => {
        const localStorageData = localStorage.getItem(key);
        if (localStorageData && localStorageData != undefined && localStorageData != 'undefined') {
            const result = JSON.parse(localStorageData);
            return result;
        }
        return null
    }

    return {
        storeDataInLocalStorage,
        getDataFromLocalStorage,
        storeDataInSessionStorage,
        getDataFromSessionStorage,
        removeDataFromSessionStorage
    }
}
