// Authentication
import { usePremium } from "Context/PremiumContext";
import Login from "Pages/Auth/Login";
import OTPVerification from "Pages/Auth/OTPVerification";
import SellerAccountSetup from "Pages/Auth/SellerAccountSetup";
import Signup from "Pages/Auth/Signup";
import Careers from "Pages/Careers";
import LandingPage from "Pages/LandingPage";
import PremiumAllProductsScreen from "Pages/Seller/All Products";
import SellerCoupons from "Pages/Seller/Coupons";

// Seller Routes
import SellerDashboard from "Pages/Seller/Dashboard";
import SellerDeals from "Pages/Seller/Deals";
import PremiumEmployeeScreen from "Pages/Seller/Employee";
import PremiumInventoryExpensesScreen from "Pages/Seller/Expenses";
import SellerHelpSupport from "Pages/Seller/Help&Support";
import SellerInventory from "Pages/Seller/Inventory";
import { AddProduct } from "Pages/Seller/Inventory/AddProduct";
import PremiumScreen from "Pages/Seller/Inventory/Premium";
import ProductDetails from "Pages/Seller/All Products/ProductDetails";
import ProductTags from "Pages/Seller/All Products/ProductTags";
import InventoryPremiumScreen from "Pages/Seller/Inventory/Premium/Inventory";
import SellerLandingPage from "Pages/Seller/LandingPage";
import SellerManageStore from "Pages/Seller/ManageStore";
import SellerOrders from "Pages/Seller/Orders";
import SellerOrderDetail from "Pages/Seller/Orders/OrderDetail";
import SellerPayments from "Pages/Seller/Payments";
import SellerPaymentDetail from "Pages/Seller/Payments/PaymentDetail";
import SellerProfile from "Pages/Seller/Profile";
import SellerEditProfile from "Pages/Seller/Profile/EditProfile";
import SellerPromote from "Pages/Seller/Promote";
import SellerReferEarn from "Pages/Seller/Refer&Earn";
import PremiumInventoryVendorScreen from "Pages/Seller/Vendor";
import RecentVendorOrder from "Pages/Seller/Vendor/RecentVendorOrder";
import VendorAllProductsScreen from "Pages/Seller/Vendor/VendorAllProductsScreen";
import VendorDetails from "Pages/Seller/Vendor/VendorDetails";
import VendorProductDetails from "Pages/Seller/Vendor/VendorProductDetails";
import SellerWallet from "Pages/Seller/Wallet";
import { VendorCartScreen } from "Pages/Seller/Vendor/VendorCartScreen";
import { RecentOrderDetail } from "Pages/Seller/Vendor/RecentOrderDetail";
import { VendorInvoiceDetail } from "Pages/Seller/Vendor/VendorInvoiceDetail";
import { VendorAllInvoices } from "Pages/Seller/Vendor/VendorAllInvoices";
import { VendorAllInvoiceDetails } from "Pages/Seller/Vendor/VendorAllInvoiceDetails";
import { VendorStatementPage } from "Pages/Seller/Vendor/VendorStatementPage";
import { VendorAddInvoice } from "Pages/Seller/Vendor/VendorAddInvoice";
import { VendorCreditMemo } from "Pages/Seller/Vendor/VendorCreditMemo";
import { VendorRequestCreditMemo } from "Pages/Seller/Vendor/VendorRequestCreditMemo";
import { SellerDiscountPage } from "Pages/Seller/Discount";
import { CreateOfferPage } from "Pages/Seller/Discount/CreateOfferPage";
import { DiscountOfferDetail } from "Pages/Seller/Discount/DiscountOfferDetail";
import { SendRequestCouponPage } from "Pages/Seller/Coupons/SendRequestCoupon";
import { CouponDetails } from "Pages/Seller/Coupons/CouponDetails";
import { SellerOtherServicesPage } from "Pages/Seller/Other Services";
import { OtherSevicesDashboard } from "Pages/Seller/Other Services/Tiffin Services/Dashboard";
import { CreatePlanPage } from "Pages/Seller/Other Services/Tiffin Services/CreatePlanPage";
import { PlanDetailsPage } from "Pages/Seller/Other Services/Tiffin Services/PlanDetailsPage";
import { PaymentDetails } from "Pages/Seller/Other Services/Tiffin Services/PaymentDetails";
import { CateringServicesDashboard } from "Pages/Seller/Other Services/Catering Services";
import { CreateCateringPlan } from "Pages/Seller/Other Services/Catering Services/CreateCateringPlan";
import { CateringOrderRequest } from "Pages/Seller/Other Services/Catering Services/CateringOrderRequest";
import { CateringChatPage } from "Pages/Seller/Other Services/Catering Services/CateringChatPage";

const useRouteHelper = () => {
    const { isPremium } = usePremium()

    const publicRoutes = [
        { path: "/home", element: <LandingPage /> },
        { path: "/", element: <SellerLandingPage /> },
        { path: "/careers", element: <Careers /> },
        { path: "/login", element: <Login /> },
        { path: "/signup", element: <Signup /> },
        { path: "/otp-verification", element: <OTPVerification /> },
        { path: "/setup-account", element: <SellerAccountSetup /> },
        { path: "/dashboard", element: <SellerDashboard /> },
        { path: "/orders", element: <SellerOrders /> },
        { path: "/order-detail", element: <SellerOrderDetail /> },


        { path: "/inventory", element: (isPremium ? <InventoryPremiumScreen /> : <SellerInventory />) },
        { path: "/inventory/addProduct", element: <AddProduct /> },
        { path: "/inventory/premium", element: <PremiumScreen /> },
        { path: "/inventory", element: <InventoryPremiumScreen /> },
        { path: "/all-products", element: <PremiumAllProductsScreen /> },
        { path: "/all-products/:name", element: <ProductDetails /> },
        { path: "/all-products/:name/tags", element: <ProductTags /> },


        { path: "/vendor", element: <PremiumInventoryVendorScreen /> },
        { path: "/vendor/:name", element: <VendorDetails /> },
        { path: "/vendor/:name/product", element: <VendorProductDetails /> },
        { path: "/vendor/:name/all-products", element: <VendorAllProductsScreen /> },
        { path: "/vendor/recent-orders", element: <RecentVendorOrder /> },
        { path: "/vendor/cart", element: <VendorCartScreen /> },
        { path: "/vendor/recent-order-details", element: <RecentOrderDetail /> },
        { path: "/vendor/invoice-details", element: <VendorInvoiceDetail /> },
        { path: "/vendor/all-invoices", element: <VendorAllInvoices /> },
        { path: "/vendor/all-invoices/invoice-details", element: <VendorAllInvoiceDetails /> },
        { path: "/vendor/statement", element: <VendorStatementPage /> },
        { path: "/vendor/add-invoice", element: <VendorAddInvoice /> },
        { path: "/vendor/credit-memo", element: <VendorCreditMemo /> },
        { path: "/vendor/request-credit-memo", element: <VendorRequestCreditMemo /> },

        { path: "/expenses", element: <PremiumInventoryExpensesScreen /> },
        { path: "/employees", element: <PremiumEmployeeScreen /> },
        { path: "/payments", element: <SellerPayments /> },
        { path: "/payment-detail", element: <SellerPaymentDetail /> },

        { path: "/discounts", element: <SellerDiscountPage /> },
        { path: "/create-discount-offer", element: <CreateOfferPage /> },
        { path: "/discount-offer-detail", element: <DiscountOfferDetail /> },


        { path: "/other-services", element: <SellerOtherServicesPage /> },
        { path: "/other-services/create-plan", element: <CreatePlanPage /> },
        { path: "/other-services/:name", element: <OtherSevicesDashboard /> },
        { path: "/other-services/:name/payment", element: <PaymentDetails /> },
        { path: "/other-services/:name/:plan", element: <PlanDetailsPage /> },


        { path: "/other-services/catering-services", element: <CateringServicesDashboard /> },
        { path: "/other-services/catering-services/create-plan", element: <CreateCateringPlan /> },
        { path: "/other-services/catering-services/order-request", element: <CateringOrderRequest /> },
        { path: "/other-services/catering-services/chats", element: <CateringChatPage /> },



        { path: "/deals", element: <SellerDeals /> },
        { path: "/promotions", element: <SellerPromote /> },
        { path: "/manage-store", element: <SellerManageStore /> },
        { path: "/refer-earn", element: <SellerReferEarn /> },
        { path: "/help-support", element: <SellerHelpSupport /> },
        { path: "/profile", element: <SellerProfile /> },
        { path: "/edit-profile", element: <SellerEditProfile /> },
        { path: "/wallet", element: <SellerWallet /> },

        { path: "/coupons", element: <SellerCoupons /> },
        { path: "/coupon-details", element: <CouponDetails /> },
        { path: "/request-coupon", element: <SendRequestCouponPage /> }
    ];
    return {
        publicRoutes
    }
}

export default useRouteHelper