import React from 'react'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput';
import Dropdown3 from 'Components/Elements/Dropdown3';
import Svgs from 'Assets/svgs';
import Button from 'Components/Elements/Button';
import { Link, useNavigate } from 'react-router-dom';
import CheckBox from 'Components/Elements/Checkbox';
import SellerLayout from 'Components/Layouts/SellerLayout';
import Pagination from 'Components/Pagination';
import BreadCrumb from 'Components/Elements/BreadCrumb';

function RecentVendorOrder() {
    const navigate = useNavigate()

    const tableData = [
        {
            id: "#2345",
            vendor: "Amrit Foods",
            date: "Mar 14, 2024, 09:12 AM ",
            items: "7",
            amount: "$110",
            payment: "Paid",
            status: "Paid",
        },
        {
            id: "#2346",
            vendor: "Amrit Foods",
            date: "Mar 13, 2024, 02:02 PM ",
            items: "3",
            amount: "$70",
            payment: "Due",
            status: "Payment due",
        },
        {
            id: "#2347",
            vendor: "Amrit Foods",
            date: "Mar 12, 2024, 12:01 PM ",
            items: "4",
            amount: "$90",
            payment: "Paid",
            status: "Paid",
        },
        {
            id: "#2348",
            vendor: "Amrit Foods",
            date: "Mar 12, 2024, 06:30 PM ",
            items: "6",
            amount: "$100",
            payment: "Due",
            status: "Payment due",
        },
        {
            id: "#2349",
            vendor: "Amrit Foods",
            date: "Mar 12, 2024, 04:20 PM ",
            items: "2",
            amount: "$40",
            payment: "Due",
            status: "Payment due",
        },
        {
            id: "#2345",
            vendor: "Amrit Foods",
            date: "Mar 14, 2024, 09:12 AM ",
            items: "7",
            amount: "$110",
            payment: "Paid",
            status: "Paid",
        },
        {
            id: "#2346",
            vendor: "Amrit Foods",
            date: "Mar 13, 2024, 02:02 PM ",
            items: "3",
            amount: "$70",
            payment: "Due",
            status: "Payment due",
        },
    ]

    return (
        <SellerLayout active={"Vendors"} title={
            <>
                <div className='flex flex-row gap-2 items-center'>
                    Recent Orders
                    <Svgs.PremiumIcon />
                </div>
            </>
        }
            bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"Vendors"}
                url={'/vendor'}
                second={"Recent orders"}
            />

            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search By Product Name'}
                        />
                    </div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2 md:items-center justify-between'>
                        <div className='flex flex-row gap-2 col-span-2 items-center'>
                            <Dropdown3
                                title={""}
                                placeholder={"Monthly"}
                                option={[
                                    { value: "", label: "Weekly" },
                                    { value: "", label: "Daily" },
                                ]}
                            />
                            <Dropdown3
                                title={""}
                                placeholder={"All Orders"}
                                option={[
                                    { value: "", label: "Pending" },
                                    { value: "", label: "Delivered" },
                                ]}
                            />

                        </div>
                        <div className='flex flex-row items-center gap-x-2 w-full '>
                            <Svgs.PdfIcon />
                            <Button customPadding={'px-3 py-2'} className={"!rounded-md w-full"} customThemeBtn={'theme-btn2'}>
                                <div className='flex gap-2 items-center justify-between text-[#686868] text-sm'>
                                    <Svgs.DownloadBlack />
                                    Download Excel
                                </div>
                            </Button>
                        </div>


                    </div>
                </div>
            </RoundedCard>

            <RoundedCard>
                <div className="relative">
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                            <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                                <tr>
                                    <th scope="col" class="pr-5 py-3 font-normal">
                                        <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal">
                                        ID
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                        Vendor
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                        Date & Time
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal">
                                        Items
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                        Amount
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                        Payment
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                        Status
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal">
                                        Details
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((data, index) => (
                                    <tr key={index} class="border-b border-[#EEEEEE]">
                                        <td class="pr-5 py-3">
                                            <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                                        </td>
                                        <td class="pr-5 py-3 text-[#949494]">
                                            {data.id}
                                        </td>
                                        <td class="pr-5 py-3 whitespace-nowrap">
                                            {data.vendor}
                                        </td>
                                        <td class="pr-5 py-3 ">
                                            {data.date}
                                        </td>
                                        <td class="pr-5 py-3 whitespace-nowrap">
                                            {data.items}
                                        </td>
                                        <td class="pr-5 py-3">
                                            {data.amount}
                                        </td>
                                        <td class="pr-5 py-3 whitespace-nowrap paid">
                                            <span className={`${data.status === "Paid" ? "settled-text" : "packed-text"}`}>
                                                {data.payment}
                                            </span>
                                        </td>
                                        <td class="pr-5 py-3 whitespace-nowrap">
                                            <span className={`${data.status === "Paid" ? "settled" : "packed"} p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                                {data.status}
                                            </span>
                                        </td>
                                        <td class="px-5 py-3">
                                            <Link
                                                to={"/vendor/recent-order-details"}
                                                state={{ status: (index % 2 === 0 ? "completed" : "pending") }}>
                                                <Svgs.EyeIcon />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </RoundedCard>

            <Pagination
                currentPage={1}
                pageSize={2}
                totalCount={5} />

        </SellerLayout >
    )
}

export default RecentVendorOrder;