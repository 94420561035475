import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Input from 'Components/Elements/Input'
import Popup from 'Components/Elements/Popup'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import SellerLayout from 'Components/Layouts/SellerLayout'
import { usePremium } from 'Context/PremiumContext'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function PremiumScreen() {
    const navigate = useNavigate()
    const [openPaymentPopup, setOpenPaymentPopup] = useState(false)
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
    const { updatePremiumStatus } = usePremium()

    useEffect(() => {
        return () => {
            setOpenPaymentPopup(false)
            setOpenSuccessPopup(false)
        }
    }, [])


    return (
        <SellerLayout active={'Inventory'} title={'Inventory'} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"Inventory"}
                url={'/inventory'}
                second={"Premium"}
            />

            <RoundedCard className={" lg:px-36 "}>
                <div className="flex flex-col items-center justify-center gap-8">
                    <div className='flex flex-col gap-5'>
                        <h2 className='font-bold text-2xl text-center'>
                            Unlock the Full Potential of Your Inventory Management
                        </h2>
                        <p className=' font-roboto font-light text-base text-center text-gray-2'>
                            Welcome to the Canadians Cart premium, where we elevate your inventory management experience to new heights. Our premium plan is designed to provide advanced features, enhanced support, and unparalleled efficiency for your store.
                        </p>
                    </div>

                    {/* Premium and free cards */}
                    <div className='lg:grid lg:grid-cols-2 gap-8  flex flex-col w-full lg:px-12 '>

                        <div className='border rounded-lg border-gray-5 p-6 w-full'>
                            <div className='flex flex-row justify-between items-center'>
                                <div className="flex flex-col gap-2 justify-center">
                                    <div className='text-gray-1 text-2xl font-inter font-bold'>Free</div>
                                    <div className='text-gray-4 text-sm'>Limited Features</div>
                                </div>
                                <div className='text-gray-2 font-semibold text-4xl font-inter'>
                                    $100
                                </div>

                            </div>
                            <div className='border-2 border-dashed mx-2 my-6' />
                            <div className='font-inter text-gray-4 font-normal flex flex-col gap-3 items-start justify-center'>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.CorrectIcon />
                                    <p>Product listing</p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.WrongIcon />
                                    <p>Profit & loss anaylitics</p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.WrongIcon />
                                    <p>Vendor details</p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.WrongIcon />
                                    <p>Vendor orders</p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.WrongIcon />
                                    <p>Early settlements</p>
                                </div>
                            </div>


                            <div className='flex items-center justify-center py-9'>
                                <button className='bg-gray-5 rounded-sm lg:w-4/5 w-full py-3 font-bold text-gray-2 text-sm'>
                                    Active
                                </button>

                            </div>

                        </div>


                        <div className='border rounded-lg border-gray-5 p-6 w-full'>
                            <div className='flex flex-row justify-between items-center'>
                                <div className="flex flex-col gap-2 justify-center">
                                    <div className='text-gray-1 text-2xl font-inter font-bold'>Premium plan</div>
                                    <div className='text-gray-4 text-sm'>All Features</div>
                                </div>
                                <div className='text-gray-2 font-semibold text-4xl font-inter'>
                                    $100
                                </div>

                            </div>

                            <div className='border-2 border-dashed mx-2 my-6' />
                            <div className='font-inter text-gray-4 font-normal flex flex-col gap-3 items-start justify-center'>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.CorrectIcon />
                                    <p>Restock reminders</p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.CorrectIcon />
                                    <p>Profit & loss anaylitics</p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.CorrectIcon />
                                    <p>Expenses, Payroll, employees</p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.CorrectIcon />
                                    <p>Vendor orders</p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <Svgs.CorrectIcon />
                                    <p>Early settlements</p>
                                </div>
                            </div>


                            <div className='flex items-center justify-center py-9'>
                                <Button
                                    onClick={() => setOpenPaymentPopup(true)}
                                    className={"lg:w-4/5 w-full"}
                                    title={"Subscribe now"}
                                />

                            </div>

                        </div>

                    </div>

                    <div className='lg:py-12 py-6'>
                        <button className='border py-3 px-5  font-bold text-gray-1 border-gray-1 rounded-lg'>
                            Need help with subscription?
                        </button>
                    </div>

                </div>
            </RoundedCard>
            {/* Pay now popup */}
            <Popup
                open={openPaymentPopup}
                removeClose={true}
                removeHeading={true}
                close={() => setOpenPaymentPopup(false)}
                header={false}
                customSizeStyle={"lg:w-[25vw]"}
            >
                <div className=' flex flex-col items-center justify-center gap-6 '>
                    <h2 className='font-roboto font-bold text-xl text-gray-1'>
                        Pay now
                    </h2>

                    <div className='flex flex-row border border-gray-5 rounded-lg p-2.5 w-full justify-between   shadow shadow-gray-5'>
                        <div className='flex flex-col font-inter'>
                            <div className='font-bold text-gray-1 text-xl'>Premium plan</div>
                            <div className='text-gray-4 text-sm'>All features</div>
                        </div>
                        <div className='font-inter font-semibold text-2xl text-gray-2'>$100</div>
                    </div>

                    <div className="flex flex-col gap-4 p-">
                        <Input
                            required={false}
                            parentClass={"w-full"}
                            title={"Card number"}
                            placeholder={"0000 0000 0000"}
                        />

                        <div className='grid grid-cols-2 gap-5'>
                            <Input
                                required={false}
                                parentClass={"w-full"}
                                title={"Expiry date"}
                                placeholder={"00/00"}
                            />
                            <Input
                                required={false}
                                parentClass={"w-full"}
                                title={"CVV"}
                                placeholder={"000"}
                            />

                        </div>

                        <Input
                            required={false}
                            parentClass={"w-full"}
                            title={"Name on card"}
                            placeholder={"Type here"}
                        />
                    </div>

                    <button className='underline text-custom-blue underline-offset-1 text-sm'>
                        Add new card
                    </button>

                    <div>
                        <Button
                            onClick={() => {
                                setOpenPaymentPopup(false)
                                setOpenSuccessPopup(true)
                            }}
                            customPadding={" px-9 py-2 "}
                            title={"Pay now"}
                        />
                    </div>
                </div>


            </Popup>

            {/* Success Popup */}
            <SuccessPopup
                customSizeStyle=" lg:w-[25vw]"
                open={openSuccessPopup}
                title={"Payment successfully!"}
                buttonTitle={"Explore premium"}
                onClick={() => {
                    setOpenSuccessPopup(false)
                    setOpenPaymentPopup(false)
                    updatePremiumStatus(true)
                    navigate("/inventory")
                }}

            />
        </SellerLayout >
    )
}

export default PremiumScreen