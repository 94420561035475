import Svgs from "Assets/svgs";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useNavigate } from "react-router-dom";

const useSellerSidebar = (active) => {
    const navigate = useNavigate()

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    const sidebarRoutes = [
        {
            url: '/dashboard',
            heading: 'Dashboard',
            active: active == 'Dashboard',
            icon: <Svgs.DashboardIcon />
        },
        {
            url: '/orders',
            heading: 'Orders',
            active: active == 'Orders',
            icon: <Svgs.OrdersIcon />
        },
        {
            url: '/inventory',
            heading: 'Inventory',
            active: active == 'Inventory',
            icon: <Svgs.InventoryIcon />
        },
        {
            url: '/payments',
            heading: 'Payments',
            active: active == 'Payments',
            icon: <Svgs.PaymentIcon />
        },
        {
            url: '/discounts',
            heading: 'Discounts',
            active: active == 'Discounts',
            icon: <Svgs.DealsIcon />
        },
        {
            url: '/coupons',
            heading: 'Coupons',
            active: active == 'Coupons',
            icon: <Svgs.CouponIcon />
        },
        {
            url: '/other-services',
            heading: 'Other Services',
            active: active == 'Other Services',
            icon: <Svgs.OtherServicesIcon />
        },
        {
            url: '/profile',
            heading: 'My Profile',
            active: active == 'My Profile',
            icon: <Svgs.OtherServicesIcon />
        },
        // {
        //     url: '/wallet',
        //     heading: 'Wallet',
        //     active: active == 'Wallet',
        //     icon: <Svgs.OtherServicesIcon />
        // },
        {
            url: '/promotions',
            heading: 'Promote',
            active: active == 'Promote',
            icon: <Svgs.PromoteIcon />
        },
        {
            url: '/manage-store',
            heading: 'Manage Store',
            active: active == 'ManageStore',
            icon: <Svgs.ManageStoreIcon />
        },
        {
            url: '/refer-earn',
            heading: 'Refer & Earn',
            active: active == 'ReferEarn',
            icon: <Svgs.ReferAndEarnIcon />
        },
        {
            url: '/help-support',
            heading: 'Help & Support',
            active: active == 'Help & Support',
            icon: <Svgs.ReferAndEarnIcon />
        },

    ]

    const sidebarRoutesWithPremium = [
        {
            url: '/dashboard',
            heading: 'Dashboard',
            active: active == 'Dashboard',
            icon: <Svgs.DashboardIcon />
        },
        {
            url: '/orders',
            heading: 'Orders',
            active: active == 'Orders',
            icon: <Svgs.OrdersIcon />
        },
        {
            url: '/inventory',
            heading: 'Inventory',
            active: active == 'Inventory',
            icon: <Svgs.InventoryIcon />
        },
        {
            url: '/all-products',
            heading: 'All products',
            active: active == 'All products',
            icon: <Svgs.AllProductsSidebarIcon />
        },
        {
            url: '/vendor',
            heading: 'Vendors',
            active: active == 'Vendors',
            icon: <Svgs.VendorSidebarIcon />
        },
        {
            url: '/expenses',
            heading: 'Expenses',
            active: active == 'Expenses',
            icon: <Svgs.ExpenseSidebarIcon />
        },
        {
            url: '/employees',
            heading: 'Employees',
            active: active === 'Employees',
            icon: <Svgs.EmployeeSidebarIcon />
        },
        {
            url: '/payments',
            heading: 'Payments',
            active: active == 'Payments',
            icon: <Svgs.PaymentIcon />
        },
        {
            url: '/discounts',
            heading: 'Discounts',
            active: active == 'Discounts',
            icon: <Svgs.DealsIcon />
        },
        {
            url: '/coupons',
            heading: 'Coupons',
            active: active == 'Coupons',
            icon: <Svgs.CouponIcon />
        },
        {
            url: '/other-services',
            heading: 'Other Services',
            active: active == 'Other Services',
            icon: <Svgs.OtherServicesIcon />
        },
        {
            url: '/profile',
            heading: 'My Profile',
            active: active == 'My Profile',
            icon: <Svgs.OtherServicesIcon />
        },
        {
            url: '/promotions',
            heading: 'Promote',
            active: active == 'Promote',
            icon: <Svgs.PromoteIcon />
        },
        {
            url: '/manage-store',
            heading: 'Manage Store',
            active: active == 'ManageStore',
            icon: <Svgs.ManageStoreIcon />
        },
        {
            url: '/refer-earn',
            heading: 'Refer & Earn',
            active: active == 'ReferEarn',
            icon: <Svgs.ReferAndEarnIcon />
        },
    ]

    // handle link click
    const handleLinkClick = (itm) => {
        navigate(itm?.url)
        storeDataInLocalStorage('activeTab', itm?.heading)
    }

    return {
        navigate, sidebarRoutes, sidebarRoutesWithPremium, defaultRoute, handleLinkClick
    }
}

export default useSellerSidebar