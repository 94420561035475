
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Image from './Image'
import Svgs from 'Assets/svgs'

const ImageUploader = ({ name, title, hideImage, required, imgSize, accept, id, multiple, mb_file_size, onChange, value,
    imgWidth, imgHeight, error, showBorder, showDummy, heading, inputPadding, inputClass, placeholder }) => {
    const [image, setImage] = useState(undefined)
    useEffect(() => {
        if (value) {
            if (typeof value == 'string') {
                setImage(value)
            }
            else {
                let url = URL.createObjectURL(value)
                setImage(url)
            }
        }
    }, [value])

    return (
        <div className={`flex flex-col gap-2 flex-1 w-full`}>
            {title && (
                <div className={`${title == '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            <label for={id ? id : 'upload_img'} className={`${error ? "border !border-[#eb3b3b]" : showBorder ? "border border-lightBlue" : ""} ${inputPadding ? inputPadding : "px-4 py-2"} min-h-10 rounded-[5px] w-full bg-lightBlue focus:outline-none ${inputClass}`} key={id}>
                {value?.name ?
                    <div className='flex gap-3 bg-darkGrey text-blueGrey items-center p-2 rounded-xl shadow justify-between'>
                        <div className="flex items-center gap-2">
                            <div><Svgs.FileIcon /></div>
                            {value?.name}
                        </div>

                        <div
                            className='cursor-pointer p-2 rounded-full'
                            onClick={() =>
                                onChange && onChange({
                                    target: {
                                        name: name ? name : 'file',
                                        value: '',
                                        files: ['']
                                    }
                                })}
                        >
                            <Svgs.CrossIcon />
                        </div>
                    </div>
                    :
                    <div className="flex items-center text-center gap-2 cursor-pointer">
                        <Svgs.Uploader />
                        <p className='text-typography text-[0.875rem]'>{placeholder ? placeholder : "Upload images"}</p>
                    </div>
                }
                <input
                    type="file"
                    id={id ? id : 'upload_img'}
                    className='hidden'
                    name={name}
                    accept={accept}
                    multiple={multiple}
                    onChange={(e) => {
                        if (multiple) {
                            for (let fl in e.target.files) {
                                let file = e.target.files[fl]
                                let kb_file_size = file.size
                                let file_size_mb = (kb_file_size / 1000) / 1000
                                if (file_size_mb > mb_file_size) {
                                    toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "toast" })
                                    return
                                }
                            }
                        }
                        else {
                            let kb_file_size = e.target.files[0].size
                            let file_size_mb = (kb_file_size / 1000) / 1000
                            if (file_size_mb > mb_file_size) {
                                toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "toast" })
                                return
                            }
                        }

                        const file = e.target.files[0];
                        if (imgWidth && imgHeight) {
                            const reader = new FileReader();
                            reader.onload = (event) => {
                                const img = new Image();
                                img.src = event.target.result;
                                img.onload = () => {
                                    const width = img.width;
                                    const height = img.height;
                                    if (width <= imgWidth && height <= imgHeight) {
                                        onChange && onChange(e)
                                        if (!multiple) {
                                            let url = URL.createObjectURL(e.target.files[0])
                                            setImage(url)
                                        }
                                    } else {
                                        toast.info(`Reduce image resolution to ${imgSize} or lower`, { toastId: "toast" });
                                        return;
                                    }
                                };
                            };

                            reader.readAsDataURL(file);
                        } else {
                            onChange && onChange(e)
                            if (!multiple) {
                                let url = URL.createObjectURL(e.target.files[0])
                                setImage(url)
                            }
                        }
                    }}
                />
            </label>
        </div>
    )
}

ImageUploader.defaultProps = {
    onClick: () => { },
    multiple: false,
    title: "",
    showBorder: true
}

export default ImageUploader