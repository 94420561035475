import React from 'react'

const MobileVerifyInput = ({ name, value, onChange, disabled = false, inp_ref, onBackSpace }) => {
    return (
        <div className='border-2 rounded-lg sm:h-[4rem] h-[2.5rem] sm:w-[4rem] w-[2.5rem] overflow-hidden'>
            <input
                disabled={false}
                ref={inp_ref}
                type="number"
                name={name}
                value={value}
                onChange={onChange && onChange}
                placeholder='-'
                className='h-full w-full outline-none p-3 text-center numberAddInp bg-lightBlue'
                onKeyDown={(e) => {
                    if (e.key.toLowerCase() == 'backspace') {
                        onBackSpace()
                    }
                }}
            />
        </div>
    )
}

MobileVerifyInput.defaultProps = {
    onBackSpace: () => { }
}

export default MobileVerifyInput