import React from 'react'
import Svgs from '../../Assets/svgs'
import useSellerTopBar from './helper'
import NotificationsDropdown from 'Components/Notifications'
import Image from 'Components/Elements/Image'
// import NotificationsDropdown from 'Components/Notifications'

const SellerTopBar = ({ openSidebar, showFullLength, showLogo, title }) => {
    const { navigate, defaultRoute, activeTab, Notifications, setNotifications } = useSellerTopBar()

    return (
        <>
            <div className={`${!showFullLength ? "md:ml-64" : ""} bg-white p-4 fixed left-0 right-0 top-0 z-[55]`}>
                <div className="flex justify-between items-center ">
                    {showLogo ?
                        <div className="flex items-center justify-between cursor-pointer" onClick={() => { navigate(defaultRoute ? defaultRoute : "/dasboard") }}>
                            <img src="/logo.png" className="mr-3 h-10" alt="Logo" />
                        </div>
                        :
                        <div className='md:hidden flex gap-4'>
                            <div onClick={openSidebar} className="p-1 rounded-lg cursor-pointer md:hidden hover:text-white hover:bg-lightGray border border-lightGray focus:bg-lightGray focus:ring-2 focus:ring-lightGray ">
                                <Svgs.MenuToggler />
                            </div>
                            <div className="flex items-center justify-between cursor-pointer md:hidden" onClick={() => { navigate(defaultRoute ? defaultRoute : "/dasboard") }}>
                                <img src="/logo.png" className="mr-3 h-10" alt="Logo" />
                            </div>
                        </div>
                    }
                    <h1 className='font-bold text-2xl md:block hidden ml-2'>{title ? title : activeTab ? activeTab : "Dashboard"}</h1>
                    <div className='flex justify-between items-center sm:gap-x-4 gap-x-2'>
                        <Svgs.SearchIcon />
                        <div className="relative cursor-pointer" onClick={() => setNotifications(!Notifications)}>
                            <Svgs.NotificationIcon />
                        </div>
                        <Image customLoaderRounded={"rounded-full"} customLoaderHeight={"h-[30px]"} customLoaderWidth={'w-[30px]'} className={'w-[31px] h-[30px] rounded-full object-cover'} src={'/images/profile.png'} />
                    </div>
                </div>
                {Notifications && <NotificationsDropdown setOpen={setNotifications} open={Notifications} />}
            </div>

            {/* {unread_count != 0 && unread_count &&
                                <span className="absolute top-0 right-0 h-5 w-5 text-xs flex items-center font-semibold justify-center border-[2px] border-secondary bg-secondary text-primary rounded-full">
                                    {unread_count}
                                </span>
                            } */}
        </>
    )
}

export default SellerTopBar