import Image from 'Components/Elements/Image'
import React from 'react'
import { Link } from 'react-router-dom'

const ProductCard = ({ imageSrc, name, price, preperationTime, stockStatus }) => {
    return (
        <div className='border-2 rounded-lg border-[#EEEEEE] p-3 flex flex-col space-y-2'>
            <Image className={'w-full h-full object-cover max-w-[252px] max-h-[140px]'} src={imageSrc} />
            <div className='flex justify-between gap-4 text-sm font-semibold'>
                <h1>{name}</h1>
                <h1>{price}</h1>
            </div>
            <div className='flex justify-between gap-4 items-center text-xs'>
                {preperationTime ?
                    <p className='text-[#686868]'>Preperation Time{" "}{preperationTime}</p>
                    :
                    <span className={`${stockStatus === 0 ? "out-of-stock" : stockStatus === 1 ? "selling-fast" : "in-stock"} px-2 py-1 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                        {stockStatus === 0 ? "Out of Stock" : stockStatus === 1 ? "Selling Fast" : "In Stock"}
                    </span>
                }
                <Link to="/all-products/pomegranate" className='text-[#2376F5]'>View Detail</Link>
            </div>
        </div>
    )
}

export default ProductCard