import React from "react";
import Image from "Components/Elements/Image";
import Svgs from "Assets/svgs";
import Input from "Components/Elements/Input";
import PasswordInput from "Components/Elements/PasswordInput";
import CheckBox from "Components/Elements/Checkbox";
import Button from "Components/Elements/Button";
import useLogin from "./helper";

const Login = () => {
    const { navigate } = useLogin()

    return (
        <div className="xl:min-h-[100vh] min-h-[110vh] grid grid-cols-1 lg:grid-cols-10 ">
            <div className="mx-auto relative w-full flex flex-col col-span-1 lg:col-span-6">
                <div className="absolute top-6 sm:left-10 left-8">
                    <Image src={'/logo.png'} className={'h-full w-full object-cover'} />
                </div>
                <div className="sm:px-16 pt-4 absolute xl:top-20 top-32 sm:left-32 px-8 sm:w-fit w-full ">
                    <div className="flex flex-col space-y-2 text-primaryBlack">
                        <h4 className="font-bold text-3xl">Welcome Back</h4>
                        <h1 className="">Please enter your login details to access your account securely.</h1>
                    </div>
                    <div className="sm:pr-12 flex flex-col space-y-5">
                        <button className="min-h-10 mt-8 gap-3 w-full text-base flex items-center justify-center rounded-md font-bold border border-[#969696] px-4 py-2 outline-none ring-[#C0C0C0] ring-offset-2 focus:ring-2">
                            <Svgs.Google />
                            Continue With Google
                        </button>
                        <div class="flex items-center text-xs">
                            <div class="flex-1 border-t border-darkBackground"></div>
                            <div class="mx-1 text-darkBackground">OR</div>
                            <div class="flex-1 border-t border-darkBackground"></div>
                        </div>
                        <Input
                            title={'Email Address or Mobile Number'}
                            placeholder={'Enter Email Address or Mobile Number'}
                            required={false}
                        />
                        <PasswordInput
                            title={'Password'}
                            placeholder={'Enter Password'}
                            required={false}
                        />
                        <div className="flex justify-between items-center mb-4 pt-2">
                            <CheckBox
                                name={'remember-me'}
                                required={true}
                                id={'remember-me'}
                                label={'Remember me'}
                            />
                            <div className="text-customBlue cursor-pointer text-sm">Forgot Passwod?</div>
                        </div>
                        <div className="pt-2">
                            <Button
                                text={'Login'}
                                className={'w-full'}
                                onClick={() => { navigate("/dashboard") }}
                            />
                        </div>
                        <div className="mx-auto text-primaryBlack text-sm">Don't have an account? <span className="text-customBlue cursor-pointer" onClick={() => { navigate('/signup') }}>Sign Up</span></div>
                    </div>
                </div>
            </div>
            <div
                className="hidden lg:block lg:col-span-4  min-h-max bg-cover bg-center"
                style={{
                    backgroundImage: `url('/images/LoginImage.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            />
        </div>
    );
};

export default Login;
