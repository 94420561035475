import React from 'react'

const NotificationShimmer = () => {
    return (
        <div className={`flex animate-pulse !mx-0 cursor-pointer gap-3 !items-start justify-between shadow-md rounded-md pl-2 pr-4 py-1.5 border transition-all bg-white`}>
            <div className="h-9 w-9 my-2 bg-gray-200 rounded-full"></div>
            <div className="flex flex-col gap-2 p-2 w-full">
                <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                <div className="h-6 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
        </div>
    )
}

export default NotificationShimmer