import Svgs from 'Assets/svgs';
import CheckBox from 'Components/Elements/Checkbox';
import Dropdown3 from 'Components/Elements/Dropdown3';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const SellerDiscountTable = () => {
    const navigate = useNavigate()
    const tableData = [
        {
            offerName: "Holiday offer",
            minOrder: "$50",
            offerApplied: "10 products",
            status: "Active",
        },
        {
            offerName: "Buy1 Get1 free",
            minOrder: "$50",
            offerApplied: "10 products",
            status: "Active",
        },
        {
            offerName: "Flat $20 discount",
            minOrder: "$50",
            offerApplied: "10 products",
            status: "Pending",
        },
        {
            offerName: "Flat $25 discount",
            minOrder: "$60",
            offerApplied: "10 products",
            status: "Inactive",
        },


    ]
    const [checkedItems, setCheckedItems] = useState(tableData.map(() => false));
    const [allChecked, setAllChecked] = useState(false);

    const handleAllChecked = () => {
        const newCheckedStatus = !allChecked;
        setAllChecked(newCheckedStatus);
        setCheckedItems(checkedItems.map(() => newCheckedStatus));
    };

    const handleCheckBoxChange = (index) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
        setAllChecked(newCheckedItems.every(item => item === true));
    };

    return (
        <>
            <div className="overflow-x-auto ">
                <div className='min-w-max'>
                    <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                        <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                            <tr>
                                <th scope="col" class="pr-5 py-4 font-normal">
                                    <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                        checked={allChecked}
                                        onChange={() => handleAllChecked()} />
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal">
                                    Offer name
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal whitespace-nowrap">
                                    Minimum order
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal min-w-max min">
                                    Offer applied
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal">
                                    Status
                                </th>
                                <th scope="col" class="pr-5 py-4 font-normal whitespace-nowrap text-center">
                                    Details
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array(3).fill().map((_, index) => (
                                tableData.map((data, index) => (
                                    <tr
                                        index={index}
                                        class="border-b border-[#EEEEEE]">
                                        <td class="pr-5 py-4">
                                            <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                                checked={checkedItems[index]}
                                                onChange={() => handleCheckBoxChange(index)} />
                                        </td>
                                        <td class="pr-5 py-4 ">
                                            {data.offerName}
                                        </td>
                                        <td class="pr-5 py-4 whitespace-nowrap">
                                            {data.minOrder}
                                        </td>
                                        <td class="pr-5 py-4  min-w-max">
                                            <Dropdown3
                                                className={" w-36 lg:w-24"}
                                                title={""}
                                                placeholder={data?.offerApplied}
                                            />
                                        </td>
                                        <td class="pr-5 py-4 whitespace-nowrap">
                                            <span className={`${data?.status === "Active" ? "settled" : data?.status === "Inactive" ? "cancelled" : "packed"} px-2.5 py-1 rounded`}>
                                                {data.status}
                                            </span>
                                        </td>
                                        <td className='pr-5 py-4 whitespace-nowrap text-center'>
                                            <button onClick={() => navigate("/discount-offer-detail")}>
                                                <Svgs.EyeIcon />
                                            </button>

                                        </td>
                                    </tr>
                                ))
                            ))}


                        </tbody>
                        {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-4 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-4 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-4">
                                                7
                                            </td>
                                            <td class="pr-5 py-4">
                                                $110
                                            </td>
                                            <td class="pr-5 py-4 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-4 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-4">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                    </table>

                </div>
            </div>
        </>

    )
}
