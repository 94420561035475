import Svgs from 'Assets/svgs'
import React from 'react'

const DashboardOrdersTable = ({ data }) => {
    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right ">
                    <thead class="text-sm text-[#686868] capitalize">
                        <tr>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                ID
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Customer
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Items
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Amount
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Payment
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Status
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 text-[#949494]">
                                #2345
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Chaince Kaur
                            </td>
                            <td class="pr-5 py-3">
                                7
                            </td>
                            <td class="pr-5 py-3">
                                $110
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`recieved p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Recieved
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <Svgs.EyeIcon />
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 text-[#949494]">
                                #2346
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Navjot Singh
                            </td>
                            <td class="pr-5 py-3">
                                3
                            </td>
                            <td class="pr-5 py-3">
                                $70
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`packed p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Packed
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <Svgs.EyeIcon />
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 text-[#949494]">
                                #2347
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Manpreet Kaur
                            </td>
                            <td class="pr-5 py-3">
                                4
                            </td>
                            <td class="pr-5 py-3">
                                $90
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`delivered p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Delivered
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <Svgs.EyeIcon />
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 text-[#949494]">
                                #2348
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Gurpreet Singh
                            </td>
                            <td class="pr-5 py-3">
                                6
                            </td>
                            <td class="pr-5 py-3">
                                $100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Paid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`dispatched p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Dispatched
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <Svgs.EyeIcon />
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3 text-[#949494]">
                                #2349
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Harpreet Kaur
                            </td>
                            <td class="pr-5 py-3">
                                2
                            </td>
                            <td class="pr-5 py-3">
                                $40
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap unpaid">
                                Unpaid
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`cancelled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Cancelled
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <Svgs.EyeIcon />
                            </td>
                        </tr>
                    </tbody>
                    {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                </table>
                {/* {data?.length == 0 &&
                    <NotFound />
                } */}
            </div>
        </>
    )
}

export default DashboardOrdersTable