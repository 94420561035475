import Svgs from 'Assets/svgs'
import CheckBox from 'Components/Elements/Checkbox'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SellerPaymentTable = ({ data }) => {
    const navigate = useNavigate()
    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right ">
                    <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                        <tr>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Order ID
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Invoice
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Amount
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Date & Time
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Transaction ID
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                MOP
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Status
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal">
                                Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2345
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10617
                            </td>
                            <td class="pr-5 py-3 settled-text">
                                $110
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                NA
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                COD
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`settled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Settled
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2346
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10618
                            </td>
                            <td class="pr-5 py-3 refunded-text">
                                $100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                460926781236
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Card
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`refunded p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Refunded
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2345
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10619
                            </td>
                            <td class="pr-5 py-3 settled-text">
                                $110
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                460926781241
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Card
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`processing p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Processing
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2346
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10620
                            </td>
                            <td class="pr-5 py-3 refunded-text">
                                $70
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                NA
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Wallet
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`failed p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Failed
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2345
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10617
                            </td>
                            <td class="pr-5 py-3 settled-text">
                                $110
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                NA
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                COD
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`settled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Settled
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2346
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10618
                            </td>
                            <td class="pr-5 py-3 refunded-text">
                                $100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                460926781236
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Card
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`refunded p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Refunded
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2345
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10619
                            </td>
                            <td class="pr-5 py-3 settled-text">
                                $110
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                460926781241
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Card
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`processing p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Processing
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2346
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10620
                            </td>
                            <td class="pr-5 py-3 refunded-text">
                                $70
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                NA
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Wallet
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`failed p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Failed
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2345
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10617
                            </td>
                            <td class="pr-5 py-3 settled-text">
                                $110
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                NA
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                COD
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`settled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Settled
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem]"} />
                            </td>
                            <td class="pr-5 py-3 text-[#949494]">
                                #2346
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                #10618
                            </td>
                            <td class="pr-5 py-3 refunded-text">
                                $100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3">
                                460926781236
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                Card
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`refunded p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Refunded
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => { navigate("/payment-detail") }} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                </table>
                {/* {data?.length == 0 &&
                    <NotFound />
                } */}
            </div>
        </>
    )
}

export default SellerPaymentTable