import React from 'react'
import Popup from 'Components/Elements/Popup'
import Input from 'Components/Elements/Input'
import Svgs from 'Assets/svgs'
import PaymentMethodCard from '../Elements/PaymentMethodCard'
import AddNewPaymentMethod from '../Elements/AddNewPaymentMethod'
import Button from 'Components/Elements/Button'

// icon, name, cardNumber, csvDate, selected
const AddFundsToWallet = ({ open, close }) => {
    const data = [
        { icon: <Svgs.VisaIcon />, name: "Visa Card", cardNumber: "**** - **** - **** - 1315", csvDate: "12/27", selected: true },
        { icon: <Svgs.ApplePayIcon />, name: "Apple Pay", cardNumber: "**** - **** - **** - 1315", selected: false }
    ]
    return (
        <Popup
            heading={'Add Funds To Wallet'}
            open={open}
            close={close}
        >
            <div className='flex flex-col gap-5'>
                <Input
                    title={'Enter Amount To Add'}
                    required={false}
                    leftIconClassName={'font-bold'}
                    titleClass={'font-bold'}
                    showBorder={false}
                    leftIcon={'$'}
                    placeholder={""}
                />
                <div>
                    <h1 className='mb-2 text-sm font-bold text-primaryBlack'>Select Payment Method</h1>
                    <div className='grid md:grid-cols-2 grid-cols-1 gap-4 justify-between'>
                        {data?.map((itm, ind) => (
                            <PaymentMethodCard
                                key={ind}
                                name={itm?.name}
                                icon={itm?.icon}
                                csvDate={itm?.csvDate}
                                cardNumber={itm?.cardNumber}
                                selected={itm?.selected}
                            />
                        ))}
                        <AddNewPaymentMethod />
                    </div>
                </div>
                <Button
                    onClick={close}
                    title={'Add Amount'}
                    customPadding={'px-5 py-2'}
                />
            </div>
        </Popup>
    )
}

export default AddFundsToWallet