import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import { objectKeyConvertToArray } from 'Hooks/useObjectKeys';

let otpTime = 120
function useOTPVerification() {
    // const dispatch = useDispatch();
    const navigate = useNavigate()

    const inp_1 = useRef();
    const inp_2 = useRef();
    const inp_3 = useRef();
    const inp_4 = useRef();
    const inp_5 = useRef();
    const inp_6 = useRef();

    const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();

    const {
        // errors,
        setErrors,
        validation,
        removeWhiteSpacesAndSpecialCharactors
    } = useValidations();

    // states
    const [emailForVerification, setEmailForVerification] = useState()
    const [loading, setLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [count, setCount] = useState(isFinished ? 0 : otpTime);
    const [test, setTest] = useState(false);
    // initials state for verification code fields 
    const [verificationCode, setVerificationCode] = useState({
        num1: "",
        num2: "",
        num3: "",
        num4: "",
        num5: "",
        num6: "",
    });

    // set verification code state here 
    const onChangeHandler = (e) => {
        e.target.value = removeWhiteSpacesAndSpecialCharactors(e.target.value)
        const { name, value } = e.target;

        if (value.length > 1) return false;
        setVerificationCode((prev) => ({
            ...prev,
            [name]: value
        }));

    }
    //decrement count in very second
    const countDown = () => {
        if (count !== 0) {
            setCount(count - 1);
        }
    };

    // handle submit function
    const handleSubmit = () => {
        const { num1, num2, num3, num4, num5, num6 } = verificationCode;
        let payload = {
            code: `${num1}${num2}${num3}${num4}${num5}${num6}`,
            email: emailForVerification
        }
        const success = (data) => {
            storeDataInLocalStorage('userData', data)
            storeDataInLocalStorage('access_token', data?.token)
            navigate("/")
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        setErrors(validation(payload));
        const result = validation(payload);
        if ((objectKeyConvertToArray + (result)?.length === 0)) {
            // setLoading(true)
            // dispatch(verifyOTP(payload, success, fail))
        }
    }

    // handle resend code function
    const handleResendCode = () => {
        let payload = {
            email: emailForVerification
        }
        // dispatch(resendOTP(payload))
        setCount(otpTime)
    }

    useEffect(() => {
        const email = getDataFromLocalStorage('emailVerification')
        if (email) {
            setEmailForVerification(email)
            localStorage.removeItem('emailVerification')
        } else {
            // navigate('/auth/account-type')
        }
    }, [])

    // handle counter function here
    useEffect(() => {
        if (!isFinished) {
            setTimeout(() => {
                countDown();
            }, 1000);
        }
    }, [count, isFinished]);

    useEffect(() => {
        let num_inps = document.querySelectorAll(".numberAddInp");
        num_inps.forEach((inp, i) => {
            inp.addEventListener("input", () => {
                const inputValue = removeWhiteSpacesAndSpecialCharactors(inp.value);
                if (inputValue && inputValue !== "") {
                    if (i !== num_inps.length - 1) {
                        num_inps[i + 1].select();
                    }
                }
            });
        });
    }, [test]);

    return {
        count, isFinished, setCount, inp_1, inp_2, inp_3, inp_4, inp_5, inp_6, verificationCode,
        setVerificationCode, onChangeHandler, navigate, handleSubmit, emailForVerification, handleResendCode
    };
}




export default useOTPVerification
