import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Dropdown3 from 'Components/Elements/Dropdown3'
import Input from 'Components/Elements/Input'
import Popup from 'Components/Elements/Popup'
import RoundedCard from 'Components/Elements/RoundedCard'
import SuccessPopup from 'Components/Elements/SuccessPopup'
import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { DateRangePicker } from 'react-date-range'
import DatePicker from 'react-datepicker'
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';
import DateInput from 'Components/Elements/DateInput'
// import DatePicker from 'react-modern-calendar-datepicker'

function Schedule() {

    const tableData = [
        {
            weekly: "12-07-2024- 18-07-2-24",
            name: "Anna",
            shift: "8 Hours",
            breaks: "3 breaks"
        },
        {
            weekly: "12-07-2024- 18-07-2-24",
            name: "Anna",
            shift: "8 Hours",
            breaks: "3 breaks"
        },
        {
            weekly: "12-07-2024- 18-07-2-24",
            name: "Anna",
            shift: "8 Hours",
            breaks: "3 breaks"
        },
    ]

    return (
        <>
            <RoundedCard>
                <div className='flex xl:flex-row flex-col gap-2 justify-between xl:items-center mb-4'>
                    <h1 className='text-lg font-semibold'>Schedule </h1>
                    <div className='flex gap-2 justify-between items-center'>
                        <Dropdown3
                            title={''}
                            required={false}
                            placeholder={'All'}
                            option={[
                                { value: "This week", label: "This week" },
                                { value: "", label: "Today" }
                            ]}
                        />
                        {/* <DateRangePicker /> */}
                        <DateInput
                            readOnly={false}
                            disabled={false}
                            title={""}
                            type='date'
                            parentClass={"bg-white"}
                            placeholder={"Calendar"}
                            inputClass={"!bg-white"} />
                    </div>

                </div>
                <div className="relative">
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-left min-w-max  ">
                            <thead class="text-sm text-[#686868] capitalize">
                                <tr>
                                    <th scope="col" class="pr-5 py-3 font-normal">
                                        Weekly                                </th>
                                    <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                        Employee name
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                        Shift time
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                        Breaks
                                    </th>
                                    <th scope="col" class="pr-5 py-3 font-normal text-center">
                                        View Details
                                    </th>
                                </tr>
                            </thead>

                            {tableData.map((data, index) => (
                                <tr
                                    key={index}
                                    className="border-b border-[#EEEEEE] text-sm py-3">
                                    <td className='pr-5 py-3'>
                                        {data.weekly}
                                    </td>
                                    <td className='pr-5 py-3'>
                                        {data.name}
                                    </td>
                                    <td className='pr-5 py-3'>
                                        {data.shift}
                                    </td>
                                    <td className='pr-5 py-3'>
                                        {data.breaks}
                                    </td>
                                    <td className="pr-5 py-3 text-center  flex justify-center items-start">
                                        <Svgs.EyeIcon />
                                    </td>


                                </tr>
                            ))
                            }

                        </table>
                    </div>
                </div>
            </RoundedCard>


        </>
    )
}

export default Schedule