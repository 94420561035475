import ImageUploader from 'Components/Elements/ImageUploader'
import React from 'react'

const LegalDocuments = () => {
    return (
        <>
            <div className="flex flex-col space-y-2 text-primaryBlack">
                <h4 className="font-bold text-3xl">Add Legal Documents</h4>
                <h1>Please upload the required legal documents of your store.</h1>
            </div>
            <div className="flex flex-col space-y-5 py-5">
                <ImageUploader
                    title={'Terms of Service'}
                    placeholder={'Upload'}
                />
                <ImageUploader
                    title={'Return & Refund Policy'}
                    placeholder={'Upload'}
                />
                <ImageUploader
                    title={'Dispute Resolution'}
                    placeholder={'Upload'}
                />
                <ImageUploader
                    title={'Business Registration Information'}
                    placeholder={'Upload'}
                />
            </div>
        </>
    )
}

export default LegalDocuments