
export const objectKeyConvertToArray = (object) => {
    return Object.keys(object);
}




export const convertImgToUrl = (file) => {
    let data;
    if (file) {
        data = URL.createObjectURL(file);
    }
    return data
};
