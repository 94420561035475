import BreadCrumb from 'Components/Elements/BreadCrumb'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Input from 'Components/Elements/Input'
import Popup from 'Components/Elements/Popup'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function AddOfferScreen({ setAddOffer }) {
    const navigate = useNavigate()
    const [openPopup, setOpenPopup] = useState(false)
    return (
        <>

            <div className='flex flex-row gap-x-1.5'>
                <Link to="/inventory" className='font-tertiary-body cursor-pointer'>Inventory</Link>
                <div className='font-tertiary-body'>{">"}</div>
                <div onClick={() => setAddOffer(false)} className='font-tertiary-body hover:cursor-pointer'>Add product</div>
                <div className='font-tertiary-body'>{">"}</div>
                <div className='font-tertiary-body !text-black' >Add offers</div>
            </div>
            <RoundedCard>
                <div className='flex md:flex-row flex-col flex-wrap gap-6'>
                    <Dropdown
                        className={"lg:w-[30%] "}
                        title={"Select offer"}
                        placeholder={"Select"}
                        option={[
                            { value: "10%", label: "10% off" },
                            { value: "25%", label: "25% off" },
                        ]}
                    />

                    <Input
                        parentClass={"lg:w-[30%] "}
                        required={false}
                        placeholder={"Type here"}
                        title={"Set minimum order"}
                    />

                    <Input
                        parentClass={"md:w-[50%] lg:w-[30%] "}

                        required={false}
                        title={"Start Date"}
                        type={"date"} />
                    <Input
                        parentClass={"md:w-[50%] lg:w-[30%] "}
                        required={false}
                        title={"End Date"}
                        type={"date"} />
                </div>

                <div className='flex items-center justify-center gap-6 flex-row py-8 flex-wrap '>
                    <Button
                        customThemeBtn={"bg-clip-text bg-clip-border text-transparent bg-btn-theme  !border !border-[#F33F41] theme-btn"} title={"Create offer"} />
                    <Button
                        onClick={() => setOpenPopup(true)}
                        customThemeBtn={"bg-clip-text bg-clip-border text-transparent bg-btn-theme  !border !border-[#F33F41] theme-btn"} title={"Skip and publish"}
                    />
                    <Button onClick={() => setOpenPopup(true)} title={"Activate offer and publish"} />
                </div>
            </RoundedCard>
            <Popup
                open={openPopup}
                removeClose={true}
                removeHeading={true}
                customSizeStyle={" lg:w-[25vw] "}
                children={
                    <div className='flex flex-col items-center gap-4 justify-center'>
                        <div className="flex flex-col gap-6 items-center justify-center ">
                            <svg width="46" height="44" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M44.0202 24.5006C42.3386 32.9088 35.9988 40.826 27.1028 42.5951C22.7641 43.4592 18.2634 42.9324 14.2416 41.0897C10.2197 39.247 6.88172 36.1825 4.70288 32.3323C2.52405 28.4822 1.61545 24.0428 2.10646 19.6462C2.59748 15.2497 4.46307 11.1201 7.4376 7.8455C13.5386 1.12562 23.8404 -0.724196 32.2487 2.63911" stroke="#20AE5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.4336 21.1368L23.8419 29.5451L44.0217 7.68359" stroke="#20AE5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <div className='text-[#27AE60] font-bold text-xl '
                                style={{
                                    fontFamily: "Roboto"
                                }}>
                                SUCCESS
                            </div>
                        </div>
                        <div className='text-[#4A4A4A] font-medium text-base font-roboto'>Product published successfully!</div>
                        <Button
                            onClick={() => {
                                navigate(-1)
                                setOpenPopup(false)
                            }}
                            className={"min-w-max !px-9 !py-2"}
                            title={"Close"} />

                    </div>
                }
            />
        </>

    )
}

export default AddOfferScreen