import React from 'react'
import SellerLayout from 'Components/Layouts/SellerLayout'
import RoundedCard from 'Components/Elements/RoundedCard'
import SearchInput from 'Components/Elements/SearchInput'
import Dropdown2 from 'Components/Elements/Dropdown2'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import { PAYMENT_STATUSES, TIMELINE } from 'Constants/Data/OrdersDropdown'
import Pagination from 'Components/Pagination'
import SellerPaymentTable from 'Utility/Tables/SellerPaymentTable'
import DashboardCard from '../Dashboard/Elements/DashboardCard'

const SellerPayments = () => {
    return (
        <SellerLayout active={'Payments'} title={'Payments'} bodyClassName={'flex flex-col space-y-4'}>
            <RoundedCard>
                <div className='flex xl:flex-row flex-col justify-between gap-4 w-full'>
                    <div className='xl:w-4/12 lg:w-6/12 w-full'>
                        <SearchInput
                            title={''}
                            required={false}
                            placeholder={'Search By Order ID or Customer Name'}
                        />
                    </div>
                    <div className='flex sm:flex-row flex-col gap-2 sm:items-center justify-between'>
                        <Dropdown2
                            title={''}
                            required={false}
                            placeholder={'Monthly'}
                            option={TIMELINE}
                        />
                        <Dropdown2
                            title={''}
                            required={false}
                            placeholder={'All'}
                            option={PAYMENT_STATUSES}
                        />
                        <Button customPadding={'px-3 py-2'} className={"!rounded-md"}>
                            <div className='flex gap-2 items-center justify-between text-sm'>
                                <Svgs.DownloadIcon />
                                Download Excel
                            </div>
                        </Button>
                    </div>
                </div>
            </RoundedCard>

            <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 lg:mt-0 mt-2'>
                <DashboardCard
                    title={'Total Revenue'}
                    value={'$100k'}
                    percentage={`2.48%`}
                    description={'higher this month'}
                    icon={<Svgs.TotalRevenueSvg />}
                />
                <DashboardCard
                    title={'Pending amount'}
                    value={'$68k'}
                    percentage={`1.02%`}
                    description={'higher this month'}
                    icon={<Svgs.DollarIcon />}
                />
                <DashboardCard
                    title={'Settled amount'}
                    value={'$32k'}
                    percentage={`4.48%`}
                    description={'higher this month'}
                    icon={<Svgs.DollarIcon color={"#00B037"} />}
                />
            </div>



            <RoundedCard className={"flex flex-col gap-6"}>
                <h3>Recent transactions</h3>
                <SellerPaymentTable />
            </RoundedCard>
            {/* {data?.length > 0 && */}
            <Pagination
                currentPage={1}
                pageSize={2}
                totalCount={3}
            // onPageChange={(page) => setCurrentPage(page)}
            />
            {/* } */}
        </SellerLayout>
    )
}

export default SellerPayments