import React, { useState } from 'react';
import useCustomLazyLoading from 'Hooks/useCustomLazyLoading';
import ImageShimmer from 'Utility/Shimmers/ImageShimmer';

const Image = ({ src, className, alt, fallback, customLoaderHeight, customLoaderWidth, customLoaderRounded }) => {
    const imageLoading = useCustomLazyLoading(src)
    const [broken, setBroken] = useState(false);

    const handleImageError = () => {
        setBroken(true);
    };

    return (
        <>
            {!imageLoading ?
                <ImageShimmer customWidth={customLoaderWidth} customHeight={customLoaderHeight} customRounded={customLoaderRounded} />
                : broken || !src ? (
                    <img className={className} src={fallback ? fallback : "/logo.svg"} alt="fallback" />
                ) : (
                    <img src={src} className={className} alt={alt ? alt : "Hotel Conierge"} onError={handleImageError} />
                )}
        </>
    );
};

export default Image;
