import Svgs from 'Assets/svgs';
import CheckBox from 'Components/Elements/Checkbox';
import Dropdown from 'Components/Elements/Dropdown';
import Dropdown2 from 'Components/Elements/Dropdown2';
import Dropdown3 from 'Components/Elements/Dropdown3';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const SellerCouponTable = () => {
    const tableData = [
        {
            name: "Christmas offer",
            minOrder: "$50",
            offerApplied: "10 products",
            status: "Active",
        },
        {
            name: "Get 10% OFF",
            minOrder: "$50",
            offerApplied: "10 products",
            status: "Active",
        },
        {
            name: "Summer offer",
            minOrder: "$50",
            offerApplied: "10 products",
            status: "Pending",
        },

    ]
    const [checkedItems, setCheckedItems] = useState(tableData.map(() => false));
    const [allChecked, setAllChecked] = useState(false);

    const handleAllChecked = () => {
        const newCheckedStatus = !allChecked;
        setAllChecked(newCheckedStatus);
        setCheckedItems(checkedItems.map(() => newCheckedStatus));
    };

    const handleCheckBoxChange = (index) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
        setAllChecked(newCheckedItems.every(item => item === true));
    };
    const navigate = useNavigate()

    return (
        <div className="overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                    <tr>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                checked={allChecked}
                                onChange={() => handleAllChecked()} />
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Coupon name
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Minimum order
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal min-w-max min">
                            Offer applied
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Status
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Details
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((data, index) => (
                        <tr
                            index={index}
                            class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                    checked={checkedItems[index]}
                                    onChange={() => handleCheckBoxChange(index)} />
                            </td>
                            <td class="pr-5 py-3 ">
                                {data.name}
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap ">
                                {data.minOrder}
                            </td>
                            <td class="pr-5 py-3  min-w-max">
                                <Dropdown3
                                    inputClass={"!border-0"}
                                    title={""}
                                    placeholder={data.offerApplied}
                                    className={"w-32 !border-0"}
                                />
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`${data.status === "Active" ? "settled" : "packed"} p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    {data.status}
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <button onClick={() => navigate("/coupon-details")} className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </button>
                            </td>
                        </tr>
                    ))}


                </tbody>

            </table>

        </div>
    )
}
