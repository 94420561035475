import React from 'react'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import VerticalStatusSlider from 'Pages/Seller/Orders/Elements/VerticalStatusSlider'

const SellerPaymentDetail = () => {
    return (
        <SellerLayout active={'Payments'} title={"Payment Detail"} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"Payments"}
                second={"Payment Detail"}
                url={'/payments'}
            />
            <RoundedCard>
                <div className='flex gap-2 justify-between'>
                    <h1 className='mb-2'>Transaction Details</h1>
                    <p className='text-[#2376F5] text-right text-xs'>View Order Detail</p>
                </div>
                <div className='flex lg:flex-row flex-col lg:gap-4 gap-2 justify-between'>
                    <div className='flex flex-col space-y-2'>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Invoice:
                            </p>
                            <p className='text-sm'>
                                10617
                            </p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Transaction ID:
                            </p>
                            <p className='text-sm'>
                                460926781236
                            </p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                MOP:
                            </p>
                            <p className='text-sm'>
                                Card
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col space-y-2 lg:text-right lg:items-end'>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Order ID:
                            </p>
                            <p className='text-sm'>
                                #234516
                            </p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-sm text-[#949494]'>
                                Date & Time:
                            </p>
                            <p className='text-sm'>
                                Mar 12, 2024, 09:10 AM
                            </p>
                        </div>
                        <span className='settled p-2 w-fit float-end rounded-md text-sm font-normal flex justify-center items-center'>
                            Settled
                        </span>
                    </div>
                </div>
            </RoundedCard>
            <RoundedCard>
                <h1 className='mb-2'>History</h1>
                <VerticalStatusSlider
                    parentClass={"pr-4 pt-4"}
                    steps={[
                        { name: "Payment Received", type: "delivered", time: "09:12 AM" },
                        { name: "Payment Processed Online Through Card", type: "packed", time: "09:12 AM" },
                        { name: "Order #2423 received", type: "placed", time: "09:12 AM" }
                    ]}
                />
            </RoundedCard>
        </SellerLayout>
    )
}

export default SellerPaymentDetail