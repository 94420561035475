import Dropdown from 'Components/Elements/Dropdown'
import Input from 'Components/Elements/Input'
import React from 'react'

const SetupAccount = () => {
    return (
        <>
            <div className="flex flex-col space-y-2 text-primaryBlack">
                <h4 className="font-bold text-3xl">Set Up Your Account</h4>
                <h1>Please enter the required details to setup your account.</h1>
            </div>
            <div className="flex flex-col space-y-5 py-5">
                <Input
                    title={'Full Name'}
                    placeholder={'Enter Full Name'}
                    required={false}
                />
                <Input
                    title={'Email'}
                    placeholder={'Enter Email Address'}
                    required={false}
                />
                <Input
                    title={'Phone Number'}
                    placeholder={'Enter Phone Number'}
                    required={false}
                />
                <Input
                    title={'Occupation'}
                    placeholder={'Enter Occupation'}
                    required={false}
                />
                <Dropdown
                    title={'Province'}
                    placeholder={'Enter Province'}
                    option={[{ label: "hello", value: "hello" },
                    { label: "hello", value: "hello" },
                    { label: "hello", value: "hello" },
                    { label: "hello", value: "hello" }]}
                />
            </div>
        </>
    )
}

export default SetupAccount