import React from 'react'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import SellerWalletTable from 'Utility/Tables/SellerWalletTable'
import useSellerWallet from './helper'
import AddFundsToWallet from './AddFundsToWallet'

const SellerWallet = () => {
    const { addFunds, setAddFunds } = useSellerWallet()

    return (
        <SellerLayout active={'Wallet'} title={"My Wallet"} bodyClassName={'flex flex-col space-y-4'}>
            {/* <BreadCrumb
                first={"Dashboard"}
                second={"My Wallet"}
                url={'/dashboard'}
            /> */}
            <RoundedCard className={'bg-[#f5ebeb]'} bgImage={'/images/wallet.png'}>
                <div className='flex justify-between items-center gap-4'>
                    <div className='flex flex-col space-y-2'>
                        <h1 className='text-primary font-bold text-2xl'>$250</h1>
                        <p className='text-sm'>Current MR Wallet Balance</p>
                    </div>
                    <div className={`cursor-pointer text-sm px-3 justify-start h-fit theme-btn py-1 w-fit`} onClick={() => { setAddFunds(true) }}>
                        <h2>Add Money To Wallet</h2>
                    </div>
                </div>
            </RoundedCard>
            <RoundedCard>
                <SellerWalletTable />
            </RoundedCard>

            <AddFundsToWallet
                open={addFunds}
                close={() => setAddFunds(false)}
            />
        </SellerLayout>
    )
}

export default SellerWallet