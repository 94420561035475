import React from 'react'

const SellerWalletTable = ({ data }) => {
    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right ">
                    <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                        <tr>
                            <th scope="col" class="pr-10 py-3 font-normal whitespace-nowrap">
                                Transaction
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Date & Time
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Amount
                            </th>
                            <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-10 py-3 whitespace-nowrap">
                                Paid for advertisement
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap failed-text">
                                -286
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`settled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Settled
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-10 py-3 whitespace-nowrap">
                                Paid for advertisement
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 13, 2024, 02:02 PM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap failed-text">
                                -579
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`settled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Settled
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-10 py-3 whitespace-nowrap">
                                Paid for advertisement
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                Mar 12, 2024, 12:01 PM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrapt">
                                100
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`pending p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Pending
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-10 py-3 whitespace-nowrap">
                                Paid for advertisement
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                            Mar 12, 2024, 06:30 PM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap failed-text">
                                -400
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`settled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Settled
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-10 py-3 whitespace-nowrap">
                            Added to wallet
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                            Mar 12, 2024, 04:20 PM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap settled-text">
                                +1000
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`settled p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Settled
                                </span>
                            </td>
                        </tr>
                        <tr class="border-b border-[#EEEEEE]">
                            <td class="pr-10 py-3 whitespace-nowrap">
                            Paid for visiting card
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                            Mar 14, 2024, 09:12 AM
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                200
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`failed p-2 capitalize w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    Failed
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
                </table>
                {/* {data?.length == 0 &&
                    <NotFound />
                } */}
            </div>
        </>
    )
}

export default SellerWalletTable