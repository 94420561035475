import { useState } from "react";
import { useNavigate } from "react-router-dom";


function useSellerAccountSetup() {
    const navigate = useNavigate();

    // states
    const [step, setStep] = useState(1)

    return {
        navigate, step, setStep
    }
}

export default useSellerAccountSetup