import Svgs from 'Assets/svgs'
import React, { useState } from 'react'
import CheckBox from 'Components/Elements/Checkbox'


function PremiumExpenseTable() {
    const tableData = [
        {
            title: "Water bill",
            amount: "$110",
            date: "Mar 14, 2024, 09:12 AM ",
            trId: "NA",
            mop: "COD",
            status: "Paid",
        },
        {
            title: "Electricity bill",
            amount: "$70",
            date: "Mar 13, 2024, 02:02 PM",
            trId: "NA",
            mop: "COD",
            status: "Paid",
        },
        {
            title: "Tea bill",
            amount: "$10",
            date: "Mar 12, 2024, 12:01 PM ",
            trId: "460926781236",
            mop: "Card",
            status: "Paid",
        },
        {
            title: "Charity",
            amount: "$100",
            date: "Mar 12, 2024, 06:34 PM ",
            trId: "460926781237",
            mop: "Card",
            status: "Refunded",
        },
        {
            title: "Salary",
            amount: "$40",
            date: "Mar 12, 2024, 04:20 AM ",
            trId: "NA",
            mop: "Wallet",
            status: "Pending",
        },
        {
            title: "Salary",
            amount: "$110",
            date: "Mar 14, 2024, 09:12 AM ",
            trId: "460926781239",
            mop: "Card",
            status: "Paid",
        },
        {
            title: "Tea bill",
            amount: "$70",
            date: "Mar 13, 2024, 02:02 PM ",
            trId: "460926781239",
            mop: "Card",
            status: "Paid",
        },
    ]
    const [checkedItems, setCheckedItems] = useState(tableData.map(() => false));
    const [allChecked, setAllChecked] = useState(false);

    const handleAllChecked = () => {
        const newCheckedStatus = !allChecked;
        setAllChecked(newCheckedStatus);
        setCheckedItems(checkedItems.map(() => newCheckedStatus));
    };

    const handleCheckBoxChange = (index) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
        setAllChecked(newCheckedItems.every(item => item === true));
    };

    return (
        <div className="overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right min-w-max ">
                <thead class="text-sm text-[#686868] capitalize border-b border-[#EEEEEE]">
                    <tr>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                checked={allChecked}
                                onChange={() => handleAllChecked()} />
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Title
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Amount
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal min-w-max min">
                            Date & Time
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Transaction ID
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            MOP
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal whitespace-nowrap">
                            Status
                        </th>
                        <th scope="col" class="pr-5 py-3 font-normal">
                            Details
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((data, index) => (
                        <tr
                            index={index}
                            class="border-b border-[#EEEEEE]">
                            <td class="pr-5 py-3">
                                <CheckBox className={"accent-primary h-[1rem] w-[1rem] cursor-pointer"}
                                    checked={checkedItems[index]}
                                    onChange={() => handleCheckBoxChange(index)} />
                            </td>
                            <td class="pr-5 py-3 ">
                                {data.title}
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap text-[#CE221E]">
                                {data.amount}
                            </td>
                            <td class="pr-5 py-3  min-w-max">
                                {data.date}
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                {data.trId}
                            </td>
                            <td class="pr-5 py-3">
                                {data.mop}
                            </td>
                            <td class="pr-5 py-3 whitespace-nowrap">
                                <span className={`${data.status === "Paid" ? "settled" : data?.status === "Refunded" ? "refunded" : "pending"} p-2 w-fit rounded-md text-sm font-normal flex justify-center items-center`}>
                                    {data.status}
                                </span>
                            </td>
                            <td class="px-5 py-3">
                                <div className='cursor-pointer'>
                                    <Svgs.EyeIcon />
                                </div>
                            </td>
                        </tr>
                    ))}


                </tbody>
                {/* <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr class="border-b border-[#EEEEEE]">
                                            <td class="pr-5 py-3 text-[#949494]">
                                                #2345
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap">
                                                Chaince Kaur
                                            </td>
                                            <td class="pr-5 py-3">
                                                7
                                            </td>
                                            <td class="pr-5 py-3">
                                                $110
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap paid">
                                                Paid
                                            </td>
                                            <td class="pr-5 py-3 whitespace-nowrap recieved">
                                                Recieved
                                            </td>
                                            <td class="pr-5 py-3">
                                                <Svgs.EyeIcon />
                                            </td>
                                        </tr>
                                    )
                                })
                                : ''
                        }

                    </tbody> */}
            </table>
            {/* {data?.length == 0 &&
                    <NotFound />
                } */}
        </div>)
}

export default PremiumExpenseTable