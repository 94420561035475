import Svgs from 'Assets/svgs'
import BreadCrumb from 'Components/Elements/BreadCrumb'
import Image from 'Components/Elements/Image'
import RoundedCard from 'Components/Elements/RoundedCard'
import SellerLayout from 'Components/Layouts/SellerLayout'
import React from 'react'

export const CateringChatPage = () => {
    return (
        <SellerLayout active={'Other Services'}
            title={'Other services'} bodyClassName={'flex flex-col space-y-4'}>
            <BreadCrumb
                first={"Other services"}
                url={"/other-services"}
                second={"Catering services"}
                secondurl={"/other-services/catering-services"}
                third={"Chats"}
            />

            <RoundedCard className={"!py-0 !px-0"}>
                <div className='grid lg:grid-cols-3 grid-cols-1'>
                    <div className='flex flex-col border-r border-[#E0E0E0] '>
                        <div className='flex flex-row justify-between items-center border-b border-[#E0E0E0] py-3.5 px-4'>
                            <div className='flex flex-row items-center gap-2'>
                                <div className='relative'>

                                    <Image
                                        customLoaderHeight={"h-[48px]"}
                                        customLoaderRounded={"rounded-full"}
                                        customLoaderWidth={"w-[48px]"}
                                        className={"object-contain w-[48px] h-[48px] rounded-full"}
                                        src={"/images/profile2.png"}
                                    />
                                    <div className="absolute bg-[#00B037] w-2.5 h-2.5  bottom-0.5 right-0.5 rounded-full border border-white"></div>
                                </div>

                                <div className='flex flex-col font-public-sans'>
                                    <p className=' font-medium text-base text-[#333333]'>
                                        Robert Fox
                                    </p>
                                    <p className='text-sm text-gray-4'>online</p>
                                </div>

                            </div>
                            <button>
                                <Svgs.SearchIcon />
                            </button>

                        </div>
                        <div className='pt-5'>
                            <h3 className='px-2.5 font-public-sans font-medium text-lg text-[#333333]'>
                                Messages
                            </h3>

                            <div className='flex flex-row px-2.5 py-3 bg-[#F2F2F2] justify-between '>
                                <div className='flex flex-row gap-2.5 items-center'>
                                    <div className='relative'>

                                        <Image
                                            customLoaderHeight={"w-[48px]"}
                                            customLoaderRounded={"rounded-full"}
                                            customLoaderWidth={"w-[48px]"}
                                            className={"object-contain w-[48px] h-[48px] rounded-full"}
                                            src={"/images/profile3.png"}
                                        />
                                        <div className="absolute bg-[#00B037] w-2.5 h-2.5  bottom-0.5 right-0.5 rounded-full border border-white"></div>
                                    </div>
                                    <div className='flex flex-col gap-0.5'>
                                        <p className='font-poppins font-medium text-base text-[#333333]'>Jimmy</p>
                                        <div className='flex flex-row overflow-hidden text-ellipsis items-center font-public-sans text-[#4F4F4F] text-base gap-2'>
                                            <Svgs.DoubleTickIcon />
                                            <p>Hey! I just checked your...</p>
                                        </div>

                                    </div>
                                </div>

                                <div className='flex items-end justify-end font-public-sans text-sm'>
                                    10:44
                                </div>
                            </div>


                            <div className='flex flex-row px-2.5 py-3 bg-white border-y border-[#E0E0E0] justify-between '>
                                <div className='flex flex-row gap-2.5 items-center'>
                                    <div className="relative">

                                        <Image
                                            customLoaderHeight={"w-[48px]"}
                                            customLoaderRounded={"rounded-full"}
                                            customLoaderWidth={"w-[48px]"}
                                            className={"object-contain w-[48px] h-[48px] rounded-full"}
                                            src={"/images/EmployeeProfile1.png"}
                                        />
                                        <div className="absolute bg-[#00B037] w-2.5 h-2.5  bottom-0.5 right-0.5 rounded-full border border-white"></div>
                                    </div>
                                    <div className='flex flex-col gap-0.5'>
                                        <p className='font-poppins font-medium text-base text-[#333333]'>Nik Smith</p>
                                        <div className='flex flex-row overflow-hidden text-ellipsis items-center font-public-sans text-[#4F4F4F] text-base gap-2'>
                                            <Svgs.DoubleTickIcon color={"#F33F41"} />
                                            <p>Per plate order related</p>
                                        </div>

                                    </div>
                                </div>

                                <div className='flex flex-col items-center justify-between font-public-sans text-sm'>
                                    <div className='bg-btn-theme w-[24px] h-[24px] text-white rounded-full text flex items-center justify-center'>
                                        <p>
                                            1
                                        </p>
                                    </div>
                                    <p>
                                        10:44
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className='flex flex-col lg:col-span-2'>
                        <div className="flex flex-row items-center justify-between px-4 py-3.5 border-b border-[#E0E0E0]">
                            <div className='flex flex-row items-center gap-2'>
                                <div className='relative'>

                                    <Image
                                        customLoaderHeight={"h-[48px]"}
                                        customLoaderRounded={"rounded-full"}
                                        customLoaderWidth={"w-[48px]"}
                                        className={"object-contain w-[48px] h-[48px] rounded-full"}
                                        src={"/images/profile3.png"}
                                    />
                                    <div className="absolute bg-[#00B037] border border-white w-2.5 h-2.5 bottom-0.5 right-0.5 rounded-full"></div>
                                </div>

                                <div className='flex flex-col font-public-sans'>
                                    <p className=' font-medium text-base text-[#333333]'>
                                        Jimmy
                                    </p>
                                    <p className='text-sm text-gray-4'>Online</p>
                                </div>

                            </div>
                            <button>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12C6.9 12 6 12.9 6 14ZM6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.899999 9.1 -1.35505e-07 8 -8.74228e-08C6.9 -3.93403e-08 6 0.9 6 2ZM6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6C6.9 6 6 6.9 6 8Z" fill="#333333" />
                                </svg>
                            </button>



                        </div>
                        <div className='flex flex-col px-4 py-5'>
                            <div className='flex flex-col gap-5'>

                                <div className=' flex flex-row items-center justify-center gap-4'>
                                    <div className='border border=[#E0E0E0] w-[214px]'></div>
                                    <p>Today</p>
                                    <div className='border border=[#E0E0E0] w-[214px]'></div>
                                </div>


                                <div className="flex flex-col   gap-10 font-public-sans ">
                                    <div className='flex flex-col justify-end items-end gap-2.5'>
                                        <div className='bg-btn-theme px-4 py-3 rounded-2xl rounded-br-none '>
                                            <p className='text-white'>Greetings! I just saw you sent order request</p>
                                        </div>
                                        <p className='text-right text-[#828282] text-sm'>05:21</p>
                                    </div>

                                    <div className="flex flex-row gap-3 items-start">
                                        <Image
                                            customLoaderHeight={"h-[36px]"}
                                            customLoaderRounded={"rounded-full"}
                                            customLoaderWidth={"w-[36px]"}
                                            className={"h-[36px] w-[36px] rounded-full object-contain"}
                                            src={"/images/EmployeeProfile3.png"}
                                        />
                                        <div className='flex flex-col justify-end items-start gap-2.5'>
                                            <div className='bg-[#E0E0E0] px-4 py-3 rounded-2xl rounded-bl-none bg-opacity-20'>
                                                <p className='text-[#333333]'>Hi! yes we have small gathering at home</p>
                                            </div>
                                            <div className='flex w-full items-end justify-end'>
                                                <p className='text-right text-[#828282] text-sm'>05:21</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col justify-end items-end gap-2.5'>
                                        <div className='bg-btn-theme px-4 py-3 rounded-2xl rounded-br-none '>
                                            <p className='text-white'>Oh sure, we provide better service at reasonable prices.</p>
                                        </div>
                                        <p className='text-right text-[#828282] text-sm'>05:21</p>
                                    </div>

                                    <div className="flex flex-row gap-3 items-start">
                                        <Image
                                            customLoaderHeight={"h-[36px]"}
                                            customLoaderRounded={"rounded-full"}
                                            customLoaderWidth={"w-[36px]"}
                                            className={"h-[36px] w-[36px] rounded-full object-contain"}
                                            src={"/images/EmployeeProfile3.png"}
                                        />
                                        <div className='flex flex-col justify-end items-start gap-2.5'>
                                            <div className='bg-[#E0E0E0] px-4 py-3 rounded-2xl rounded-bl-none bg-opacity-20'>
                                                <p className='text-[#333333]'>Sure here is my contact number  -  +1 989 332 4543</p>
                                            </div>
                                            <div className='flex w-full items-end justify-end'>
                                                <p className='text-right text-[#828282] text-sm'>05:21</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='pt-[72px] w-full'>

                                <div className='w-full flex flex-row border rounded-2xl px-5 py-3 border-[#E0E0E0] justify-between'>
                                    <input className='w-full !outline-none font-poppins text-base placeholder:text-[#828282]'
                                        placeholder='Write a message ...'
                                    />
                                    <button>
                                        <Svgs.SendMessageIcon />
                                    </button>


                                </div>
                            </div>



                        </div>

                    </div>

                </div>
            </RoundedCard>


        </SellerLayout>

    )
}
