import React, { createContext, useContext, useState } from 'react'

const PremiumContext = createContext()

export const PremiumContextProvider = ({ children }) => {
    const [isPremium, setIsPremium] = useState(false);

    const updatePremiumStatus = (status) => {
        setIsPremium(status)
    }


    return (
        <PremiumContext.Provider value={{ isPremium, updatePremiumStatus }}>
            {children}
        </PremiumContext.Provider>
    )
}

export const usePremium = () => useContext(PremiumContext)