import Image from 'Components/Elements/Image'
import React from 'react'

const ServiceCard = ({ imageSrc, name, type }) => {
    return (
        <div className='rounded-lg flex flex-col bg-white h-full'>
            <Image className={'w-full h-full object-cover  max-h-[200px] rounded-t-lg'} customLoaderHeight={'h-[140px]'} src={imageSrc} />
            <div className='flex justify-between gap-4 p-3'>
                <div className='flex flex-col space-y-0.5'>
                    <h1 className='font-semibold text-lg'>{name}</h1>
                    <p className='text-sm text-[#1E1E1E]'>{type}</p>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard