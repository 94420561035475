export const ORDER_STATUSES = [
    { label: 'All Orders', value: "" },
    { label: 'Recieved', value: "recieved" },
    { label: 'Packed', value: "packed" },
    { label: 'Delivered', value: "delivered" },
    { label: 'Dispacted', value: "dispatched" },
    { label: 'Cancelled', value: "cancelled" }
]

export const TIMELINE = [
    { label: 'Weekly', value: "weekly" },
    { label: 'Biweekly', value: "biweekly" },
    { label: 'Monthly', value: "monthly" },
    { label: 'Half Yearly', value: "halfyearly" },
    { label: 'Yearly', value: "yearly" },
    { label: 'Custom', value: "custom" }
]

export const TIMELINE2 = [
    { label: 'Added Last Week', value: "added_last_week" },
    { label: 'Added A Month Ago', value: "added_a_month_ago" },
    { label: 'More Than Month Ago', value: "more_than_month_ago" },
]

export const STOCK = [
    { label: 'All', value: "" },
    { label: 'In Stock', value: "in_stock" },
    { label: 'Selling Fast', value: "selling_fast" },
    { label: 'Out Of Stock', value: "out_of_stock" },
]

export const PAYMENT_STATUSES = [
    { label: 'All', value: "" },
    { label: 'Settled', value: "settled" },
    { label: 'processing', value: "processing" },
    { label: 'pending', value: "pending" },
    { label: 'Failed', value: "failed" }
]