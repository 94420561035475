import React, { useState } from 'react';
import Svgs from 'Assets/svgs';

const Accordion = ({ heading, content, index, arrayLength, className }) => {
    const [isOpen, setIsOpen] = useState();

    const toggleAccordion = (index) => {
        if (index === isOpen) {
            setIsOpen()
        } else {
            setIsOpen(index);
        }
    };
    return (
        <div className={`${className ? className : arrayLength != (index + 1) && `mb-2 border-b border-[#EEEEEE]`}`} key={index}>
            <div
                onClick={() => { toggleAccordion(index) }}
                className="cursor-pointer py-4 flex justify-between items-center"
            >
                <h2 className='text-sm'>{index + 1}.{" "}{heading}</h2>
                <span>{isOpen === index ? <Svgs.Minus /> : <Svgs.PlusFill />}</span>
            </div>
            {(isOpen === index && content) && (
                <p className='text-sm text-[#686868] mb-5'>{content}</p>
            )}
        </div>
    );
};

export default Accordion;
