import Svgs from 'Assets/svgs';
import React from 'react';

const SearchInput = ({ title, value, name, onChange, placeholder, required, parentClass }) => {
    return (
        <div className={`${parentClass ? parentClass : ""} w-full relative flex flex-col gap-2`}>

            {title && (
                <div className={`${title == '' ? 'hidden' : ''} text-sm text-primaryBlack flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Svgs.SearchIcon2 />
            </div>
            <input
                type="text"
                placeholder={placeholder ? placeholder : "Search..."}
                className="pl-10 pr-4 py-2 border border-[#EEEEEE] text-[#686868] rounded-md focus:!outline-none focus:!border-[#EEEEEE] focus:!ring-none"
                onChange={onChange && onChange}
                value={value}
                name={name}
            />
        </div>
    );
};

export default SearchInput;
